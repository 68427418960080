import { useState, useEffect } from "react";

const useResize = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  useEffect(() => {
    const checkZoom = () => {
      // Calculate zoom level based on inner width and outer width
      const zoomLevel = (window.innerWidth / window.outerWidth) * 101;
      setIsZoomed(zoomLevel < 99.9); // If zoom is beyond 110%, this condition becomes true
    };

    checkZoom();
    window.addEventListener("resize", checkZoom);

    return () => {
      window.removeEventListener("resize", checkZoom);
    };
  }, []);

  return { isZoomed };
};

export default useResize;
