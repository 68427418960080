import { BASE_URL, FILE_SERVICE_BASE_URL } from "../constant/apiUrl";
import { getAPI, postAPI } from "./ApiService";
import { useQuery } from "@tanstack/react-query";
import CryptoJS from "crypto-js";
import axios from "axios";
import {
  LOOKUP_API_FIELDS,
  CURATOR_PAGE_API_CONSTANT,
} from "../constant/constant";
import { showErrorToast } from "../utils/Toast/toast";

export const useGetFilterOptions = (filterType) => {
  const fetchFilterOptions = useQuery({
    queryKey: ["filter-options"],
    queryFn: async () => {
      const res = await getAPI(`/lookup/all?lookUpType=${filterType}`);
      return res?.data;
    },
    onError: (error) => {
      console.error("Failed to fetch filter options", error);
    },
  });

  return fetchFilterOptions;
};

export const useGetPendingApprovalList = () => {
  const fetchPendingApprovalList = useQuery({
    queryKey: ["pending-approval-list"],
    queryFn: async () => {
      const res = await getAPI(`${BASE_URL}/curator/pending-approval-list`);
      return res?.data;
    },
    onError: (error) => {
      console.error("Failed to fetch market places", error);
    },
  });

  return fetchPendingApprovalList;
};

export const getCuratorApiListByPageName = async (pageName) => {
  if (pageName === CURATOR_PAGE_API_CONSTANT.pending_approval) {
    return await getAPI(`${BASE_URL}/curator/pending-approval-list`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error;
      });
  } else if (pageName === CURATOR_PAGE_API_CONSTANT.yearly_review) {
    return await getAPI(`${BASE_URL}/curator/yearly-review-list`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const getSelectedPendingApprovalData = async (contentGuid) => {
  return await getAPI(`${BASE_URL}/curator/pending-approval/${contentGuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSelectedYearlyApprovalData = async (contentGuid) => {
  return await getAPI(`${BASE_URL}/curator/yearly-review/${contentGuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};


export const getSelectedArtificatsHitoryData = async (contentGuid) => {
  return await getAPI(`${BASE_URL}/curator/${contentGuid}/approved-history`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};


export const getSelectedYearyPendingApprivalData = async (contentGuid) => {
  return await getAPI(`${BASE_URL}/curator/yearly-review/${contentGuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const submitPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/approve-content`,
    apiPayload
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const denyPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/reject-content`,
    apiPayload
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const submitYearyPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/approve-yearly-review`,
    apiPayload
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const archiveYearlyPendingApprovalRequest = async (apiPayload) => {
  return await postAPI(
    `${BASE_URL}/curator/pending-approval/reject-yearly-review`,
    apiPayload
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// export const uploadYearlyReviewArtificatsFiles = async (
//   filePayload,
//   isLegalDoc = false
// ) => {
//   let formData = new FormData();
//   // if (isLegalDoc) {
//   //   formData.append("file", filePayload?.[0]);
//   // } else {
//   //   formData.append(
//   //     "file",
//   //     filePayload
//   //   );
//   // }
//   console.log('uploadYearlyReviewArtificatsFiles', filePayload);
//   formData.append("file", filePayload?.[0]);

//   return await postAPI(
//     // `${BASE_URL}/file-manager/upload`,
//     `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/upload?user=ADMIN`,
//     formData,
//     {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     }
//   )
//     .then((response) => {
//       if (response?.status !== 200) {
//         return {
//           error: true,
//           message: response?.data?.msg,
//           status: response?.status
//         }
//       } else {
//         return {
//           response: response?.data,
//           status: response?.status
//         };
//       }

//     })
//     .catch((error) => {
//       console.log("uploadYearlyReviewArtificatsFiles data", error);
//       return error;
//     });
// };

export const deleteCuratorFile = async (fileId) => {
  if (!fileId) {
    console.error("File ID is required to delete the file.");
    return;
  }

  try {
    const response = await axios.delete(
      `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error deleting the file:", error);
  }
};

export const downloadFileToLocal = async (contentId, isFileId = false) => {
  const token = localStorage.getItem("auth_token");
  try {
    // Step 1: Get the range information
    const rangeResponse = await axios.get(
      `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/download/blob-range-info/${contentId}?isFileId=${isFileId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const totalParts = rangeResponse.data;

    if (!totalParts || totalParts <= 0) {
      throw new Error("No parts available to download.");
    }

    // Step 2: Make parallel calls to download the parts
    const downloadPromises = [];

    for (let index = 0; index < totalParts; index++) {
      downloadPromises.push(
        axios.get(
          `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/download/blob-range/${contentId}/${index}?isFileId=${isFileId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/octet-stream",
            },
            responseType: "arraybuffer",
          }
        )
      );
    }

    const responses = await Promise.all(downloadPromises);
    const fileName = responses[0].headers["file-name"];
    const fileExtension = fileName.split(".").pop();

    // Step 3: Combine the downloaded parts into one Blob
    const combinedBlob = new Blob(responses.map((response) => response.data), {
      type: responses[0].headers["content-type"] || fileExtension === 'pdf' ? "application/pdf" : "application/octet-stream",
    });

    // Step 4: Trigger file download
    const url = URL.createObjectURL(combinedBlob);

    if (fileExtension === "pdf") {
      window.open(url, "_blank");
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName; // Set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return {
      status: 200,
      data: [],
    };
  } catch (error) {
    console.error("Error downloading file:", error);
    showErrorToast("Failed to download file");
    return null;
  }
};

export const curatorPageLookUpFirstApiCall = async () => {
  let curtorLookup = {
    specialProjects: [],
    subjects: [],
    sectors: [],
    countries: [],
    language: [],
    subjectMatterExpert: [],
  };
  return Promise.allSettled([
    getSpecialProjectLookUpData(),
    getSubjectLookUpData(),
    getSectorLookUpData(),
    getCountriesLookUpData(),
    getLanguageLookUpData(),
  ])
    .then((response) => {
      if (response.length > 0) {
        curtorLookup = {
          specialProjects: response?.[0].value,
          subjects: response?.[1].value,
          sectors: response?.[2].value,
          countries: response?.[3].value,
          language: response?.[4].value,
        };
      }

      return curtorLookup;
    })
    .catch((error) => {
      console.log("curatorPageLookUpFirstApiCall error ", error);
      return error;
    });
};

export const curatorPageLookUpSecondApiCall = async () => {
  let curtorLookup = {
    disclaimer: [],
    periods: [],
    subjectMatterExpert: [],
    cropType: [],
    visbility: [],
    contentLevel: [],
  };
  return Promise.allSettled([
    getDisclaimerLookUpData(),
    getPeriodLookUpData(),
    getSMELookupData(),
    getCropTypeLookupData(),
    getVisibilityLookup(),
    getContentLevelLookup(),
  ])
    .then((response) => {
      if (response.length > 0) {
        curtorLookup = {
          disclaimer: response?.[0].value,
          periods: response?.[1].value,
          subjectMatterExpert: response?.[2].value,
          cropType: response?.[3].value,
          visibility: response?.[4].value,
          contentLevel: response?.[5].value,
        };
      }

      return curtorLookup;
    })
    .catch((error) => {
      console.log("curatorPageLookUpSecondApiCall error ", error);
      return error;
    });
};

export const getSpecialProjectLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.SPECIAL_PROJECTS}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSubjectLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.SUBJECT}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSectorLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.SECTOR}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCountriesLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.COUNTRY}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getLanguageLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.LANGUAGE}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDisclaimerLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.DISCLAIMER}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPeriodLookUpData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.PERIOD}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getCropTypeLookupData = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.CROP_TYPE}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getVisibilityLookup = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.VISIBILITY}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getContentLevelLookup = async () => {
  return await getAPI(
    `${BASE_URL}/lookup/all?lookUpType=${LOOKUP_API_FIELDS?.CONTENT_LEVEL}`
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getSMELookupData = async () => {
  return await getAPI(`${BASE_URL}/sme/list-sme`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllSubjectSectorCountryList = async () => {
    try {
      return await getAPI(`${BASE_URL}/lookup/find-all-sector-country`)
        .then((response) => {
          return response?.data?.response?.sectors;
        })
        .catch((error) => {
          console.log("counry based on sector list error", error);
        })
    } catch (error) {
      console.log("Error", error);
    }
};

export const uploadYearlyReviewArtificatsFiles = async (acceptedFiles) => {
    const token = localStorage.getItem('auth_token');
    const MAX_FILE_SIZE = 250 * 1024 * 1024;
    const file = acceptedFiles?.[0];

    if (!file) return;
    if (file?.size > MAX_FILE_SIZE) {
      showErrorToast(
        "File size exceeds the 250 MB limit. Please upload a smaller file."
      );
      // alert("File size exceeds the 250 MB limit. Please upload a smaller file.");
      return;
    }
    
    try {
      // Step 1: Initiate upload
      const isVideo = file.type.startsWith("video/");
      const chunkSize = 5 * 1024 * 1024; // 5MB
      const initResponse = await postAPI(
        `${FILE_SERVICE_BASE_URL}/fritolay/upload/init`,
        {
          fileName: file?.name,
          fileSize: file?.size,
          chunkSize,
        }
      );
      console.log("upload initiated", initResponse.data);
      const { fileId, chunkCount } = initResponse.data;
      const chunks = Math.ceil(file?.size / chunkSize);
      const chunkUploadPromises = [];

      // Step 2: Upload each chunk
      for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(file?.size, start + chunkSize);
        const chunk = file?.slice(start, end);

        const chunkArrayBuffer = await chunk.arrayBuffer();
        const checksum = CryptoJS.MD5(
          CryptoJS.lib.WordArray.create(chunkArrayBuffer)
        ).toString(CryptoJS.enc.Base64);

        chunkUploadPromises.push(
          axios.put(
            `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/chunk/${i}`,
            chunk,
            {
              headers: {
                fileId,
                chunkIndex: i,
                checksum,
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
        );
      }

      await Promise.all(chunkUploadPromises);

        // Step 3: Verify each chunk
        const computeChunkChecksum = (chunk) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    try {
                        const wordArray = CryptoJS.lib.WordArray.create(reader.result);
                        const checksum = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
                        resolve(checksum);
                    } catch (err) {
                        reject(err);
                    }
                };

                reader.onerror = () => reject(reader.error);
                reader.readAsArrayBuffer(chunk); // Efficient, does not load entire file at once
            });
        };

        const chunkVerificationPromises = [];
        let allChunkChecksums = []; // Array to store all chunk checksums

        for (let i = 0; i < chunks; i++) {
            const chunk = file.slice(i * chunkSize, Math.min(file.size, (i + 1) * chunkSize));

            // Handle chunk checksum and upload
            const checksumPromise = computeChunkChecksum(chunk).then((checksum) => {
                allChunkChecksums.push(checksum); // Store the checksum for later
                return axios.post(
                    `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/chunk/${i}/complete`,
                    { checksum },
                    {
                        headers: {
                            fileId,
                            chunkIndex: i,
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                        },
                    }
                );
            });

            chunkVerificationPromises.push(checksumPromise);

            // Once we have 5 promises, wait for them to settle
            if (chunkVerificationPromises.length >= 5) {
                await Promise.allSettled(chunkVerificationPromises);
                chunkVerificationPromises.length = 0;  // Reset the batch
            }
        }

        // Wait for all remaining promises to settle
        await Promise.allSettled(chunkVerificationPromises);

        // Calculate final checksum by combining all chunk checksums
        const finalChecksum = CryptoJS.MD5(allChunkChecksums.join('')).toString(CryptoJS.enc.Base64);

        // Final completion request with final checksum
      
      return await axios.post(
            `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/complete`,
            {
                fileName: file.name,
                finalChecksum,
            },
            {
                headers: {
                    fileId,
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            }
      );

    } catch (error) {
      console.error("Error uploading file:", error);
    } 
  };
