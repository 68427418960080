import React from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import { useSelector } from "react-redux";
import Loading from "../utils/Loader";
import { setPotatoQuality } from "../slices/internalPageSlice";
import useFetchInternal from "../hooks/useFetchInternal";

export default function PotatoQuality() {
  const { potatoQuality } = useSelector((store) => store.internalPage);
  const userId = 1;

  const { loading } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Potato%20Quality&userId=${userId}`,
    setPotatoQuality,
    potatoQuality
  );

  if (loading) return <Loading data-testid="loading" />;

  return (
    <InternalPageLayout
      pageData={potatoQuality}
      header="Potato quality"
      data-testid="potato-quality"
    />
  );
}

React.memo(PotatoQuality);
