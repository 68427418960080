import { Box, Typography } from "@mui/material";
import React from "react";
import DropDown from "../DropDown/DropDown";
import RenderPage from "../RenderPage/RenderPage";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";

export default function Administration() {
  const isDarkTheme = useSelector((state) => state.theme);

  return (
    <div
      className="px-[83px] py-4 "
      style={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          boxSizing: "border-box",
          width: "100%",
          padding: "var(--Spacing-spacing-24, 24px)",
          gap: "var(--Spacing-spacing-24, 24px)",
        }}
      >
        <Typography
          sx={{
            alignSelf: "stretch",
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFeatureSettings: "'liga' off, 'clig' off", // Disabling specific font features
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
            fontSize: "var(--Font-Size-H5, 28px)", // Font size for H5
            fontStyle: "normal", // Normal style
            fontWeight: 700, // Bold font weight
            lineHeight: "var(--Line-Height-H5, 32px)", // Line height
          }}
        >
          Administration
        </Typography>
        <DropDown />
        <div style={{ width: "100%" }}>
          <RenderPage />
        </div>
      </Box>
    </div>
  );
}
