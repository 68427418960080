import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Typography, styled, Button, useMediaQuery } from "@mui/material";
import styles from "../Style/varietyDetailStyle";
import {
  cardContainerStyles,
  headerBoxStyles,
  headerTextStyles,
  fieldContainerStyles,
  fieldBoxStyles,
  fieldTextLabelStyles,
  fieldTextContentStyles,
} from "../CountrySection/varietyCardStyle"; // Import styles
import { useDispatch } from "react-redux";
import {
  openDrawer,
  setEdit,
} from "../../../../../slices/potatoProtectionSlice";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../../../theme/Theme";
import DateFormat from "../../../../../utils/DateFormat";
import { formatDate } from "../../../../../utils/function";
// Use styled component with extracted styles

export default function VarietyDetailCard({ variety }) {
  const dispatch = useDispatch();
  const HandleOpen = () => {
    dispatch(openDrawer());
    dispatch(setEdit(true));
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const StyledBox = styled(Box)({
    ...cardContainerStyles,
    backgroundColor: isDarkTheme
      ? darkTheme.backgroundColorLayer3
      : "var(--Solid-White, #FFF)",
    boxShadow: isDarkTheme ? "none" : cardContainerStyles.boxShadow,
  });
  return (
    <StyledBox>
      <Box sx={headerBoxStyles}>
        <Typography
          sx={
            isMobile
              ? {
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : "var(--Tokens-Text-Primary, #3A3A3A)", // Default text color
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family, default to Inter
                  fontSize: "var(--Font-Size-Body, 16px)", // Font size, default to 16px
                  fontStyle: "normal", // Font style (normal)
                  fontWeight: 700, // Font weight (bold)
                  lineHeight: "var(--Line-Height-Body, 22px)",
                  wordBreak: "break-word",
                }
              : {
                  ...headerTextStyles,
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : headerTextStyles.color,
                }
          }
        >
          {variety?.variety}
        </Typography>
        <Button
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2, // Equivalent to 'gap-2' in Tailwind (theme spacing unit)
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
          }}
          onClick={HandleOpen}
        >
          <EditOutlinedIcon sx={{ fill: "#005CBC" }} />
          <Typography sx={styles.backText}>Edit</Typography>
        </Button>
      </Box>

      <Box
        sx={
          isDarkTheme
            ? {
                ...fieldContainerStyles,
                backgroundColor: darkTheme.backgroundColorLayer1,
              }
            : fieldContainerStyles
        }
      >
        <div className="flex flex-col gap-3 w-full ">
          <Box
            sx={
              isMobile
                ? {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }
                : fieldBoxStyles
            }
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "50%",
                padding: "8px 16px", // Equivalent to `p-2 px-4`
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "8px", // Equivalent to `gap-2`
                flex: isMobile ? "unset" : "1",
                backgroundColor: isDarkTheme
                  ? darkTheme.insideCardBackgroundColorLayer3
                  : "",
              }}
            >
              <Typography
                sx={
                  isDarkTheme
                    ? {
                        ...fieldTextLabelStyles,
                        color: darkTheme.textColorLayer2,
                      }
                    : fieldTextLabelStyles
                }
              >
                Date of first worldwide sale
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              >
                <Typography
                  sx={
                    isDarkTheme
                      ? {
                          ...fieldTextContentStyles,
                          color: darkTheme.textColorLayer2,
                        }
                      : fieldTextContentStyles
                  }
                >
                  {variety?.firstSaleDate === null
                    ? null
                    : formatDate(variety?.firstSaleDate)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: isMobile ? "100%" : "50%",
                padding: "8px 16px", // Equivalent to `p-2 px-4`
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "8px", // Equivalent to `gap-2`
                flex: isMobile ? "unset" : "1",
                backgroundColor: isDarkTheme
                  ? darkTheme.insideCardBackgroundColorLayer3
                  : "",
              }}
            >
              <Typography
                sx={
                  isDarkTheme
                    ? {
                        ...fieldTextLabelStyles,
                        color: darkTheme.textColorLayer2,
                      }
                    : fieldTextLabelStyles
                }
              >
                Country of first worldwide sale
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              >
                <Typography
                  sx={
                    isDarkTheme
                      ? {
                          ...fieldTextContentStyles,
                          color: darkTheme.textColorLayer2,
                        }
                      : fieldTextContentStyles
                  }
                >
                  {variety?.firstSaleCountry}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "50%",
              padding: "8px 16px", // Equivalent to `p-2 px-4`
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "8px", // Equivalent to `gap-2`
              flex: isMobile ? "unset" : "1",
              backgroundColor: isDarkTheme
                ? darkTheme.insideCardBackgroundColorLayer3
                : "",
            }}
          >
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              Comments
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <Typography
                sx={
                  isDarkTheme
                    ? {
                        ...fieldTextContentStyles,
                        color: darkTheme.textColorLayer2,
                      }
                    : fieldTextContentStyles
                }
              >
                {variety?.comments}
              </Typography>
            </Box>
          </Box>
        </div>
      </Box>
    </StyledBox>
  );
}
