import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Typography, TextField, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import { getAllSubjectSectorCountryList } from "../../API/curatorApi";
import CustomSelect from "./CustomSelect";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { setDisableNextButton } from "../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import {  getDropDownOptionValues, getCountryList } from "../../utils/function";
import { Dropdown, TextArea, TextInput } from "pepsico-ds";
import './ExternalStepTwoForm.css';

const quillModules = {
  // toolbar: [
  //   ["bold", "italic", "underline"],
  //   [{ align: "" }, { align: "center" }, { align: "right" }],
  //   [{ list: "ordered" }, { list: "bullet" }],
  // ],
  toolbar: false,
};
const optimizedStyles = (isDarkTheme) => `
 .editor-container {
    position: relative;
    width:100%;
  }

  .editor-container .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    box-shadow: none; 
  }

  .editor-container .ql-container {
    height: 200px; 
    width: 100%;
    border: 1px solid #C0C0C0; 
    border-radius:4px;
    box-shadow: none;
    outline: none; 
    color: ${
      isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
    };
  }
.editor-container .ql-container .ql-editor.ql-blank::before {
    color: ${
      isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4
    };
  }
  .editor-container .ql-container:before {
  content: '';
  position: absolute;
  top: -1px; 
  left: 0;
  right: 0;
  height: 1px;
  background-color: #C0C0C0;
  border-radius: 4px;
}
`;
const ExternalStepTwoForm = ({
  externalData,
  setExternalData,
  handleDropDown,
}) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const { sectorFilter, countryFilter, subjectFilter } = useSelector(
    (store) => store.internalFilter
  );
  const [sectorData, setSectorData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [secotrByCountrylist, setSectorByCountryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleDocumentNotesChange = (value) => {
    setExternalData((prevData) => ({
      ...prevData,
      notes: value,
    }));
  };

  useEffect(() => {
    getAllSubjectSectorCountryList()
      .then((data) => {
        console.log("counry based on sector list", data);
        setSectorByCountryList(data);
      })
      .catch((error) => {
        console.log("counry based on sector list error", error);
      });
    setSectorData(getDropDownOptionValues(sectorFilter));
    setSubjectData(getDropDownOptionValues(subjectFilter, true));
    setCountryData(getDropDownOptionValues(countryFilter));
  }, []);
  if (typeof window !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = optimizedStyles(isDarkTheme);
    document.head.appendChild(styleSheet);
  }
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };
  const [selectedSectorData, setSelectedSectorData] = useState([]);
  const [selectedCountryData, setSelectedCountryData] = useState([]);
  const [selectedSubjectData, setSelectedSubjectData] = useState([]);

  useEffect(() => {
    const selectedData = sectorData?.filter((item) =>
      externalData?.sectorIds?.includes(item?.id)
    );
    setSelectedSectorData(selectedData);
  }, [externalData?.sectorIds, sectorData]);
  useEffect(() => {
    const selectedData = subjectData?.filter((item) =>
      externalData?.subjectIds?.includes(item?.id)
    );
    setSelectedSubjectData(selectedData);
  }, [externalData?.subjectIds, subjectData]);
  useEffect(() => {
    const selectedData = countryData?.filter((item) =>
      externalData?.countryIds?.includes(item?.id)
    );
    setSelectedCountryData(selectedData);
  }, [externalData?.countryIds, countryData]);
    useEffect(() => {
      if (countryData.length === 0 && externalData?.sectorIds?.length > 0) {
        const countryList = getCountryList(externalData?.sectorIds, secotrByCountrylist);
        setCountryData(getDropDownOptionValues(countryList, true));
      }
    }, [secotrByCountrylist]);

  const handleSelect = (selectedItem, catagory) => {
      const ids = selectedItem ? selectedItem.map((item) => item.id) : [];
      if (catagory === "sectorIds" && ids.length > 0) {
        const countryList = getCountryList(ids, secotrByCountrylist);
        setCountryData(getDropDownOptionValues(countryList, true));
      } else if (catagory === "sectorIds" && ids.length === 0) {
        setCountryData([]);
      }
      handleDropDown(catagory, ids);
    };
  const options = {
    subject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    country: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    sector: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    specialProject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
  };
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,

    width: "100%",
  };
  const renderEditor = (value, onChange, placeholder) => (
    <Box className="editor-container">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={quillModules}
        style={{ height: "100%", width: "100%" }}
      />
    </Box>
  );
  const handleTitleChange = (value) => {
    setExternalData((prevData) => ({
      ...prevData,
      contentTitle: value,
    }));
  };
  useEffect(() => {
    dispatch(setDisableNextButton(true));
  }, []);

  return (
    <Box
    className="external-step-two-form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        ...sectionStyles,
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 2: Metadata
      </Typography>
      <Box sx={{width: {md:"400px",xs :"100%"}}}> 
        <TextInput
          label="Content title"
          value={externalData?.contentTitle}
          onUpdate={handleTitleChange}
          placeholderText="Add Title"
          isDark={isDarkTheme}
          inputSize="medium"
          style={{ fontSize: "var(--Font-Size-small, 14px)", width: "100%"  }}
        />
      </Box>

    
      <Box sx={{width: {md:"400px",xs :"100%"}}}> 

          {subjectData && (
            <Dropdown
              childList={subjectData.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isClearable
              isRequired
              label="Subjects"
              selection="multiple"
              setSelectedValue={(values) => handleSelect(values, "subjectIds")}
              size={isMobile ? "small" : "medium"}
              sx={{
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-XSmall, 12px)",
                fontStyle: "normal",
                fontWeight: 400,
                width: "100%",
                lineHeight: "var(--Line-Height-XSmall, 18px)",
              }}
              style={{ fontSize: "var(--Font-Size-Small, 14px)", maxWidth: "50rem" , width: "100%",}}
              selectedValue={selectedSubjectData?.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isDark={isDarkTheme}
            />
          )}
      </Box>
      <Box sx={{width: {md:"400px",xs :"100%"}}}> 
          {sectorData && (
            <Dropdown
              childList={sectorData.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isClearable
              isRequired
              label="Sectors"
              selection="multiple"
              setSelectedValue={(values) => handleSelect(values, "sectorIds")}
              size={isMobile ? "small" : "medium"}
              style={{ fontSize: "var(--Font-Size-small, 14px)" , maxWidth: "50rem", width: "100%",}}
              selectedValue={selectedSectorData?.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isDark={isDarkTheme}
            />
          )}
        </Box>
        <Box sx={{width: {md:"400px",xs :"100%"}}}> 
          {countryData && (
            <Dropdown
              childList={countryData.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isClearable
              isRequired
              label="Countries"
              selection="multiple"
              setSelectedValue={(values) => handleSelect(values, "countryIds")}
              size={isMobile ? "small" : "medium"}
              style={{
                fontSize: "var(--Font-Size-small, 14px)",
                maxWidth: "50rem",
                width: "100%",
              }}
              selectedValue={selectedCountryData?.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isDark={isDarkTheme}
            />
          )}
       </Box>

<Box sx={{width: {md:"400px",xs :"100%"}}}><TextArea
  helperText=""
  label="Document Notes"
  onUpdate={handleDocumentNotesChange}
  placeholder="Type here"
  showCharacterCount={false}
  showLabelIcon={false}
  showToolbar
  value={externalData.notes}
/>
        </Box>
    </Box>
  );
};

export default ExternalStepTwoForm;
