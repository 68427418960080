import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import DefaultPage from "../Screens/DefaultPage";
import ExternalForm from "../Screens/ExternalAdminInvitationForm/ExternalForm";
import InternalForm from "../Screens/InternalAdminInvitationForm/InternalForm";
import InvitationHistory from "../Screens/InvitationHistory-PendingApproval/InvitationHistoryPending";
import Spotlight from "../Screens/Spotlight/Spotlight";
import Subjects from "../Screens/Subjects/Subjects";
import SME from "../Screens/SME/SME";
import { resetSelectedPage, setReset } from "../../../slices/adminPageSlice";
import LandingSection from "../Screens/LandingPageHero.js/LandingSection";
import SpecialProject from "../Screens/SpecialProject/SpecialProject";
import ListOfValues from "../Screens/ListOfValues/ListOfValues";

export default function RenderPage(props) {
  const { selectedPage, resetpage } = useSelector((store) => store.adminpage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (resetpage) {
      return () => {
        dispatch(resetSelectedPage());
      };
    } else {
      dispatch(setReset(true));
    }
  }, []);

  const getComponentProps = (selectedPage) => {
    switch (selectedPage) {
      case "In the spotlight":
        return {
          flexDirection: props.flexDirection,
          width: props.width,
        };

      default:
        return {}; // Return an empty object if no specific props are needed.
    }
  };

  const renderPage = () => {
    const pageProps = getComponentProps(selectedPage); // Get the specific props for the page.

    switch (selectedPage) {
      case "Subject matter experts":
        return <SME />;
      case "Send invitation: external partners":
        return <ExternalForm />;
      case "Send invitation: internal users":
        return <InternalForm />;
      case "Access: invitation history":
        return <InvitationHistory header="Access:invitation history" />;
      case "In the spotlight":
        return <Spotlight {...pageProps} />;
      case "Subjects":
        return <Subjects />;
      case "Landing page hero":
        return <LandingSection />;
      case "Access: pending approvals":
        return <InvitationHistory header="Pending approvals" />;
      case "Access":
        return <InvitationHistory header="Access history" />;
      case "Special projects":
        return <SpecialProject />;
      case "List of values":
        return <ListOfValues />;
      default:
        return <DefaultPage text={"Please select administration type."} />;
    }
  };

  return <div className="w-full">{renderPage()}</div>;
}

// Prop validation
RenderPage.propTypes = {
  flexDirection: PropTypes.string, // 'flexDirection' should be a string (optional)
  width: PropTypes.string, // 'width' should be a string (optional)
};
