import React from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Popper,
} from "@mui/material";
import { useSelector } from "react-redux";
import commonStyles from "../../components/PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import { autoCompletStyles } from "../FieldStyles/FieldStyles";
import { darkTheme, lightTheme } from "../../theme/Theme";
import ArrowDownwardIcon from "@mui/icons-material/KeyboardArrowDown";

export default function AutoCompleteField({
  label,
  options,
  isMobile,
  errors,
  name,
  placeholder,
  width,
  required,
  onChange,
  value,
  register,
  width1,
  datatestid = "auto-input",
}) {
  const isDarkTheme = useSelector((store) => store.theme);

  const hasError = !!errors[name];
  console.log(hasError, "-----hasError------");

  return (
    <Box sx={{ ...commonStyles.container }}>
      {/* Label Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: width1
            ? { xs: "100%", md: width1 }
            : { xs: "100%", md: "400px", lg: "400px" },
        }}
      >
        <Typography
          sx={{
            ...commonStyles.typographyLabel,
            color: hasError
              ? "red"
              : isDarkTheme
              ? darkTheme.textColorLayer1
              : "var(--Tokens-Text-Primary, #3A3A3A)",
          }}
        >
          {label}
        </Typography>
        {required && (
          <Typography
            sx={{
              ...commonStyles.typographyRequiredLabel,
              color: hasError
                ? "red"
                : isDarkTheme
                ? darkTheme.textColorLayer1
                : "var(--Tokens-Text-Primary, #3A3A3A)",
            }}
          >
            Required *
          </Typography>
        )}
      </Box>
      {/* Autocomplete Field */}
      <Autocomplete
        popupIcon={<ArrowDownwardIcon />}
        disablePortal
        id={`autocomplete-${name}`}
        options={options}
        getOptionLabel={(option) => option.label || ""}
        value={value || null}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        clearIcon={null} // Removes the cross button
        clearOnEscape={false} // Prevents clearing when pressing ESC
        data-testid={datatestid}
        PopperComponent={(props) => (
          <Popper
            {...props}
            sx={{
              "& .MuiAutocomplete-listbox": {
                background: isDarkTheme
                  ? darkTheme.backgroundColorLayer3
                  : lightTheme.backgroundColorLayer3,

                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
                "& .MuiAutocomplete-option:hover": {
                  backgroundColor: isDarkTheme
                    ? darkTheme.dropdownHoverColorLayer3
                    : lightTheme.dropdownHoverColorLayer3,
                },
              },
            }}
          />
        )}
        ListboxProps={{
          sx: {
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer1
              : "white",
            "& .MuiAutocomplete-option": {
              color: isDarkTheme ? "white" : "var(--color-Text-Primary)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "var(--Line-Height-Small, 20px)",
              "&:hover": {
                backgroundColor: isDarkTheme ? "#333" : "#f0f0f0",
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register(name)}
            size="small"
            placeholder={placeholder ?? ""}
            sx={autoCompletStyles(isDarkTheme, isMobile, darkTheme, width)}
            // error={!!errors[name]}
            autoComplete="off"
            // helperText={errors[name] ? errors[name].message : ""}
          />
        )}
      />
    </Box>
  );
}
