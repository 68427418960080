import React from "react";
import { Box, Typography, Button } from "@mui/material";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { darkTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";
import useThemeRender from "../../theme/useThemeRender";
export default function SubjectMatterCards({ data }) {
  const isDarktheme = useSelector((state) => state.theme);
  const handleClick = (item) => {
    console.log(item, "---aditya------");
    window.open(item, "_blank");
    // window.location = `${item}`;
  };

  console.log(data, "aditya");

  return (
    <Box
      sx={{
        display: "flex",
        width: { xs: "100%", md: "299px" },
        height: "auto",
        padding: "24px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "8px",
        backgroundColor: isDarktheme
          ? darkTheme.insideCardBackgroundColorLayer1
          : "#FFF",
        boxShadow: `0px 2px 24px 8px ${useThemeRender("boxShadowlayerColor1")}`, // Elevation
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <Typography
          sx={{
            color: useThemeRender("textColorLayer2"),
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Body, 16px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-Body, 22px)",
          }}
        >
          {data?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-8, 8px)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-8, 8px)",
          flex: "1 0 0",
          alignSelf: "stretch",
          background: isDarktheme
            ? darkTheme.insideCardBackgroundColorLayer2
            : "var(--Tokens-Background-Default, #F3F7FD)", // Set background
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-4, 4px)",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-2, 2px)",
            alignSelf: "stretch",
            borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
            background: isDarktheme
              ? darkTheme.insideCardBackgroundColorLayer2
              : "var(--Tokens-Background-Layer-02, #EFF5FC)",
          }}
        >
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer2
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            Organization
          </Typography>
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer1
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-XSmall, 12px)",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "var(--Line-Height-XSmall, 18px)",
            }}
          >
            {data?.organization}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-4, 4px)",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-2, 2px)",
            alignSelf: "stretch",
            borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
            background: isDarktheme
              ? darkTheme.insideCardBackgroundColorLayer2
              : "var(--Tokens-Background-Layer-02, #EFF5FC)",
          }}
        >
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer2
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            Expertise
          </Typography>
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer1
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-XSmall, 12px)",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "var(--Line-Height-XSmall, 18px)",
            }}
          >
            {data?.role}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-4, 4px)",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-2, 2px)",
            alignSelf: "stretch",
            borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
            background: isDarktheme
              ? darkTheme.insideCardBackgroundColorLayer2
              : "var(--Tokens-Background-Layer-02, #EFF5FC)",
          }}
        >
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer2
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            Email
          </Typography>
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer1
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-XSmall, 12px)",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "var(--Line-Height-XSmall, 18px)",
            }}
          >
            {data?.email}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-4, 4px)",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-2, 2px)",
            alignSelf: "stretch",
            borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
            background: isDarktheme
              ? darkTheme.insideCardBackgroundColorLayer2
              : "var(--Tokens-Background-Layer-02, #EFF5FC)",
          }}
        >
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer2
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            Phone Number
          </Typography>
          <Typography
            sx={{
              color: isDarktheme
                ? darkTheme.textColorLayer1
                : "var(--Tokens-Text-Secondary, #616161)",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-XSmall, 12px)",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "var(--Line-Height-XSmall, 18px)",
            }}
          >
            {data?.phone}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <Button
          sx={{
            display: "flex",
            height: "var(--Spacing-spacing-32, 32px)", // Uses custom spacing variable
            gap: "4px",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          disabled={data.linkedinLink === ""}
          onClick={() => handleClick(data?.linkedinLink)}
        >
          <Typography
            sx={{
              color:
                data.linkedinLink === ""
                  ? "gray"
                  : "var(--Tokens-Button-Borderless, #005CBC)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
              textTransform: "none",
            }}
          >
            View on Linkedin
          </Typography>
          <LaunchOutlinedIcon
            sx={{
              fill: data?.linkedinLink === "" ? "grey" : "#005CBC",
              width: "20px",
              height: "20px",
            }}
          />
        </Button>
      </Box>
    </Box>
  );
}
