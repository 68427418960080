import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../theme/Theme";
import useThemeRender from "../theme/useThemeRender";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import Card from "./Card";
import DefaultPage from "../components/Administration/Screens/DefaultPage";
const ListWrapper = styled(Box)(({ isDarkTheme }) => ({
  display: "flex",
  padding: "var(--Spacing-spacing-24, 24px)",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--Spacing-spacing-16, 16px)",
  flexShrink: 0,
  borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
  background: useThemeRender("backgroundColorLayer3"),
  boxShadow:
    "-10px 0px 24px 0px rgba(0, 92, 188, 0.08), var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
}));

const CardHeader = styled(Typography)(({ theme, isDarkTheme }) => ({
  color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
  fontSize: "var(--Font-Size-H4, 28px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-H4, 40px)",
}));

const ListComponent = ({ title, data }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const navigate = useNavigate();

  const handleNavigate = (item) => {
    navigate(`/frito-forum-detail-page/${item?.id}`, { state: item });
  };

  return (
    <ListWrapper data-testid="list-component">
      <CardHeader
        variant="body2"
        isDarkTheme={isDarkTheme}
        data-testid="list-title"
      >
        {title}
      </CardHeader>
      {data?.length > 0 ? (
        <List
          sx={{
            width: "100%",
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            height: "auto",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
          data-testid="list-container"
        >
          {data?.length &&
            data?.map((item) => (
              <Card
                data-testid={`card-${item.id}`}
                key={item.id}
                thumbnailFileId={item?.thumbnailFileId}
                title={item?.title}
                description={item?.description}
                onButtonClick={() => handleNavigate(item)}
              />
            ))}
        </List>
      ) : (
        <Box
          data-testid="empty-list"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DefaultPage text={"No Records Added"} />
        </Box>
      )}
    </ListWrapper>
  );
};

ListComponent.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ListComponent;
