// import styled from "styled-components";
import styled from '@mui/system/styled';
import { Box, Typography } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";

  export const TableWrapper =  styled(Box)(({ theme }) => ({
    display: "flex",
    padding: "24px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: theme.spacing(2),
    background: useThemeRender("backgroundColorLayer3"),
    boxShadow:
      "var(--Elevation-X-Elevation-02, 0px) var(--Elevation-Y-Elevation-02, 4px) var(--Elevation-Blur-Elevation-02, 24px) var(--Elevation-Spread-Elevation-02, 0px) var(--Elevation-Colors-Elevation-02, rgba(58, 58, 58, 0.08))",
          minHeight: "400px"
  }));

  export const TableHeader =  styled(Typography)(({ theme }) => ({
    color: useThemeRender("textColorLayer1"),
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
    fontSize: "var(--Font-Size-H5, 24px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-H5, 32px)",
  }));