import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import CustomSearch from "../../utils/CustomSearch/CustomSearch";
import { Button } from "pepsico-ds";
import {
  commonButtonStyle,
  commonTextStyle,
} from "../PotatoProtection/LargeScreen/Landing/potatoprotectstyle";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import UploadHistoryTable from "./UploadHistoryTable";
import { useSelector } from "react-redux";
import { darkTheme } from "../../theme/Theme";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import Loading from "../../utils/Loader";
import {
  escapeCsvValue,
  formatDate,
  getTheCsvArrayValue,
  sortTableData,
} from "../../utils/function";
export default function UploadPendigHistory() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const isDarkTheme = useSelector((store) => store.theme);
  const { flagUser } = useSelector((store) => store.user);
  const handleSearch = (value) => {
    setSearchText(value);
  };
  useEffect(() => {
    setSearchText("");
    setLoading(true);
    fetchHistory().then((data) => {
      if (data?.status === 200) {
        const fecData = data?.data?.map((item) => {
          return { ...item, id: item.contentId };
        });
        const newData = sortTableData(fecData, "title");

        setRows(newData);
      }
    });
  }, []);
  const fetchHistory = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/user-upload/content-history?userId=ADMIN`
      );
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const handleExportCsv = () => {
    const csvRows = [];
    const headers = [
      "Content Title",
      "Content Type",
      "Status",
      "Sector",
      "Country",
      "Language",
      "Subject",
      "Visibility",
      "Upload date",
      "Document notes",
    ];
    csvRows.push(headers.join(","));
    rows.forEach((row) => {
      const values = [
        escapeCsvValue(row.title),
        escapeCsvValue(row.type),
        escapeCsvValue(row.status),
        escapeCsvValue(getTheCsvArrayValue(row.sector)),
        escapeCsvValue(getTheCsvArrayValue(row.country)),
        escapeCsvValue(row.language),
        escapeCsvValue(getTheCsvArrayValue(row.subject)),
        escapeCsvValue(row.visibility),
        escapeCsvValue(formatDate(row.uploadedDate)),
        escapeCsvValue(row.documentNotes),
      ];
      csvRows.push(values.join(","));
    });
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "upload_History";
    a.click();
    URL.revokeObjectURL(url);
  };
  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding: "24px", // Equivalent to var(--Spacing-spacing-24, 24px)
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px", // Equivalent to var(--Spacing-spacing-16, 16px)
        borderRadius: "8px", // Equivalent to var(--Corner-radius-effect-corner-2, 8px)

        background: isDarkTheme ? darkTheme.backgroundColorLayer1 : "#FBFCFE",
        // Box Shadow
        boxShadow: "0px 0px 24px 0px rgba(0, 92, 188, 0.08)", // Elevation settings
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "" : "flex-end",
          gap: isMobile ? "16px" : "8px",
          alignSelf: "stretch",
          borderBottom: isMobile ? "" : "1px solid #DFDFDF",
          height: isMobile ? "" : "40px",
        }}
      >
        <CustomSearch handleSearch={handleSearch} />
        <Button
          style={{ ...commonButtonStyle, width: isMobile ? "100%" : "" }}
          onClick={handleExportCsv}
        >
          <Typography sx={commonTextStyle}>
            <SaveAltOutlinedIcon sx={{ height: "20px", width: "20px" }} />
            Export
          </Typography>
        </Button>
      </Box>
      <UploadHistoryTable searchText={searchText} rows={rows} />
    </Box>
  );
}
