import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import useThemeRender from "./../../../theme/useThemeRender";
import PropTypes from "prop-types";
const MessageBox = ({
  open,
  handleCloseDialog,
  message,
  buttonOneText,
  buttonTwoText,
  handleButtonOneClick,
  handleButtonTwoClick,
}) => {
  MessageBox.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    buttonOneText: PropTypes.string.isRequired,
    buttonTwoText: PropTypes.string.isRequired,
    handleButtonOneClick: PropTypes.func.isRequired,
    handleButtonTwoClick: PropTypes.func.isRequired,
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid  ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("cardBackgroundColorLayer1"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: "32px",
          width: "459px",
          gap: "16px"
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: "left",
          fontWeight: 700,
          lineHeight: "32px",
          color: useThemeRender("textColorLayer2"),
          padding:"0px",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "24px",
          marginBottom: "8px",
        }}
      >
        {message}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "right", gap: "16px", padding: "0px" }}>
        <Button
          onClick={handleButtonOneClick}
          variant="outlined"
          sx={{
            textTransform: "none",
            borderRadius: "24px",
            "&:hover": {
              background: "#004B9A",
            },
          }}
        >
          {buttonOneText}
        </Button>
        <Button
          onClick={handleButtonTwoClick}
          variant="contained"
          sx={{
            textTransform: "none",
            borderRadius: "24px",
            background: "#005CBC",
            marginLeft: "0px !important",
          }}
        >
          {buttonTwoText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageBox;
