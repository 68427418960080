const commonTextStyle = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px",
};

const buttonStyle = {
  display: "flex",
  minWidth: "40px",
  minHeight: "40px",
  height: "32px",
  padding: "8px 4px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  '.menu-list': {
    paddingBottom: "8px",
    '.separator:last-child': {
      borderBottom: "none"
    }
  }
};

const headerTextStyle = {
  fontFamily: "Inter",
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "40px",
};

const filterStyle = {
  width: "240px",
  "& .MuiInputBase-root": {
    height: "32px",
  },
};

export { filterStyle, headerTextStyle, boxStyle, buttonStyle, commonTextStyle };
