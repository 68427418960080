import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useSelector } from "react-redux";
import PrivacyPolicyCommon from "./../../../assets/privacyPolicy.pdf";
export default function LinkHeader() {
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const privacyPolicyUrl = PrivacyPolicyCommon;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding:
          "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-24, 24px)",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "var(--Spacing-spacing-24, 24px)",
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
      }}
      data-testid="link-header-containe"
    >
      {/* Link Header */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          fontSize: isMobile ? "12px" : "14px",
          color: "#616161",
        }}
      >
        <a
          href={`https://www.pepsico.com/legal/privacy`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Privacy policy
        </a>

        {/* <a
          href={TermsAndCondition}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Terms and conditions
        </a> */}
        <a
          href={`https://www.pepsico.com/legal/accessibility-statement`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Accessibility statement
        </a>
        <a
          href="mailto:FritoLayAgSupport@pepsico.com?Subject=Customer%20Support%20Query"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Customer support
        </a>
      </Box>
    </Box>
  );
}
