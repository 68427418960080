import {
  Card as MuiCard,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import styled from "styled-components";
import { truncateText } from "../utils/Upload/truncateText";
import PropTypes from "prop-types";
import { Button } from "pepsico-ds";
import LazyImage from "../utils/LazyImage";

const StyledCard = styled(MuiCard)({
  display: "flex",
  flexDirection: "column",
  border: "1px solid var(--color-border-default)",
  borderRadius: "8px",
  padding: "8px",
});

const ContentWrapper = styled(Box)({
  display: "flex",
  gap: "16px",
});

const StyledImage = styled("img")({
  width: "64px",
  height: "56px",
  borderRadius: "4px",
  objectFit: "cover",
});

const TextContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
});

const Card = ({
  title,
  description,
  buttonText,
  onButtonClick,
  thumbnailFileId,
}) => {
  return (
    <StyledCard variant="outlined">
      <CardContent>
        <ContentWrapper>
          {/* <StyledImage src={image} alt="" /> */}
          <div data-testid="lazy-image">
            <LazyImage
              width={64}
              height={56}
              id={thumbnailFileId}
              blocksize={true}
            />
          </div>

          <TextContent>
            <Typography variant="h6" fontWeight={700}>
              {title}
            </Typography>
            <Typography variant="body2">{truncateText(description)}</Typography>
          </TextContent>
        </ContentWrapper>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end", pt: 0 }}>
        <Button
          iconTrailing="arrow_forward"
          size="large"
          text={buttonText}
          variant="secondary"
          onClick={onButtonClick}
          data-testid="card-button"
        />
      </CardActions>
    </StyledCard>
  );
};

export default Card;

Card.propTypes = {
  title: PropTypes.string.isRequired,
};

// create defaultProps for Card
Card.defaultProps = {
  image: "",
  description: "",
  buttonText: "Learn More",
  onButtonClick: () => {},
};
