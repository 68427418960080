import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
  Button,
  TextField,
  InputAdornment,
  LinearProgress,
  IconButton,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { useDropzone } from "react-dropzone";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";
import uploadIcon from "../../assets/upload.png";
import Thumbnail from "../../assets/Thumbnail.png";
import { FILE_SERVICE_BASE_URL } from "../../constant/apiUrl.js";
import { darkTheme, lightTheme } from "../../theme/Theme";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import useThemeRender from "../../theme/useThemeRender";
import axios from "axios";
import CryptoJS from "crypto-js";
import { postAPI } from "../../API/ApiService.js";
import { showErrorToast } from "../../utils/Toast/toast";
import {
  setDisableNextButton,
  setExternalFileDocument,
  setFileUploaded,
  setFileUploadPageName,
} from "../../slices/adminPageSlice.js";
import { useDispatch } from "react-redux";
import { Straight } from "@mui/icons-material";
import { setImage } from "./../../slices/imageSlice.js"

const ExternalStepOneForm = ({
  externalData,
  setExternalData,
  setFileType,
}) => {
  const [contentType, setContentType] = useState("DOCUMENT");
  // const [document, setDocument] = useState(null);
  const [isUploadingDocument, setUploadingDocument] = useState(false);
  // const [thumbnail, setThumbnail] = useState(null);
  const [isUploadingThumbnail, setUploadingThumbnail] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const thumbnailInputRef = useRef();
  const token = localStorage.getItem("auth_token");
  const dispatch = useDispatch();
  const { fileUploaded, externalFileDocument } = useSelector(
    (store) => store.adminpage
  );
  const thumbnail = useSelector((state) => state.images.images["thumbnail"]);
  const handlePreview = () => {
    if (externalFileDocument.file) {
      console.log(externalFileDocument.type);
      try {
        if (externalFileDocument.type === "application/pdf") {
          const fileURL = URL.createObjectURL(externalFileDocument.file);
          window.open(fileURL, "_blank");
        }
      } catch (error) {
        console.error("Error opening PDF:", error);
      }
    }
  };
  const isDarkTheme = useSelector((state) => state.theme);
  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setExternalData((prevData) => ({
      ...prevData,
      attachmentLinkUrl: newLink,
      attachmentFileId: 0,
    }));
    if (newLink) {
      dispatch(setDisableNextButton(false));
    } else {
      dispatch(setDisableNextButton(true));
    }
  };
  const getThemeColor = (lightColor, darkColor) =>
    isDarkTheme ? darkColor : lightColor;

  const handleChange = (event) => {
    setContentType(event.target.value);
    setExternalData((prevData) => ({
      ...prevData,
      contentTypeId: event.target.value,
    }));
  };

  const onDrop = async (acceptedFiles) => {
    const MAX_FILE_SIZE = 250 * 1024 * 1024;
    const file = acceptedFiles[0];
    if (!file) return;
    if (file.size > MAX_FILE_SIZE) {
      // alert("File size exceeds the 250 MB limit. Please upload a smaller file.");
      showErrorToast(
        "File size exceeds the 250 MB limit. Please upload a smaller file."
      );
      return;
    }
    setUploadingDocument(true);

    try {
      // Step 1: Initiate upload
      const isVideo = file.type.startsWith("video/");
      setFileType(isVideo ? "VIDEO" : "DOCUMENT");
      const chunkSize = 5 * 1024 * 1024; // 5MB
      const initResponse = await postAPI(
        `${FILE_SERVICE_BASE_URL}/fritolay/upload/init`,
        {
          fileName: file.name,
          fileSize: file.size,
          chunkSize,
        }
      );
      console.log("upload initiated", initResponse.data);
      const { fileId, chunkCount } = initResponse.data;
      const chunks = Math.ceil(file.size / chunkSize);
      const chunkUploadPromises = [];

      // Step 2: Upload each chunk
      for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);

        const chunkArrayBuffer = await chunk.arrayBuffer();
        const checksum = CryptoJS.MD5(
          CryptoJS.lib.WordArray.create(chunkArrayBuffer)
        ).toString(CryptoJS.enc.Base64);

        chunkUploadPromises.push(
          axios.put(
            `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/chunk/${i}`,
            chunk,
            {
              headers: {
                fileId,
                chunkIndex: i,
                checksum,
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
        );
      }

      await Promise.all(chunkUploadPromises);

        // Step 3: Verify each chunk
        const computeChunkChecksum = (chunk) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    try {
                        const wordArray = CryptoJS.lib.WordArray.create(reader.result);
                        const checksum = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
                        resolve(checksum);
                    } catch (err) {
                        reject(err);
                    }
                };

                reader.onerror = () => reject(reader.error);
                reader.readAsArrayBuffer(chunk); // Efficient, does not load entire file at once
            });
        };

        const chunkVerificationPromises = [];
        let allChunkChecksums = []; // Array to store all chunk checksums

        for (let i = 0; i < chunks; i++) {
            const chunk = file.slice(i * chunkSize, Math.min(file.size, (i + 1) * chunkSize));

            // Handle chunk checksum and upload
            const checksumPromise = computeChunkChecksum(chunk).then((checksum) => {
                allChunkChecksums.push(checksum); // Store the checksum for later
                return axios.post(
                    `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/chunk/${i}/complete`,
                    { checksum },
                    {
                        headers: {
                            fileId,
                            chunkIndex: i,
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                        },
                    }
                );
            });

            chunkVerificationPromises.push(checksumPromise);

            // Once we have 5 promises, wait for them to settle
            if (chunkVerificationPromises.length >= 5) {
                await Promise.allSettled(chunkVerificationPromises);
                chunkVerificationPromises.length = 0;  // Reset the batch
            }
        }

        // Wait for all remaining promises to settle
        await Promise.allSettled(chunkVerificationPromises);

        // Calculate final checksum by combining all chunk checksums
        const finalChecksum = CryptoJS.MD5(allChunkChecksums.join('')).toString(CryptoJS.enc.Base64);

        // Final completion request with final checksum
        await axios.post(
            `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/complete`,
            {
                fileName: file.name,
                finalChecksum,
            },
            {
                headers: {
                    fileId,
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            }
        );

      dispatch(
        setExternalFileDocument({
          name: file.name,
          type: file.type,
          file: file,
          fileId: fileId,
        })
      );
      setExternalData((prevData) => ({
        ...prevData,
        attachmentFileId: fileId,
        attachmentLinkUrl: "",
      }));
      dispatch(setDisableNextButton(false));
      dispatch(setFileUploaded(true));
      dispatch(setFileUploadPageName("internal"));
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadingDocument(false);
    }
  };
  useEffect(() => {
    if (contentType === "DOCUMENT") {
      if (
        !externalFileDocument ||
        Object?.keys(externalFileDocument)?.length === 0
      ) {
        dispatch(setFileUploaded(false));
        dispatch(setExternalFileDocument(null));
      } else {
        dispatch(setDisableNextButton(false));
        dispatch(setFileUploaded(true));
        dispatch(setFileUploadPageName("internal"));
      }
    } else if (contentType === "LINK") {
      if (
        externalData?.attachmentLinkUrl &&
        externalData?.attachmentLinkUrl?.trim() !== ""
      ) {
        dispatch(setDisableNextButton(false));
      } else {
        dispatch(setDisableNextButton(true));
      }
    }
  }, [contentType]);
  useEffect(() => {
    if (fileUploaded) {
      dispatch(setDisableNextButton(false));
    } else {
      dispatch(setDisableNextButton(true));
    }
  }, [fileUploaded]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    "application/pdf": [],
    "video/*": [],
  });

  const handleThumbnailUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploadingThumbnail(true);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${FILE_SERVICE_BASE_URL}/fritolay/content-management/upload?user=ADMIN`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;
      // setThumbnail(URL.createObjectURL(file));
      const fileUrl = URL.createObjectURL(file);
      dispatch(setImage({ id: "thumbnail", url: fileUrl }));
      console.log("Upload successful:", data);
      setExternalData((prevData) => ({
        ...prevData,
        thumbnailFileId: data?.fileId,
      }));
    } catch (error) {
      // Handle error cases
      console.error(
        "Error uploading file:",
        error.response?.data || error.message
      );
    } finally {
      setUploadingThumbnail(false);
    }
  };

  const handleDocumentDelete = async (fileId) => {
    if (!fileId) {
      console.error("File ID is required to delete the file.");
      return;
    }

    try {
      const response = await axios.delete(
        `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      console.log("File deleted successfully:", response.data);
      dispatch(setFileUploaded(false));
      dispatch(setExternalFileDocument(null));
    } catch (error) {
      console.error("Error deleting the file:", error);
    }
  };

  const renderProgressBar = () => (
    <Box sx={{ width: "100%", mt: 2 }}>
      <LinearProgress />
    </Box>
  );
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  // const renderDropzone = (label) => (
  //   <Box>
  //     <FormLabel
  //       sx={{
  //         color: getThemeColor(
  //           lightTheme.textColorLayer1,
  //           darkTheme.textColorLayer1
  //         ),
  //       }}
  //     >
  //       {label}
  //     </FormLabel>
  //     <Box
  //       sx={{
  //         display: "flex",
  //         mt: 2,
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderRadius: "8px",
  //         border: "1px dashed #D7E5F8",
  //         padding: "24px",
  //         width: { xs: "100%", sm: "50%" },
  //         background: getThemeColor(
  //           lightTheme.insideCardBackgroundColorLayer3,
  //           darkTheme.insideCardBackgroundColorLayer3
  //         ),
  //         position: "relative",
  //       }}
  //     >
  //       {isUploadingThumbnail ? (
  //         renderProgressBar()
  //       ) : thumbnail ? (
  //         <>
  //           <img
  //             src={thumbnail}
  //             alt="Uploaded Thumbnail"
  //             style={{
  //               maxWidth: "100%",
  //               borderRadius: "4px",
  //               objectFit: "cover",
  //             }}
  //           />
  //           <Button
  //             variant="contained"
  //             sx={{
  //               position: "absolute",
  //               top: "50%",
  //               left: "45%",
  //               borderRadius: "24px",
  //               fontWeight: 700,
  //             }}
  //             startIcon={<AutorenewOutlinedIcon />}
  //             onClick={() => thumbnailInputRef.current.click()}
  //           >
  //             Replace
  //           </Button>
  //           <input
  //             type="file"
  //             accept="image/*"
  //             ref={thumbnailInputRef}
  //             style={{ display: "none" }}
  //             onChange={handleThumbnailUpload}
  //           />
  //         </>
  //       ) : (
  //         <>
  //           <img src={Thumbnail} alt="Thumbnail Icon" />
  //           <Typography
  //             variant="body1"
  //             sx={{
  //               textAlign: "center",
  //               color: getThemeColor(
  //                 lightTheme.textColorLayer1,
  //                 darkTheme.textColorLayer1
  //               ),
  //             }}
  //           >
  //             Drag and drop your file here <br /> (only .jpg, .png)
  //           </Typography>
  //           <Button
  //             variant="text"
  //             sx={{
  //               mt: 2,
  //               borderRadius: "24px",
  //               border: "2px solid #005CBC",
  //               fontWeight: 700,
  //               py: 1,
  //               px: 2,
  //             }}
  //             startIcon={<UploadOutlinedIcon />}
  //             onClick={() => thumbnailInputRef.current.click()}
  //           >
  //             Upload File
  //           </Button>
  //           <input
  //             type="file"
  //             accept="image/*"
  //             ref={thumbnailInputRef}
  //             style={{ display: "none" }}
  //             onChange={handleThumbnailUpload}
  //           />
  //         </>
  //       )}
  //     </Box>
  //   </Box>
  // );
  const renderDropzone = (label) => {


    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const file = e.dataTransfer.files[0];
      if (file) {
        handleThumbnailUpload({ target: { files: [file] } }); // Simulate input file event
      }
    };

    return (
      <Box>
        <FormLabel
          sx={{
            color: getThemeColor(
              lightTheme.textColorLayer1,
              darkTheme.textColorLayer1
            ),
          }}
        >
          {label}
        </FormLabel>
        <Box
          sx={{
            display: "flex",
            mt: 2,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            border: isDragging ? "2px solid #005CBC" : "1px dashed #D7E5F8",
            background: isDragging ? "#E3F2FD" : getThemeColor(
              lightTheme.insideCardBackgroundColorLayer3,
              darkTheme.insideCardBackgroundColorLayer3
            ),
            padding: "24px",
            width: { xs: "100%", sm: "50%" },
            position: "relative",
            transition: "background 0.2s ease, border 0.2s ease",
          }}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {isUploadingThumbnail ? (
            renderProgressBar()
          ) : thumbnail ? (
            <>
              <img
                src={thumbnail}
                alt="Uploaded Thumbnail"
                style={{
                  maxWidth: "100%",
                  borderRadius: "4px",
                  objectFit: "cover",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "45%",
                  borderRadius: "24px",
                  fontWeight: 700,
                }}
                startIcon={<AutorenewOutlinedIcon />}
                onClick={() => thumbnailInputRef.current.click()}
              >
                Replace
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={thumbnailInputRef}
                style={{ display: "none" }}
                onChange={handleThumbnailUpload}
              />
            </>
          ) : (
            <>
              <img src={Thumbnail} alt="Thumbnail Icon" />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  color: getThemeColor(
                    lightTheme.textColorLayer1,
                    darkTheme.textColorLayer1
                  ),
                }}
              >
                {isDragging ? "Drop the file here" : "Drag and drop your file here (only .jpg, .png)"}
              </Typography>
              <Button
                variant="text"
                sx={{
                  mt: 2,
                  borderRadius: "24px",
                  border: "2px solid #005CBC",
                  fontWeight: 700,
                  py: 1,
                  px: 2,
                }}
                startIcon={<UploadOutlinedIcon />}
                onClick={() => thumbnailInputRef.current.click()}
              >
                Upload File
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={thumbnailInputRef}
                style={{ display: "none" }}
                onChange={handleThumbnailUpload}
              />
            </>
          )}
        </Box>
      </Box>
    );
  };

  const dropzoneStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px dashed #D7E5F8",
    padding: "24px",
    background: useThemeRender("insideCardBackgroundColorLayer3"),
    width: { xs: "100%", sm: "50%" },
  };
  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        background: getThemeColor(
          lightTheme.cardBackgroundColorLayer1,
          darkTheme.cardBackgroundColorLayer1
        ),
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 1: Upload
      </Typography>

      <FormLabel
        sx={{
          color: getThemeColor(
            lightTheme.textColorLayer1,
            darkTheme.textColorLayer1
          ),
        }}
      >
        Content type
      </FormLabel>
      <RadioGroup value={contentType} onChange={handleChange} row>
        {/* {["DOCUMENT", "LINK"].map((type) => (
          <FormControlLabel
            key={type}
            value={type}
            control={
              <Radio
                sx={{
                  color: getThemeColor(
                    lightTheme.radioButtonLayer1,
                    darkTheme.radioButtonLayer1
                  ),
                }}
              />
            }
            label={type.charAt(0).toUpperCase() + type.slice(1)}
          />
        ))} */}
         <FormControlLabel
            value="DOCUMENT"
            control={
              <Radio
                sx={{
                  color: getThemeColor(
                    lightTheme.radioButtonLayer1,
                    darkTheme.radioButtonLayer1
                  ),
                }}
              />
            }
            label="Upload"
          />
           <FormControlLabel
            value="LINK"
            control={
              <Radio
                sx={{
                  color: getThemeColor(
                    lightTheme.radioButtonLayer1,
                    darkTheme.radioButtonLayer1
                  ),
                }}
              />
            }
            label="Link"
          />
      </RadioGroup>

      {contentType === "LINK" && (
        <TextField
          placeholder="Paste Link"
          sx={{ mt: 2, width: "100%" }}
          value={externalData.attachmentLinkUrl}
          onChange={handleLinkChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />
      )}

      {contentType === "DOCUMENT" && (
        <>
          <Box>
            {!externalFileDocument ? (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ width: { xs: "100%", sm: "50%" } }}
                >
                  <FormLabel
                    sx={{
                      color: getThemeColor(
                        lightTheme.textColorLayer1,
                        darkTheme.textColorLayer1
                      ),
                    }}
                  >
                    Document
                  </FormLabel>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel
                      sx={{
                        color: getThemeColor(
                          lightTheme.textColorLayer4,
                          darkTheme.textColorLayer4
                        ),
                        fontSize: "14px",
                      }}
                    >
                      *Required
                    </FormLabel>
                  </Box>
                </Box>
                <Box
                  {...getRootProps()}
                  sx={{ mt: 2, mb: 2, ...dropzoneStyle }}
                >
                  {isUploadingDocument ? (
                    renderProgressBar()
                  ) : (
                    <img src={uploadIcon} alt="Upload Icon" />
                  )}
                  <input {...getInputProps()} />
                  <Typography
                    sx={{
                      color: getThemeColor(
                        lightTheme.textColorLayer1,
                        darkTheme.textColorLayer1
                      ),
                    }}
                  >
                    Drag and drop your file here
                  </Typography>
                  <Button
                    variant="text"
                    sx={{
                      mt: 2,
                      borderRadius: "24px",
                      border: "2px solid #005CBC",
                      fontWeight: 700,
                      py: 1,
                      px: 2,
                    }}
                    startIcon={<UploadOutlinedIcon />}
                  >
                    Upload File
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: insideCardBackgroundColorLayer3,
                  width: { xs: "100%", sm: "50%" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handlePreview}
                >
                  <InsertDriveFileOutlinedIcon
                    sx={{ marginRight: 1, color: "primary.main" }}
                  />
                  <Typography> {externalFileDocument?.name}</Typography>
                </Box>
                <IconButton
                  onClick={() =>
                    handleDocumentDelete(externalFileDocument?.fileId)
                  }
                >
                  <DeleteOutlineOutlinedIcon color="primary" />
                </IconButton>
              </Box>
            )}
          </Box>
          {renderDropzone("Thumbnail upload")}
        </>
      )}

      {contentType === "LINK" && renderDropzone("Thumbnail upload")}
    </Box>
  );
};

export default ExternalStepOneForm;