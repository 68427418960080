import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormatableTextArea from "./FormatableTextArea";
import { CropTypeWrapper } from "./curatorApprovalStyle";
import { Dropdown } from "pepsico-ds";
import {getPepsicoDropdownOptions, getDisplayTextForDropDown} from "../../../utils/function";
import CustomCuratorRadioGroup from "./CustomCuratorRadioGroup";
import {CURATOR_SUMMARY_LANGUAGE_OPTIONS} from "../../../constant/constant";
import { useSelector } from "react-redux";
import useThemeRender from "../../../theme/useThemeRender";
import "../CuratorCommon.css";

const SummarySection = ({
  editorRef,
  summarySectionData,
  setSummarySectionData,
  lookUpOptions,
  getDisclaimerDetailsFromId
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [languageOptions, setLanguageOptions] = useState(summarySectionData?.isTranslation);
  useEffect(() => {
    setLanguageOptions(summarySectionData?.isTranslation);
  }, [summarySectionData?.isTranslation])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "16px",
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      <Box
        display={"flex"}
        gap={"32px"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <FormatableTextArea
          editorRef={editorRef}
          isDarkTheme={isDarkTheme}
          setDescription={summarySectionData?.documentNotes}
          showToolbar={true}
          changeFormatableText={(data) => {
            setSummarySectionData((stateData) => {
              return {
                ...stateData,
                documentNotes: data,
              };
            });
          }}
          label={"Document notes"}
          maxLength={500}
        />

        <FormatableTextArea
          editorRef={editorRef}
          isDarkTheme={isDarkTheme}
          setDescription={summarySectionData?.summary}
          showToolbar={true}
          changeFormatableText={(data) => {
            setSummarySectionData((stateData) => {
              return {
                ...stateData,
                summary: data,
              };
            });
          }}
          label={"Summary"}
          maxLength={500}
        />
      </Box>

      <Box
        display={"flex"}
        gap={"32px"}
        paddingTop={"16px"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <CropTypeWrapper>
          <Dropdown
            childList={getPepsicoDropdownOptions(lookUpOptions?.disclaimer)}
            className="curator-single-dropdown"
            isClearable
            label="Disclaimer"
            placeholder="default option"
            selection="single"
            style={{
              zIndex: '99'
            }}
            selectedValue={getDisplayTextForDropDown(summarySectionData?.disclaimer, getPepsicoDropdownOptions(lookUpOptions?.disclaimer))}
            setSelectedValue={(e) => {
              if (e !== undefined && e.length > 0) {
                setSummarySectionData((stateData) => {
                  return {
                    ...stateData,
                    disclaimer: e?.[0]?.id,
                    disclaimerDetails: getDisclaimerDetailsFromId(e[0]?.id, lookUpOptions?.disclaimer)
                  };
                });
              }
            }}
            size="medium"
            helperText="Note: Select an option above to display the disclaimer details"
          />
        </CropTypeWrapper>

        <FormatableTextArea
          editorRef={editorRef}
          isDarkTheme={isDarkTheme}
          isDisabled={true}
          setDescription={summarySectionData?.disclaimerDetails}
          showToolbar={true}
          changeFormatableText={(data) => {
            setSummarySectionData((stateData) => {
              return {
                ...stateData,
                disclaimerDetails: data,
              };
            });
          }}
          label={"Disclaimer text"}
        />
      </Box>

      <Box
        display={"flex"}
        gap={"32px"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box sx={{ width: { sm: "100%", md: "360px" } }}>
          <CustomCuratorRadioGroup
            label={"Translate summary"}
            value={summarySectionData?.isTranslation ? CURATOR_SUMMARY_LANGUAGE_OPTIONS?.[0].id: CURATOR_SUMMARY_LANGUAGE_OPTIONS?.[1].id }
            onChange={(e) => {
              setLanguageOptions(parseInt(e.target.value));
              setSummarySectionData((stateData) => {
                return {
                  ...stateData,
                  isTranslation: parseInt(e.target.value) === 1 ? true : false,
                };
              });
            }}
            radioGroup={CURATOR_SUMMARY_LANGUAGE_OPTIONS}
          />
        </Box>
        {languageOptions === true && ( 
        <CropTypeWrapper>
          <Dropdown
            childList={getPepsicoDropdownOptions(lookUpOptions?.language)}
            className="curator-single-dropdown"
            isClearable
            label="Translate summary to"
            option
            placeholder="Select"
            selection="single"
          selectedValue={getDisplayTextForDropDown(summarySectionData?.languageId, getPepsicoDropdownOptions(lookUpOptions?.language))}
            setSelectedValue={(e) => {
              if (e !== undefined && e.length > 0) {
              setSummarySectionData((stateData) => {
                return {
                  ...stateData,
                  languageId:  e?.[0]?.id
                };
              });
            } else {
              setSummarySectionData((stateData) => {
                return {
                  ...stateData,
                  languageId : "",
                };
              });
            }
            }}
            size="medium"
          />
        </CropTypeWrapper>
        )}
        {languageOptions === true && ( 
        <FormatableTextArea
          editorRef={editorRef}
          isDarkTheme={isDarkTheme}
          setDescription={summarySectionData?.translatedText}
          showToolbar={true}
          changeFormatableText={(data) => {
            setSummarySectionData((stateData) => {
              return {
                ...stateData,
                translatedText: data,
              };
            });
          }}
          label={"Translated summary"}
        />
      )}
      </Box>

    </Box>
  );
};

export default SummarySection;
