// src/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import navReducer from "../slices/navSlice";
import adminPageReducer from "../slices/adminPageSlice";
import viewReducer from "../slices/viewSlice";
import darkThemeReducer from "../slices/themeSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import potatoProtectionReducer from "../slices/potatoProtectionSlice";
import internalPageReducer from "../slices/internalPageSlice";
import libraryPageSlice from "../slices/LibraryPageSlice";
import viewSummaryReducer from "../slices/viewSummarySlice";
import fritoForumPageReducer from "../slices/fritoForumPageSlice";
import buttonReducer from "../slices/buttonSlice";
import spotlightReducer from "../slices/spotlightSlice";
import invitationReducer from "../slices/invitationSlice";
import curatorPageReducer from "../slices/curatorPageSlice";
import curatorPageLookUpReducer from "../slices/curatorPageLookupSlice";
import uploadHistoryReducer from "../slices/uploadHistoryslice";
import configReducer from "../slices/configSlice";
import userSlice from "../slices/userSlice";
import searchResultReducer from "../slices/searchResultSlice";
import rfiReducer from "../slices/rfiSlice";
import imageReducer from "../slices/imageSlice";
import internalFilterReducer from "../slices/internalFilterSlice";
import sustainabilityPageReducer from "../slices/sustainabilityPageSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["theme", "config"],
  blacklist: ["auth"],
};

const rootReducer = combineReducers({
  nav: navReducer,
  adminpage: adminPageReducer,
  view: viewReducer,
  theme: darkThemeReducer,
  protectionPotato: potatoProtectionReducer,
  internalPage: internalPageReducer,
  libraryDetailPage: libraryPageSlice,
  viewSummary: viewSummaryReducer,
  fritoForumPage: fritoForumPageReducer,
  button: buttonReducer,
  spotlight: spotlightReducer,
  curatorpage: curatorPageReducer,
  curatorPageLookUpData: curatorPageLookUpReducer,
  invitation: invitationReducer,
  uploadHistory: uploadHistoryReducer,
  config: configReducer,
  user: userSlice,
  searchResults: searchResultReducer,
  rfi: rfiReducer,
  images: imageReducer,
  internalFilter: internalFilterReducer,
  sustainable: sustainabilityPageReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
