import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./MobileScreenDocument.css";
import Button from "@mui/material/Button";
import useThemeRender from "./../../theme/useThemeRender";
import { postAPI, getAPI } from "../../API/ApiService";

import { useLocation, useParams } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";

import DocumentSummary from "./DocumentSummary";
import DocumentDetails from "./DocumentDetails";
import { useSelector, useDispatch } from "react-redux";
import Disclaimer from "./Disclaimer";
import Loading from "../../utils/Loader";
import { showErrorToast } from "../../utils/Toast/toast";
import { increaseViewCount } from "../../utils/function";
import { setFetchFavourtie } from "../../slices/internalPageSlice";
import { downloadFileToLocal } from "../../API/curatorApi";
import SuccessBox from "../Upload/SuccessBox";
export default function Document() {
  const location = useLocation();

  const documentCardData = location.state;
  const [fetch, setFetch] = useState(false);
  const { selectedGlobalState, selectedAction } = useSelector(
    (store) => store.internalPage
  );
  const [documentData, setDocumentData] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [unauthorized, setUnauthorized] = useState(false);
  const backgroundColor = useThemeRender("backgroundColorLayer1");
  const textColor = useThemeRender("textColorLayer2");
  const userId = 1;

  const { contentId } = useParams();

  const isMobile = useMediaQuery("(max-width: 600px)");
  const handleAddFavourite = async () => {
    setLoading(true);
    try {
      const response = await postAPI(
        `${BASE_URL}/favorites/save?contentId=${contentId}&userId=1&favorite=${!documentData?.isFavorite}`
      );
      if (response?.status === 200) {
        fetchData();
        dispatch(setFetchFavourtie(true));
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    await downloadFileToLocal(contentId, false);
    setLoading(false);
  };

  // Function to merge all ArrayBuffers into one

  const fetchData = async () => {
    setLoading(true);
    if (contentId) {
      try {
        const response = await getAPI(
          `${BASE_URL}/content/find-detail-by-id/${contentId}`
        );

        if (response?.status === 200) {
          const views = response?.data?.viewCount;
          const likes = response?.data?.likes;

          console.log(response, "-------responseItemmmmm----------");

          setDocumentData({
            ...response?.data,
            thumbnailUrl: documentCardData?.thumbnailUrl,
          });
          const data = increaseViewCount(
            selectedGlobalState,
            Number(contentId),
            documentCardData?.content,
            views,
            likes
          );

          dispatch(selectedAction(data));
        }
        if (response?.status === 403) {
          setUnauthorized(true);
          console.log(response, "------responseee------");
        }
        if (response?.status === 500) {
          showErrorToast(response.message);
        }
      } catch (error) {
        console.log(error, "---------errorrr---------");

        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [contentId, fetch]);

  if (unauthorized)
    return <SuccessBox text={true} data-testid="access-denied" />;
  return (
    <Box
      sx={{
        display: "flex",
        padding: {
          xs: "0px",
          sm: " 16px 53.3px",
        },
        flexDirection: "column",
        gap: "var(--Spacing-spacing-24, 24px)",
        margin: 0,
        backgroundColor: backgroundColor,
      }}
      data-testid="test-document"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", sm: "space-between" },
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: "var(--Spacing-spacing-24, 24px)", sm: "10px" },
          alignSelf: { xs: "stretch", sm: "auto" },
          flexDirection: { xs: "column", sm: "row" },
          padding: { xs: "16px", sm: "8px 0px" },
        }}
      >
        <Typography
          sx={{
            color: textColor,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H4, 28px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H4, 40px)",
            width: { xs: "100%", sm: "70%" },
            height: { sm: "40px" },
          }}
        >
          {documentData?.title}
        </Typography>
        <div style={{ width: isMobile ? "100%" : "", minWidth: "162px" }}>
          <Button
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
              background: "var(--Tokens-Button-Primary, #005CBC)",
              minWidth: "162px",
              height: "32px",
              padding: "0px",
            }}
            data-testid="test-download"
            onClick={handleDownload}
          >
            <Box
              sx={{
                display: "flex",
                height: "32px",
                justifyContent: "center",
                alignItems: "center",
                gap: "var(--Spacing-spacing-03, 8px)",
                padding:
                  "var(--Spacing-spacing-02, 4px) var(--Spacing-spacing-04, 16px)",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Colors-White, #FFF)",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Body, 14px)",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "var(--Line-Height-Body, 20px)",
                  textTransform: "none",
                }}
              >
                {documentData?.contentType === "DOCUMENT"
                  ? "Open document"
                  : "Open Link"}
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                  fill="white"
                />
              </svg>
            </Box>
          </Button>
        </div>
      </Box>
      <DocumentSummary
        handleAddFavourite={handleAddFavourite}
        documentData={documentData}
        contentId={contentId}
        fetch={fetch}
        setFetch={setFetch}
        url={location.pathname}
      />
      <DocumentDetails documentData={documentData} />
      <Disclaimer documentData={documentData} />
      {loading && <Loading data-testid="loading-spinner" />}
    </Box>
  );
}
