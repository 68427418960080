import React, { useEffect, useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  Popper,
  useMediaQuery,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import DeletePopUp from "./DeletePopUp";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector, useDispatch } from "react-redux";
import useThemeRender from "../../../../theme/useThemeRender";
import { resetButtonState } from "../../../../slices/buttonSlice";
import MobileNotifications from "./../../../Notifications/MobileNotifications";
import axios from "axios";
import { BASE_URL } from "./../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { getAPI, postAPI } from "../../../../API/ApiService";
import { showErrorToast } from "../../../../utils/Toast/toast";
import ActionButtons from "./ActionButtons";
import { setSubjectFilter } from "../../../../slices/internalFilterSlice";
import { Button } from "pepsico-ds";
const Subjects = () => {
  const [options, setOptions] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [newSubject, setNewSubject] = useState(null);
  const [isAddingSubject, setIsAddingSubject] = useState(false); // default set to false (edit mode)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationType, setNotificationType] = useState("success");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [artifact, setArtifact] = useState({});
  const [artifactCount, setArtifactCount] = useState(0);
  const [fetch, setFetch] = useState(false);
  const dispatch = useDispatch();
  const buttonState = useSelector((state) => state.button.buttonState);
  console.log("button state ", buttonState);
  const token = localStorage.getItem("auth_token");
  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/administrative-subject/list`
        );
        if (response.status === 200) {
          console.log("response subject", response.data);
          const subjectData = response.data;
          const newData = subjectData?.map((item) => {
            return { id: item.id, name: item.name, label: item.name };
          });
          dispatch(setSubjectFilter(newData));
          setOptions(subjectData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [buttonState, dispatch, fetch]);

  const handleChange = (event, newValue) => {
    setSelectedSubject(newValue || {});
    console.log("selected subject", newValue);
  };
  const fetchArtifactCount = async (selectedSubject) => {
    setLoading(true);
    try {
      const response = await getAPI(
        `${BASE_URL}/administrative-subject/checkContentSubjectExists/${selectedSubject.id}`
      );
      console.log("artifact", response);
      if (response?.status === 200) {
        if (response && response?.data && typeof response?.data === "number") {
          return response?.data;
        } else {
          console.warn("Unexpected response format:", response);
          return 0;
        }
      }
    } catch (error) {
      console.error("Error fetching artifact count:", error.message);
      return 0;
    } finally {
      setLoading(false);
    }
  };
  const handleOpenDeleteDialog = async () => {
    if (!selectedSubject || selectedSubject === "" || !selectedSubject?.name) {
      showErrorToast("Please select the subject");
    } else if (selectedSubject?.name) {
      const data = await fetchArtifactCount(selectedSubject);
      setArtifactCount(data);
      console.log(data, "--dataaaArtifactCount--------");
      if (data === 0) {
        console.log("adityaaaaaaaa");

        handleDeleteSubject(selectedSubject, true);
      } else {
        setIsDeleteDialogOpen(true);
      }
    }
  };

  const handleDeleteSubject = async (selectedSubject, flag) => {
    console.log(selectedSubject, artifact, "----selectedSubject-------");

    // If flag is true, directly call the API
    if (flag) {
      const response = await axios.delete(
        `${BASE_URL}/administrative-subject/deleteSubject/${selectedSubject?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 204) {
        setSelectedSubject({});
        setNewSubject("");
        setArtifact("");
        setFetch(!fetch);
      }
    } else {
      if (selectedSubject?.id === artifact?.id) {
        showErrorToast("Invalid artifact selected");
        return;
      }

      setLoading(true);

      if (!artifact || artifact === "" || !artifact?.id) {
        setLoading(false);
        showErrorToast("Please select the subject");
        return;
      }

      // Call API if all conditions pass
      deleteSubjectAPI(selectedSubject);
    }

    // Validation checks
  };

  // Function to handle API call separately
  const deleteSubjectAPI = async (selectedSubject) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/administrative-subject/deleteSubject/${selectedSubject?.id}/${artifact?.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setNotificationType("success");
        setNotificationMessage("Subject successfully deleted");
        setOptions((prevOptions) =>
          prevOptions.filter((subject) => subject?.id !== selectedSubject?.id)
        );
        handleCloseDeleteDialog();
        setSelectedSubject({});
        setNewSubject("");
        setArtifact("");
        setFetch(!fetch);
        setFetch((prev) => !prev);
      } else {
        setNotificationType("error");
        setNotificationMessage("Failed to delete subject");
      }
    } catch (error) {
      console.error("Error deleting subject: ", error);
      setNotificationType("error");
      setNotificationMessage(
        error.response?.data?.message || "Failed to delete subject"
      );
    } finally {
      setLoading(false);
      setNotificationOpen(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleAddSave = async () => {
    console.log(newSubject, "----newSubject------");

    // if (!newSubject?.trim()) {
    //   showErrorToast("Subject name cannot be empty");
    //   return;
    // }
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/administrative-subject/addSubject`,
        { name: newSubject.trim() },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("added subject", response.data);

      if (response?.status >= 200 && response?.status < 300) {
        setNotificationType("success");
        setNotificationMessage("Subject successfully created");
        setSelectedSubject({});
        setNewSubject("");
        setIsAddingSubject(false);
        setFetch(!fetch);
      } else {
        throw new Error("Failed to add subject");
      }
    } catch (error) {
      console.error("Error adding subject: ", error);
      setNotificationType("error");
      setNotificationMessage("Subject Name already exists");
    } finally {
      setLoading(false);
      setNotificationOpen(true);
    }
  };

  const handleAddCancel = () => {
    setNewSubject("");
    setIsAddingSubject(false);
    dispatch(resetButtonState());
    setSelectedSubject("");
  };

  const handleEditSave = async () => {
    setLoading(true);
    const name = newSubject;
    try {
      const response = await postAPI(
        `${BASE_URL}/administrative-subject/editSubject/${selectedSubject?.id}`,
        { name: name }
      );

      if (response.status === 200 || response.status === 201) {
        setOptions((prevOptions) =>
          prevOptions?.map((item) =>
            item?.id === selectedSubject?.id
              ? { ...item, name: newSubject.trim() }
              : item
          )
        );
        setFetch(!fetch);
        setNotificationType("success");
        setNotificationMessage("Subject successfully updated");
        setSelectedSubject({});
        setNewSubject("");
      } else {
        throw new Error("Failed to update subject");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating subject: ", error);
      setNotificationType("error");
      setNotificationMessage(
        error.response?.data?.message || "Failed to update subject"
      );
    } finally {
      setNotificationOpen(true);
      setNewSubject("");
      dispatch(resetButtonState());
    }
  };

  const handleEditCancel = () => {
    setNewSubject("");
    setSelectedSubject("");
    dispatch(resetButtonState());
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: isMobile ? "12px" :"16px 24px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: useThemeRender("cardBackgroundColorLayer1"),
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };

  const popperStyles = {
    "& .MuiAutocomplete-listbox": {
      background: isDarkTheme
        ? darkTheme.backgroundColorLayer3
        : lightTheme.backgroundColorLayer3,
      color: isDarkTheme
        ? darkTheme.textColorLayer1
        : lightTheme.textColorLayer1,
      "& .MuiAutocomplete-option:hover": {
        backgroundColor: isDarkTheme
          ? darkTheme.dropdownHoverColorLayer3
          : lightTheme.dropdownHoverColorLayer3,
      },
    },
  };

  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const iconColor1 = useThemeRender("iconColor1");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  useEffect(() => {
    setNewSubject(selectedSubject?.name);
  }, [selectedSubject?.name]);

  const handleAddSubject = () => {
    setNewSubject(null);
    setIsAddingSubject(true);
  };
  const disabled = isAddingSubject
    ? newSubject === null
    : Object.keys(selectedSubject).length === 0
    ? true
    : false;
  console.log(
    disabled,
    selectedSubject,
    isAddingSubject,
    "-----disabled---------"
  );

  return (
    <div className="flex flex-col gap-9">
      <Box sx={baseBoxStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            type="primary"
            size="medium"
            iconTrailing="add_circle_outline"
            endIcon={<ControlPointIcon />}
            onClick={handleAddSubject}
            disabled={isAddingSubject}
            text="Add Subject"
            isDark={isDarkTheme}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            minHeight: "350px",
            background: insideCardBackgroundColorLayer3,
          }}
        >
          {!isAddingSubject && ( // When isAddingSubject is false, we are in "edit" mode
            <Box
              sx={{
                borderRadius: "8px",
                background: insideCardBackgroundColorLayer3,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                width: "100%",
                padding: "16px",
              }}
            >
              <Box sx={{ width: "100%", height: "100%" }}>
                <Typography sx={{ color: textColorLayer2, mb:"8px", lineHeight: "18px", fontSize: "12px", fontFamily: "var(--Typography-Font-Family-Body, Inter)" }}>
                  Subject
                </Typography>
                <Autocomplete
                  onChange={handleChange}
                  options={[...options]}
                  getOptionLabel={(option) => option?.name || ""}
                  value={
                    selectedSubject ? { name: selectedSubject?.name } : null
                  }
                  PopperComponent={(props) => (
                    <Popper {...props} sx={popperStyles} />
                  )}
                  sx={{
                    width: isMobile ? "100%" : "400px",
                    background: backgroundColorLayer3,
                    "& .MuiInputBase-root": {
                      height: "32px",
                      color: textColorLayer2,
                    },
                    "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: borderColorLayer2 },
                      "&:hover fieldset": { borderColor: borderColorLayer2 },
                      "& .MuiAutocomplete-clearIndicator": {
                        color: iconColor1,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select a subject" className="text-field" />
                  )}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ color: textColorLayer2, mb:"8px", lineHeight: "18px", fontSize: "12px", fontFamily: "var(--Typography-Font-Family-Body, Inter)"  }}>
                  Subject Name
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Please Enter"
                  className="text-field"
                  sx={{
                    width: isMobile ? "100%" : "400px",
                    background: insideCardBackgroundColorLayer1,
                    "& .MuiInputBase-root": {
                      height: "32px",
                      color: textColorLayer2,
                    },
                    "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: borderColorLayer2 },
                      "&:hover fieldset": { borderColor: borderColorLayer2 },
                      "& .MuiAutocomplete-clearIndicator": {
                        color: iconColor1,
                      },
                    },
                  }}
                  // placeholder="edit subject name"
                  value={newSubject}
                  onChange={(e) => setNewSubject(e.target.value)}
                />
              </Box>

              <Button
                text="Remove subject"
                variant="secondary"
                size="medium"
                iconTrailing="delete"
                onClick={handleOpenDeleteDialog}
              />
            </Box>
          )}

          {isAddingSubject && ( // When isAddingSubject is true, we are in "add" mode
            <Box
              sx={{
                borderRadius: "8px",
                background: insideCardBackgroundColorLayer3,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                width: "100%",
                padding: "16px",
              }}
            >
              <Typography sx={{ color: textColorLayer2, lineHeight: "18px", fontSize: "12px", fontFamily: "var(--Typography-Font-Family-Body, Inter)" }}>
                New subject name
              </Typography>
              <TextField
                fullWidth
                sx={{
                  width: isMobile ? "100%" : "400px",
                  background: insideCardBackgroundColorLayer1,
                  "& .MuiInputBase-root": {
                    height: "32px",
                    color: textColorLayer2,
                  },
                  "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: borderColorLayer2 },
                    "&:hover fieldset": { borderColor: borderColorLayer2 },
                    "& .MuiAutocomplete-clearIndicator": { color: iconColor1 },
                  },
                }}
                placeholder="Enter new subject name"
                value={newSubject}
                onChange={(e) => setNewSubject(e.target.value)}
                className="text-field"
              />
            </Box>
          )}
        </Box>

        <ActionButtons
          onSave={isAddingSubject ? handleAddSave : handleEditSave}
          onCancel={isAddingSubject ? handleAddCancel : handleEditCancel}
          disabled={disabled}
        />

        {loading && <Loading />}
      </Box>
      <DeletePopUp
        options={[...options]}
        selectedSubject={selectedSubject?.name}
        open={isDeleteDialogOpen}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmClick={() => handleDeleteSubject(selectedSubject, false)}
        artifact={artifact}
        setArtifact={setArtifact}
        artifactCount={artifactCount}
      />
      <MobileNotifications
        type={notificationType}
        message={notificationMessage}
        open={notificationOpen}
        handleClose={handleNotificationClose}
      />
    </div>
  );
};

export default Subjects;
