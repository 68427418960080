import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import InternalCarousal from "../InternalPages/Carousal-ExpandedView/InternalCarousal";
import { useSelector } from "react-redux";
import { LIBRARY_CONSTANT } from "../../constant/constant";
import ListViewDesign from "../InternalPages/ListView/ListViewDesign";
import { darkTheme, lightTheme } from "../../theme/Theme";

export default function LibraryTabContent({
  data,
  grid,
  selectedSection,
  setItemCounts,
  currentTab,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [filteredRows, setFilteredRows] = useState({
    documents: [],
    links: [],
    videos: [],
  });
  // const { search } = useSelector((store) => store.internalPage);
  // const [searchText, setSearchText] = useState('');
  const isDarkTheme = useSelector((state) => state.theme);

  useEffect(() => {
    // if (searchText) {
    //   const searchResult = searchItems(searchText);
    //   setItemCounts({
    //     documents: searchResult?.documents?.length || 0,
    //     videos: searchResult?.videos?.length || 0,
    //     links: searchResult?.links?.length || 0,
    //     all: searchResult?.documents?.length + searchResult?.videos?.length + searchResult?.links?.length || 0,
    //   });
    //   setFilteredRows(searchResult);
    // } else {
    setFilteredRows(data);
    // setItemCounts({
    //   documents: data?.documents?.length || 0,
    //   videos: data?.videos?.length || 0,
    //   links: data?.links?.length || 0,
    //   all: data?.documents?.length + data?.videos?.length + data?.links?.length || 0,
    // });
    // }
  }, [data]);

  // const searchItems = (text) => {
  //   const result = {
  //     documents: [],
  //     videos: [],
  //     links: []
  //   };

  //   const matchingDocuments = data?.documents?.filter((doc) =>
  //     doc?.title?.toLowerCase().includes(text?.toLowerCase())
  //   ) || [];
  //   result.documents.push(...matchingDocuments);

  //   const matchingVideos = data?.videos?.filter((video) =>
  //     video?.title?.toLowerCase().includes(text?.toLowerCase())
  //   ) || [];
  //   result.videos.push(...matchingVideos);

  //   const matchingLinks = data?.links?.filter((link) =>
  //     link?.title?.toLowerCase().includes(text?.toLowerCase())
  //   ) || [];
  //   result.links.push(...matchingLinks);

  //   return result;
  // };

  useEffect(() => {
    if (selectedSection) {
      const sectionElement = document.getElementById(selectedSection);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selectedSection]);

  return (
    <div
      style={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer2
          : lightTheme.backgroundColorLayer2,
        borderRadius: "8px",
      }}
    >
      {grid === "tile" && (
        <>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              // boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {/* Documents Carousal */}
            {filteredRows?.documents?.length > 0 &&
              (currentTab === LIBRARY_CONSTANT.document ||
                currentTab === LIBRARY_CONSTANT.all) && (
                <InternalCarousal
                  data={filteredRows.documents}
                  content={"Documents"}
                  heading={"Documents"}
                  flexDirection={"column"}
                />
              )}
            {/* </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "13px",
            }}
          > */}
            {filteredRows?.videos?.length > 0 &&
              (currentTab === LIBRARY_CONSTANT.videos ||
                currentTab === LIBRARY_CONSTANT.all) && (
                <InternalCarousal
                  data={filteredRows.videos}
                  content={"Videos"}
                  heading={"Videos"}
                  flexDirection={"column"}
                />
              )}
            {/* </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "12px",
            }}
          > */}
            {/* Links Carousal */}
            {filteredRows?.links?.length > 0 &&
              (currentTab === LIBRARY_CONSTANT.links ||
                currentTab === LIBRARY_CONSTANT.all) && (
                <InternalCarousal
                  data={filteredRows.links}
                  content={"Links"}
                  heading={"Links"}
                  flexDirection={"column"}
                />
              )}
          </Box>
        </>
      )}
      {grid !== "tile" && (
        <>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {/* Documents Carousal */}
            {filteredRows?.documents?.length > 0 && (currentTab === LIBRARY_CONSTANT.document ||
                currentTab === LIBRARY_CONSTANT.all) && (
              <ListViewDesign
                data={filteredRows.documents}
                content={"Documents"}
                pageType={"LibraryDetails"}
                viewMode={grid}
              />
            )}
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {filteredRows?.videos?.length > 0 && (currentTab === LIBRARY_CONSTANT.videos ||
                currentTab === LIBRARY_CONSTANT.all) && (
              <ListViewDesign
                data={filteredRows.videos}
                content={"Videos"}
                pageType={"LibraryDetails"}
                viewMode={grid}
              />
            )}
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {/* Links Carousal */}
            {filteredRows?.links?.length > 0 && (currentTab === LIBRARY_CONSTANT.links ||
                currentTab === LIBRARY_CONSTANT.all) && (
              <ListViewDesign
                data={filteredRows.links}
                content={"Links"}
                pageType={"LibraryDetails"}
                viewMode={grid}
              />
            )}
          </Box>
        </>
      )}
    </div>
  );
}
