import React, { useState } from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { Icon } from "pepsico-ds";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import PreferencesDrawer from "./PreferencesDrawer";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { setConfig } from "../../slices/configSlice";
import Loading from "../../utils/Loader";
import PrivacyPolicyCommon from "../../assets/privacyPolicy.pdf";

const textstyle = {
  overflow: "hidden",
  color: "var(--Tokens-Text-Secondary, #616161)",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap", // Ensures text is truncated in one line
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-Small, 20px)", // Ensures proper spacing
};
const DropdownMenu = () => {
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const isDarkTheme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const { External } = useSelector((store) => store.config);
  const dispatch = useDispatch();
  const privacyPolicyUrl = PrivacyPolicyCommon;
  const handleClick = (event) => {
    setDropdownAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedMenuItem(0);
    setDropdownAnchor(null);
  };

  const handlePreferencesClick = () => {
    setSelectedMenuItem(1);
    setDrawerOpen(true);
    handleClose();
  };

  const handleFavoriteRedirect = () => {
    handleClose();
    setSelectedMenuItem(2);
    navigate("/favorites");
  };
  const clearOktaCookies = () => {
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      if (cookieName.startsWith("okta-oauth")) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    });
  };

  const logout = async () => {
    // dispatch(setConfig(null));
    setLoading(true);
    await oktaAuth.revokeAccessToken(); // Revokes access token
    await oktaAuth.revokeRefreshToken(); // Revokes refresh token
    // window.location.href = ``;
    // navigate("/login");
    if (External) {
      dispatch(setConfig(null));
      navigate("/login");
      setLoading(false);
    } else {
      dispatch(setConfig(null));
      const response = await oktaAuth.signOut();
      if (response?.status === 200) {
        setLoading(false);
      }
    }

    // await oktaAuth.signOut({
    //   postLogoutRedirectUri: `${window.location.origin}/login `, // Change as needed
    // });
  };
  // const handleUserAgreement = () => {
  //   window.location;
  // };
  if (loading) return <Loading />;
  return (
    <>
      <Icon
        alt="Dropdown"
        icon="arrow_drop_down"
        size="medium"
        onClick={handleClick}
        style={{
          color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
        }}
      />

      <Menu
        id="dropdown-menu"
        anchorEl={dropdownAnchor}
        open={Boolean(dropdownAnchor)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          marginTop: "20px",
          borderRadius: 4,
          "& .MuiPaper-root": {
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer3
              : lightTheme.backgroundColorLayer3,
            padding: "10px",
          },
        }}
      >
        <MenuItem
          // selected={selectedMenuItem === 1 ? true : false}
          sx={{
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          }}
        >
          <a
            href={privacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography sx={textstyle}> User Agreement</Typography>
          </a>
        </MenuItem>
        <MenuItem
          onClick={handlePreferencesClick}
          selected={selectedMenuItem === 1 ? true : false}
          sx={{
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          }}
          key={1}
        >
          <Typography sx={textstyle}>Preferences</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleFavoriteRedirect}
          selected={selectedMenuItem === 2 ? true : false}
          sx={{
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          }}
          key={2}
        >
          <Typography sx={textstyle}> My Favorites</Typography>
        </MenuItem>
        <MenuItem
          onClick={logout}
          sx={{
            color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
          }}
          key={3}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography sx={textstyle}>Signout</Typography>

            <LoginIcon fontSize="small" />
          </Box>
        </MenuItem>
      </Menu>

      <PreferencesDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
};

export default DropdownMenu;
