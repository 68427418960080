import { Box } from "@mui/material";
import { useState } from "react";

import Loading from "../utils/Loader";
import InternalCarousal from "./InternalPages/Carousal-ExpandedView/InternalCarousal";
import useThemeRender from "../theme/useThemeRender";

const SpotLight = ({ pageData }) => {
  const [loading, setLoading] = useState(false);
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");

  const sortedData = [...(pageData || [])]
    ?.sort((a, b) => a.slotNumber - b.slotNumber)
    .map((item) => {
      return { ...item, thumbnailFileId: item.thumbnailId };
    });
  if (loading) return <Loading data-testid="spotlight-loader" />;

  return (
    <Box
      sx={{
        backgroundColor: backgroundColorLayer3,
        marginBottom: "16px",
      }}
      className="curosalBackground"
      data-testid="spotlight-container"
    >
      <InternalCarousal
        data={sortedData}
        content={"Documents"}
        flexDirection={"column"}
        heading={"In the Spotlight"}
        isHeadingBig={true}
        data-testid="spotlight-carousal"
      />
    </Box>
  );
};

export default SpotLight;
