import React, { useEffect, useState } from "react";
import SubjectMatterLayout from "../components/SubjectMatterExpert/SubjectMatterLayout";
import { BASE_URL } from "../constant/apiUrl";
import Loading from "../utils/Loader";
import { getAPI } from "../API/ApiService";
import { showErrorToast } from "../utils/Toast/toast";

export default function SubjectMatterExperts() {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/sme/list-sme`);
        if (response?.status === 200) {
          setPageData(response?.data);
        }
      } catch (error) {
        showErrorToast("Fail To Load Data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <Loading data-testid="loading-spinner" />;
  return (
    <SubjectMatterLayout
      loading={loading}
      pageData={pageData}
      data-testid="subject-matter-layout"
    />
  );
}
