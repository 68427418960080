import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../slices/navSlice";
import LogoComponent from "./Logo/LogoComponent";
import SearchComponent from "./Search/SearchComponent";
import NotificationComponent from "./Notification/NotificationComponent";
import MobileNavFooter from "../MobileNav/MobileNavFooter";
import MobileNav from "../MobileNav/MobileNav";
import AppDrawer from "../AppDrawer/AppDrawer";
import AppPopover from "./Navigation/AppPopover";
import DropdownMenu from "./DropdownMenu";
import AvatarComponent from "./Avatar/AvatarComponent"; // New Avatar component
import NavigationBar from "./Navigation/NavigationBar";
import { useState, useEffect } from "react";
import LinkHeader from "./LinkHeader/LinkHeader";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useResize from "../../hooks/useResize";
const MainHeader = () => {
  const dispatch = useDispatch();
  const isAbove600px = useMediaQuery("(min-width:600px)");
  const isMobile = useMediaQuery("(max-width:1493px)");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  const OpenApps = () => {
    dispatch(setOpen(true));
    handleDrawerClose();
  };
  const { isZoomed } = useResize();
  const isDarkTheme = useSelector((state) => state.theme);
  console.log(isMobile, isZoomed, "-----Zooming----");

  return (
    <Box
      sx={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer3
          : lightTheme.backgroundColorLayer3,
      }}
    >
      {/* Fixed LinkHeader */}

      {/* Fixed AppBar directly below LinkHeader */}
      <AppBar
        position="fixed"
        sx={{
          bgcolor: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
          color: "black",
          boxShadow: "none",
          borderBottom: "1px solid #ddd",
          width: "100%",
          zIndex: 1200, // Ensure it's below LinkHeader
        }}
      >
        <LinkHeader />
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            {(isMobile || isZoomed) && (
              <IconButton onClick={handleDrawerOpen} data-testid="test-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                >
                  <path
                    d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
                    fill={
                      isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1
                    }
                  />
                </svg>
              </IconButton>
            )}

            <MobileNav
              drawerOpen={drawerOpen}
              handleDrawerClose={handleDrawerClose}
            />
            {/* <AppDrawer /> */}
            <LogoComponent />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "4px", md: "14px" },
              // mr: ".1rem",
              ml: "auto",
            }}
          >
            <SearchComponent />
            {/* <NotificationComponent /> */}
            {!isAbove600px && <MobileNavFooter OpenApps={OpenApps} />}
            {/* {isAbove600px && <AppPopover />} */}
            <AvatarComponent />
            <DropdownMenu />
          </Box>
        </Toolbar>
        <div
          style={{
            height: "1px",
            backgroundColor: "#ddd", // Line color
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Shadow effect
            display: isMobile || isZoomed ? "none" : "",
          }}
        ></div>
        <Box
          sx={{
            display: isMobile || isZoomed ? "none" : "",
            background: "yellow",
          }}
        >
          <NavigationBar />
        </Box>
      </AppBar>
    </Box>
  );
};

export default MainHeader;
