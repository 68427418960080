import React, { useEffect, useState } from "react";
import { TabsNavigation, Tab } from "pepsico-ds";
import UploadTabSection from "./UploadTabSection";
import { useSelector } from "react-redux";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import Loading from "../../utils/Loader";
import { sortTableData } from "../../utils/function";
import PropTypes from "prop-types";
export default function UploadPendingApproval({ search }) {
  UploadPendingApproval.propTypes = {
    search: PropTypes.string,
  };
  const [tab, setTab] = useState(0);
  const handleClick = (index) => setTab(index);
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [videos, setVideos] = useState([]);
  const [links, setLinks] = useState([]);

  const isDarkTheme = useSelector((state) => state.theme);
  useEffect(() => {
    setLoading(true);
    fetchHistory().then((data) => {
      if (data?.status === 200) {
        console.log(data, "-------responseeee------");
        const combinedData = [
          ...(data?.data?.documents ?? []),
          ...(data?.data?.videos ?? []),
          ...(data?.data?.links ?? []),
        ];
        setDocuments(data?.data?.documents);
        setRow(combinedData);
        setTotalData(combinedData);
      }
      setVideos(data?.data?.videos);
      setLinks(data?.data?.links);
    });
  }, []);

  useEffect(() => {
    let filterRows = {
      documents: [],
      videos: [],
      links: [],
    };

    const filteredRecords = row.filter((record) => {
      if (record?.title?.toLowerCase()?.includes(search?.toLowerCase())) {
        if (record?.contentType === "DOCUMENT") {
          filterRows.documents.push(record);
        }
        if (record?.contentType === "VIDEO") {
          filterRows.videos.push(record);
        }
        if (record?.contentType === "LINK") {
          filterRows.links.push(record);
        }
        return true;
      }
      return false;
    });

    setDocuments(filterRows.documents);
    setVideos(filterRows.videos);
    setLinks(filterRows.links);
    setTotalData(filteredRecords); // Ensure totalData is updated to match filtered results
  }, [search, row]);

  const fetchHistory = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/user-upload/pending-content-list?userId=ADMIN`
      );
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  console.log(videos, "-----emptyVideos-----");

  if (loading) return <Loading />;
  return (
    <div className="flex flex-col gap-6">
      <TabsNavigation selectedIndex={tab} isDark={isDarkTheme}>
        <Tab
          iconTrailing={false}
          label={`All (${totalData?.length}) `}
          onClick={() => handleClick(0)}
          orientation="horizontal"
          isDark={isDarkTheme}
          className="!text-[#616161]"
        />
        <Tab
          iconTrailing={false}
          label={`Documents (${documents?.length ? documents?.length : 0}) `}
          onClick={() => handleClick(1)}
          orientation="horizontal"
          isDark={isDarkTheme}
          className="!text-[#616161]"
        />
        <Tab
          label={`Videos (${videos?.length ? videos?.length : 0})`}
          iconTrailing={false}
          onClick={() => handleClick(2)}
          isDark={isDarkTheme}
          className="!text-[#616161]"
        />
        <Tab
          label={`Links (${links?.length ? links?.length : 0})`}
          iconTrailing={false}
          onClick={() => handleClick(3)}
          isDark={isDarkTheme}
          className="!text-[#616161]"
        />
      </TabsNavigation>
      {tab === 0 && (
        <UploadTabSection data={sortTableData(totalData, "title")} />
      )}
      {tab === 1 && (
        <UploadTabSection data={sortTableData(documents, "title")} />
      )}
      {tab === 2 && <UploadTabSection data={sortTableData(videos, "title")} />}
      {tab === 3 && <UploadTabSection data={sortTableData(links, "title")} />}
    </div>
  );
}
