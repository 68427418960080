import React, { useState, useRef, useEffect } from "react";
import { Box, Divider, useMediaQuery } from "@mui/material";
import MobileNotifications from "../Notifications/MobileNotifications";
import commonprivacy from "./../../assets/tandc.pdf";
import axios from "axios";
import { FILE_SERVICE_BASE_URL } from "../../constant/apiUrl";
import { Checkbox, Link, Modal, TextInput, Dropdown } from "pepsico-ds";
import "./AccessRequestForm.css";

import Loading from "../../utils/Loader";

const AccessRequestForm = ({
  open,
  handleClose,
  errors,
  setErrors,
  checkBox,
  setCheckBox,
}) => {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const companyRef = useRef(null);
  const emailRef = useRef(null);
  const confirmEmailRef = useRef(null);
  const sponsorEmailRef = useRef(null);
  const contactNumberRef = useRef(null);
  const countryRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:1000px)");
  const [selectedCountry, setSelectedCountry] = useState();
  const [countries, setCountries] = useState([]);
  const setCountry = (val) => {
    setSelectedCountry(val);
  };

  const handleModalClose = () => {
    setCountry(null);
    handleClose();
  };
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    type: "", // success, information, warning, error
    message: "",
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };
  const validateEmail = (value) => {
    const regex = /^[a-zA-Z0-9._%+-]+@pepsico\.com$/;
    return regex.test(value);
  };

  useEffect(() => {
    const fetchCountry = async () => {
      const response = await axios.get("../../testData/country.json");
      if (response?.status === 200) {
        const data = response.data;
        const newData = data.filter((item) => {
          return item.active === true;
        });
        setCountries(newData);
      }
    };
    fetchCountry();
  }, []);
  const handleRequestAccess = async () => {
    if (termsAccepted === false) {
      setCheckBox(true);
    }

    const firstName = firstNameRef.current?.value;
    const lastName = lastNameRef.current?.value;
    const company = companyRef.current?.value;
    const email = emailRef.current?.value;
    const confirmEmail = confirmEmailRef.current?.value;
    const sponsorEmail = sponsorEmailRef.current?.value;
    const contactNumber = contactNumberRef.current?.value;
    const country = selectedCountry ? selectedCountry[0].id : null;

    let newErrors = {
      firstName: firstName ? null : " ",
      lastName: lastName ? null : " ",
      email: isValidEmail(email) ? null : " ",

      confirmEmail:
        confirmEmail === email ? null : "Confirm Email must match Email",
      company: company ? null : " ",
      sponsorEmail: validateEmail(sponsorEmail) ? null : "Not a Pepsico Email",
      country: country === null ? " " : null,
      termsAccepted: termsAccepted ? null : "You must accept the terms",
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    const requestData = {
      firstName,
      lastName,
      company,
      email,
      sponsorEmail,
      countryId: country,
      contactNo: contactNumber,
      termsCondition: termsAccepted,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${FILE_SERVICE_BASE_URL}/fritolay/admin/access-request-form/save`,
        requestData
      );

      if (response.status === 200 || response.status === 201) {
        setNotification({
          open: true,
          type: "success",
          message: "Access request submitted successfully!",
        });

        // Reset fields
        firstNameRef.current.value = null;
        lastNameRef.current.value = "";
        companyRef.current.value = "";
        emailRef.current.value = "";
        confirmEmailRef.current.value = "";
        sponsorEmailRef.current.value = "";
        contactNumberRef.current.value = "";
        setCountry(null);
        setTermsAccepted(false);
        setErrors({});
        setCheckBox(false);
        handleModalClose();
      }
    } catch (error) {
      console.log(error, "api error");

      setNotification({
        open: true,
        type: "error",
        message:
          error.response?.data?.validationErrors?.message ||
          "An error occurred while submitting the access request. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };
  console.log(errors, "-----errrorsss----");

  return (
    <>
      {loading && <Loading />}
      <Modal
        auxiliaryButtonProps={{}}
        className="sign-in-access-request-modal"
        linkProps={{}}
        onCloseModal={handleModalClose}
        primaryButtonProps={{
          variant: "primary",
          onClick: handleRequestAccess,
          text: "Request Access",
        }}
        secondaryButtonProps={{
          variant: "secondary",
          onClick: handleModalClose,
          text: "Cancel",
        }}
        showAuxiliaryButton={false}
        showModal={open}
        tertiaryButtonProps={{}}
        title="Access Request Form"
        type={isMobile ? "L" : "S"}
      >
        <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",

              gap: isMobile ? "" : "16px",
            }}
          >
            <TextInput
              helperText=""
              label="First Name"
              placeholderText="Type here"
              required
              ref={firstNameRef}
              error={errors.firstName}
              inputSize={isMobile ? "small" : "medium"}
            />
            <TextInput
              helperText=""
              label="Last Name"
              placeholderText="Type here"
              required
              error={errors.lastName}
              ref={lastNameRef}
              inputSize={isMobile ? "small" : "medium"}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: isMobile ? "" : "16px",
            }}
          >
            <TextInput
              helperText=""
              label="Email Address"
              placeholderText="Type here"
              required
              ref={emailRef}
              error={errors.email}
              inputSize={isMobile ? "small" : "medium"}
            />
            <TextInput
              helperText=""
              label="Confirm Email"
              placeholderText="Type here"
              required
              ref={confirmEmailRef}
              inputSize={isMobile ? "small" : "medium"}
              error={errors.confirmEmail}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: isMobile ? " " : "16px",
            }}
          >
            <TextInput
              helperText=""
              label="Company/Organization"
              placeholderText="Type here"
              required
              error={errors.company}
              ref={companyRef}
              inputSize={isMobile ? "small" : "medium"}
            />
            <TextInput
              helperText=""
              label="Contact Number"
              placeholderText="Type here"
              required={false}
              ref={contactNumberRef}
              // onKeyUp={handleKeyStroke}
              inputSize={isMobile ? "small" : "medium"}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "" : "16px",
              alignItems: "center",
            }}
          >
            {/* <TextInput
              helperText=""
              label="Country"
              placeholderText="Type here"
              required
              error={errors.country}
              ref={countryRef}
              // onKeyUp={handleKeyStroke}
              inputSize={isMobile ? "small" : "medium"}
            /> */}
            <Dropdown
              childList={countries.map((country) => ({
                id: country.id,
                displayText: country.name,
              }))}
              className="modal-form-country-dropdown"
              dropdownHeader=""
              feedbackType="info"
              helperText=""
              isClearable={false}
              isRequired
              label="Country"
              placeholder="Select"
              selection="single"
              selectedValue={selectedCountry}
              setSelectedValue={setCountry}
              size={isMobile ? "small" : "medium"}
              error={errors.country}
              tabIndex={7}
            />

            <TextInput
              helperText=""
              label="PepsiCo Sponsor’s Email"
              placeholderText="Type here"
              required
              ref={sponsorEmailRef}
              inputSize={isMobile ? "small" : "medium"}
              error={errors.sponsorEmail}
            />
          </Box>
        </Box>

        <Divider sx={{ marginTop: "25px", marginBottom: "16px" }} />

        {/* Terms and Conditions Checkbox */}
        <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
          <Checkbox
            checked={termsAccepted}
            text="Accept"
            label={undefined}
            onUpdate={(value) => {
              setTermsAccepted(value);
              setCheckBox(false);
            }}
            size={isMobile ? "small" : "large"}
            className="access-request-terms-checkbox"
          />
          <Link
            label="Terms and Conditions"
            size={isMobile ? "small" : "large"}
            useAnchor={false}
            onClick={() =>
              window.open(commonprivacy, "_blank", "noopener noreferrer")
            }
            style={{ fontWeight: "bold", color: checkBox ? "red" : "#005cbc" }}
          />
        </div>

        <Divider sx={{ marginY: 2 }} />
      </Modal>

      {/* Notification */}
      <MobileNotifications
        type={notification.type}
        message={notification.message}
        open={notification.open}
        handleClose={handleCloseNotification}
      />
    </>
  );
};

export default AccessRequestForm;
