import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Card, Grid } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import { formatDate } from "../../utils/function";
import LazyImage from "../../utils/LazyImage";
import { useSelector } from "react-redux";

function DocumentDetails({ documentData, content }) {
  const insideCardBackgroundColorLayer4 = useThemeRender(
    "insideCardBackgroundColorLayer4"
  );
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  const { flagUser } = useSelector((store) => store.user);

  const gridData1 = [
    { label: "View count", value: documentData?.viewCount },
    { label: "Likes count", value: documentData?.likes },
    { label: "Content level", value: documentData?.contentLevel },
    {
      label: "Document type",
      value: documentData?.documentType,
      visible: content !== "video",
    },
    { label: "Language", value: documentData?.languages },
    { label: "Version", value: documentData?.version },
    { label: "Edited by", value: documentData?.editedBy },
    { label: "Upload date", value: formatDate(documentData?.createdOn) },
    { label: "Uploaded by", value: documentData?.createdBy },
    { label: "Reviewed date", value: formatDate(documentData?.reviewDate) },
  ];

  const grid =
    flagUser?.roleName?.toLowerCase() !== "external"
      ? [
          ...gridData1,
          {
            label: "Legal review",
            value: documentData?.isLegalReview ? "Yes" : "No",
          },
        ]
      : gridData1;

  return (
    <Card
      className="SummaryCard"
      sx={{ background: useThemeRender("backgroundColorLayer3") }}
      data-testid="test-doumentdetails"
    >
      <Typography
        className="SummaryTitle"
        sx={{ color: useThemeRender("textColorLayer2") }}
      >
        Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-16, 16px)",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
          background: useThemeRender("backgroundColorLayer1"),
          height: "auto",
        }}
      >
        <LazyImage
          width={276}
          height={103}
          id={documentData?.thumbnailFileId}
        />
        <Grid
          container
          rowSpacing={1.5}
          columnSpacing={2}
          className="DetailCardGridContainer"
        >
          {grid.map(
            (item) =>
              item.visible !== false && (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={item.label === "Subjects" ? 12 : 3}
                  key={item.label}
                  className="DetailCardGrid"
                >
                  <Box
                    className="DetailCardBox"
                    sx={{ background: insideCardBackgroundColorLayer3 }}
                  >
                    <Typography
                      className="DetailCardGridFirstContent"
                      sx={{ color: textColorLayer4 }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      className="DetailCardGridSecondContent"
                      sx={{ color: textColorLayer4 }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                </Grid>
              )
          )}
          <Grid item xs={12} className="DetailCardGrid">
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "auto",
                padding:
                  "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px)",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "var(--Spacing-spacing-8, 8px)",
                borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                Subjects
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  flexWrap: "wrap",
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--Spacing-spacing-12, 12px)",
                  height: "auto",
                }}
              >
                {documentData?.subjects?.map((val) => (
                  <Typography
                    sx={{
                      background: insideCardBackgroundColorLayer4,
                      color: textColorLayer1,
                      padding: "4px 4px",
                      lineHeight: "16px",
                      borderRadius: "4px",
                      fontSize: "var(--Font-font-size-small, 12px)",
                    }}
                    key={val}
                  >
                    {val}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className="DetailCardGrid">
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "auto",
                padding:
                  "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px)",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "var(--Spacing-spacing-8, 8px)",
                borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
            >
              <Typography
                className="DetailCardGridFirstContent"
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
              >
                {content === "video" ? "Keywords" : "Tags"}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  flexWrap: "wrap",
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--Spacing-spacing-12, 12px)",
                  height: "auto",
                }}
              >
                {documentData?.keywords?.split(",")?.map((val) => (
                  <Typography
                    sx={{
                      background: insideCardBackgroundColorLayer4,
                      color: textColorLayer1,
                      padding: "4px 4px",
                      lineHeight: "16px",
                      borderRadius: "4px",
                      fontSize: "var(--Font-font-size-small, 12px)",
                    }}
                    key={val}
                  >
                    {val}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

// ✅ PropTypes Validation
DocumentDetails.propTypes = {
  documentData: PropTypes.shape({
    viewCount: PropTypes.number,
    likes: PropTypes.number,
    contentLevel: PropTypes.string,
    documentType: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    version: PropTypes.string,
    editedBy: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    reviewDate: PropTypes.string,
    isLegalReview: PropTypes.bool,
    subjects: PropTypes.arrayOf(PropTypes.string),
    keywords: PropTypes.string,
    thumbnailFileId: PropTypes.number,
  }).isRequired,
  content: PropTypes.oneOf(["video", "document"]), // Ensures only "video" or "document" are valid
};

export default DocumentDetails;
