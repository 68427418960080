import { Box, useMediaQuery } from "@mui/material";
import { Button } from "pepsico-ds";
import DividerComponent from "../CommonComponent/DividerComponent";
import { useForm } from "react-hook-form";
import TextInputField from "../../../../utils/Fields/TextInputField";
import AutoCompleteField from "../../../../utils/Fields/AutoComplete";
import { useEffect, useState } from "react";
import { postAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import formValidations from "./formValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";
import { useSelector, useDispatch } from "react-redux";
import { setDisplay } from "../../../../slices/adminPageSlice";
import { darkTheme } from "../../../../theme/Theme";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email address is required"),
  company: yup.string().required("Company/Organization is required"),
  countryId: yup.string().required("Country is required"),
  sponsorEmail: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._%+-]+@pepsico\.com$/,
      "Email must end with @pepsico.com"
    )
    .required("Email address is required"),
});

export default function ExternalForm() {
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallOrMedium = useMediaQuery("(max-width: 800px)");
  const [autoComplete, setAutoComplete] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDisplay(false));
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { countryFilter } = useSelector((store) => store.internalFilter);

  const onFieldChange = (field, value) => {
    setValue(field, value); // Update the field value
    const validationErrors = formValidations(
      getValues("firstName"),
      getValues("lastName"),
      getValues("email"),
      getValues("company"),
      getValues("countryId"),
      getValues("sponsorEmail")
    );

    if (field === "countryId" && value) {
      // Clear country errors explicitly if a value exists
      clearErrors(field);
    } else if (validationErrors[field]) {
      setError(field, { type: "manual", message: validationErrors[field] });
    } else {
      clearErrors(field); // Clear errors for other fields if validation passes
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const newData = { ...data, countryId: autoComplete };

    console.log(newData, "-----payloadData--------");

    try {
      const response = await postAPI(
        `${BASE_URL}/invitations/send-external-user`,
        newData
      );

      if (response?.status === 200) {
        showSuccessToast("Invitation sent successfully!");
        handleClose();
      } else if (response?.status === 409) {
        showErrorToast("User Already Exist");
      } else {
        showErrorToast("Failed to send the invitation. Please try again.");
      }
    } catch (error) {
      console.error("API Error:", error);
      showErrorToast("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    reset();
    setValue("country", null);
    setAutoComplete(null);
  };

  const onInvalid = (data) => {
    console.log("Validation Errors:", data);
  };

  return (
    <Box
      sx={{
        minHeight: "60vh",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : "var(--color-interaction-form-background)",
        boxShadow:
          "var(--Elevation-X-Elevation-04, 4px) var(--Elevation-Y-Elevation-04, 0px) var(--Elevation-Blur-Elevation-04, 24px) var(--Elevation-Spread-Elevation-04, -2px) var(--Elevation-Colors-Elevation-04, rgba(0, 92, 188, 0.08))",
        padding: "48px 24px 24px",
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
          height: isMobile ? "100%" : "auto",
        }}
        onSubmit={handleSubmit(onSubmit, onInvalid)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-16, 16px)",
            alignSelf: "stretch",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: isMobile ? "100%" : "58px",
              alignItems: isMobile ? "centre" : "flex-start",
              flexDirection: isMobile ? "column" : "",
              width: isMobile ? "100%" : "400px",
              gap: "var(--Spacing-spacing-16, 16px)",
              alignSelf: "stretch",
            }}
          >
            <TextInputField
              label="First name"
              errors={errors}
              register={register}
              name="firstName"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              isMobile={isMobile}
              placeholder="Type here"
              onChange={(e) => onFieldChange("firstName", e.target.value)}
              datatestid="text-input-first-name"
            />

            <TextInputField
              label="Last name"
              errors={errors}
              register={register}
              name="lastName"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              isMobile={isMobile}
              placeholder="Type here"
              onChange={(e) => onFieldChange("lastName", e.target.value)}
              datatestid="text-input-last-name"
            />

            <TextInputField
              label="Email address"
              errors={errors}
              register={register}
              name="email"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              isMobile={isMobile}
              type="email"
              placeholder="Type here"
              onChange={(e) => onFieldChange("email", e.target.value)}
              datatestid="text-input-email-address"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              height: isMobile ? "100%" : "58px",
              alignItems: isMobile ? "centre" : "flex-start",
              flexDirection: isMobile ? "column" : "",
              width: isMobile ? "100%" : "400px",
              gap: "var(--Spacing-spacing-16, 16px)",
              alignSelf: "stretch",
            }}
          >
            <TextInputField
              label="Company/Organisation"
              errors={errors}
              register={register}
              name="company"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              isMobile={isMobile}
              placeholder="Type here"
              onChange={(e) => onFieldChange("company", e.target.value)}
              datatestid="text-input-organisation"
            />

            <AutoCompleteField
              key={autoComplete}
              label="Country"
              options={countryFilter?.map((item) => ({
                label: item.name,
                id: item.id,
              }))}
              getOptionLabel={(option) => option.name || ""}
              name="countryId"
              placeholder="Select"
              isMobile={true}
              errors={errors}
              datatestid="text-input-country"
              required
              value={
                autoComplete
                  ? {
                      id: autoComplete,
                      label: countryFilter.find(
                        (item) => item.id === autoComplete
                      )?.name,
                    }
                  : null
              }
              onChange={(newValue) => {
                const selectedCountryId = newValue?.id || null;
                setAutoComplete(selectedCountryId); // Update selected value
                setValue("countryId", selectedCountryId); // Sync with form field
                onFieldChange("countryId", selectedCountryId); // Trigger validation
              }}
              register={register}
              width={"400px"}
            />

            <TextInputField
              label="PepsiCo sponsor’s email address"
              errors={errors}
              register={register}
              name="sponsorEmail"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              type="email"
              isMobile={isMobile}
              placeholder="Type here"
              onChange={(e) => onFieldChange("sponsorEmail", e.target.value)}
              datatestid="text-input-pepsico-email"
            />
          </Box>

          <DividerComponent />
        </Box>

        <FormActions
          isMobile={isMobile}
          handleClose={handleClose}
          isSubmitting={isSubmitting}
          reset={reset}
        />
      </form>
    </Box>
  );
}

const FormActions = ({ isMobile, handleClose, isSubmitting }) => {
  return (
    <div
      className="flex flex-col gap-4"
      style={{
        width: "100%",
        marginTop: "35vh",
      }}
    >
      <DividerComponent />
      <Box
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-end",
          alignItems: isMobile ? "" : "flex-end",
          flexDirection: isMobile ? "column-reverse" : "row",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <Button
          size="large"
          text="Cancel"
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
          data-testid="cancel-button"
          style={{
            width: isMobile ? "100%" : "",
            justifyContent: "center",
          }}
        />

        <Button
          size="large"
          text="Send Invitation"
          variant="primary"
          type="submit"
          data-testid="submit-button"
          disabled={isSubmitting}
          style={{
            width: isMobile ? "100%" : "",
            justifyContent: "center",
          }}
        />
      </Box>
    </div>
  );
};

FormActions.propTypes = {
  isMobile: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.func.isRequired,
};
