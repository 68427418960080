import React from "react";
import { useSelector } from "react-redux";

import UploadPendingApproval from "./UploadPendingApproval";
import UploadPendigHistory from "./UploadPendigHistory";

export default function UploadRenderPage({ search }) {
  const { select } = useSelector((store) => store.uploadHistory);

  const renderPage = () => {
    console.log("Render page for:", select); // In UploadRende
    switch (select) {
      case "Pending approvals":
        return <UploadPendingApproval search={search} />;
      case "History":
        return <UploadPendigHistory />;

      default:
        return <div>Helo</div>;
    }
  };

  return <div className="w-full">{renderPage()}</div>;
}
