const cardContainer = {
  display: "flex",
  padding: "var(--Spacing-spacing-16, 16px)",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--Spacing-spacing-16, 16px)",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  boxShadow:
    "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
};

const buttonStyle = {
  display: "flex",
  height: "var(--Spacing-spacing-32, 32px)",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  borderRadius: "var(--Corner-radius-corner-radius-large, 24px)",
  border:
    "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
};

const linkContainer = {
  display: "flex",
  padding: "4px 0px", // `var(--Spacing-spacing-02, 4px)` for top and bottom, `var(--Spacing-spacing-0, 0px)` for left and right
  justifyContent: "center",
  alignItems: "center",
  gap: "4px", // `var(--Spacing-spacing-02, 4px)`
  cursor: "pointer",
};

const linkTypography = {
  color: "var(--Tokens-Button-Borderless, #005CBC)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "0.75rem",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
};
const arrowButtonStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
};

export {
  arrowButtonStyle,
  linkContainer,
  linkTypography,
  buttonStyle,
  cardContainer,
};
