// InternalPageLayout.js
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InternalHeader from "../components/InternalPages/InternalHeader";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import Loading from "../utils/Loader";
import TabContentView from "../components/LibraryPage/TabContentView";
import LibraryFilter from "../components/LibraryPage/LibraryFilter";
import { Link, useParams } from "react-router-dom";
import ViewSummary from "../components/ViewSummary/ViewSummary";

export default function LibraryDetails() {
  const selectedView = useSelector((state) => state.view);
  const [loading, setLoading] = useState(false);
  const [grid, setGrid] = useState(selectedView);
  const [pageData, setPageData] = useState(null);
  const [pageHeader, setPageHeader] = useState();
  const [searchText, setSearchText] = useState(null);
  const { favourite } = useSelector((store) => store.internalPage);
  const [filteredData, setFilteredData] = useState(null);
  const queryParams = useParams();
  const updateTheViewType = (viewType) => {
    setGrid(viewType);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/library-page/find-by-subject-id?subjectId=${queryParams?.id}`
        );
        setPageData(response?.data?.response);
        setPageHeader(response?.data?.response?.subjectItems?.[0]?.subjectName);
        setFilteredData(response?.data?.response);
      } catch (err) {
        console.log("Library Details Error", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [favourite]);

  useEffect(() => {
    console.log("Library Details searchText", searchText, searchText?.length);
    if (searchText?.length > 0) {
      let filteredItemData = {
        subjectId:pageData?.[0]?.subjectId,
        subjectName: pageData?.[0]?.subjectName,
        documents: [],
        links: [],
        videos: [],
      }
      const filteredItem = searchItems(searchText);
      filteredItemData.documents = filteredItem?.documents;
      filteredItemData.videos = filteredItem?.videos;
      filteredItemData.links = filteredItem?.links;
      console.log('subjectItems', filteredItem, filteredItemData);
      setFilteredData({subjectItems:[filteredItemData]});
    } else {
      setFilteredData(pageData);
    }
  }, [searchText]);

  const searchItems = (text) => {
    const subjectItems = {
      documents: [],
      links: [],
      videos: [],
    
    };
    console.log("Library Details pageData", pageData[0]);
    const result = pageData?.subjectItems?.filter((item) => {
      // const subjectNameMatch = item.subjectName
      //   .toLowerCase()
      //   .includes(text.toLowerCase());
      console.log("Library Details text", text);
      const documentTitlesMatch = item.documents.filter((doc) => {
        if (doc.title.toLowerCase().includes(text.toLowerCase())) {
          subjectItems.documents.push(doc);
        }
      });
      
      const videoTitlesMatch = item.videos.filter((video) => {
        if (video.title.toLowerCase().includes(text.toLowerCase())) {
          subjectItems.videos.push(video);
        }
      });

      const linkTitlesMatch = item.links.some((link) => {
        if (link.title.toLowerCase().includes(text.toLowerCase())) {
          subjectItems.links.push(link);
        }
      });

      return (
        //  subjectNameMatch ||
        documentTitlesMatch || videoTitlesMatch || linkTitlesMatch
      );
    });

    console.log("Library Details documentTitlesMatch", result, subjectItems);

    return subjectItems;
  };

  const setSearchTextData = (data) => {
    console.log("setSearchTextData Details data", data);
    setTimeout(() => {
      setSearchText(data);
    }, 800);
  };

  console.log("Library Details data", pageData, filteredData);

  return loading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <div
        style={{
          display: "flex",
          height: "40px",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Link
          to={"/library"}
          style={{
            fontWeight: 700,
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            color: "#005CBC",
          }}
        >
          <ArrowBackIcon
            sx={{
              width: "24px",
              height: "24px",
              top: "2px",
              left: "4px",
              paddingRight: "8px",
            }}
          />
          Back to library
        </Link>
      </div>
      <InternalHeader header={pageHeader} /> {/* Pass setSelection */}
      <Box sx={{ zIndex: 1000 }}>
        {/* Pass setSelection to InternalFilter */}
        <LibraryFilter
          setGrid={updateTheViewType}
          pageData={pageData}
          header={"LibraryDetails"}
          grid={grid}
          setSearchTextData={setSearchTextData}
          setSelection={null}
        />
      </Box>
      {filteredData !== null && (
        <TabContentView
          tabData={filteredData}
          pageName="LibraryDetails"
          selectedView={grid}
        />
      )}
      <ViewSummary />
    </Box>
  );
}
