const getTextFieldStyles = (
  isMobile,
  isDarkTheme,
  darkTheme,
  width = "338px"
) => ({
  width: isMobile ? "100%" : width, // Adjust width for mobile/desktop
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white", // Background color for dark mode
  "& .MuiInputBase-root": {
    color: isDarkTheme ? "white " : "black", // Input text color
    height: "32px", // Height for desktop
  },
  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ", // Border color
  },
  "& .MuiSvgIcon-root": {
    color: isDarkTheme ? "white" : "black", // Icon color
  },
  "& .MuiInputBase-input::placeholder": {
    fontWeight: 400,
    fontSize: "var(--Font-Size-Small, 14px)",
    color: "var(--color-interaction-disabled)",
    lineHeight: "var(--Line-Height-Small, 20px)",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: "Inter",
    padding: "0px var(--Spacing-spacing-8, 8px)",
  },
});

const datePickerStyles = (isDarkTheme, isMobile, darkTheme, commonStyles) => ({
  width: isMobile ? "100%" : "338px", // Adjust width for mobile/desktop
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white", // Background color for dark mode
  "& .MuiInputBase-root": {
    color: isDarkTheme ? "white" : "black", // Input text color
    height: "32px", // Height for desktop
  },
  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ", // Border color
  },
  "& .MuiSvgIcon-root": {
    color: isDarkTheme ? "white" : " ", // Icon color
  },
});

const autoCompletStyles = (
  isDarkTheme,
  isMobile,
  darkTheme,
  width = "338px",
  fontSize = "14px"
) => ({
  width: isMobile ? "100%" : width,
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white",

  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ",
    height: "32px",
    "&:hover": {
      borderColor: isDarkTheme ? "white" : " ",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "2px",
    },
    "& .MuiAutocomplete-input": {
      padding: 0,
      color: isDarkTheme ? "white" : "var(--color-Text-Primary)",
      fontSize: `var(--Font-Size-Small, ${fontSize})`,
    },
  },
  "& .MuiInputBase-input": {
    color: isDarkTheme ? "white" : " ",
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: isDarkTheme ? "white" : " ", // Change dropdown icon color
  },
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
    padding: 0,
  },
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    padding: "6px 8px",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A0A0A0",
  },
});

export { getTextFieldStyles, datePickerStyles, autoCompletStyles };
