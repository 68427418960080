import React, { useState, useEffect } from "react";
import { Box, Typography, Card, Button } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { BASE_URL } from "../../constant/apiUrl";
import { postAPI } from "../../API/ApiService";
import { showErrorToast } from "../../utils/Toast/toast";
import { handleShare } from "../../utils/function";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
function DocumentSummary({
  documentData,
  handleAddFavourite,
  contentId,
  fetch,
  setFetch,
}) {
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const locaion = useLocation();
  useEffect(() => {
    if (documentData) {
      setIsLiked(documentData.isLiked);
      setIsDisliked(documentData.isDisliked);
    }
  }, [documentData]); // Runs when documentData updates

  const handleLike = async () => {
    if (isLiked) {
      const data = {
        contentId: contentId,
        likes: false,
        dislikes: false,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          data
        );
        if (response.status === 200) {
          setIsLiked(false); // Remove dislike
          setFetch(!fetch);
        }
      } catch {}
    } else {
      const newData = {
        contentId: contentId,
        likes: true,
        dislikes: false,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          newData
        );
        if (response?.status === 200) {
          setIsLiked(true); // Set like
          setIsDisliked(false); // Remove dislike (toggle logic)
          setFetch(!fetch);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDislike = async () => {
    if (isDisliked) {
      const data = {
        contentId: contentId,
        likes: false,
        dislikes: false,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          data
        );
        if (response.status === 200) {
          setIsDisliked(false); // Remove dislike

          setFetch(!fetch);
        }
      } catch {}
    } else {
      const newData = {
        contentId: contentId,
        likes: false,
        dislikes: true,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/content-metrics/save`,
          newData
        );
        if (response.status === 200) {
          setIsDisliked(true); // Set dislike
          setIsLiked(false); // Remove like (toggle logic)
          setFetch(!fetch);
        }
        if (response?.status === 400) {
          showErrorToast(response?.message);
        }
      } catch (error) {
        console.log(error, "-----err-------");
      }
    }
  };

  return (
    <Card
      className="SummaryCard"
      sx={{
        background: useThemeRender("backgroundColorLayer3"),
      }}
    >
      <Typography
        className="SummaryTitle"
        sx={{
          color: useThemeRender("textColorLayer2"),
        }}
      >
        Summary
      </Typography>
      <Box
        className="SummaryContentBox"
        sx={{
          background: useThemeRender("backgroundColorLayer1"),
        }}
      >
        <Typography
          className="SummaryContents"
          sx={{
            color: useThemeRender("textColorLayer2"),
          }}
        >
          {documentData?.summary}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "var(--Spacing-spacing-16, 16px)",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "var(--Spacing-spacing-32, 32px)",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="LogoButton"
              onClick={handleAddFavourite}
              disabled={isDisliked}
              data-testid="test-favourite-button"
            >
              {documentData?.isFavorite ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 17"
                  fill="none"
                >
                  <path
                    d="M11.3417 6.18366L17.3334 6.70033L12.7917 10.642L14.15 16.5003L9.00002 13.392L3.85002 16.5003L5.21669 10.642L0.666687 6.70033L6.65835 6.19199L9.00002 0.666992L11.3417 6.18366Z"
                    fill="#005CBC"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M18.3334 7.70033L12.3417 7.18366L10 1.66699L7.65835 7.19199L1.66669 7.70033L6.21669 11.642L4.85002 17.5003L10 14.392L15.15 17.5003L13.7917 11.642L18.3334 7.70033ZM10 12.8337L6.86669 14.7253L7.70002 11.1587L4.93335 8.75866L8.58335 8.44199L10 5.08366L11.425 8.45033L15.075 8.76699L12.3084 11.167L13.1417 14.7337L10 12.8337Z"
                    fill="#005CBC"
                  />
                </svg>
              )}
              <Typography className="LogoContent">Favorite</Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "var(--Spacing-spacing-32, 32px)",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button className="LogoButton" onClick={() => handleShare(locaion)}>
              <Typography className="LogoContent">Share</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 13.3998C14.3667 13.3998 13.8 13.6498 13.3667 14.0415L7.425 10.5832C7.46667 10.3915 7.5 10.1998 7.5 9.99984C7.5 9.79984 7.46667 9.60817 7.425 9.4165L13.3 5.9915C13.75 6.40817 14.3417 6.6665 15 6.6665C16.3833 6.6665 17.5 5.54984 17.5 4.1665C17.5 2.78317 16.3833 1.6665 15 1.6665C13.6167 1.6665 12.5 2.78317 12.5 4.1665C12.5 4.3665 12.5333 4.55817 12.575 4.74984L6.7 8.17484C6.25 7.75817 5.65833 7.49984 5 7.49984C3.61667 7.49984 2.5 8.6165 2.5 9.99984C2.5 11.3832 3.61667 12.4998 5 12.4998C5.65833 12.4998 6.25 12.2415 6.7 11.8248L12.6333 15.2915C12.5917 15.4665 12.5667 15.6498 12.5667 15.8332C12.5667 17.1748 13.6583 18.2665 15 18.2665C16.3417 18.2665 17.4333 17.1748 17.4333 15.8332C17.4333 14.4915 16.3417 13.3998 15 13.3998ZM15 3.33317C15.4583 3.33317 15.8333 3.70817 15.8333 4.1665C15.8333 4.62484 15.4583 4.99984 15 4.99984C14.5417 4.99984 14.1667 4.62484 14.1667 4.1665C14.1667 3.70817 14.5417 3.33317 15 3.33317ZM5 10.8332C4.54167 10.8332 4.16667 10.4582 4.16667 9.99984C4.16667 9.5415 4.54167 9.1665 5 9.1665C5.45833 9.1665 5.83333 9.5415 5.83333 9.99984C5.83333 10.4582 5.45833 10.8332 5 10.8332ZM15 16.6832C14.5417 16.6832 14.1667 16.3082 14.1667 15.8498C14.1667 15.3915 14.5417 15.0165 15 15.0165C15.4583 15.0165 15.8333 15.3915 15.8333 15.8498C15.8333 16.3082 15.4583 16.6832 15 16.6832Z"
                  fill="#005CBC"
                />
              </svg>
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "var(--Spacing-spacing-32, 32px)",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="LogoButton"
              onClick={handleLike}
              data-testid="like-button"
            >
              <Typography className="LogoContent">Like</Typography>
              {isLiked ? (
                <ThumbUpIcon
                  style={{
                    color: "#005CBC",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                <ThumbUpOutlinedIcon
                  style={{
                    color: "#005CBC",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              )}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "var(--Spacing-spacing-32, 32px)",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="LogoButton"
              onClick={handleDislike}
              data-testid="dislike-button"
              disabled={documentData?.isFavorite}
            >
              <Typography className="LogoContent">Dislike</Typography>
              {isDisliked ? (
                <ThumbDownAltIcon
                  style={{
                    color: "#005CBC",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                <ThumbDownOffAltOutlinedIcon
                  style={{
                    color: "#005CBC",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

DocumentSummary.propTypes = {
  documentData: PropTypes.shape({
    summary: PropTypes.string,
    isLiked: PropTypes.bool,
    isDisliked: PropTypes.bool,
    isFavorite: PropTypes.bool,
  }),
  handleAddFavourite: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  fetch: PropTypes.bool.isRequired,
  setFetch: PropTypes.func.isRequired,
};

export default DocumentSummary;
