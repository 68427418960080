import { Box, Button, Drawer, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../../../utils/Loader";
import useThemeRender from "../../../../theme/useThemeRender";
import { useSelector } from "react-redux";
import DrawerHeader from "./DrawerHeader";
import {
  setListOfFieldValueDescription,
  setListOfValueDescriprion,
  setListOfValueName,
  setListOfValueSelectedCountry,
  setlistOfValueSelectedSector,
  setListOfValueStatus,
  setSelectedListOfRowValue,
  setSpecialProjectDescription,
  setSpecialProjectDrawer,
  setSpecialProjectStatus,
  setSpecialProjectTitle,
} from "../../../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import SpecialProjectInformation from "./SpecialProjectInformation";
import { postAPI, getAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import { setFavourite } from "../../../../slices/internalPageSlice";
import "./SpecialProject.css";
import AddUser from "./AddUser";
import axios from "axios";
import ListOfValuesSideTray from "../ListOfValues/ListOfValuesSideTray";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";
function AddSpecialProject({ screen, existingUserData, handleAPIError }) {
  const [drawerHeight, setDrawerHeight] = useState("90%");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeProject, setActiveProject] = useState(true);
  const dispatch = useDispatch();
  const handleResizeStart = (e) => {
    e.preventDefault();
    console.log(existingUserData, "existingUserData");
    const handleResize = (moveEvent) => {
      const currentY = moveEvent.clientY || moveEvent.touches[0].clientY;
      const newHeight = `calc(100% - ${currentY}px)`;
      console.log(newHeight, "newHeight");
      setDrawerHeight(newHeight);
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", stopResize);
      window.removeEventListener("touchmove", handleResize);
      window.removeEventListener("touchend", stopResize);
    };

    window.addEventListener("mousemove", handleResize);
    window.addEventListener("mouseup", stopResize);
    window.addEventListener("touchmove", handleResize);
    window.addEventListener("touchend", stopResize);
  };

  const { specialProjectDrawer } = useSelector((store) => store.adminpage);
  const {
    specialProjectStatus,
    specialProjectTitle,
    specialProjectDescription,
    specialProjectEditId,
  } = useSelector((store) => store.adminpage);
  const { favourite } = useSelector((store) => store.internalPage);
  const handleCancel = () => {
    dispatch(setSpecialProjectDrawer(false));
    dispatch(setSpecialProjectTitle(""));
    dispatch(setSpecialProjectDescription(""));
    dispatch(setSpecialProjectStatus(true));
  };
  const handleAddProject = async () => {
    const newData = {
      name: specialProjectTitle.trim(),
      description: specialProjectDescription.trim(),
      status: specialProjectStatus,
    };
    if (newData?.name?.length > 0) {
      try {
        const result = await postAPI(
          `${BASE_URL}/special-projects/createSpecialProject`,
          newData
        );
        if (result?.status === 200) {
          handleCancel();
          dispatch(setFavourite(favourite + 1));
          showSuccessToast("special project created successfully");
        } else if (result?.status === 400) {
          const errors = result?.data;
          Object.keys(errors).forEach((key) => {
            if (
              errors[key] ===
              "Query did not return a unique result: 2 results were returned"
            ) {
              showErrorToast("Special Project Name already exists");
            } else {
              showErrorToast(errors[key]);
            }
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else if (newData?.name?.length == 0) {
      showErrorToast("Please enter the special project title");
    }
  };
  const handleAddUser = () => {
    const newUser = selectedUser.map((user) => user.id);
    const newData = {
      name: specialProjectTitle.trim(),
      description: specialProjectDescription.trim(),
      status: specialProjectStatus,
      userIds: newUser,
    };
    if (newUser?.length > 0 && newData?.name?.length > 0) {
      const addUser = async () => {
        try {
          const response = await axios.put(
            `${BASE_URL}/special-projects/updateSpecialProject/${specialProjectEditId}`,
            newData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          );
          if (response?.status === 200) {
            dispatch(setFavourite(favourite + 1));
            dispatch(setSpecialProjectDrawer(false));
            setIsActive(false);
            setSearchUser("");
            setSelectedUser([]);
            showSuccessToast("Users updated succesfully");
          }
        } catch (err) {
          handleAPIError(err);
        } finally {
          setLoading(false);
        }
      };
      addUser();
    } else if (newData?.name?.length == 0) {
      showErrorToast("Please enter the special project title");
    } else if (newUser?.length == 0) {
      showErrorToast("Please add the user");
    }
  };

  const [userData, setUserData] = useState([]);
  const [filterUser, setFilterUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [searchUser, setSearchUser] = useState();
  const [isActive, setIsActive] = useState(false);
  const handleSelectUser = (item) => {
    setSelectedUser((prevSelectedUsers) => [...prevSelectedUsers, item]);
  };
  const handleRemoveSelectedUser = (data) => {
    let updatedData = selectedUser.filter((item) => item.id !== data.id);
    setSelectedUser(updatedData);
    const idsToRemove = updatedData?.map((item) => item.id);
    const result = userData?.filter((item) => !idsToRemove?.includes(item.id));
    setFilterUser(result);
  };
  const handleSearchUser = (e) => {
    console.log(e, "---userrr----");

    const searchValue = e.target.value.toLowerCase();
    console.log(searchValue, "----userrr1-------");

    setSearchUser(searchValue);
    const idsToRemove = selectedUser?.map((item) => item.id);
    console.log(idsToRemove, "idsToRemove");
    const result = userData?.filter((item) => !idsToRemove?.includes(item.id));
    const filteredData = result.filter((user) =>
      user.name.toLowerCase().includes(searchValue)
    );
    setFilterUser(filteredData);
  };
  const handleFocus = () => {
    if (!isActive) setIsActive(true);
  };
  console.log(userData, "resultresult");
  const handleBlur = () => {
    if (searchUser?.length === 0 || searchUser == undefined) {
      setTimeout(() => {
        setIsActive(false);
      }, 300);
    }
  };
  const handleResetData = () => {
    if (screen === "add" || screen === "addUser") {
      dispatch(setSpecialProjectDrawer(false));
      setIsActive(false);
      setSearchUser("");
      setSelectedUser([]);
      if (screen === "add") {
        handleCancel();
      }
    } else if (screen === "addListOfValues") {
      dispatch(setlistOfValueSelectedSector(""));
      dispatch(setListOfValueSelectedCountry(""));
      dispatch(setListOfValueName(""));
      dispatch(setListOfFieldValueDescription(""));
      dispatch(setSelectedListOfRowValue(""));
      dispatch(setListOfValueStatus(true));
      dispatch(setSpecialProjectDrawer(false));
    }
  };
  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    if (screen == "addUser") {
      const fetchUserData = async () => {
        try {
          const response = await getAPI(`${BASE_URL}/admin/users/find-all`);
          if (response?.status === 200) {
            // setUserData(response?.data);
            console.log(existingUserData, "ttttt");
            const idsToRemove = existingUserData?.map((item) => item.id);
            console.log(idsToRemove, "idsToRemove");
            const result = response?.data?.response?.filter(
              (item) => !idsToRemove?.includes(item.id)
            );
            setFilterUser(result);
            setUserData(result);
            console.log(result, "qwertyu");
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchUserData();
    }
    console.log("first");
  }, [existingUserData]);
  useEffect(() => {
    if (selectedUser?.length > 0) {
      const idsToRemove = selectedUser?.map((item) => item.id);
      const result = filterUser?.filter(
        (item) => !idsToRemove?.includes(item.id)
      );
      setFilterUser(result);
    } else {
      setFilterUser(userData);
    }
  }, [selectedUser]);
  useEffect(() => {
    if (existingUserData?.length > 0 && userData?.length > 0) {
      const idsToRemove = existingUserData?.map((item) => item.id);
      console.log(idsToRemove, "idsToRemove");
      const result = userData?.filter(
        (item) => !idsToRemove?.includes(item.id)
      );
      setFilterUser(result);
      setUserData(result);
      console.log(result, "pppppp");
      console.log(existingUserData, "existingUserData");
    }
  }, [existingUserData]);

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={specialProjectDrawer}
      onClose={handleResetData}
      PaperProps={{
        sx: {
          height: isMobile ? drawerHeight : "100%",
          overflowY: "auto",
          maxWidth: isMobile ? "100%" : "400px",
          minWidth: "300px",
          backgroundColor: useThemeRender("backgroundColorLayer2"),
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1"),
          },
        },
      }}
    >
      {/* {loading && <Loading />} */}
      {isMobile && (
        <Box
          sx={{
            width: "36px",
            height: "5px",
            background:
              "linear-gradient(0deg, rgba(61, 61, 61, 0.5) 0%, rgba(61, 61, 61, 0.5) 100%), rgba(127, 127, 127, 0.4)",
            backgroundBlendMode: "overlay, luminosity",
            borderRadius: "2.5px",
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "ns-resize",
            zIndex: 1,
          }}
          onMouseDown={handleResizeStart}
          onTouchStart={handleResizeStart}
        />
      )}
      {screen == "add" && (
        <>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              paddingTop: { xs: "16px" },
              paddingBottom: "24px",
              flexDirection: "column",
              margin: 0,
            }}
          >
            <DrawerHeader
              header={"Add new special project"}
              handleResetData={handleResetData}
            />
            <SpecialProjectInformation screen={"add"} />
          </Box>
          <Box className="ButtonBox">
            <Box
              sx={{
                border:
                  "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
              }}
              className="ButtonStyle"
              onClick={handleCancel}
            >
              <Typography
                sx={{
                  color: "#005CBC",
                  fontWeight: 700,
                }}
                className="textStyle"
              >
                Cancel
              </Typography>
            </Box>
            <Box
              sx={{
                background: "var(--Tokens-Button-Primary, #005CBC)",
              }}
              className="ButtonStyle"
              onClick={handleAddProject}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 700,
                  p: "5px 10px",
                }}
                className="textStyle"
              >
                Save
              </Typography>
            </Box>
          </Box>
        </>
      )}
      {screen == "addUser" && (
        <AddUser
          handleResetData={handleResetData}
          selectedUser={selectedUser}
          handleRemoveSelectedUser={handleRemoveSelectedUser}
          isActive={isActive}
          filterUser={filterUser}
          handleSelectUser={handleSelectUser}
          handleAddUser={handleAddUser}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleSearchUser={handleSearchUser}
          searchUser={searchUser}
          isMobile={isMobile}
        />
      )}
      {screen == "addListOfValues" && (
        <ListOfValuesSideTray handleResetData={handleResetData} />
      )}
    </Drawer>
  );
}

export default AddSpecialProject;
