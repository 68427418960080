import React, { useState } from "react";
import { Filter, Search, Button, Dropdown } from "pepsico-ds";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyle } from "./InternalStyle";
import {
  setCountry,
  setFlashColor,
  setIpOwnerShip,
  setSearch,
  setSector,
  setVariety,
} from "../../slices/internalPageSlice";

import PropTypes from "prop-types";
import "./interFilter.css";
export default function InternalFilter({
  setGrid,
  pageData,
  header,
  setSelection,
  grid,
  showDropdown = true,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();

  const isDarkTheme = useSelector((state) => state.theme);

  //const { variety } = useSelector((state) => state.internalPage);
  const handleSelectedSector = (sectorName) => {
    dispatch(setSector(sectorName));
  };

  const handleSelectedCountry = (countryName) => {
    dispatch(setCountry(countryName));
  };
  const handleSearch = (value) => {
    dispatch(setSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };
  const {
    sectorFilter,
    countryFilter,
    ipOwnerShipFilter,

    flashColorFilter,
  } = useSelector((store) => store.internalFilter);

  const dropDown = pageData?.internalSubPages?.map((item) => {
    return {
      id: item?.subPageId,
      displayText: item?.subPageName,
    };
  });

  const handleScrollToSection = (event) => {
    if (!event) return;

    const { displayText } = event[0];

    if (displayText) {
      setSelection(displayText);
    }
  };

  const handleVarietyChange = (value) => {
    dispatch(setVariety(value));
  };
  const handleSelectedFleshColor = (value) => {
    console.log(value, "valuevalue");
    dispatch(setFlashColor(value));
  };
  const handleIpOwnerShip = (value) => {
    dispatch(setIpOwnerShip(value));
  };

  const RenderGridButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <Button
          onClick={() => handleSelect("tile")}
          style={{
            ...buttonStyle,
            backgroundColor: grid === "tile" ? "#005CBC" : "transparent",
            color: grid === "tile" ? "#fff" : "#005CBC",
            border: grid === "tile" ? "none" : "1px solid #D7E5F8",
          }}
          className="mr-[8px]"
        >
          <GridOnIcon sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }} />
        </Button>

        <Button
          onClick={() => handleSelect("list")}
          style={{
            ...buttonStyle,
            backgroundColor: grid !== "tile" ? "#005CBC" : "transparent",
            color: grid !== "tile" ? "#fff" : "#005CBC",
            border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
          }}
        >
          <ViewListOutlinedIcon
            sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
          />
        </Button>
      </Box>
    );
  };

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <div className="w-full">
        {/* <Box sx={boxStyle}> */}
        {showDropdown && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Dropdown
              childList={dropDown || []}
              isClearable={false}
              placeholder="Go to Section"
              selection="single"
              setSelectedValue={handleScrollToSection}
              size="medium"
              isDark={isDarkTheme}
            />
          </Box>
        )}
        <div
          className={isMobile ? "mt-[24px]" : "flex justify-between mt-[16px]"}
          style={
            header === "Special Project" ? { justifyContent: "end" } : null
          }
        >
          {header !== "Special Project" && (
            <Box
              sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
              className={isMobile ? "mb-[24px]" : ""}
            >
              <Filter
                label={
                  <span style={{ fontSize: ".75rem", fontFamily: "Inter" }}>
                    Sector
                  </span>
                }
                onChange={handleSelectedSector}
                options={(sectorFilter || []).map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
                selection="single"
                size="medium"
                isDark={isDarkTheme}
                className="font-[Inter]"
              />
              {header === "Potato variety" && (
                <>
                  <Filter
                    label={
                      <span style={{ fontSize: ".75rem", fontFamily: "Inter" }}>
                        IP Ownership
                      </span>
                    }
                    onChange={handleIpOwnerShip}
                    options={(ipOwnerShipFilter || []).map((item) => ({
                      id: item.id,
                      label: item.name,
                    }))}
                    selection="single"
                    size="medium"
                    isDark={isDarkTheme}
                    className="font-[Inter] text-[14px]"
                  />

                  <Filter
                    label={
                      <span style={{ fontSize: ".75rem", fontFamily: "Inter" }}>
                        Flesh Color
                      </span>
                    }
                    onChange={handleSelectedFleshColor}
                    options={(flashColorFilter || []).map((item) => ({
                      id: item.id,
                      label: item.name,
                    }))}
                    selection="single"
                    size="medium"
                    isDark={isDarkTheme}
                    className="font-[Inter] text-[14px]"
                  />
                </>
              )}
              {header !== "Potato variety" && (
                <Filter
                  label={
                    <span style={{ fontSize: ".75rem", fontFamily: "Inter" }}>
                      Country
                    </span>
                  }
                  onChange={handleSelectedCountry}
                  options={(countryFilter || []).map((item) => ({
                    id: item.id,
                    label: item.name,
                  }))}
                  selection="single"
                  size="medium"
                  isDark={isDarkTheme}
                  className="font-[Inter] text-[14px]"
                />
              )}
            </Box>
          )}
          {/* </Box> */}
          <Box
            sx={
              isMobile
                ? { display: "flex", justifyContent: "space-between" }
                : {
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "flex-end",
                  }
            }
          >
            <Search
              onUpdate={handleSearch}
              isDark={isDarkTheme}
              className={isMobile ? "!max-w-[272px] w-[272px]" : ""}
              style={{ marginBottom: 0 }}
            />
            <div
              className={`${
                isMobile ? "ml-[16px] mr-[16px]" : ""
              } h-[35px] bg-[#DFDFDF] w-[1px]`}
            />
            {header !== "Library" && <RenderGridButtons />}
          </Box>
        </div>
      </div>
    </Box>
  );
}
InternalFilter.propTypes = {
  setGrid: PropTypes.func.isRequired,
  pageData: PropTypes.shape({
    internalSubPages: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }).isRequired,
  header: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
  grid: PropTypes.string.isRequired, // Assuming grid is a string, adjust if necessary
};
