// components/AvatarComponent.js
import Box from "@mui/material/Box";
import { Icon } from "pepsico-ds";
import { Avatar } from "@mui/material";
const AvatarComponent = () => {
  return (
    <Box>
      <Icon
        alt="User avatar"
        svg={
          <Avatar
            //type="image"
            iconType="person"
            data-testid="avatar"
            //imageUrl={"https://i.pravatar.cc/150?"}
            type="image"
            name="Jon Do"
            size="small"
            onClick={() => {}}
            style={{ pointerEvents: "none" }}
          />
        }
      />
    </Box>
  );
};

export default AvatarComponent;
