import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  TextField,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import CustomPagination from "../../components/PotatoProtection/LargeScreen/Landing/CustomPagination";
import { tableCellStyle, headerTextStyle, dividerStyle } from "./tableStyle";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import FilterListIcon from "@mui/icons-material/FilterList";
import PropTypes from "prop-types";
const DynamicTable = React.memo(
  ({
    columns,
    rows,
    searchText,
    onRowClick,
    rowsPerPage,
    showColumnFilters = true,
    renderActions,
    expandedRow,
    renderChildComponent,
    cellRenderer,
  }) => {
    const isDarkTheme = useSelector((state) => state.theme);
    const [page, setPage] = useState(0);
    const isMobile = useMediaQuery("(max-width:800px)");
    const [columnSearch, setColumnSearch] = useState(
      columns.reduce((acc, column) => {
        acc[column.id] = "";
        return acc;
      }, {})
    );
    useEffect(() => {
      setPage(0);
    }, [searchText, columnSearch]);
    const handleColumnSearchChange = (columnId, value) => {
      setColumnSearch((prev) => ({
        ...prev,
        [columnId]: value,
      }));
    };

    const filteredRows = rows?.filter((row) => {
      const matchesGlobalSearch = Object.values(row).some((value) =>
        String(value || "")
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase())
      );

      const matchesColumnSearch = columns?.every((column) => {
        const columnValue = String(row[column.id] || "")?.toLowerCase();
        const columnSearchValue = columnSearch[column.id]?.toLowerCase();
        return (
          columnSearchValue === "" || columnValue?.includes(columnSearchValue)
        );
      });

      return matchesGlobalSearch && matchesColumnSearch;
    });

    const paginatedRows = filteredRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return (
      <TableContainer>
        <Table
          sx={{
            borderCollapse: "collapse",
            backgroundColor: useThemeRender("cardBackgroundColorLayer1"),
          }}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell
                  key={column.id}
                  sx={{
                    width: column.width || "auto",
                    color: isDarkTheme
                      ? darkTheme.textColorLayer4
                      : lightTheme.textColorLayer4,
                    ...tableCellStyle,
                    // padding: index == 0 ? "16px" : "16px 0",
                  }}
                >
                  <Typography
                    sx={{
                      ...headerTextStyle,
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                      width: column.headerWidth ? column.headerWidth : "65%",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
            {showColumnFilters && !isMobile && (
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      padding: 0,
                      alignItems: "center",
                    }}
                  >
                    {/* Only show filter if column is filterable */}
                    {column.filterable && (
                      <>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={columnSearch[column.id]}
                          onChange={(e) =>
                            handleColumnSearchChange(column.id, e.target.value)
                          }
                          placeholder={`User Input`}
                          sx={{
                            width: "70%",
                            marginBottom: "16px",
                            "& .MuiInputBase-root": {
                              height: "24px",
                              border: isDarkTheme ? "1px solid white" : "",
                            },
                            "& .MuiOutlinedInput-input": {
                              padding: "4px 8px",
                              color: isDarkTheme ? "white" : "black",
                            },
                          }}
                        />
                        <FilterListIcon
                          sx={{
                            marginLeft: "8px",
                            width: "16px",
                            height: "16px",
                            fill: isDarkTheme ? "white" : "black",
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}

            <TableRow>
              <TableCell
                colSpan={columns?.length}
                sx={{ padding: 0, border: "none" }}
              >
                <Box sx={dividerStyle} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length > 0 ? (
              paginatedRows?.map((row, index) => (
                <React.Fragment key={row.id}>
                  <TableRow
                    onClick={() => onRowClick(row)}
                    sx={{
                      cursor: "pointer",
                      boxShadow:
                        "0px 2px 0px 0px var(--color-border-default) inset",
                      borderBottom:
                        paginatedRows.length - 1 === index
                          ? "2px solid var(--color-border-default)"
                          : "none",
                      "&:hover": {
                        backgroundColor: isDarkTheme
                          ? "rgba(255, 255, 255, 0.1)"
                          : "#f5f5f5",
                      },
                      "&:nth-of-type(odd)": {
                        color: isDarkTheme
                          ? darkTheme.textColorLayer4
                          : lightTheme.textColorLayer4,
                      },
                    }}
                  >
                    {columns?.map((column, index) => (
                      <TableCell
                        key={column.id}
                        sx={{
                          border: "none",
                          color: isDarkTheme
                            ? darkTheme.textColorLayer4
                            : lightTheme.textColorLayer4,
                          fontFamily:
                            "var(--Typography-Font-Family-Body, Inter)",
                          fontWeight: 400,
                          fontSize: "var(--Font-Size-Small, 14px)",
                          // lineHeight: "var(--Line-Height-Small, 50px)",
                        }}
                      >
                        {column.cellStyle === "cellStyle"
                          ? cellRenderer
                            ? cellRenderer(row[column.id], row)
                            : row[column.id]
                          : column.id === "actions" && renderActions
                          ? renderActions(row)
                          : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>

                  {/* Collapsible Child Component */}
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      style={{
                        padding: "0 0 0 0px",
                        border: "none",
                      }}
                    >
                      <Collapse
                        in={expandedRow === row.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box>
                          {renderChildComponent && renderChildComponent(row)}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{
                    textAlign: "center",
                    color: isDarkTheme ? "white" : "black",
                    fontWeight: "bold",
                    padding: "16px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {filteredRows?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "16px",
            }}
          >
            <CustomPagination
              page={page}
              setPage={setPage}
              totalPages={Math.ceil(filteredRows.length / rowsPerPage)}
            />
          </Box>
        )}
      </TableContainer>
    );
  },
  // Custom props comparison function
  (prevProps, nextProps) => {
    return (
      prevProps.columns === nextProps.columns &&
      prevProps.searchText === nextProps.searchText &&
      prevProps.expandedRow === nextProps.expandedRow
    );
  }
);

export default DynamicTable;

DynamicTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.oneOf(["left", "right", "center"]),
      // Add more properties based on your column structure
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchText: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  rowsPerPage: PropTypes.number,
  showColumnFilters: PropTypes.bool,
  renderActions: PropTypes.func,
  expandedRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderChildComponent: PropTypes.func,
  cellRenderer: PropTypes.func,
};
