import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Loading from "../../utils/Loader";
import LibraryAccordion from "./LibraryAccordion";
import LibraryTabContent from "./LibraryTabContent";
import { LIBRARY_CONSTANT } from "../../constant/constant";
import useThemeRender from "../../theme/useThemeRender";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

export default function TabContentView({ tabData, pageName, selectedView }) {
  const navigation = useNavigate();
  const [isTabLoading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(tabData?.subjectItems);
  const [value, setValue] = useState(LIBRARY_CONSTANT.all);
  const isDarkTheme = useSelector((state) => state.theme);
  const selectedViewFromPreference = useSelector((state) => state.view);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [itemCounts, setItemCounts] = useState({
    documents: 0,
    videos: 0,
    links: 0,
    all: 0,
  });

  useEffect(() => {
    setPageData(tabData?.subjectItems);
  }, [tabData]);

  const handleChange = (event, tabType) => {
    setLoading(true);
    let pageContent = [];
   // if (tabType === LIBRARY_CONSTANT.all) {
      pageContent = [...tabData?.subjectItems];
    //} else 
    // if (tabType === LIBRARY_CONSTANT.document) {
    //   tabData?.subjectItems?.forEach((item) => {
    //     if (item?.documents?.length > 0) {
    //       pageContent.push({
    //         documents: item?.documents,
    //         subjectName: item?.subjectName,
    //         subjectId: item?.subjectId
    //       });
    //     }
    //   });
    // } else if (tabType === LIBRARY_CONSTANT.videos) {
    //   tabData?.subjectItems?.forEach((item) => {
    //     if (item?.videos?.length > 0) {
    //       pageContent.push({
    //         videos: item?.videos,
    //         subjectName: item?.subjectName,
    //         subjectId: item?.subjectId
    //       });
    //     }
    //   });
    // } else if (tabType === LIBRARY_CONSTANT.links) {
    //   tabData?.subjectItems?.forEach((item) => {
    //     if (item?.links?.length > 0) {
    //       pageContent.push({
    //         links: item?.links,
    //         subjectName: item?.subjectName,
    //         subjectId: item?.subjectId
    //       });
    //     }
    //   });
    // }

    setPageData(pageContent);
    setValue(tabType);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const redirectLibraryDetailView = (content) => {
    console.log('contnet', content);
    navigation(`/library-details/${content?.subjectId}`);
  };

  useEffect(() => {
    calculateItemCounts();
  }, [tabData]);

  const calculateItemCounts = () => {
    let newCountObj = {
      all: 0,
      documents: 0,
      videos: 0,
      links: 0,
    };
    if (tabData?.subjectItems?.length > 0) {
      tabData?.subjectItems?.forEach((subject) => {
        const documents = Array.isArray(subject.documents)
          ? subject.documents
          : [];
        const links = Array.isArray(subject.links) ? subject.links : [];
        const videos = Array.isArray(subject.videos) ? subject.videos : [];

        newCountObj.documents += documents.length;
        newCountObj.links += links.length;
        newCountObj.videos += videos.length;

        newCountObj.all += documents.length + links.length + videos.length;
      });

      setItemCounts(newCountObj);
    } else {
      setItemCounts(newCountObj);
    }
  };

  const renderTabContentHtml = () => {
    if (pageName === "Library") {
      return pageData?.map((section, index) => {
        return (
          <LibraryAccordion
            tabValue={value}
            data={section}
            redirectLibraryDetailView={(content) =>
              redirectLibraryDetailView(content)
            }
            key={index}
            grid={selectedView}
            subPageId={section?.subjectId}
          />
        );
      });
    } else if (pageName === "LibraryDetails") {
      return pageData?.map((section, index) => {
        return (
          <LibraryTabContent
            data={section}
            key={"all"}
            currentTab={value}
            grid={selectedView}
            selectedSection={selectedView}
            setGrid={selectedView}
            setItemCounts={setItemCounts}
          />
        );
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={`All (${itemCounts.all})`}
            value={LIBRARY_CONSTANT.all}
            sx={{
              // color: useThemeRender("textColorLayer4"),
              fontFamily: "Inter",
              textTransform: "none",
              fontWeight: value === LIBRARY_CONSTANT.all ? 700 : 400,
            }}
            style={{
              color: isDarkTheme
                    ? darkTheme.textColorLayer4
                    : lightTheme.textColorLayer4,
            }}
          />
          <Tab
            label={`Documents (${itemCounts.documents})`}
            value={LIBRARY_CONSTANT.document}
            sx={{
              // color: useThemeRender("textColorLayer4"),
              fontFamily: "Inter",
              textTransform: "none",
              fontWeight: value === LIBRARY_CONSTANT.document ? 700 : 400,
            }}
            style={{
              color: isDarkTheme
                    ? darkTheme.textColorLayer4
                    : lightTheme.textColorLayer4,
            }}
          />
          <Tab
            label={`Videos (${itemCounts.videos})`}
            value={LIBRARY_CONSTANT.videos}
            sx={{
              // color: useThemeRender("textColorLayer4"),
              fontFamily: "Inter",
              textTransform: "none",
              fontWeight: value === LIBRARY_CONSTANT.videos ? 700 : 400,
            }}
            style={{
              color: isDarkTheme
                    ? darkTheme.textColorLayer4
                    : lightTheme.textColorLayer4,
            }}
          />
          <Tab
            label={`Links (${itemCounts.links})`}
            value={LIBRARY_CONSTANT.links}
            sx={{
              // color: useThemeRender("textColorLayer4"),
              fontFamily: "Inter",
              textTransform: "none",
              fontWeight: value === LIBRARY_CONSTANT.links ? 700 : 400,
            }}
            style={{
              color: isDarkTheme
                    ? darkTheme.textColorLayer4
                    : lightTheme.textColorLayer4,
            }}
          />
        </Tabs>
      </Box>
      {isTabLoading ? (
        <Loading />
      ) : (
        value === LIBRARY_CONSTANT.all && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "400px",
              marginBottom: "25px",
              padding: isMobile ? 0 : "16px",
              background: "#FBFCFE",
              borderRadius: "16px",
              boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            {renderTabContentHtml()}
          </Box>
        )
      )}
      {value === LIBRARY_CONSTANT.document && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            marginBottom: "25px",
            // background: "#FBFCFE",
            padding: isMobile ? 0 : "16px",
            background: "#FBFCFE",
            borderRadius: "16px",
            boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          {renderTabContentHtml()}
        </Box>
      )}
      {value === LIBRARY_CONSTANT.videos && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            marginBottom: "25px",
            // background: "#FBFCFE",
            padding: isMobile ? 0 : "16px",
            background: "#FBFCFE",
            borderRadius: "16px",
            boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          {renderTabContentHtml()}
        </Box>
      )}
      {value === LIBRARY_CONSTANT.links && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            marginBottom: "25px",
            // background: "#FBFCFE",
            padding: isMobile ? 0 : "16px",
            background: "#FBFCFE",
            borderRadius: "16px",
            boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          {renderTabContentHtml()}
        </Box>
      )}
    </>
  );
}
