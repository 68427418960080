import React from "react";

import { Search } from "pepsico-ds";
import { useSelector } from "react-redux";
export default function CustomSearch({ handleSearch, handleNavigate }) {
  const isDarkTheme = useSelector((state) => state.theme);

  return (
    <Search
      placeholderText="Search..."
      className="!mb-0"
      isDark={isDarkTheme}
      onUpdate={handleSearch}
      onSearch={handleNavigate}
    />
  );
}
