import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import CustomAccordion from "../../../utils/CustomAccordian/CustomAccordian";
import AccordionChild from "../../../utils/CommonAccordianChild/AccordionChild";
import { darkTheme } from "../../../theme/Theme";
import {
  accordionContent,
  capitalizeFirstLetter,
  generateSectionData,
} from "../../../utils/function";
import Summary from "../Components/Summary";
import { Button } from "pepsico-ds";
import { useNavigate } from "react-router-dom";

export default function PendingTabData({ currentTabData, activeTab }) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [accordionData, setAccordionData] = useState(currentTabData);
  const isDarkTheme = useSelector((store) => store.theme);
  const navigate = useNavigate();
  const handleAccordionToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    const newTabData = currentTabData.map((item) => {
      return { ...item, contentType: capitalizeFirstLetter(item.contentType) };
    });
    console.log(newTabData, "----currentTabData-------");

    setAccordionData(newTabData);
  }, [currentTabData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "16px",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
        borderRadius: "16px",
        background: isDarkTheme ? darkTheme.backgroundColorLayer2 : "#FBFCFE",
        boxShadow:
          "var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))",
      }}
    >
      <h6
        className={`${
          isDarkTheme ? "text-[#EDEDED]" : "text-[#3A3A3A]"
        }  font-bold`}
      >
        {activeTab === 0
          ? "All"
          : activeTab === 1
          ? "Documents"
          : activeTab === 2
          ? "Videos"
          : activeTab === 3
          ? "Links"
          : ""}
      </h6>
      {accordionData?.map((accordion, index) => (
        <CustomAccordion
          key={index}
          headerText={accordion?.title}
          footerText={accordion?.uploadedBy}
          expanded={expandedIndex === index}
          onToggle={() => handleAccordionToggle(index)}
        >
          <AccordionChild
            accordionContent={accordionContent(accordion)}
            sectionData={generateSectionData(accordion)}
            component={
              <Summary label={"Summary"} description={accordion?.summary} />
            }
          />
          <Box
            sx={{
              background: isDarkTheme
                ? darkTheme.backgroundColorLayer2
                : "#F3F7FD",
            }}
          >
            <Button
              size="medium"
              style={{
                // border: isDarkTheme ? "1px solid #616161" : "1px solid #D7E5F8",
                border: "none",
                fontWeight: 700,
                color: "#005CBC",
                fontSize: 14,
                margin: "16px 0",
                marginLeft: "auto",
              }}
              text="Edit, review and publish"
              variant="secondary"
              isDark={isDarkTheme}
              iconTrailing="arrow_forward"
              onClick={() =>
                navigate(`/curator/pending-approval/${accordion?.contentGuid}`)
              }
            />
          </Box>
        </CustomAccordion>
      ))}
      {accordionData?.length === 0 && (
        <span style={{ display: "flex", justifyContent: "center" }}>
          No records found.
        </span>
      )}
    </Box>
  );
}
