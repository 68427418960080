import React from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import { useSelector } from "react-redux";
import Loading from "../utils/Loader";
import useFetchInternal from "../hooks/useFetchInternal";
import { setSustainability } from "../slices/sustainabilityPageSlice";
export default function Sustainable() {
  const { sustainable } = useSelector((store) => store.sustainable);

  const userId = 1;

  const { loading } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Sustainability&userId=${userId}`,
    setSustainability,
    sustainable
  );

  if (loading) return <Loading data-testid="loading" />;
  return (
    <InternalPageLayout
      pageData={sustainable}
      header={"Sustainability"}
      data-testid="sustainable-layout"
    />
  );
}
