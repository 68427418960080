import api from "./API";

export const postAPI = async (url, data, config) => {
  try {
    const response = await api.post(`${url}`, data, config);
    return response;
  } catch (error) {
    const errorDetails = JSON.parse(error.message); // Parse the custom error message
    console.log("Error details:", errorDetails?.data);
    // Show user-friendly error message or handle redirect logic here

    return {
      message: errorDetails.data.message,
      status: errorDetails?.status || 500,
      data: errorDetails?.data || "Something went wrong",
    };
  }
};

export const getAPI = async (url) => {
  try {
    const response = await api.get(`${url}`);
    return response;
  } catch (error) {
    const errorDetails = JSON.parse(error.message); // Parse the custom error message
    console.log("Error details:", errorDetails);
    // Show user-friendly error message or handle redirect logic here
    const newError = {
      message: errorDetails.data.message,
      status: error.response?.status || errorDetails.status || 500,
      data: error.response?.data || "Something went wrong",
    };
    return newError;
  }
};

export const patchAPI = async (url, data) => {
  try {
    const response = await api.patch(`${url}`, data);
    return response;
  } catch (error) {
    const errorDetails = JSON.parse(error.message); // Parse the custom error message
    console.log("Error details:", errorDetails);
    // Show user-friendly error message or handle redirect logic here

    return {
      message: errorDetails.data.message,
      status: error.response?.status || 500,
      data: error.response?.data || "Something went wrong",
    };
  }
};
