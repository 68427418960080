import React, { createContext, useContext } from "react";
import { saveImageToDB, getImageFromDB } from "../utils/indexedDB";
import { FILE_SERVICE_BASE_URL } from "../constant/apiUrl";
const ImageCacheContext = createContext();

export const useImageCache = () => {
    return useContext(ImageCacheContext);
};

const ImageCacheProvider = ({ children }) => {
    const fetchImage = async (imageId) => {
        const cachedImage = await getImageFromDB(imageId);
        if (cachedImage) {
            return cachedImage.blob;
        }

        const response = await fetch(`${FILE_SERVICE_BASE_URL}/fritolay/file-manager/download-thumbnail-by-id/${imageId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
        });
        const imageBlob = await response.blob();
        saveImageToDB(imageId, imageBlob);

        return imageBlob;
    };

    return (
        <ImageCacheContext.Provider value={{ fetchImage }}>
            {children}
        </ImageCacheContext.Provider>
    );
};

export default ImageCacheProvider;
