import React from "react";
import "./LoginHeader.css";
import LoginPepsiSvg from "./LoginPepsiSvg";
import { Avatar, Font, Header } from "pepsico-ds";

export default function LoginHeader() {
  return (
    <Header
      leftIcon={<LoginPepsiSvg />}
      middleIcon={
        <Font size="body" variant="bold" style={{ color: "var(--white)" }}>
          Frito-Lay Agriculture
        </Font>
      }
      className="ag-website-header"
    />
  );
}
