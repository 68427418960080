import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PendingApproval from "./PendingApproval";
import YearlyReview from "./YealryReview";
import DeniedArtifactHistory from "./DeniedHistory/DeniedArtifactHistory";
import ApprovalHistroy from "./ArtifactHistory/ApprovalHistroy";
import Loading from "../../utils/Loader";

export default function SelectCurrentCurator(props) {
  const [isLookupLoaded, setIsLookupLoaded] = useState(false);
  const currentCurator = useSelector(
    (store) => store?.curatorpage?.selectedCuratorPage
  );

  useEffect(() => {
   
           setTimeout(() => {
          setIsLookupLoaded(true);
        }, 250);
  }, []);


  const renderPage = () => {
    switch (currentCurator) {
      case "Pending approvals":
        return <PendingApproval />;
      case "Yearly review":
        return <YearlyReview />;
      case "Artifact history":
        return <ApprovalHistroy />;
      case "Denied history":
        return <DeniedArtifactHistory />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full">{isLookupLoaded ? renderPage() : <Loading />}</div>
  );
}
