import React from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Popper,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { autoCompletStyles } from "../../utils/FieldStyles/FieldStyles";
import { setSelectedPage } from "../../slices/uploadHistoryslice";
import { Dropdown as CustomDropdown } from "pepsico-ds";
export default function UploadDropDown() {
  const dispatch = useDispatch();
  const options = ["Pending approvals", "History"].map((item, idx) => ({
    id: idx + 1,
    displayText: item,
  }));
  const isMobile = useMediaQuery("(max-width:600px)");
  const { select } = useSelector((store) => store.uploadHistory);

  const HandleChange = (event, newValue) => {
    newValue && dispatch(setSelectedPage(newValue));
  };
  const isDarkTheme = useSelector((state) => state.theme);
  console.log("Dropdown selected:", select); // In UploadDropDown
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "400px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-8, 8px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "400px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-8, 8px)",
        }}
      >
        {/* <Autocomplete
          onChange={HandleChange}
          value={select}
          options={options}
          getOptionLabel={(option) => option}
          freeSolo={false} // Prevents typing new values
          disableClearable // Prevents clearing the value
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                onKeyDown: (e) => e.preventDefault(), // Prevents typin
              }}
            />
          )}
          PopperComponent={(props) => (
            <Popper
              {...props}
              sx={{
                "& .MuiAutocomplete-listbox": {
                  background: isDarkTheme
                    ? darkTheme.backgroundColorLayer3
                    : lightTheme.backgroundColorLayer3,

                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  "& .MuiAutocomplete-option:hover": {
                    backgroundColor: isDarkTheme
                      ? darkTheme.dropdownHoverColorLayer3
                      : lightTheme.dropdownHoverColorLayer3,
                  },
                },
              }}
            />
          )}
          sx={autoCompletStyles(
            isDarkTheme,
            isMobile,
            darkTheme,
            "300px",
            "12px"
          )}
          ListboxProps={{
            sx: {
              backgroundColor: isDarkTheme
                ? darkTheme.backgroundColorLayer1
                : "white",
              fontSize: ".75rem",
              "& .MuiAutocomplete-option": {
                color: isDarkTheme ? "white" : "black",
                "&:hover": {
                  backgroundColor: isDarkTheme ? "#333" : "#f0f0f0",
                },
              },
            },
          }}
        /> */}
        <CustomDropdown
          childList={options}
          isDark={isDarkTheme}
          setSelectedValue={(e) => HandleChange(e, e && e[0]?.displayText)}
          className="admin-dropdown"
          selectedValue={
            options.filter((item) => item.displayText === select) || []
          }
          isClearable={false}
          placeholder="Select One"
          value={select}
        />
      </Box>
    </Box>
  );
}
