import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import DrawerHeader from "./DrawerHeader";
import { darkTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import useThemeRender from "../../../../theme/useThemeRender";
import SearchIcon from "@mui/icons-material/Search";
function AddUser({
  handleResetData,
  selectedUser,
  handleRemoveSelectedUser,
  isActive,
  filterUser,
  handleSelectUser,
  handleAddUser,
  handleFocus,
  handleBlur,
  handleSearchUser,
  searchUser,
  isMobile,
}) {
  const isDarkTheme = useSelector((store) => store.theme);
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const selectedBoxLayer1 = useThemeRender("selectedBoxLayer1");
  const smallTextBackground = useThemeRender("smallTextBackground");
  console.log(filterUser, "filterUser");
  return (
    <>
      <Box sx={{ mt: "18px", width: isMobile ? "100%" : "400px" }}>
        <DrawerHeader header={"Add users"} handleResetData={handleResetData} />
        <Box sx={{ px: "10px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              variant="standard"
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: textColorLayer1 }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",

                "& .MuiInputBase-root": {
                  height: "32px",
                  color: textColorLayer2,
                },
                "& .MuiInputBase-input::placeholder": {
                  color: textColorLayer4,
                },

                "& .MuiInput-underline:before": {
                  borderBottomColor: borderColorLayer2,
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: borderColorLayer2,
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: borderColorLayer2,
                },
              }}
              value={searchUser}
              onChange={handleSearchUser}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Box>
        </Box>
        <Box sx={{ p: "10px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {selectedUser?.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                padding: "2px 8px",
                alignItems: "center",
                gap: "2px",
                borderRadius: "4px",
                backgroundColor: smallTextBackground,
                width: "fit-content",
              }}
            >
              <Box
                sx={{
                  color: textColorLayer1,
                  fontWeight: 400,
                }}
                className="textStyle"
              >
                {item?.name}
              </Box>
              <Box onClick={() => handleRemoveSelectedUser(item)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.6668 4.27337L11.7268 3.33337L8.00016 7.06004L4.2735 3.33337L3.3335 4.27337L7.06016 8.00004L3.3335 11.7267L4.2735 12.6667L8.00016 8.94004L11.7268 12.6667L12.6668 11.7267L8.94016 8.00004L12.6668 4.27337Z"
                    fill={`${textColorLayer1}`}
                  />
                </svg>
              </Box>
            </Box>
          ))}
        </Box>
        {isActive && searchUser?.length > 0 && filterUser?.length > 0 && (
          <Box sx={{ px: "10px" }}>
            <Box
              sx={{
                display: "flex",
                maxHeight: "240px",
                padding: "8px 8px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                borderRadius: "0px 0px 4px 4px",
                border: "1px solid #C0C0C0",
                background: insideCardBackgroundColorLayer1,
                boxShadow: "0px 4px 24px 0px rgba(58, 58, 58, 0.08)",
                alignSelf: "stretch",
                mt: "10px",
                overflowY: "scroll",
              }}
            >
              {filterUser?.map((item) => (
                <div
                  style={{ width: "100%" }}
                  onClick={() => handleSelectUser(item)}
                  key={item.id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                      "&:hover": {
                        backgroundColor: selectedBoxLayer1,
                      },
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        color: textColorLayer1,
                        fontWeight: 700,
                      }}
                      className="textStyle"
                    >
                      {item?.name}
                    </Box>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Box
                        sx={{
                          color: textColorLayer1,
                          fontWeight: 400,
                        }}
                        className="textStyle"
                      >
                        {item?.country}
                      </Box>
                      <Box
                        sx={{
                          color: textColorLayer1,
                          fontWeight: 400,
                        }}
                        className="textStyle"
                      >
                        {item?.company}
                      </Box>
                      <Box
                        sx={{
                          color: textColorLayer1,
                          fontWeight: 400,
                        }}
                        className="textStyle"
                      >
                        {item?.email}
                      </Box>
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      background: isDarkTheme && darkTheme.textColorLayer1,
                      width: "100%",
                    }}
                  />
                </div>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Box className="ButtonBox">
        <Box
          sx={{
            border:
              "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
          }}
          className="ButtonStyle"
          onClick={handleResetData}
        >
          <Typography
            sx={{
              color: "#005CBC",
              fontWeight: 700,
            }}
            className="textStyle"
          >
            Cancel
          </Typography>
        </Box>
        <Box
          sx={{
            background: "var(--Tokens-Button-Primary, #005CBC)",
          }}
          className="ButtonStyle"
          onClick={handleAddUser}
        >
          <Typography
            sx={{
              color: "#fff",
              fontWeight: 700,
              p: "5px 10px",
            }}
            className="textStyle"
          >
            Save
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default AddUser;
