import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { useSelector } from "react-redux";
import { setFarmerIncentive } from "../slices/sustainabilityPageSlice";

function FarmerIncentiveProgram() {
  const { farmerIncentive } = useSelector((store) => store.sustainable);

  return (
    <SustainabilitySubpage
      urlName={"Farmer%27s%20Incentive%20Programs"}
      header={"Farmers incentive programs"}
      action={setFarmerIncentive}
      globalState={farmerIncentive}
      data-testid="sustainability-subpage"
    />
  );
}

export default FarmerIncentiveProgram;
