import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  setAppoveModal,
  setDeny,
  setEditSlider,
  setActionData,
} from "../../../../slices/invitationSlice";

const styles = {
  container: {
    paddingLeft: "48px",
  },
  section: {
    display: "flex",
    padding: "var(--Spacing-spacing-16, 16px)",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "var(--Spacing-spacing-16, 16px)",
    flex: "1 0 0",
    borderTop: "1px solid var(--Colors-Border-Gray-150, #D0D0D0)",
  },
  card: {
    height: "100%",
    display: "flex",
    minWidth: "280px",
    padding: "8px 16px",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "8px",
    alignSelf: "stretch",
    flexWrap: "wrap",
    borderRadius: "4px",
    background: "var(--Tokens-Feedback-Info-background, #CCEAF6)",
  },
  typography: {
    color: "var(--Tokens-Text-Black, #000)",
    fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
    fontSize: "var(--Font-Size-H6, 18px)",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-H6, 28px)",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    paddingY: "4px",
    cursor: "pointer",
  },
  buttonText: {
    color: "var(--color-button-borderless)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Small, 14px)",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-Small, 20px)",
    textTransform: "none",
    paddingRight: "2px",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "32px",
    marginY: "auto",
  },
};

const PendingApproval = React.memo(
  ({ data }) => {
    const dispatch = useDispatch();

    return (
      <Box sx={styles.container}>
        <Box sx={styles.section}>
          <Box sx={styles.card}>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <div className="flex flex-row gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 7.00098H13V9.00098H11V7.00098ZM11 11.001H13V17.001H11V11.001ZM12 2.00098C6.48 2.00098 2 6.48098 2 12.001C2 17.521 6.48 22.001 12 22.001C17.52 22.001 22 17.521 22 12.001C22 6.48098 17.52 2.00098 12 2.00098ZM12 20.001C7.59 20.001 4 16.411 4 12.001C4 7.59098 7.59 4.00098 12 4.00098C16.41 4.00098 20 7.59098 20 12.001C20 16.411 16.41 20.001 12 20.001Z"
                    fill="#0078A7"
                  />
                </svg>
                <div>
                  <Typography sx={styles.typography}>
                    Approve {data.firstName} {data.lastName}'s request?
                  </Typography>
                </div>
              </div>
            </Box>
            <Box sx={styles.actionsContainer}>
              <Box
                sx={styles.buttonBox}
                onClick={() => {
                  dispatch(setAppoveModal(true));
                  dispatch(setDeny(true));
                }}
              >
                <Typography sx={styles.buttonText}>Reject</Typography>
                <CloseIcon
                  sx={{ height: "20px", width: "20px", fill: "#005CBC" }}
                />
              </Box>
              <Box
                sx={styles.buttonBox}
                onClick={() => {
                  dispatch(setEditSlider(true));
                }}
              >
                <Typography sx={styles.buttonText}>Edit request</Typography>
                <EditOutlinedIcon
                  sx={{ height: "20px", width: "20px", fill: "#005CBC" }}
                />
              </Box>
              <Box
                sx={styles.buttonBox}
                onClick={() => {
                  dispatch(setAppoveModal(true));
                  dispatch(setDeny(false));
                }}
              >
                <Typography sx={styles.buttonText}>Approve</Typography>
                <CheckOutlinedIcon
                  sx={{ height: "20px", width: "20px", fill: "#005CBC" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    // Compare the previous and next props
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default PendingApproval;
