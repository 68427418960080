import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import SpotlightCardItem from "./SpotlightCardItem";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import { setDisplay } from "../../../../slices/adminPageSlice";
import useThemeRender from "../../../../theme/useThemeRender";
import ViewSummary from "../../../ViewSummary/ViewSummary";
import AlertBox from "./AlertBox";
import PropTypes from "prop-types";
import { getAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { urlTagging } from "../../../../utils/function";
import { setSpotlight } from "../../../../slices/spotlightSlice";
import { setViewSummaryDrawer } from "../../../../slices/viewSummarySlice";
import { Button } from "pepsico-ds";
export default function Spotlight({ flexDirection, width } = {}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteSlotId, setDeleteSlotId] = React.useState(false);
  const [fetch, setFetch] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const token = localStorage.getItem("auth_token");
  const { viewSummaryDrawer } = useSelector((store) => store.viewSummary);
  const { spotlight } = useSelector((store) => store.spotlight);
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );

  useEffect(() => {
    dispatch(setDisplay(false));
    dispatch(setViewSummaryDrawer(false));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    const fetchAllData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/spotlights`);
        if (response?.status === 200) {
          const fetch = response?.data;
          const data = await urlTagging(fetch, "thumbnailId");
          dispatch(setSpotlight(data));
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [fetch, dispatch]);

  const sortedData = [...(spotlight || [])].sort(
    (a, b) => a.slotNumber - b.slotNumber
  );
  if (loading) return <Loading />;
  return (
    <div className="flex flex-col gap-4">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: "16px var(--Spacing-spacing-16, 16px)",
          flexDirection: flexDirection ? flexDirection : "column",
          alignItems: "flex-start",
          gap: "16px",
          borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
          backgroundColor: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : "#FFF",
            paddingTop: '28px'
        }}
      >
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFamily: "Inter",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
          }}
        >
          {` Landing page - ${spotlight?.length} of 9 slots filled`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "2rem",
            flexWrap: "wrap",
          }}
        >
          {(sortedData || [])?.map((item, index) => {
            return (
              <SpotlightCardItem
                key={item?.id}
                card={item}
                width={width}
                contenId={item?.contenId}
                slotNumber={item?.slotNumber}
                id={item?.id}
                setDeleteSlotId={setDeleteSlotId}
              />
            );
          })}
          <Box
            sx={{
              display: "flex",
              width: width ? width : "397px",
              padding: "24px",
              height: "477px",
              flexDirection: "column",
              justifyContent: "center", // Centers content vertically
              alignItems: "center", // Centers content horizontally
              gap: "16px",
              borderRadius: "8px",
              background: "#FBFCFE",
              boxShadow: "0px 0px 24px 0px rgba(0, 92, 188, 0.08)",
              backgroundColor: insideCardBackgroundColorLayer3,
            }}
          >
            <Button
              text="Add content"
              variant="secondary"
              onClick={() => {}} //This implementation is missing
              iconTrailing="add_circle_outline"
            />
          </Box>
          {viewSummaryDrawer && <ViewSummary />}
          <AlertBox
            deleteSlotId={deleteSlotId}
            setFetch={setFetch}
            token={token}
            action={"Remove"}
          />
        </Box>
      </Box>
    </div>
  );
}
Spotlight.propTypes = {
  flexDirection: PropTypes.string, // Optional string for flex direction
  width: PropTypes.string, // Optional string for width
};
