import React from "react";
import { Filter, Button } from "pepsico-ds";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyle, boxStyle } from "../InternalPages/InternalStyle";
import {
  setCountry,
  setSearch,
  setSector,
  setVariety,
} from "../../slices/internalPageSlice";
import CustomSearch from "../../utils/CustomSearch/CustomSearch";

export default function LibraryFilter({ setGrid, header, grid, setSearchTextData }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();

  const isDarkTheme = useSelector((state) => state.theme);

  const { sector, country, variety } = useSelector(
    (state) => state.internalPage
  );

  const { regionFilter, countryFilter, laguageFilter, cropTypeFilter } =
    useSelector((store) => store.internalFilter);
  const handleSelectedSector = (sectorName) => {
    dispatch(setSector(sectorName));
  };

  const handleSelectedCountry = (countryName) => {
    dispatch(setCountry(countryName));
  };
  const handleSearch = (value) => {
    setSearchTextData(value);
    dispatch(setSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };

  const handleVarietyChange = (value) => {
    dispatch(setVariety(value));
  };

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box sx={boxStyle} >
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Filter
            label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Crop Type</span>}
            onChange={handleSelectedSector}
            options={(cropTypeFilter || [])?.map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Region</span>}
            onChange={handleSelectedCountry}
            options={(regionFilter || [])?.map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Country</span>}
            onChange={handleSelectedCountry}
            options={(countryFilter || [])?.map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Box sx={{ display: "flex", gap: 2 }}>
            <Filter
              label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Language</span>}
              onChange={handleVarietyChange}
              options={(laguageFilter || [])?.map((item) => ({
                id: item.id,
                label: item.name,
              }))}
              selection="single"
              size="medium"
              isDark={isDarkTheme}
              value={variety}
              dropdownPosition="bottom"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", flexDirection: "row", gap: "16px" }
        }
      >
        <CustomSearch handleSearch={handleSearch} />
        {header !== "Library" && (
          <>
              <div className={`${isMobile ? "ml-[16px] mr-[16px]" :""} h-[34px] bg-[#DFDFDF] w-[1px]`}/>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
              <Button
                onClick={() => handleSelect("tile")}
                style={{
                  ...buttonStyle,
                  backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
                  color: grid === "tile" ? "#fff" : "#005CBC",
                  border: grid === "tile" ? "none" : "1px solid #D7E5F8",
                }}
              >
                <GridOnIcon
                  sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }}
                />
              </Button>

              <Button
                onClick={() => handleSelect("list")}
                style={{
                  ...buttonStyle,
                  backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
                  color: grid !== "tile" ? "#fff" : "#005CBC",
                  border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
                }}
              >
                <ViewListOutlinedIcon
                  sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
                />
              </Button>
            </Box>
          </>
          
        )}
      </Box>
    </Box>
  );
}
