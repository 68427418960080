import React from "react";

import InternalPageLayout from "../components/InternalPages/InternalPageLayout";

import { BASE_URL } from "../constant/apiUrl";
import { useSelector } from "react-redux";
import useFetchInternal from "../hooks/useFetchInternal";
import Loading from "../utils/Loader";
import { setPotatoStorage } from "../slices/internalPageSlice";
export default function PotatoStorage() {
  const { potatoStorage } = useSelector((store) => store.internalPage);
  const userId = 1;

  const { loading } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Potato%20Storage&userId=${userId}`,
    setPotatoStorage,
    potatoStorage
  );
  if (loading) return <Loading data-testid="loading" />;
  return (
    <InternalPageLayout
      pageData={potatoStorage}
      header={"Potato storage"}
      data-testid="potato-storage"
    />
  );
}
