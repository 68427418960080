import React from "react";
import { Box, Typography, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import accordionStyles from "./CustomAccordianStyles";
import { useSelector } from "react-redux";
import { darkTheme } from "../../theme/Theme";

export default function CustomAccordion({
  headerText,
  children,
  footerText,
  expanded,
  onToggle,
}) {
  const isDarkTheme = useSelector((store) => store.theme);

  return (
    <Box
      sx={accordionStyles.container}
      className="border rounded-sm border-[#D7E5F8] "
    >
      {/* Accordion Summary */}
      <Box
        onClick={onToggle}
        sx={accordionStyles.summaryBox(isDarkTheme)}
        className={`${expanded ? "" : "!shadow-none"}`}
      >
        <Box sx={accordionStyles.summaryContent}>
          <Typography
            sx={{
              ...accordionStyles.headerText,
              color: isDarkTheme ? "white" : "#3A3A3A",
            }}
            className="capitalize"
          >
            {headerText}
          </Typography>
          <Box sx={accordionStyles.expandBox}>
            {/* Toggle the text and icon based on the expanded state */}
            {/* <Typography sx={accordionStyles.expandText}>
              {expanded ? "Collapse" : "Expand"}
            </Typography> */}
            {expanded ? (
              <ExpandLessIcon sx={{ color: "#3A3A3A" }} /> // Show CollapseIcon when expanded
            ) : (
              <ExpandMoreIcon sx={{ color: "#3A3A3A" }} /> // Show ExpandMoreIcon when collapsed
            )}
          </Box>
        </Box>

        {/* Optional footer */}
        {/* {!expanded && ( */}
        <Box sx={accordionStyles.footerBox}>
          <Typography sx={accordionStyles.footerText}>Uploaded by: </Typography>
          <Typography sx={accordionStyles.footerSubText}>
            {footerText}
          </Typography>
        </Box>
        {/* )} */}
      </Box>

      {/* Accordion Details (Content Area) */}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            ...accordionStyles.detailsBox,
            background: isDarkTheme
              ? darkTheme.insideCardBackgroundColorLayer1
              : "var(--Tokens-Background-Layer-01, #FBFCFE)",
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
}
