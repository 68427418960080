import React from "react";
import useThemeRender from "./../../../../theme/useThemeRender";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from "@mui/material";

const DeletePopUp = ({
  options,
  selectedSubject,
  open,
  handleCloseDialog,
  handleConfirmClick,
  artifact,
  setArtifact,
  artifactCount,
}) => {
  const handleArtifactChange = (event, newValue) => {
    setArtifact(newValue);
  };
  const textColorLayer2 = useThemeRender("textColorLayer2");
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("backgroundColorLayer3"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: { xs: "25px", md: "32px" },
          width: "512px"
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1"),
          },
        },
      }}
    >
      <Box sx={{paddingBottom: "8px", marginBottom: "16px"}}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            fontSize: "24px",
            lineHeight: "32px",
            padding: "0px",
            color: useThemeRender("textColorLayer2"),
            marginBottom: "12px",
          }}
        >
          Remove Subject
          <IconButton
          onClick={handleCloseDialog}
          sx={{
            fontWeight: 700,
            lineHeight: "24px",
            color: useThemeRender("iconColor1"),
            padding: "0px",
            position: "absolute",
            right:{xs: "25px", md: "32px"}, 
            top: {xs: "25px", md: "32px"},
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            fontWeight: 400,
            lineHeight: "20px",
            padding: "0px",
            color: useThemeRender("textColorLayer2"),
          }}
        >
          <Box
            display="flex"
            justifyContent="left"
            sx={{ backgroundColor: "#EFF5FC", padding: "12px 16px", borderRadius: "4px", marginBottom: "12px" }}
          >
            <IconButton sx={{ padding: 0 }}>
              <InfoOutlinedIcon
                sx={{
                  color: useThemeRender("iconColor2"),
                  marginBottom: 6,
                }}
              />
            </IconButton>

            <Box ml={1}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 700,
                  color: "#000",
                  fontSize: "18px",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  lineHeight: "28px",
                }}
              >
                We identified {artifactCount} artifacts associated with this
                subject.
                <br />
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "#000",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Please select where to move the artifacts.
              </Typography>
            </Box>
          </Box>
          <Box display="flex"  flexDirection="column"  sx={{ backgroundColor: "#EFF5FC", padding: "8px", borderRadius: "8px", height: "74.95px", marginBottom: "21.42px"}}>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 700,
                  color: "#000",
                  fontSize: "14px",
                }}
              >
                Original subject
                
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "#000",
                }}
              >
                {selectedSubject}
              </Typography>
    
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="8px"
          >
            <Typography variant="body2">Move artifact to:</Typography>
            <Typography
              variant="caption"
              sx={{ color: useThemeRender("textColorLayer4"), fontSize: "12px" }}
            >
              *Required
            </Typography>
          </Box>

          <Autocomplete
            onChange={handleArtifactChange}
            options={[...options]}
            getOptionLabel={(option) => option.name || ""}
            value={artifact}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Please select"
                className="text-field"
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    color: textColorLayer2,
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    fontSize: "var(--Font-Size-Small, 14px)",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "var(--Line-Height-Small, 20px)",
                    
                  },
                }}
              />
            )}
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer1"),
              marginBottom: "19.63px",
              "& .MuiInputBase-root": {
                height: "36px",
                color: useThemeRender("textColorLayer2"),
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: useThemeRender("iconColor1"),
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderColor: useThemeRender("borderColorLayer2"),
                },
                "&:hover fieldset": {
                  borderColor: useThemeRender("borderColorLayer2"),
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: useThemeRender("iconColor1"),
                },
              },
            }}
          />
        </DialogContent>
      </Box>
      <DialogActions sx={{ justifyContent: "right", padding: "0px" }}>
        <Box className="ButtonBox" sx={{padding: "0px"}} flexDirection={{xs: "column", md: "row"}} >
          <Box
            sx={{
              width:{xs: "100%", md: "auto"},
              background: {xs:"var(--Tokens-Button-Primary, #005CBC)", md: "none"}
            }}
            className="ButtonStyle"
            onClick={handleCloseDialog}
          >
            <Typography
              sx={{
                color: {xs:"#fff", md: "#005CBC"},
                fontWeight: 700,
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                letterSpacing: 0,
                fontSize: "14px",
                lineHeight: "20px",
              }}
              className="textStyle"
            >
              Cancel
            </Typography>
          </Box>
          <Box
            width={{xs: "100%", md: "auto"}}
            sx={{
              background: "var(--Tokens-Button-Primary, #005CBC)",
              
            }}
            className="ButtonStyle"
            onClick={handleConfirmClick}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 700,
                p: "5px 10px",
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                letterSpacing: 0,
                fontSize: "14px",
                lineHeight: "20px",
              }}
              className="textStyle"
            >
              Confirm
            </Typography>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopUp;
