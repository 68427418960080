import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import { ToastContainer } from "react-toastify";
import FormatableTextArea from "../Curator/Components/FormatableTextArea";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { setDisableNextButton } from "../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import {
  getActiveItems,
  getPepsicoDropdownOptions,
  getDisplayTextForDropDown,
  getDisclaimerDetailsFromId,
} from "../../utils/function";
import { Card, Dropdown, Radio } from "pepsico-ds";
import { useMediaQuery } from "@mui/material";

const quillModules = {
  // toolbar: [
  //   ["bold", "italic", "underline"],
  //   [{ align: "" }, { align: "center" }, { align: "right" }],
  //   [{ list: "ordered" }, { list: "bullet" }],
  // ],
  toolbar: false,
};

const optimizedStyles = (isDarkTheme) => `
 .editor-container {
    position: relative;
    width:100%;
  }

  .editor-container .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    box-shadow: none; 
  }

  .editor-container .ql-container {
    height: 200px; 
    width: 100%;
    border: 1px solid #C0C0C0; 
    border-radius:4px;
    box-shadow: none;
    outline: none; 
    color: ${
      isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
    };
  }
.editor-container .ql-container .ql-editor.ql-blank::before {
    color: ${
      isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4
    };
  }
  .editor-container .ql-container:before {
  content: '';
  position: absolute;
  top: -1px; 
  left: 0;
  right: 0;
  height: 1px;
  background-color: #C0C0C0;
  border-radius: 4px;
}
`;

const StepFourForm = ({ internalData, setInternalData }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disclaimerData, setDisclaimerData] = useState();
  const [disclaimerDetails, setDisclaimerDetails] = useState("");
  const [languageData, setLanguageData] = useState(null);
  const dispatch = useDispatch();
  const { disclaimers } = useSelector((store) => store.internalFilter);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleChange = (field) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let fieldValue = null;
    if (field === "disclaimerId") {
      fieldValue = value;
    } else {
      fieldValue = value === "true" ? true : false;
    }
    setInternalData((prev) => ({
      ...prev,
      [field]: fieldValue,
    }));
  };

  const handleDropDownSelect = (field, value) => {
    if (field === "disclaimerId") {
      setDisclaimerDetails(
        getDisclaimerDetailsFromId(value?.[0]?.id, disclaimers)
      );
    }
    setInternalData((prev) => ({
      ...prev,
      [field]: value?.[0]?.id,
      selectedDisclaimerData: value,
    }));
  };

  const handleTranslationChoice = (field, value) => {
    setInternalData((prev) => ({
      ...prev,
      [field]: value === "Yes" ? true : false,
    }));
  };

  const handleLanguageChange = (field, value) => {
    setInternalData((prev) => ({
      ...prev,
      [field]: value?.[0]?.id,
      selectedLanguageData: value,
    }));
  };

  const [selectedLanguageData, setSelectedLanguageData] = useState();
  useEffect(() => {
    const selectedData = languageData?.find(
      (item) => item?.id === internalData.languageId
    );
    setSelectedLanguageData(selectedData);
  }, [internalData.languageId, languageData]);
  const handleQuillChange = (field) => (value) => {
    setInternalData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const isDarkTheme = useSelector((state) => state.theme);
  if (typeof window !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = optimizedStyles(isDarkTheme);
    document.head.appendChild(styleSheet);
  }
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    // margin: "16px 0px 16px 0px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,

    // width: "100%",
  };

  const renderEditor = (value, onChange, placeholder) => (
    <Box className="editor-container">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={quillModules}
        style={{ height: "100%", width: "100%", marginBottom: "16px" }}
      />
    </Box>
  );

  useEffect(() => {
    const fetchDisclaimerData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=DISCLAIMER`
        );
        if (response?.status == 200) {
          const activeItems = getActiveItems(response?.data);
          setDisclaimerData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchLanguageData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=LANGUAGE`
        );
        if (response?.status == 200) {
          const activeItems = getActiveItems(response?.data);
          setLanguageData(activeItems);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchLanguageData();
    fetchDisclaimerData();
  }, []);
  useEffect(() => {
    dispatch(setDisableNextButton(true));
  }, []);

  return (
    <Card
      ellipsis={false}
      isDark={isDarkTheme}
      icon=""
      title="Step 4: Summary"
      style={{ borderRadius: "8px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {/* Document section */}
        <Box sx={baseBoxStyle}>
          <FormatableTextArea
            label="Document Notes"
            showToolbar={true}
            setDescription={internalData.notes}
            changeFormatableText={handleQuillChange("notes")}
            placeholder="Type here"
            maxLength={500}
          />
        </Box>

        {/* Summary section */}
        <Box sx={{ ...baseBoxStyle, mt: 2 }}>
          <FormatableTextArea
            label="Summary"
            showToolbar={true}
            setDescription={internalData.summary}
            changeFormatableText={handleQuillChange("summary")}
            placeholder="Type here"
            maxLength={500}
          />
        </Box>

        <Box sx={{ mt: 2, mb: 2 }}>
          <FormControl>
            {disclaimerData?.length > 0 && (
              <Dropdown
                childList={disclaimerData.map((item) => ({
                  id: item.id,
                  displayText: item.name,
                }))}
                isClearable
                selection="single"
                setSelectedValue={(values) =>
                  handleDropDownSelect("disclaimerId", values)
                }
                size="medium"
                style={{
                  fontSize: "var(--Font-Size-XSmall, 12px)",
                  wordWrap: isMobile ? "break-word" : "",
                  maxWidth: isMobile ? "20rem" : "",
                }}
                selectedValue={internalData?.selectedDisclaimerData}
                helperText="Note: Select an option to display the disclaimer details"
                isDark={isDarkTheme}
                label="Disclaimer"
                required={true}
              />
            )}
          </FormControl>

          <FormatableTextArea
            isDarkTheme={isDarkTheme}
            isDisabled={true}
            setDescription={disclaimerDetails}
            showToolbar={true}
            label={"Disclaimer text"}
          />
        </Box>

        {/* Translation section */}
        <Box sx={baseBoxStyle}>
          <FormControl
            component="fieldset"
            sx={{ mb: 2 }}
          >
            {
              <Radio
                items={["Yes", "No"]}
                label="Language Translation"
                orientation="horizontal"
                reference="radio button"
                showText
                size="small"
                onUpdate={(index, value) =>
                  handleTranslationChoice("isTranslated", value)
                }
                selectedItemIndex={1}
                isDark={isDarkTheme}
                style={{ margin: "8px 0 0 0" }}
              />
            }
          </FormControl>

          {internalData?.isTranslated && (
            <>
              <FormControl sx={{ mb: 2, width: { xs: "100%", md: "40%" } }}>
                {languageData?.length > 0 && (
                  <Dropdown
                    childList={languageData.map((item) => ({
                      id: item.id,
                      displayText: item.name,
                    }))}
                    isClearable
                    label="Select Language"
                    selection="single"
                    setSelectedValue={(values) =>
                      handleLanguageChange("languageId", values)
                    }
                    size="medium"
                    selectedValue={internalData?.selectedLanguageData}
                    isDark={isDarkTheme}
                  />
                )}
              </FormControl>

              <Typography
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  // mb: 1,
                  fontSize: "12px",
                  fontFamily: "inherit",
                  lineHeight: "18px",
                }}
              >
                Translation
              </Typography>
              <Box className="editor-container">
                {renderEditor(
                  internalData.translatedText,
                  handleQuillChange("translatedText"),
                  "Type here"
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default StepFourForm;
