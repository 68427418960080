import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedCuratorPage: "Pending approvals",
  deniedHistoryContentType: "",
  deniedHistoryVisibility: "",
  deniedHistoryContentLevel: "",
  pendingApprovalData: {},
  annualApprovalData: {},
};

const curatorPageSlice = createSlice({
  name: "curatorPageSlice",
  initialState,
  reducers: {
    setSelectedCuratorPage: (state, action) => {
      state.selectedCuratorPage = action.payload;
    },
    setDeniedHistoryContentType: (state, action) => {
      state.deniedHistoryContentType = action.payload;
    },
    setDeniedHistoryVisibility: (state, action) => {
      state.deniedHistoryVisibility = action.payload;
    },
    setDeniedHistoryContentLevel: (state, action) => {
      state.deniedHistoryContentLevel = action.payload;
    },
    setPendingApprovalData: (state, action) => {
      state.pendingApprovalData = action.payload;
    },
    setAnnualPendingApprovalData: (state, action) => {
      state.annualApprovalData = action.payload;
    },
  },
});

export const {
  setSelectedCuratorPage,
  setDeniedHistoryContentType,
  setDeniedHistoryVisibility,
  setDeniedHistoryContentLevel,
  setPendingApprovalData,
  setAnnualPendingApprovalData,
} = curatorPageSlice.actions;
export default curatorPageSlice.reducer;
