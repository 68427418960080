import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import prop-types
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Filter, Button } from "pepsico-ds";
import { useSelector } from "react-redux";
import { styles } from "./searchFilterStyle";
import { useNavigate } from "react-router-dom";
import CustomSearch from "../../../utils/CustomSearch/CustomSearch";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { buttonStyle } from "../../InternalPages/InternalStyle";

const SearchFilter = ({
  setSearch,
  setVariety,
  setSector,
  setFlesh,
  setSelectedValue,
  selectedValue,
  searchText,
  setReload,
  reload,
  grid,
  setGrid,
  setLoad,
}) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [selectedFilter] = useState([]);
  const isMobile = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  const handleSelect = (view) => {
    setGrid(view);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSelectedSector = (sectorName) => {
    setSector(sectorName?.label);
  };

  const handleVariety = (sectorName) => {
    setVariety(sectorName?.label);
  };

  const handleFreshColor = (sectorName) => {
    setFlesh(sectorName?.label);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };
  const handleNavigate = useCallback(() => {
    if (searchText !== null && searchText !== undefined && searchText !== "")
      navigate(`/search-results`, {
        state: searchText,
      });
    setLoad(true);
    setFlesh(null);
    setVariety(null);
    setSector(null);
    setReload(!reload);
  }, [
    navigate,
    reload,
    searchText,
    setReload,
    setLoad,
    setFlesh,
    setVariety,
    setSector,
  ]);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleNavigate();
      }
    };
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress); // Cleanup event listener
    };
  }, [handleNavigate]);

  const { sectorFilter, varietyFilter, flashColorFilter } = useSelector(
    (store) => store.internalFilter
  );

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
            }
          : styles.container
      }
    >
      <Box sx={styles.filterWrapper}>
        <Filter
          label="Sector"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          value={selectedFilter}
          onChange={handleSelectedSector}
          options={sectorFilter.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />
        <Filter
          label="Variety"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          onChange={handleVariety}
          options={varietyFilter?.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />
        <Filter
          label="Flesh color"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          onChange={handleFreshColor}
          options={flashColorFilter.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />
      </Box>

      <Box
        sx={
          isMobile
            ? {
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                width: "100%",
              }
            : {
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                alignItems: "flex-end",
              }
        }
      >
        <Box
          sx={
            isMobile
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "16px",
                }
              : {
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  alignItems: "flex-end",
                }
          }
        >
          <CustomSearch
            handleNavigate={handleNavigate}
            handleSearch={handleSearch}
            searchText={searchText}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Button
              onClick={() => handleSelect("tile")}
              style={{
                ...buttonStyle,
                backgroundColor: grid === "tile" ? "#005CBC" : "transparent",
                color: grid === "tile" ? "#fff" : "#005CBC",
                border: grid === "tile" ? "none" : "1px solid #D7E5F8",
              }}
              className="mr-[8px]"
            >
              <GridOnIcon
                sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }}
              />
            </Button>

            <Button
              onClick={() => handleSelect("list")}
              style={{
                ...buttonStyle,
                backgroundColor: grid !== "tile" ? "#005CBC" : "transparent",
                color: grid !== "tile" ? "#fff" : "#005CBC",
                border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
              }}
            >
              <ViewListOutlinedIcon
                sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
              />
            </Button>
          </Box>
        </Box>

        <Box
          sx={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                  maxWidth: "400px",
                }
              : styles.resultBox
          }
        >
          <Typography
            sx={
              isDarkTheme
                ? { ...styles.typography, color: "white" }
                : styles.typography
            }
          >
            Show results by
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              sx={styles.select(isDarkTheme)}
            >
              <MenuItem value="exactMatch" sx={styles.menuItem(isDarkTheme)}>
                Top Results
              </MenuItem>
              <MenuItem
                value="recommendedMatch "
                sx={styles.menuItem(isDarkTheme)}
              >
                Recommended
              </MenuItem>
              <MenuItem
                value="historicalMatch"
                sx={styles.menuItem(isDarkTheme)}
              >
                Historical
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

// PropType validations
SearchFilter.propTypes = {
  setSearch: PropTypes.func.isRequired,
  setVariety: PropTypes.func.isRequired,
  setSector: PropTypes.func.isRequired,
  setFlesh: PropTypes.func.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  setReload: PropTypes.func.isRequired,
  reload: PropTypes.bool.isRequired,
  setGrid: PropTypes.func.isRequired,
  grid: PropTypes.string.isRequired,
};

export default SearchFilter;
