import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPI } from "../API/ApiService";
import { showErrorToast } from "../utils/Toast/toast";
import fetchAndCacheImage from "../utils/fetchandCatchImages";
import {
  setFetchFavourtie,
  setGlobalSelectedState,
  setSelectedAction,
} from "../slices/internalPageSlice";

export default function useFetchInternal(
  path,
  action,
  globalState, // Redux global state (potatoQuality)
  dependency
) {
  const { fetchFavourite } = useSelector((store) => store.internalPage);
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth_token");

  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(globalState || []); // Ensure local state initializes with globalState

  const tagThumbnailUrlsForSubPages = async (subPages) => {
    return Promise.all(
      subPages.map(async (subPage) => ({
        ...subPage,
        documents: await Promise.all(
          subPage.documents.map(async (doc) => ({
            ...doc,
            thumbnailUrl: await fetchAndCacheImage(doc.thumbnailFileId),
          }))
        ),
        videos: await Promise.all(
          subPage.videos.map(async (video) => ({
            ...video,
            thumbnailUrl: await fetchAndCacheImage(video.thumbnailFileId),
          }))
        ),
        links: await Promise.all(
          subPage.links.map(async (link) => ({
            ...link,
            thumbnailUrl: await fetchAndCacheImage(link.thumbnailFileId),
          }))
        ),
      }))
    );
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAPI(path);
      if (response?.status === 200) {
        setPageData(response?.data); // Update local state
        dispatch(action(response?.data)); // Update Redux state
        dispatch(setGlobalSelectedState(response?.data));
        dispatch(setSelectedAction(action));
        dispatch(setFetchFavourtie(false));
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      showErrorToast("Failed to Load Data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchFavourite, token]); // 🔹 Added `globalState` dependency

  return { loading, pageData };
}
