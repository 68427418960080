import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import useThemeRender from "../../../../theme/useThemeRender";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsOpenListOfvaluePopup,
  setListOfValueSearch,
  setlistOfValueSideTrayName,
  setSelectedListOfValues,
  setSpecialProjectDrawer,
} from "../../../../slices/adminPageSlice";

import { Button, Search } from "pepsico-ds";
import ListOfValuesTable from "./ListOfValuesTable";
import AddSpecialProject from "../SpecialProject/AddSpecialProject";
import ListOfDropDown from "./ListOfDropDown";
import Notification from "../SpecialProject/Notification";
import { TableWrapper } from "../../styles";
import { SECTOR_COUNTRY_MAPPING } from "./CONSTANT";
function ListOfValues() {
  const dispatch = useDispatch();

  const {
    selectedListOfValues,
    isOpenListOfvaluePopup,
    listOfValuePopupMessage,
    listOfValuePopupStatus,
  } = useSelector((store) => store.adminpage);
  const handleOpenSideTray = () => {
    dispatch(setSpecialProjectDrawer(true));
    dispatch(setlistOfValueSideTrayName("Add value"));
  };

  useEffect(() => {
    dispatch(setSelectedListOfValues(""));
  }, []);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleSearch = (value) => {
    dispatch(setListOfValueSearch(value));
  };
  const options = [
    // "Content level",
    "Sector",
    "Country",
    SECTOR_COUNTRY_MAPPING,
    "Crop type",
    "Disclaimer",
    "Language",
    "IP Ownership",
    "Flesh Color",
    // "Variety",
    // "Organization",
    // "Bussiness Unit",
    // "Company",
    // "Department",
    // "Partner Type",
    // "Period",
    // "Reason For Access",
    // "Region",
    // "Special Projects",
    // "Visibility",
  ]
    .sort((a, b) => a.localeCompare(b))
    .map((item, idx) => ({ id: idx + 1, displayText: item }));
  const textColorLayer2 = useThemeRender("textColorLayer2");

  return (
    <TableWrapper>
      <Typography variant="h5" fontWeight={700}>
        List of values
      </Typography>
      <ListOfDropDown
        isPeacock={true}
        options={options}
        label={"Value type"}
        value={"valueType"}
      />
      {selectedListOfValues && (
        <Box sx={{ backgroundColor: "#FBFCFE", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: textColorLayer2,
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                fontSize: "var(--Font-Size-H4, 28px)",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "var(--Line-Height-H4, 40px)",
                marginLeft: "8px",
              }}
            >
              {selectedListOfValues}
            </Typography>
            <Box sx={{ mt: "5px" }}>
              <Search onUpdate={handleSearch} isDark={isDarkTheme} />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Button
              onClick={handleOpenSideTray}
              variant="primary"
              size="medium"
              className="mb-2"
            >
              Add Value
            </Button>
          </Box>
          <ListOfValuesTable />
        </Box>
      )}
      <AddSpecialProject screen={"addListOfValues"} />
      <Notification
        open={isOpenListOfvaluePopup}
        setOpen={setIsOpenListOfvaluePopup}
        type={listOfValuePopupStatus}
        message={listOfValuePopupMessage}
      />
    </TableWrapper>
  );
}

export default ListOfValues;
