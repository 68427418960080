import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import MainHeader from "../components/MainHeader/MainHeader";
import "./container.css";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../theme/Theme";
import LoginFooter from "../components/LoginComponent/LoginFooter";
import useResize from "../hooks/useResize";

const Container = () => {
  const isDarkTheme = useSelector((state) => state.theme);

  const { isZoomed } = useResize();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <MainHeader />

      <main
        className={`responsive-main `}
        style={{
          marginTop: isZoomed ? "6rem" : "",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : lightTheme.backgroundColorLayer1,
        }}
      >
        <Outlet />
      </main>
      <LoginFooter />
    </div>
  );
};

export default Container;
