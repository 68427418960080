import React from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";

import useFetchInternal from "../hooks/useFetchInternal";
import Loading from "../utils/Loader";
import { useSelector } from "react-redux";
import { setGlobalTrials } from "../slices/internalPageSlice";
export default function GlobalTrials() {
  const { globalTrials } = useSelector((store) => store.internalPage);
  const userId = 1;
  const { loading } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Global%20Trials&userId=${userId}`,
    setGlobalTrials,
    globalTrials
  );
  if (loading) return <Loading data-testid="loading" />;

  return (
    <InternalPageLayout
      pageData={globalTrials}
      header={"Global trial"}
      data-testid="global-trials"
    />
  );
}
