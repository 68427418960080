export const lightTheme = {
  backgroundColorLayer1: "#F3F7FD",
  backgroundColorLayer2: "#FBFCFE",
  backgroundColorLayer3: "#FFF",
  cardBackgroundColorLayer1: "#FBFCFE",
  cardBackgroundColorLayer2: "#F3F7FD",
  cardBackgroundColorLayer3: "#FFF",
  insideCardBackgroundColorLayer1: "#FBFCFE",
  insideCardBackgroundColorLayer2: "#F3F7FD",
  insideCardBackgroundColorLayer3: "#EFF5FC",
  insideCardBackgroundColorLayer4: "#D7E5F8",
  insideCardBackgroundColorLayer5: "#EFF5FC",
  insideCardBackgroundColorLayer6: "#B2CAEA",
  iconColor1: "#3A3A3A",
  iconColor2: "#000",
  iconColor3: "#BABBBC",
  iconColor4: "#000",
  textColorLayer1: "var(--color-text-primary)",
  textColorLayer2: "#3A3A3A",
  textColorLayer3: "#D8DAE0",
  textColorLayer4: "#616161",
  textColorLayer5: "#27251F",
  borderColorLayer1: "#ddd",
  borderColorLayer2: "#000",
  borderColorLayer3: "#D7E5F8",
  carosalBackground: "FBFCFE",
  smallTextBackground: "#AAD2E2",
  radioButtonLayer1: "#757575",
  checkBoxLayer1: "#BDBDBD",
  selectedBoxLayer1: "#DFEAF9",
  disableButtonColor: "#BDBDBD",
  buttonBackgroundLayer1: "#D0D0D0",
  buttonBackgroundLayer2: "#DFDFDF",
  buttonBackgroundLayer3: "#DFDFDF",
  dropdownHoverColorLayer3: "#D8DAE0",
  drawerOverlayColorLayer1: "#00000080",
  boxShadowlayerColor1: "rgba(0, 92, 188, 0.08)",
  tableRowBackgroundColor: "#FBFCFE",
};

export const darkTheme = {
  backgroundColorLayer1: "#1D1D1D",
  backgroundColorLayer2: "#1C1F26",
  backgroundColorLayer3: "#121212",
  cardBackgroundColorLayer1: "#181A1B",
  cardBackgroundColorLayer2: "#1A1E23",
  cardBackgroundColorLayer3: "#0D0D0D",
  insideCardBackgroundColorLayer1: "#1C1E21",
  insideCardBackgroundColorLayer2: "#1A1E23",
  insideCardBackgroundColorLayer3: "#0A0F12",
  insideCardBackgroundColorLayer4: "#2A2D3E",
  insideCardBackgroundColorLayer5: "#818181",
  insideCardBackgroundColorLayer6: "#444444",
  iconColor1: "#EDEDED",
  iconColor2: "#fff",
  iconColor3: "#454444",
  iconColor4: "#202020",
  textColorLayer1: "var(--color-text-primary-dark)",
  textColorLayer2: "#EDEDED",
  textColorLayer3: "#27251F",
  textColorLayer4: "#D8D8D8",
  textColorLayer5: "#D8DAE0",
  borderColorLayer1: "#f2f2f2",
  borderColorLayer2: "#fff",
  borderColorLayer3: "#2E3843",
  carosalBackground: "#1D1D1D",
  smallTextBackground: "#3A5560",
  radioButtonLayer1: "#8A8A8A",
  checkBoxLayer1: "#424242",
  selectedBoxLayer1: "#1C2A3A",
  disableButtonColor: "#424242",
  buttonBackgroundLayer1: "#2F2F2F",
  buttonBackgroundLayer2: "#fff",
  dropdownHoverColorLayer3: "#27251F",
  drawerOverlayColorLayer1: "#FFFFFF80",
  boxShadowlayerColor1: "rgba(80, 80, 80, 0.3)",
  tableRowBackgroundColor: "#1C1E21",
};
