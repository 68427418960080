import React from "react";
import { Box, Typography } from "@mui/material";
import { Button } from "pepsico-ds";
import { postAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";

export default function PendingRequest({ data }) {
  console.log(data, "-------data------");
  const handleResend = async () => {
    try {
      const newData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        countryId: data.countryId,
        sponsorEmail: data.sponsorEmail,
        company: data.company,
      };
      const response = await postAPI(
        `${BASE_URL}/invitations/send-external-user`,
        newData
      );
      if (response?.status === 200) {
        console.log(response);
        showSuccessToast("Invitation Resend Successfully");
      }
    } catch (e) {
      showErrorToast("Failed to Send Invitation");
    }
  };
  return (
    <Box sx={{ paddingLeft: "48px" }}>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-16, 16px)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-16, 16px)",
          flex: "1 0 0",
          borderTop: "1px solid var(--gray-150)",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            minWidth: "280px",
            padding: "8px 16px",
            alignItems: "flex-start",
            alignContent: "flex-start",
            justifyContent: "space-between",
            gap: "8px",
            alignSelf: "stretch",
            flexWrap: "wrap",
            borderRadius: "4px",
            background: "var(--Tokens-Feedback-Info-background, #CCEAF6)",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <div className="flex flex-row gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 7.00098H13V9.00098H11V7.00098ZM11 11.001H13V17.001H11V11.001ZM12 2.00098C6.48 2.00098 2 6.48098 2 12.001C2 17.521 6.48 22.001 12 22.001C17.52 22.001 22 17.521 22 12.001C22 6.48098 17.52 2.00098 12 2.00098ZM12 20.001C7.59 20.001 4 16.411 4 12.001C4 7.59098 7.59 4.00098 12 4.00098C16.41 4.00098 20 7.59098 20 12.001C20 16.411 16.41 20.001 12 20.001Z"
                  fill="#0078A7"
                />
              </svg>
              <div>
                <Typography
                  sx={{
                    color: "var(--Tokens-Text-Black, #000)",
                    fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                    fontSize: "var(--Font-Size-H6, 18px)",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "var(--Line-Height-H6, 28px)", // 155.556%
                  }}
                >
                  {data.firstName} has not accepted your invitation yet
                </Typography>
                <Typography
                  sx={{
                    color: "var(--Tokens-Text-Black, #000)",
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    fontSize: "var(--Font-Size-Small, 14px)",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
                  }}
                >
                  Do you want to resend it ?
                </Typography>
              </div>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              marginY: "auto",
            }}
          >
            <Button
              size="medium"
              text="Resend invitation"
              variant="primary"
              onClick={handleResend}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
