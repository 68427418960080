import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import LikeButton from "../LikeButton";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { handleShare } from "../../../utils/function";

const typeStyle = {
  color: "var(--Tokens-Button-Borderless, #005CBC)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)",
  letterSpacing: "0.14px",
};

const boxStyle = {
  display: "flex",
  height: "var(--Spacing-spacing-32, 32px)",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  cursor: "pointer",
};

export default function ActionButtons({ card, fetch, setFetch }) {
  const location = useLocation();

  const [updatedLikes, setUpdatedLikes] = useState(card?.likes || 0);
  const [loading, setLoading] = useState(false);

  const handleLikesUpdated = (newLikes) => {
    setUpdatedLikes(newLikes);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "var(--Spacing-spacing-16, 16px)",
        }}
      >
        {/* Share Button */}
        <Box
          sx={boxStyle}
          onClick={() => handleShare(location)}
          style={{ cursor: "pointer" }}
        >
          <Typography sx={typeStyle}>Share</Typography>
          <ShareOutlinedIcon
            sx={{ fill: "#005CBC", height: "20px", width: "20px" }}
          />
        </Box>

        {/* Like Button */}
        <Box sx={boxStyle}>
          <Typography sx={typeStyle}>Like</Typography>
          <LikeButton
            cardId={card?.id}
            Liked={card?.isLiked}
            payloadLike={!card?.isLiked}
            setUpdatedLikes={setUpdatedLikes}
            onLikesUpdated={handleLikesUpdated}
            setLoading={setLoading}
            fetch={fetch}
            setFetch={setFetch}
          />
        </Box>
      </Box>
    </Box>
  );
}

// ✅ PropTypes Validation
ActionButtons.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired, // ID is required and should be a string
    isLiked: PropTypes.bool, // isLiked is optional and should be a boolean
    likes: PropTypes.number, // likes is optional and should be a number
  }).isRequired, // The entire `card` object is required
  fetch: PropTypes.bool.isRequired, // fetch is required and should be a boolean
  setFetch: PropTypes.func.isRequired, // setFetch is required and should be a function
};
