import React, { useEffect, useState } from "react";
import useFetchInternal from "../hooks/useFetchInternal";
import LibraryPageLayout from "../components/LibraryPage/LibraryPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import Loading from "../utils/Loader";
import { useSelector } from "react-redux";
import { setLibrary } from "../slices/LibraryPageSlice";
import { getAPI } from "../API/ApiService";
export default function Library() {
  const { library } = useSelector((store) => store.libraryDetailPage);
  const { favourite } = useSelector((store) => store.internalPage);
  const [pageData, setPageData] = useState();
  const [loading, setLoading] = useState(true);
  console.log(library, "libraryPage");

  // const { loading, pageData } = useFetchInternal(
  //   `${BASE_URL}/library-page/find-all`,
  //   setLibrary,
  //   library,
  //   "Library"
  // );

  useEffect(() => {
    const getLibraryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/library-page/find-all`
        );
        console.log("library response", response);

        if (response?.status === 200) {
          console.log("library subjectItems", response?.data?.subjectItems);
          setPageData(response?.data?.response);
          setLoading(false);
        }
      } catch (error) {
        console.log('Library api error', error);
        setLoading(false);
      }
    };
    getLibraryData();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <LibraryPageLayout pageData={pageData} header={"Library"} />
  );
}
