import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DropDown from "../DropDown/DropDown";
import { useSelector } from "react-redux";
import RenderPage from "../RenderPage/RenderPage";
import { darkTheme, lightTheme } from "../../../theme/Theme";

export default function MobileAdmin() {
  const isDarkTheme = useSelector((state) => state.theme);
  const [heroData, setHeroData] = useState(
    Array(5).fill({ images: [], formData: {} }) // 5 hero components
  );
  const props = {
    flexDirection: "column",
    width: "100%",
  };
  const updateHeroData = (index, images, formData) => {
    setHeroData((prev) =>
      prev.map((hero, i) => (i === index ? { images, formData } : hero))
    );
  };

  return (
    <Box
      sx={{
        display: "flex", // Flexbox layout
        width: "100%", // Full width
        flexDirection: "column", // Stack elements vertically
        padding: "var(--Spacing-spacing-24, 24px)", // Padding from custom variable
        backgroundColor: isDarkTheme
          ? darkTheme.backgroundColorLayer2
          : "background: var(--Tokens-Background-Layer-02, #EFF5FC);",
        boxShadow:
          "var(--Elevation-X-Elevation-04, 4px) var(--Elevation-Y-Elevation-04, 0px) var(--Elevation-Blur-Elevation-04, 24px) var(--Elevation-Spread-Elevation-04, -2px) var(--Elevation-Colors-Elevation-04, rgba(0, 92, 188, 0.08))", // Box shadow
      }}
    >
      <div className="flex flex-col gap-6 w-full h-auto">
        <Typography
          sx={{
            alignSelf: "stretch",
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFeatureSettings: "'liga' off, 'clig' off", // Disabling specific font features
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)", // Font family
            fontSize: "var(--Font-Size-H5, 28px)", // Font size for H5
            fontStyle: "normal", // Normal style
            fontWeight: 700, // Bold font weight
            lineHeight: "var(--Line-Height-H5, 32px)", // Line height
          }}
        >
          Administration
        </Typography>
        <Box data-testid="render-dropdown">
          <DropDown />
        </Box>

        <Box
          sx={{
            display: "flex", // Set display to flex
            flexDirection: "column", // Flex direction set to column
            alignItems: "flex-start", // Align items to start
            alignSelf: "stretch", // Stretch the box to fill available space
            background: "#DFDFDF",
            height: "1px", // This might be intended for a divider
          }}
        />
        <Box data-testid="render-page">
          <RenderPage
            flexDirection={props.flexDirection}
            width={props.width}
            heroData={heroData}
            updateHeroData={updateHeroData}
          />
        </Box>
      </div>
    </Box>
  );
}
