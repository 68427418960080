import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { useDropzone } from "react-dropzone";
import upload from "../../../../../../assets/upload.png";
import commonStyles from "../../../Landing/addVariteyDrawerStyle";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  addFiles,
  deleteFile,
} from "../../../../../../slices/potatoProtectionSlice";
import { darkTheme } from "../../../../../../theme/Theme";
import axios from "axios";
import { FILE_SERVICE_BASE_URL } from "../../../../../../constant/apiUrl";
import { showSuccessToast } from "../../../../../../utils/Toast/toast";

const dropzoneStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: "1px dashed #D7E5F8",
  width: "100%",
  height: "200px",
  gap: "8px",
  cursor: "pointer",
};

export default function FileUpload({ data, handleDeleteFile }) {
  const dispatch = useDispatch();
  const { files } = useSelector((store) => store.protectionPotato);
  const isDarkTheme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      dispatch(addFiles(acceptedFiles));
    }
  };
  const token = localStorage.getItem("auth_token");
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [],
      "application/msword": [".doc"],
      "text/plain": [".txt"],
     // "image/jpeg": [],
      //"image/png": [],
    //  "image/svg+xml": [],
    },
    onDrop,
    multiple: true,
  });

  console.log(data, "----dataFileee-----------");

  const handleDelete = async (file) => {
    console.log(file.id, "--fillllll--------");

    setLoading(true);
    if (file.fetch) {
      try {
        const response = await axios.delete(
          `${FILE_SERVICE_BASE_URL}/fritolay/upload/${file.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/octet-stream",
            },
          }
        );
        if (response?.status === 200) {
          dispatch(deleteFile(file.name));
          handleDeleteFile(file.id);

          showSuccessToast("File Deleted Successfully");
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      dispatch(deleteFile(file.name));
      showSuccessToast("File Deleted Successfully");
    }
  };

  return (
    <Box sx={commonStyles.container}>
      <Typography
        sx={{
          ...commonStyles.typographyLabel,
          color: isDarkTheme && darkTheme.textColorLayer1,
        }}
      >
        Documents
      </Typography>
      <Box
        {...getRootProps()}
        sx={dropzoneStyle}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
      >
        <input {...getInputProps()} />
        <img src={upload} alt="Document Icon" />
        <Typography
          variant="body1"
          sx={{
            textAlign: { xs: "center" },
            width: { xs: "80%" },
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          {isDragActive ? (
            "Drop the file here..."
          ) : (
            <>
              Drag and drop your file here <br /> (only  .doc, .pdf)
            </>
          )}
        </Typography>
        <Button
          variant="text"
          sx={{
            mt: 2,
            borderRadius: "24px",
            border: "1px solid #005CBC",
            px: 2,
          }}
          startIcon={<UploadOutlinedIcon />}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#005CBC",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "18px",
              textTransform: "none",
            }}
          >
            Upload file
          </Typography>
        </Button>
      </Box>

      {files?.map((file, index) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          key={index}
        >
          <Box
            sx={{
              display: "flex",
              padding: "8px 16px",
              flexDirection: "row",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <InsertDriveFileOutlinedIcon sx={{ fill: "#005CBC" }} />
            <Typography
              sx={{
                color: "#3A3A3A",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              {file.name.length > 15
                ? `${file.name.slice(0, 12)}...`
                : file.name}
            </Typography>
          </Box>
          <Button
            onClick={() => handleDelete(file)}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#005CBC",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Delete
            </Typography>
            <DeleteOutlineOutlinedIcon sx={{ fill: "#005CBC" }} />
          </Button>
        </Box>
      ))}
    </Box>
  );
}
