import { darkTheme, lightTheme } from "../../../theme/Theme";

const boxStyles = (theme) => ({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  flex: "1 0 0",
  minWidth: "20%",
  background: theme ? theme.insideCardBackgroundColorLayer3 : "#EFF5FC",
});

const labelStyles = (isDarkTheme) => ({
  color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer4,
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "20px", // Adjust line height based on your design tokens
});

const contentStyles = (isDarkTheme) => ({
  color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer4,
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  width: "100%",
  wordWrap: "break-word"
});

export { contentStyles, labelStyles, boxStyles };
