import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import useThemeRender from "../../../theme/useThemeRender";

function DeniedDetailLastContainer({ detailData }) {
  return (
    <Box
      sx={{
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
      className="custom-container"
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Document notes
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.documentNotes}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Summary
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.summary}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Disclaimer
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.disclaimer}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Disclaimer details
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.disclaimerDetails}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Language translation
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {String(detailData?.languageTranslation) === "true"
                ? "Yes"
                : "No"}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Language
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.language}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Translation
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {detailData?.translationContent}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DeniedDetailLastContainer;
