import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "../Style/varietyDetailStyle";
import VarietyDetailCard from "./VarietyDetailCard";
import CountryDetails from "../CountrySection/CountryDetails";
import AddVariteyDrawer from "../../Landing/AddVariteyDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  addFiles,
  closeDrawer,
  fetchVariety,
} from "../../../../../slices/potatoProtectionSlice";
import Loading from "../../../../../utils/Loader";
import EditCountryDrawer from "../CountrySection/CountryDrawer/EditCountryDrawer";
import { darkTheme, lightTheme } from "../../../../../theme/Theme";
import axios from "axios";
import { BASE_URL } from "../../../../../constant/apiUrl";
import { getAPI } from "../../../../../API/ApiService";
import fetchAndCacheImageName from "../../../../../utils/fetchAndCatchImageName";
export default function VarietyDetail() {
  const [loading, setLoading] = useState(false);
  const [variety, setVariety] = useState({});
  const [countryData, setCountryData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = localStorage.getItem("auth_token");
  const { fetch } = useSelector((store) => store.protectionPotato);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const result = await getAPI(`${BASE_URL}/variety/${id}`);
        if (result?.status === 200) {
          setVariety(result?.data);
          dispatch(fetchVariety(false));
          const newResult = await getAPI(
            `${BASE_URL}/country-detail/variety/${id}`
          );
          if (newResult?.status === 200) {
            const fetched = newResult?.data;
            const modified = await Promise.all(
              fetched?.map(async (item) => {
                if (item?.fileIds && item.fileIds.length > 0) {
                  const files = await Promise.all(
                    item?.fileIds.map((id) => fetchAndCacheImageName(id))
                  );

                  const newFiles = files.map((item) => {
                    return { ...item, fetch: true };
                  });

                  return { ...item, newFiles }; // Attach the fetched files to the object
                }
                return { ...item, files: [] }; // Ensure files key is always present
              })
            );

            setCountryData(modified);
          }
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, fetch, token]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { isOpen } = useSelector((store) => store.protectionPotato);
  const HandleBack = () => {
    navigate("/potato-protection-status");
  };
  const isDarkTheme = useSelector((state) => state.theme);
  console.log(countryData, "----countryData-------");
  const handleDeleteFile = (id) => {
    console.log(countryData, "---Before Deletion---");

    setCountryData((prevData) =>
      prevData.map((item) => ({
        ...item,
        newFiles: Array.isArray(item.newFiles) // Ensure newFiles is an array
          ? item.newFiles.filter((file) => file.id !== id)
          : [], // If undefined, set an empty array
      }))
    );

    console.log(countryData, "---After Deletion---");
  };

  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        backgroundColor: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
        minHeight: "77vh",
      }}
    >
      <Box
        sx={
          isMobile
            ? {
                display: "flex",
                flexDirection: "column", // Align children vertically
                alignItems: "flex-start", // Align children to the start of the container
                gap: "16px", // Space between children
                alignSelf: "stretch", // Ensure the element stretches to fill available space
              }
            : styles.content
        }
      >
        <Typography
          sx={
            isMobile
              ? {
                  padding: "16px",
                  width: "100%",
                  mt: "16px",
                  ...styles.heading,
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : lightTheme.textColorLayer2,
                }
              : {
                  ...styles.heading,
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : lightTheme.textColorLayer2,
                }
          }
        >
          Potato protection status
        </Typography>
        <Box sx={isMobile ? { padding: "16px", width: "100%" } : {}}>
          <Button onClick={HandleBack} sx={styles.button}>
            <ArrowBackIcon sx={styles.arrowIcon} />
            <Typography sx={styles.backText}>Back</Typography>
          </Button>
        </Box>
        <VarietyDetailCard variety={variety} />
        <CountryDetails data={countryData} />
      </Box>
      <AddVariteyDrawer
        open={isOpen}
        Close={() => dispatch(closeDrawer())}
        variety={variety}
      />
      <EditCountryDrawer
        data={countryData}
        handleDeleteFile={handleDeleteFile}
      />
    </Box>
  );
}
