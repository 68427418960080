import { useEffect, useState } from "react";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import { showErrorToast } from "../utils/Toast/toast";
import { getActiveItems } from "../utils/function";
import { useDispatch, useSelector } from "react-redux";
import {
  setSectorFilter,
  setCountryFilter,
  setVarietyFilter,
  setFlashColorFilter,
  setIpOwnerShipFilter,
  setRegionFilter,
  setLanguageFilter,
  setSubjectFilter,
  setCropTypeFilter,
  setContentLevel,
  setVisibility,
  setSpecialProjects,
  setPeriods,
  setDisclaimers,
} from "../slices/internalFilterSlice";

const useLookupData = (lookUpType, dependancy = null) => {
  const [sectorData, setSectorData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [varietyData, setVarietyData] = useState([]);
  const [fleshColorData, setFleshColorData] = useState([]);
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { reload } = useSelector((store) => store.internalFilter);
  useEffect(() => {
    const fetchLookupData = async () => {
      setLoading(true);
      setError(null); // Reset error for a new request
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=${lookUpType}`
        );
        if (response.status === 200) {
          const activeItems = getActiveItems(response?.data);

          switch (lookUpType) {
            case "SECTOR":
              dispatch(setSectorFilter(activeItems));
              setSectorData(activeItems);
              break;
            case "COUNTRY":
              dispatch(setCountryFilter(activeItems));
              setCountryData(activeItems);
              break;
            case "VARIETY":
              dispatch(setVarietyFilter(activeItems));
              setVarietyData(activeItems);
              break;
            case "FLESH_COLOR":
              dispatch(setFlashColorFilter(activeItems));
              setFleshColorData(activeItems);
              break;
            case "REASON_FOR_ACCESS":
              setUser(activeItems);
              break;
            case "IPOWNERSHIP":
              dispatch(setIpOwnerShipFilter(activeItems));
              break;
            case "REGION":
              dispatch(setRegionFilter(activeItems));
              break;
            case "LANGUAGE":
              dispatch(setLanguageFilter(activeItems));
              break;
            // case "SUBJECT":
            //   dispatch(setSubjectFilter(activeItems));
            //   break;
            case "CROP_TYPE":
              dispatch(setCropTypeFilter(activeItems));
              break;
            case "SPECIAL_PROJECTS":
              dispatch(setSpecialProjects(activeItems));
              break;
            case "VISIBILITY":
              dispatch(setVisibility(activeItems));
              break;
            case "CONTENT_LEVEL":
              dispatch(setContentLevel(activeItems));
              break;
            case "DISCLAIMER":
              dispatch(setDisclaimers(activeItems));
              break;
            case "PERIOD":
              dispatch(setPeriods(activeItems));
              break;
            case "VARIETY":
                dispatch(setPeriods(activeItems));
                break;
            default:
              throw new Error("Invalid lookup type");
          }
        }
        if (response?.status === 500) {
          showErrorToast(`Failed to load  ${lookUpType}`);
        }
      } catch (err) {
        showErrorToast(`Something went wrong`);
      } finally {
        setLoading(false);
      }
    };
    fetchLookupData();
  }, [dependancy, reload, dispatch, lookUpType]);

  return {
    sectorData,
    countryData,
    varietyData,
    fleshColorData,
    loading,
    error,
    setLoading,
    user,
  };
};

export default useLookupData;
