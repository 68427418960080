import React, { useState, useMemo, useCallback } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { Pagination } from "pepsico-ds";
import CardComponent from "./CardComponent";
import { commonTextStyle } from "../InternalStyle";
import Expanded from "./Expanded";
import useThemeRender from "../../../theme/useThemeRender";
import PropTypes from "prop-types";
import Loading from "../../../utils/Loader";
import { useSelector } from "react-redux";
import "./InternalCarousal.css";
import { darkTheme } from "../../../theme/Theme";

const InternalCarousal = ({
  data = [],
  content,
  flexDirection = "row",
  heading = "",
  isHeadingBig = false,
  subPageId,
  localState,
  setLocalState,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  // Memoized values to avoid recalculations
  const totalPages = useMemo(() => Math.ceil(data.length || 0), [data.length]);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMedium = useMediaQuery("(min-width:600px) and (max-width:1300px)");
  const isDarkTheme = useSelector((state) => state.theme);

  const iconColor1 = useThemeRender("iconColor1");
  const textColorLayer2 = useThemeRender("textColorLayer2");

  // Handlers memoized to prevent unnecessary re-renders
  const handlePaginationChange = useCallback(
    (page) => {
      if (page > 0 && page <= totalPages) {
        setCurrentIndex(page - 1);
      }
    },
    [totalPages]
  );

  const handleExpandClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);
  const translateValue = isMobile ? 101 : isMedium ? 49.3 : 33;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        padding: "16px",
        justifyContent: "center",
        borderRadius: "8px",
      }}
    >
      {loading && <Loading />}

      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          sx={
            isHeadingBig
              ? {
                  color: iconColor1,
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontFamily: "Inter",
                  fontSize: "clamp(1.25rem, 0.625rem + 2vw, 1.75rem)",
                  fontWeight: 700,
                  lineHeight: "clamp(1.875rem, 1.0938rem + 2.5vw, 2.5rem)",
                }
              : {
                  ...commonTextStyle,
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: textColorLayer2,
                }
          }
        >
          {heading}
        </Typography>

        {/* Expand/Collapse Button */}
        <Button
          sx={{
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            gap: "2px",
          }}
          onClick={handleExpandClick}
          aria-label={isExpanded ? "Collapse all items" : "Expand all items"}
        >
          <Typography
            sx={{
              color: "#005CBC",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "22px",
            }}
          >
            {isExpanded ? "Collapse" : "Expand All"}
          </Typography>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 12 13"
            fill="none"
          >
            <path
              d={
                isExpanded
                  ? "M3.705 8.07121L6 5.78121L8.295 8.07121L9 7.36621L6 4.36621L3 7.36621L3.705 8.07121Z"
                  : "M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
              }
              fill="#005CBC"
            />
          </svg>
        </Button>
      </Box>

      {/* Expanded View */}
      {isExpanded ? (
        <Expanded
          data={data}
          content={content}
          flexDirection={flexDirection}
          heading={heading}
          setLoading={setLoading}
        />
      ) : (
        <>
          {/* Carousel Container */}
          <Box
            sx={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
              padding: "15px 0px",
              paddingTop: "8px",
              background: isDarkTheme
                ? darkTheme.backgroundColorLayer1
                : "#fff",
            }}
          >
            {/* Slide Track */}
            <Box
              sx={{
                display: "flex",
                transform: `translateX(-${currentIndex * translateValue}%)`,
                transition: "transform 0.5s ease-in-out",
                gap: "0.9%",
              }}
            >
              {/* Cards */}
              {data?.map((card) => (
                <CardComponent
                  key={card?.id}
                  card={card}
                  isMobile={isMobile}
                  content={content}
                  flexDirection={flexDirection}
                  heading={heading}
                  setLoading={setLoading}
                  subPageId={subPageId}
                  setLocalState={setLocalState}
                  localState={localState}
                />
              ))}
            </Box>
          </Box>

          {/* Pagination */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <Pagination
              currentPage={currentIndex + 1}
              onUpdate={handlePaginationChange}
              pageCount={totalPages}
              size="medium"
              variant="dot"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

// Custom prop comparison function to avoid unnecessary re-renders
const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.heading === nextProps.heading &&
    prevProps.isHeadingBig === nextProps.isHeadingBig &&
    prevProps.flexDirection === nextProps.flexDirection &&
    prevProps.subPageId === nextProps.subPageId &&
    prevProps.content === nextProps.content &&
    prevProps.localState === nextProps.localState &&
    prevProps.setLocalState === nextProps.setLocalState &&
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) // Deep compare data
  );
};

// Memoized component with deep prop comparison
export default React.memo(InternalCarousal, arePropsEqual);

InternalCarousal.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Assuming each card has an `id`
      // Add other fields specific to your card data if needed
    })
  ).isRequired, // Ensure `data` is an array of objects
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Depending on what `content` is
  flexDirection: PropTypes.oneOf(["row", "column"]), // If flex direction is limited to "row" or "column"
  heading: PropTypes.string, // Heading should be a string
  isHeadingBig: PropTypes.bool, // Boolean flag for heading style
};
