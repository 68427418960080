import React, { useEffect, useState } from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import Loading from "../utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setFavourites } from "../slices/internalPageSlice";

import { showErrorToast } from "../utils/Toast/toast";

export default function Favorites() {
  const [loading, setLoading] = useState(true);

  const { favourites } = useSelector((store) => store.internalPage);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/favorites/find-all-by-userid`
        );

        if (response.status === 200) {
          dispatch(
            setFavourites({
              internalSubPages: [response?.data?.response],
            })
          );
        }
      } catch (err) {
        showErrorToast(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  if (loading) return <Loading data-testid="loading-spinner" />;

  return (
    <InternalPageLayout
      pageData={favourites || []}
      header={"My favorites"}
      showDropdown={false}
      data-testid="internal-page-layout"
    />
  );
}
