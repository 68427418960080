import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { commonTextStyle } from "../InternalStyle";
import InternalCarousal from "../Carousal-ExpandedView/InternalCarousal";
import { useSelector } from "react-redux";
import ListViewDesign from "../ListView/ListViewDesign";
import useThemeRender from "../../../theme/useThemeRender";
import PropTypes from "prop-types";

// Memoized component to prevent unnecessary re-renders
const InternalCommonSection = React.memo(
  ({ data, grid, selectedSection, subPageId }) => {
    const { documents, links, videos } = data;

    const {
      search,
      sector,
      variety,
      country,
      flashColor,
      ipOwnerShip,
      selectedGlobalState,
    } = useSelector((store) => store.internalPage);

    const [localState, setLocalState] = useState(selectedGlobalState);

    useEffect(() => {
      if (localState !== selectedGlobalState) {
        setLocalState(selectedGlobalState);
      }
    }, [selectedGlobalState]);

    // Memoize filtered data to avoid unnecessary calculations
    const filteredRows = useMemo(() => {
      const filterData = (items) =>
        items?.filter((item) => {
          const matchesSearch = Object.values(item)?.some((value) =>
            String(value)
              ?.toLowerCase()
              ?.includes(search?.toLowerCase() || "")
          );
          return (
            matchesSearch &&
            (!sector ||
              item?.sector?.toLowerCase() === sector?.label?.toLowerCase()) &&
            (!country ||
              item?.country?.toLowerCase() === country?.label?.toLowerCase()) &&
            (!variety ||
              item?.variety?.toLowerCase() === variety?.label?.toLowerCase()) &&
            (!flashColor ||
              item?.fleshColor?.toLowerCase() ===
                flashColor?.label?.toLowerCase()) &&
            (!ipOwnerShip ||
              item?.ipOwnerShip?.toLowerCase() ===
                ipOwnerShip?.label?.toLowerCase())
          );
        });

      return {
        documents: filterData(documents),
        links: filterData(links),
        videos: filterData(videos),
      };
    }, [
      documents,
      links,
      videos,
      search,
      sector,
      country,
      variety,
      flashColor,
      ipOwnerShip,
    ]);

    useEffect(() => {
      if (selectedSection) {
        const element = document.getElementById(selectedSection);
        if (element) {
          const offset = 150; // Adjust this value to match your header height
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY - offset;

          window.scrollTo({
            top: elementPosition,
            behavior: "smooth",
          });
        }
      }
    }, [selectedSection]);

    return (
      <section
        id={data?.subPageName}
        style={{
          display: "flex",
          width: "100%",
          padding: "24px",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "8px",
          backgroundColor: useThemeRender("backgroundColorLayer2"),
          boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
        }}
      >
        {/* Section Header */}
        <Box
          sx={{ display: "flex", alignItems: "center", alignSelf: "stretch" }}
        >
          <Typography
            sx={{
              ...commonTextStyle,
              fontSize: "24px",
              lineHeight: "32px",
              color: useThemeRender("textColorLayer2"),
            }}
          >
            {data?.subPageName}
          </Typography>
        </Box>

        {/* Grid View */}
        {grid === "tile" ? (
          <Box sx={{ width: "100%" }}>
            {filteredRows?.documents?.length > 0 && (
              <Box sx={{ marginBottom: "16px" }}>
                <InternalCarousal
                  data={filteredRows?.documents}
                  content={"Documents"}
                  flexDirection={"column"}
                  heading={"Documents"}
                  isHeadingBig={false}
                  subPageId={subPageId}
                  localState={localState}
                  setLocalState={setLocalState}
                />
              </Box>
            )}
            {filteredRows?.videos?.length > 0 && (
              <Box sx={{ marginBottom: "16px" }}>
                <InternalCarousal
                  data={filteredRows?.videos}
                  content={"Videos"}
                  flexDirection={"column"}
                  heading={"Videos"}
                  isHeadingBig={false}
                  localState={localState}
                  setLocalState={setLocalState}
                />
              </Box>
            )}
            {filteredRows?.links?.length > 0 && (
              <Box sx={{ marginBottom: "16px" }}>
                <InternalCarousal
                  data={filteredRows?.links}
                  content={"Links"}
                  flexDirection={"column"}
                  heading={"Links"}
                  isHeadingBig={false}
                  subPageId={subPageId}
                  localState={localState}
                  setLocalState={setLocalState}
                />
              </Box>
            )}
          </Box>
        ) : (
          // List View
          <Box sx={{ width: "100%", marginTop: "16px" }}>
            {filteredRows?.documents?.length > 0 && (
              <Box sx={{ marginBottom: "16px" }}>
                <ListViewDesign
                  data={filteredRows?.documents}
                  content={"Documents"}
                  localState={localState}
                  setLocalState={setLocalState}
                />
              </Box>
            )}
            {filteredRows?.videos?.length > 0 && (
              <Box sx={{ marginBottom: "16px" }}>
                <ListViewDesign
                  data={filteredRows?.videos}
                  content={"Videos"}
                  localState={localState}
                  setLocalState={setLocalState}
                />
              </Box>
            )}
            {filteredRows?.links?.length > 0 && (
              <Box sx={{ marginBottom: "16px" }}>
                <ListViewDesign
                  data={filteredRows?.links}
                  content={"Links"}
                  localState={localState}
                  setLocalState={setLocalState}
                />
              </Box>
            )}
          </Box>
        )}
      </section>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.data === nextProps.data &&
      prevProps.grid === nextProps.grid &&
      prevProps.selectedSection === nextProps.selectedSection &&
      prevProps.subPageId === nextProps.subPageId
    );
  }
);

InternalCommonSection.propTypes = {
  data: PropTypes.shape({
    subPageName: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        sector: PropTypes.string,
        country: PropTypes.string,
        variety: PropTypes.string,
        fleshColor: PropTypes.string,
        ipOwnerShip: PropTypes.string,
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        sector: PropTypes.string,
        country: PropTypes.string,
        variety: PropTypes.string,
        fleshColor: PropTypes.string,
        ipOwnerShip: PropTypes.string,
      })
    ),
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        sector: PropTypes.string,
        country: PropTypes.string,
        variety: PropTypes.string,
        fleshColor: PropTypes.string,
        ipOwnerShip: PropTypes.string,
      })
    ),
  }).isRequired,
  grid: PropTypes.oneOf(["tile", "list"]).isRequired,
  selectedSection: PropTypes.string,
  subPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InternalCommonSection;
