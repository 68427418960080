import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";

import LoginCards from "../components/LoginComponent/LoginCards";
import MobileSign from "../components/LoginComponent/MobileSign";
import LoginHeader from "../components/LoginComponent/LoginHeader";

import LoginFooter from "../components/LoginComponent/LoginFooter";
import LoginBanner from "../components/LoginComponent/LoginBanner";
import Loading from "../utils/Loader";

const Login = () => {
  const isSmallScreen = useMediaQuery("(max-width:899px)");
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <Loading data-testid="loading" />}
      {isSmallScreen ? (
        <MobileSign setLoading={setLoading} data-testid="mobile-sign" /> // <MobileView />
      ) : (
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#EFF5FC",
            flexShrink: 0,
            justifyContent: "center",
            gap: "24px",
          }}
        >
          {/* Header */}
          <LoginHeader data-testid="login-header" />

          <main
            style={{ flex: 1 }}
            className="flex flex-row justify-center items-center gap-4"
          >
            <LoginBanner data-testid="login-banner" />
            <LoginCards setLoading={setLoading} data-testid="login-cards" />
          </main>
          <LoginFooter data-testid="login-footer" />
        </Box>
      )}
    </>
  );
};

export default Login;
