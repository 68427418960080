import { Box, useMediaQuery } from "@mui/material";
import { Button, Filter, Search } from "pepsico-ds";
import React from "react";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { useDispatch, useSelector } from "react-redux";

import {
  setFritoCountry,
  setFritoLanguage,
  setFritoSearch,
  setFritoSector,
  setFritoSubject,
} from "../../slices/fritoForumPageSlice";

function ForumFilter({ setGrid, grid }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme);
  const { sectorFilter, countryFilter, laguageFilter, subjectFilter } =
    useSelector((store) => store.internalFilter);

  const handleSelectedSector = (sectorName) => {
    dispatch(setFritoSector(sectorName));
  };
  const handleSelectedCountry = (countryName) => {
    dispatch(setFritoCountry(countryName));
  };
  const handleSearch = (value) => {
    dispatch(setFritoSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };

  const handleLanguageChange = (value) => {
    dispatch(setFritoLanguage(value));
  };

  const handleSubjectChange = (value) => {
    dispatch(setFritoSubject(value));
  };

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Filter
            label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Sector</span>}
            onChange={handleSelectedSector}
            options={(sectorFilter || []).map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Country</span>}
            onChange={handleSelectedCountry}
            options={(countryFilter || []).map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Language</span>}
            onChange={handleLanguageChange}
            options={(laguageFilter || []).map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          <Filter
            label={<span style={{fontFamily: "Inter", fontSize: "14px"}}>Subject</span>}
            onChange={handleSubjectChange}
            options={(subjectFilter || []).map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", flexDirection: "row", gap: "16px" }
        }
      >
        <Search onUpdate={handleSearch} isDark={isDarkTheme} className={isMobile ? "w-full !max-w-full" : ""} />
        {
          !isMobile ?
          <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
          <Button
            onClick={() => handleSelect("tile")}
            style={{
              display: "flex",
              minWidth: "40px",
              minHeight: "40px",
              height: "32px",
              padding: "8px 4px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
              color: grid === "tile" ? "#fff" : "#005CBC",
              border: grid === "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <GridOnIcon sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }} />
          </Button>

          <Button
            onClick={() => handleSelect("list")}
            style={{
              display: "flex",
              minWidth: "40px",
              minHeight: "40px",
              height: "32px",
              padding: "8px 4px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
              color: grid !== "tile" ? "#fff" : "#005CBC",
              border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <ViewListOutlinedIcon
              sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
            />
          </Button>
        </Box>
        :null
        }
      </Box>
    </Box>
  );
}

export default ForumFilter;
