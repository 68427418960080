import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { useSelector } from "react-redux";
import { setEnvironmental } from "../slices/sustainabilityPageSlice";

function Environmental() {
  const { environmental } = useSelector((store) => store.sustainable);

  return (
    <SustainabilitySubpage
      urlName={"Environmental%2C%20social%20%26%20governance%20topics"}
      header={"Environmental, social & governance topics"}
      action={setEnvironmental}
      globalState={environmental}
      data-testid="sustainability-subpage"
    />
  );
}

export default Environmental;
