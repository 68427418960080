import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import commonStyles from "../../components/PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import { getTextFieldStyles } from "../FieldStyles/FieldStyles";
import { darkTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";
export default function TextInputField({
  register,
  label,
  isMobile,
  errors,
  name,
  type,
  width,
  required,
  placeholder,
  width1,
  onChange,
  value,
  disabled = false,
  datatestid = "text-input",
}) {
  const isDarkTheme = useSelector((store) => store.theme);

  const hasError = !!errors[name];

  return (
    <Box sx={{ ...commonStyles.container }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: width1
            ? { xs: "100%", md: width1 }
            : { xs: "100%", md: "400px", lg: "400px" },
        }}
      >
        <Typography
          sx={{
            ...commonStyles.typographyLabel,
            color: hasError
              ? "red"
              : isDarkTheme
              ? darkTheme.textColorLayer1
              : "var(--Tokens-Text-Primary, #3A3A3A)",
          }}
          data-testid="title-label"
        >
          {label}
        </Typography>
        {required && (
          <Typography
            sx={{
              ...commonStyles.typographyRequiredLabel,
              color: hasError
                ? "red"
                : isDarkTheme
                ? darkTheme.textColorLayer1
                : "var(--Tokens-Text-Primary, #3A3A3A)",
            }}
            data-testid="title-label"
          >
            Required *
          </Typography>
        )}
      </Box>
      <TextField
        id="outlined-size-small"
        className="text-field"
        size="small"
        key={`${value}-text-field`}
        type={type}
        value={value}
        autoComplete="off"
        disabled={disabled}
        data-testid={datatestid}
        sx={{
          ...getTextFieldStyles(isMobile, isDarkTheme, darkTheme, width),
          "& .MuiInputBase-input::placeholder": {
            color: "var(--Tokens-Interaction-Disabled, #A0A0A0)",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Small, 14px)",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
            letterSpacing: "0.14px",
            opacity: 1, // Ensure visibility of placeholder
          },
        }}
        {...register(name)}
        placeholder={placeholder ?? ""}
        inputProps={{ maxLength: 40 }}
        onChange={(e) => {
          register?.(name)?.onChange(e); // Preserve react-hook-form functionality
          onChange && onChange(e); // Call custom onChange if provided
        }}
      />
    </Box>
  );
}
