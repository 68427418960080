import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";

import { Typography } from "@mui/material";

export default function DefaultPage({ text }) {
  const isDarkTheme = useSelector((state) => state.theme);

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        padding: "4rem",
      }}
    >
      <Typography
        sx={{
          color: isDarkTheme
            ? "white"
            : "var(--Grayscale-PepsiCo-Black, #27251F)",
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
        }}
      >
        {text}
      </Typography>
    </div>
  );
}
