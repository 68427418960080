import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import fetchAndCacheImage from "./fetchandCatchImages";
import placeholderImage from "../assets/spotlight3.png";
import { setUniqueImages } from "../slices/imageSlice";
import { useDispatch, useSelector } from "react-redux";

const fetchedImagesRef = new Set(); // Prevent duplicate API calls

const LazyImage = ({
  height = "100%",
  width = "100%",
  id,
  borderRadius = 8,
  blocksize = false,
}) => {
  const dispatch = useDispatch();
  const { uniqueImages } = useSelector((store) => store.images);
  const [imageSrc, setImageSrc] = useState(uniqueImages[id] || null);
  const [loaded, setLoaded] = useState(!!imageSrc);

  /** ✅ Effect 1: Update imageSrc immediately if found in Redux */
  useEffect(() => {
    if (uniqueImages[id] && imageSrc !== uniqueImages[id]) {
      setImageSrc(uniqueImages[id]);
      setLoaded(true);
    }
  }, [uniqueImages, id]);

  /** ✅ Effect 2: Fetch image if new ID is received */
  useEffect(() => {
    if (!id || fetchedImagesRef.has(id)) return; // Skip if already fetched

    fetchedImagesRef.add(id); // Mark as fetching

    const fetchImage = async () => {
      try {
        const fetchedUrl = await fetchAndCacheImage(id);

        if (fetchedUrl) {
          dispatch(setUniqueImages({ id, url: fetchedUrl })); // Update Redux
          setImageSrc(fetchedUrl);
        }
      } finally {
        fetchedImagesRef.delete(id); // Allow re-fetching for new IDs
      }
    };

    fetchImage();
  }, [id, dispatch]);

  /** ✅ Effect 3: Set placeholder image if ID is 0 or null */
  useEffect(() => {
    if (!id) {
      setImageSrc(placeholderImage);
      setLoaded(true);
    }
  }, [id]);

  return (
    <div style={{ width, height, overflow: "hidden", position: "relative" }}>
      {!loaded && (
        <Skeleton
          data-testid="skeleton-loader"
          variant="rectangular"
          width="100%"
          height={height}
          style={{ borderRadius: `${borderRadius}px`, position: "absolute" }}
        />
      )}
      <img
        src={imageSrc}
        alt="Thumbnail"
        width="100%"
        style={{
          height: `${height}px`,
          width: `${width}px`,
          opacity: loaded ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
          borderRadius: `${borderRadius}px`,
          maxWidth: "100%",
          maxInlineSize: "100%",
          blockSize: `${blocksize ? "none" : "auto"}`,
          aspectRatio: "16/7",
          objectFit: "cover",
          objectPosition: "center center",
          position: "absolute",
        }}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default LazyImage;
