import React, { useEffect, useState, memo } from "react";
import HomePageCarousal from "./../components/HomePage/HomePageCarousal";
import SpotLight from "../components/Spotlight";
import Popover from "../components/Popover";

import { Box } from "@mui/material";
import { darkTheme, lightTheme } from "../theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import ViewSummary from "../components/ViewSummary/ViewSummary";
import { getAPI } from "../API/ApiService";
import { BASE_URL } from "../constant/apiUrl";
import Loading from "../utils/Loader";
import { setForumData } from "../slices/fritoForumPageSlice";

import { setSpotlight } from "../slices/spotlightSlice";
import useFetchInternal from "../hooks/useFetchInternal";
const Home = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);

  const { spotlight } = useSelector((store) => store.spotlight);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchForumData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/forum/find-all`);
        if (response?.status === 200) {
          const fetch = response?.data.response;

          dispatch(setForumData(fetch));
        }
      } catch (err) {
        console.log(err);
      } finally {
        // setLoading(false);
      }
    };
    fetchForumData();
  }, [dispatch]);

  const { pageData } = useFetchInternal(
    `${BASE_URL}/spotlights`,
    setSpotlight,
    spotlight
  );

  if (loading) return <Loading />;
  return (
    <Box
      data-testid="home"
      sx={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
      }}
    >
      <HomePageCarousal data-testid="home-page-carousel" />
      <Box
        sx={{
          padding: { sm: "40px", xs: "40px 0px 0px" },
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <SpotLight pageData={pageData} data-testid="spotlight" />

        <Popover data-testid="popover" />
      </Box>
      <ViewSummary data-testid="view-summary" />
    </Box>
  );
};

export default memo(Home);
