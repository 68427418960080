import React from "react";
import SustainabilitySubpage from "./SustainabilitySubpage";
import { useSelector } from "react-redux";
import { setLifeMetrice } from "../slices/sustainabilityPageSlice";
function LIFEMetrics() {
  const { lifeMetrice } = useSelector((store) => store.sustainable);
  return (
    <SustainabilitySubpage
      urlName={"LFE%20Metrics"}
      header={"LFE metrics"}
      action={setLifeMetrice}
      globalState={lifeMetrice}
      data-testid="sustainability-subpage"
    />
  );
}

export default LIFEMetrics;
