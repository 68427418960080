import { Box } from "@mui/material";
import ListComponent from "./ListComponent";
import { useSelector } from "react-redux";

const Popover = () => {
  const { forumData } = useSelector((store) => store.fritoForumPage);
  if (!forumData) return null; // Prevents errors
  return (
    <Box
      data-testid="popover-container"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: {
          xs: "16px",
        },
      }}
    >
      <Box
        data-testid="popover-list-container"
        sx={{
          width: {
            xs: "100%",
            md: "100%",
          },
        }}
      >
        <ListComponent
          data-testid="list-component"
          title={"Forums"}
          data={forumData}
        />
      </Box>
    </Box>
  );
};

export default Popover;
