// redux/imageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  images: {},
  uniqueImages: {},
};

const imageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setImage(state, action) {
      const { id, url } = action.payload;
      state.images[id] = url;
    },
    deleteImage(state, action) {
      const { id } = action.payload;
      delete state.images[id]; // Remove the image with the specified ID
    },
    resetImage(state, action) {
      state.images = {};
    },
    setUniqueImages(state, action) {
      const { id, url } = action.payload;
      if (!state.uniqueImages[id]) {
        state.uniqueImages[id] = url; // Only add if not already present
      }
    },
  },
});

export const { setImage, deleteImage, resetImage, setUniqueImages } =
  imageSlice.actions;
export default imageSlice.reducer;
