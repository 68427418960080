import React from "react";
import useThemeRender from "../../theme/useThemeRender";
import { Box, Grid, Typography } from "@mui/material";
import { formatDate } from "../../utils/function";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import LazyImage from "../../utils/LazyImage";
function ForumSubDetailPage({ data }) {
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const insideCardBackgroundColorLayer6 = useThemeRender(
    "insideCardBackgroundColorLayer6"
  );
  const { fritoimages } = useSelector((store) => store.fritoForumPage);

  return (
    <Box
      sx={{
        display: "flex",
        padding: "var(--Spacing-spacing-16, 16px)",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-12, 12px)",
        alignSelf: "stretch",
        background: useThemeRender("backgroundColorLayer1"),
      }}
    >
      <LazyImage width={276} height={103} id={data?.thumbnailFileId} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              View count
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.views}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Likes count
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.likes}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Posted date
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {formatDate(data?.postedDate)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Posted by
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.postedBy}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Language
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.languages?.join(", ")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Sector
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.sectors?.join(", ")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Country
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.countries && data?.countries.join(", ")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Subjects
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-12, 12px)",
                flexWrap: "wrap",
              }}
            >
              {data?.subjects?.map((val) => (
                <Typography
                  key={val}
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                    background: insideCardBackgroundColorLayer6,
                    padding: "2px 4px",
                    borderRadius: "4px",
                    lineBreak: "anywhere",
                  }}
                >
                  {val}
                </Typography>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Keywords
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-12, 12px)",
                flexWrap: "wrap",
              }}
            >
              {data?.keywords?.map((val) => (
                <Typography
                  key={val}
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                    background: insideCardBackgroundColorLayer6,
                    padding: "2px 4px",
                    borderRadius: "4px",
                    lineBreak: "anywhere",
                  }}
                >
                  {val}
                </Typography>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
ForumSubDetailPage.propTypes = {
  data: PropTypes.shape({
    views: PropTypes.number,
    likes: PropTypes.number,
    postedDate: PropTypes.string,
    postedBy: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    sectors: PropTypes.arrayOf(PropTypes.string),
    countries: PropTypes.arrayOf(PropTypes.string),
    subjects: PropTypes.arrayOf(PropTypes.string),
    keywords: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ForumSubDetailPage;
