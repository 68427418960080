import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FormStepper from "./FormStepper";
import NavigationButtons from "./NavigationButtons";
import ExternalStepOneForm from "./ExternalStepOneForm";
import ExternalStepTwoForm from "./ExternalStepTwoForm.jsx";
import SuccessBox from "./SuccessBox";
import axios from "axios";
import { BASE_URL, FILE_SERVICE_BASE_URL } from "../../constant/apiUrl.js";
import Loading from "../../utils/Loader.js";
import { showSuccessToast } from "../../utils/Toast/toast";
import {
  setExternalFileDocument,
  setFileUploaded,
  setInternalFileDocument,
} from "../../slices/adminPageSlice.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ProgressIndicator } from "pepsico-ds";
const steps = ["STEP 1", "STEP 2"];

const ExternalUpload = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState({ open: false, error: false });
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("DOCUMENT");
  const [externalData, setExternalData] = useState({
    contentTypeId: "DOCUMENT",
    contentTitle: "",
    visibilityId: null,
    notes: "",
    attachmentLinkUrl: "",
    attachmentFileId: null,
    thumbnailFileId: 0,
    subjectIds: [0],
    sectorIds: [0],
    countryIds: [0],
    specailProjects: [0],
    languageId: 0,
    isTranslated: false,
    translatedText: "",
  });

  const token = localStorage.getItem("auth_token");
    const {
      visibility,
    } = useSelector((store) => store.internalFilter);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const dispatch = useDispatch();
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const { fileUploadPageName } = useSelector((store) => store.adminpage);
  const handleSaveDraft = async () => {
    setLoading(true);
    const getUpdatedData = (fileType, externalData) => {
      if (externalData?.contentTypeId === "LINK") {
        return { ...externalData, contentTypeId: 2 };
      }

      return {
        ...externalData,
        contentTypeId: fileType === "VIDEO" ? 3 : 1,
      };
    };
    const updatedData = getUpdatedData(fileType, externalData);

    try {
      const response = await axios.post(
        `${BASE_URL}/content/savedraft`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        showSuccessToast("Draft saved successfully!");
        console.log("data passed", externalData);
        console.log("Response:", response.data);
      } else {
        alert("Failed to save draft");
        console.error("Error:", response.data);
      }
    } catch (error) {
      console.error("Error saving draft:", error);
      alert("Failed to save draft");
    } finally {
      setLoading(false);
    }
  };

  const handleDropDown = (label, val) => {
    setExternalData((prev) => {
      return { ...prev, [label]: val };
    });
  };
  useEffect(() => {
    dispatch(setInternalFileDocument(null));
    dispatch(setFileUploaded(false));
    dispatch(setExternalFileDocument(null));
  }, []);

  useEffect(() => {
    const externalVisbility = visibility?.filter(item => item?.name === 'External');
    setExternalData(prevState => {
      return {
        ...prevState,
        visibilityId:externalVisbility?.[0]?.id
      }
    }); 
  }, [visibility])
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ExternalStepOneForm
            externalData={externalData}
            setExternalData={setExternalData}
            setFileType={setFileType}
          />
        );
      case 1:
        return (
          <ExternalStepTwoForm
            externalData={externalData}
            setExternalData={setExternalData}
            handleDropDown={handleDropDown}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      {!success?.open ? (
        <>
          <Box
            sx={{
              width: "90%",
              margin: "auto",
              mt: 8,
              mb: 2,
              color: "var(--color-text-primary)",
            }}
          >
            <Box
              sx={{
                width: "100%",
                backgroundColor: "var(--color-background-layer-01)",
                pb: 2,
                pt: 4,
                borderRadius: "8px",
                boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
              }}
            >
              <ProgressIndicator
                steps={steps.length}
                currentStep={activeStep + 1}
                showStepNumber
              />
            </Box>
            <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
          </Box>

          <NavigationButtons
            onSaveDraft={handleSaveDraft}
            activeStep={activeStep}
            totalSteps={steps.length}
            handleNext={handleNext}
            handleBack={handleBack}
            setSuccess={setSuccess}
            data={externalData}
            setData={setExternalData}
            fileType={fileType}
          />
        </>
      ) : (
        <>
          <SuccessBox setActiveStep={setActiveStep} setSuccess={setSuccess} />
        </>
      )}
      {loading && <Loading />}
    </>
  );
};

export default ExternalUpload;
