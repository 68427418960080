import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  titleTypographyStyles,
  descriptionTypographyStyles,
  labelTypographyStyles,
  valueTypographyStyles,
  flexBoxStyles,
} from "./cardStyle";
import { Button } from "pepsico-ds";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../../theme/Theme";
import LikeButton from "../../LikeButton";
import { patchAPI, postAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { formatDate } from "../../../../utils/function";
export default function MainBodyCard({ card, setFetch, fetch, flagUser }) {
  const [updatedLikes, setUpdatedLikes] = useState(card?.likes || 0);
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleDetails = async (id) => {
    navigate(`/details-request-information/${id}`);
    setTimeout(async () => {
      try {
        await patchAPI(`${BASE_URL}/rfi/increment-views/${id}`);
      } catch (e) {
        console.log(e);
      }
    }, 2000); // 2 seconds delay
  };

  const handleLikesUpdated = (newLikes) => {
    setUpdatedLikes(newLikes);
  };
  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        borderBottom: "1px solid #D7E5F8",
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",
        background: isDarkTheme ? darkTheme.backgroundColorLayer3 : "",
      }}
    >
      <Typography
        sx={{
          ...titleTypographyStyles,
          color: isDarkTheme ? "white" : "#27251F",
        }}
      >
        {card?.title}
      </Typography>
      <Typography
        sx={{
          ...descriptionTypographyStyles,
          color: isDarkTheme ? "white" : "#27251F",
        }}
      >
        {card?.description}
      </Typography>

      <Box
        sx={
          isMobile
            ? { display: "flex", flexDirection: "column" }
            : flexBoxStyles
        }
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              ...labelTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            Requested Date :{" "}
            <span
              style={{
                ...valueTypographyStyles,
                color: isDarkTheme ? "white" : "#27251F",
              }}
            >
              {formatDate(card?.requestedDate)}
            </span>
          </Typography>
        </Box>
        {flagUser?.roleName?.toLowerCase() !== "external" && (
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                ...labelTypographyStyles,
                color: isDarkTheme ? "white" : "#27251F",
              }}
            >
              Requested by:{" "}
              <span
                style={{
                  ...valueTypographyStyles,
                  color: isDarkTheme ? "white" : "#27251F",
                }}
              >
                {card?.displayName}
              </span>
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              ...labelTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            View Counts :{" "}
            <span
              style={{
                ...valueTypographyStyles,
                color: isDarkTheme ? "white" : "#27251F",
              }}
            >
              {" "}
              {card?.views}
            </span>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <LikeButton
            cardId={card?.id}
            card={card}
            Liked={card?.isLiked}
            payloadLike={!card?.isLiked}
            setUpdatedLikes={setUpdatedLikes}
            onLikesUpdated={handleLikesUpdated}
            setLoading={setLoading}
            setFetch={setFetch}
            fetch={fetch}
          />
          <Typography
            sx={{
              ...valueTypographyStyles,
              color: isDarkTheme ? "white" : "#27251F",
            }}
          >
            Likes: {updatedLikes ? updatedLikes : 0}
            {/* Show the updated likes */}
          </Typography>
        </Box>
        <Button
          variant="secondary"
          text="View details"
          size="medium"
          iconTrailing="arrow_forward"
          onClick={() => handleDetails(card?.id)}
        />
      </Box>
    </Box>
  );
}

// PropTypes validation
MainBodyCard.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    requestedDate: PropTypes.string,
    displayName: PropTypes.string,
    views: PropTypes.number,
    likes: PropTypes.number, // Optional likes count
    liked: PropTypes.bool, // Optional like status
    id: PropTypes.string.isRequired, // ID of the card
  }).isRequired, // Ensure card is required and follows the shape
};
