import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BASE_URL, FILE_SERVICE_BASE_URL } from "../../constant/apiUrl.js";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import Loading from "../../utils/Loader.js";
import { postAPI } from "../../API/ApiService.js";
import { toast, ToastContainer } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../../utils/Toast/toast";
import { setDisableNextButton } from "../../slices/adminPageSlice.js";
import { resetImage } from "../../slices/imageSlice.js";
import { useDispatch } from "react-redux";
import { contextType } from "react-quill";
import { current } from "@reduxjs/toolkit";
import { Button } from "pepsico-ds";
const NavigationButtons = ({
  onSaveDraft,
  activeStep,
  totalSteps,
  handleNext,
  handleBack,
  setSuccess,
  data,
  setData,
  fileType,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDarkTheme = useSelector((state) => state.theme);
  const { disableNextButton } = useSelector((store) => store.adminpage);
  const dispatch = useDispatch();
  const handleSendForApproval = async () => {
    setLoading(true);
    console.log("active", activeStep);
    if (data.disclaimerId === "" && activeStep === 3) {
      showErrorToast("Please ensure all mandatory fields are filled out.");
      setLoading(false);
      return;
    }
    if (
      (!data.contentTitle ||
        data.subjectIds.length === 0 ||
        data.sectorIds.length === 0 ||
        data.countryIds.length === 0) &&
      activeStep === 1
    ) {
      showErrorToast("Please ensure all mandatory fields are filled out.");
      setLoading(false);
      return;
    }
    const transformDataDynamic = (obj, fieldsToCheck) => {
      let updatedData =
        fileType === "VIDEO"
          ? { ...obj, contentTypeId: 3 }
          : { ...obj, contentTypeId: 1 };
      console.log("send approval conetnt id", obj?.contentTypeId);
      if (obj?.contentTypeId === "LINK") {
        updatedData = { ...obj, contentTypeId: 2 };
        delete updatedData?.attachmentFileId;
      }
      console.log("update", updatedData);
      fieldsToCheck.forEach((field) => {
        if (
          Array.isArray(updatedData[field]) &&
          updatedData[field].length === 0
        ) {
          updatedData[field] = [0];
        }
      });
      return updatedData;
    };
    const fieldsToCheck = [
      "subjectIds",
      "sectorIds",
      "countryIds",
      "specailProjects",
      "languageId",
    ];
    const updatedData = transformDataDynamic(data, fieldsToCheck);
    const apiPayload = {
      ...updatedData,
      summary: updatedData?.summary?.replace(/(<([^>]+)>)/gi, ""),
      notes: updatedData?.notes?.replace(/(<([^>]+)>)/gi, ""),
      translatedText: updatedData?.translatedText?.replace(/(<([^>]+)>)/gi, ""),
    };
    console.log("apiPayload", apiPayload);
    try {
      const response = await postAPI(
        // `${FILE_SERVICE_BASE_URL}/fritolay/content-management/send-approval`,
        `${BASE_URL}/content/saveapproval`,
        apiPayload
      );
      if (response.status === 200) {
        console.log("data on send approved ", data);
        console.log("Response:", response.data);
        if (activeStep === totalSteps - 1) {
          setSuccess({ open: true, error: false });
        }
      } else {
        console.log("inside else", totalSteps - 1);
        if (activeStep === totalSteps - 1) {
          setSuccess({ open: true, error: true });
          console.log("inside else if", totalSteps - 1);
        }
        showErrorToast("Failed to send approval");
        console.error("Error:", response.data);
      }
    } catch (error) {
      console.log("inside catch", totalSteps - 1);
      if (activeStep === totalSteps - 1) {
        console.log("inside catch if", totalSteps - 1);
        setSuccess({ open: true, error: true });
      }
      console.error("Error saving draft:", error);
      showErrorToast("Failed to save draft");
    } finally {
      setLoading(false);
      setData({
        contentTitle: "",
        // visibility: "EXTERNAL",
        visibilityId: 1,
        // subjects: [
        // ],
        // sectors: [
        // ],
        // countries: [
        // ],
        // contentLevel: "",
        contentLevelId: 1,
        cropTypeId: 0,
        keywords: "",
        notes: "",
        summary: "",
        // disclaimer: "",
        disclaimerId: 0,
        //  languageTranslationEnabled: false,
        // translationLanguage: "",
        // translation: "",
        //attachmentLinkUrl: "",
        thumbnailFileId: 0,
        attachmentFileId: 0,
        // contentType: "",
        contentTypeId: "DOCUMENT",
        subjectIds: [],
        sectorIds: [],
        countryIds: [],
        specailProjects: [],
        languageId: 0,
      });
      dispatch(resetImage());
    }
    // if (activeStep === totalSteps - 1) {
    //   setSuccess(true);
    // }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: isMobile ? "center" : "space-between",
        alignItems: "center",
        mt: 2,
        boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
        py: "16px",
        px: "24px",
        gap: isMobile ? 2 : 0,
      }}
    >
      <Button
        size="medium"
        text="Save draft"
        variant="primary"
        onClick={onSaveDraft}
        disabled={activeStep === 0}
        isDark={isDarkTheme}
        style={isMobile ? { width: "100%", justifyContent: "center" } : {}}
      />

      <Box
        sx={{
          display: "flex",
          gap: 2,
          ...(isMobile
            ? { order: -1, justifyContent: "space-between", width: "100%" }
            : {}),
        }}
      >
        <Button
          iconLeading="arrow_back"
          size="medium"
          text="Previous"
          variant="tertiary"
          disabled={activeStep === 0}
          onClick={handleBack}
          isDark={isDarkTheme}
        />

        <Button
          iconTrailing="arrow_forward"
          size="medium"
          text="Next"
          variant="tertiary"
          disabled={disableNextButton}
          onClick={handleNext}
          isDark={isDarkTheme}
        />
      </Box>

      <Button
        size="medium"
        text="Send for approval"
        variant="primary"
        style={isMobile ? { width: "100%", justifyContent: "center" } : {}}
        onClick={handleSendForApproval}
        disabled={activeStep < totalSteps - 1}
        isDark={isDarkTheme}
      />

      {loading && <Loading />}
    </Box>
  );
};

export default NavigationButtons;
