import React from "react";
import { Button, Font } from "pepsico-ds";
import "./HomePageCarousal.css";
import { Box } from "@mui/material";

const OverlayContent = React.memo(
  ({ data }) => {
    const handleNavigation = (link) => {
      window.open(link, "_blank");
    };

    return (
      <div className="relative text-white rounded w-full md:w-[493px] md:h-[216px] flex flex-col gap-6 items-start justify-start">
        {/* Text centered for small screens, left-aligned for larger screens */}
        <Box>
        <Font headingLevel={3} isDark>
          {data?.headline}
        </Font>
        </Box>
        <Box>
        <Font headingLevel={"medium6"} isDark>
          <span style={{ overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 6,
            lineClamp: 6,
}}>{data?.description}</span>
        </Font>
        </Box>
        <Box sx={{ mt: "auto" }}>
          <Button
            className="px-12"
            size="large"
            text={`${data?.buttonText}`}
            variant="primaryInverse"
            iconTrailing="arrow_forward"
            onClick={() => handleNavigation(data?.descriptionLink)}
          />
        </Box>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if the data prop changes
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default OverlayContent;
