import { Box, Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  setIsOpenListOfvaluePopup,
  setListOfValuePopupMessage,
  setListOfValuePopupStatus,
  setOpenSpecialProjectPopup,
  setSpecialProjectEditData,
} from "../../../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import Notification from "./Notification";
import { BASE_URL } from "../../../../constant/apiUrl";
import axios from "axios";
import useThemeRender from "../../../../theme/useThemeRender";
import { showSuccessToast } from "../../../../utils/Toast/toast";

function ConfirmPopup() {
  const {
    openSpecialProjectPopup,
    popupContent,
    specialProjectCardData,
    specialProjectCardType,
    specialProjectEditId,
    isOpenListOfvaluePopup,
    listOfValuePopupMessage,
    listOfValuePopupStatus,
  } = useSelector((store) => store.adminpage);
  const [statusType, setStatusType] = useState("");
  let [statusMessage, setStatusMessage] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("auth_token");
  const handleRemove = () => {
    const removeUser = async () => {
      try {
        const response = await axios.put(
          `${BASE_URL}/special-projects/removeUser/${specialProjectEditId}/${specialProjectCardData?.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          const result = response?.data?.find(
            (item) => item?.id === specialProjectEditId
          );
          dispatch(setSpecialProjectEditData(result));
          dispatch(setOpenSpecialProjectPopup(false));
          // dispatch(setIsOpenListOfvaluePopup(true));
          // dispatch(
          //   setListOfValuePopupMessage(
          //     "User removed succesfully from special project"
          //   )
          // );
          showSuccessToast("User removed succesfully from special project");
          // dispatch(setListOfValuePopupStatus("success"));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const removeDocument = async () => {
      try {
        const response = await axios.put(
          `${BASE_URL}/special-projects/removeContent/${specialProjectEditId}/${specialProjectCardData?.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          const result = response?.data?.find(
            (item) => item?.id === specialProjectEditId
          );
          dispatch(setSpecialProjectEditData(result));
          dispatch(setOpenSpecialProjectPopup(false));
          showSuccessToast(
            "Document removed successfully from special project"
          );
          // dispatch(setIsOpenListOfvaluePopup(true));
          // dispatch(
          //   setListOfValuePopupMessage(
          //     "Document removed successfully from special project"
          //   )
          // );
          // dispatch(setListOfValuePopupStatus("success"));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (specialProjectCardType === "user") {
      removeUser();
    } else {
      removeDocument();
    }
  };

  console.log(
    specialProjectCardType,
    "specialProjectCardData",
    specialProjectEditId
  );
  return (
    <>
      <Dialog
        open={openSpecialProjectPopup}
        onClose={() => dispatch(setOpenSpecialProjectPopup(false))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            background: useThemeRender("insideCardBackgroundColorLayer1"),
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-40, 40px)",
            flexDirection: "column",
            alignItems: "center",
            gap: "var(--Spacing-spacing-16, 16px)",
            alignSelf: "stretch",
            borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
            background: useThemeRender("insideCardBackgroundColorLayer1"),
            border: `10px solid ${useThemeRender("borderColorLayer3")}`,
            boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          }}
        >
          <Typography
            sx={{
              color: useThemeRender("textColorLayer2"),
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H5, 24px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-H5, 32px)",
            }}
          >
            {popupContent}
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                border:
                  "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
              }}
              className="ButtonStyle"
              onClick={() => dispatch(setOpenSpecialProjectPopup(false))}
            >
              <Typography
                sx={{
                  color: "#005CBC",
                  fontWeight: 700,
                }}
                className="textStyle"
              >
                Cancel
              </Typography>
            </Box>
            <Box
              sx={{
                background: "var(--Tokens-Button-Primary, #005CBC)",
              }}
              className="ButtonStyle"
              onClick={handleRemove}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 700,
                }}
                className="textStyle"
              >
                Remove
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Notification
        open={isOpenListOfvaluePopup}
        setOpen={setIsOpenListOfvaluePopup}
        type={listOfValuePopupStatus}
        message={listOfValuePopupMessage}
      />
    </>
  );
}

export default ConfirmPopup;
