import React, { useState } from "react";
import { Box, Typography, TextField, useMediaQuery } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import TextInputField from "../../../utils/Fields/TextInputField";
import { useForm } from "react-hook-form";
import { Button } from "pepsico-ds";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useNavigate } from "react-router-dom";
import { postAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import Loading from "../../../utils/Loader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast/toast";

const formValidations = (title) => {
  const errors = {};

  if (!title || title.trim() === "") {
    errors.title = "Title cannot be empty.";
  }

  return errors;
};
const validationSchema = yup.object().shape({
  title: yup.string().required("Title  is required"),
  description: yup.string().required("Description is required"),
});
export default function AddRequestInformation() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onFieldChange = (field, value) => {
    setValue(field, value); // Update the field value
    const validationErrors = formValidations(getValues("title"));

    if (validationErrors[field]) {
      setError(field, { type: "manual", message: validationErrors[field] });
    } else {
      clearErrors(field); // Clear the error if validation passes
    }
  };
  const isDarkTheme = useSelector((state) => state.theme);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isLargeScreen = useMediaQuery("(min-width:1400px)");
  const formHeight = isLargeScreen ? "80vh" : "100vh";
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/request-information");
  };

  console.log("form submitted");

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      console.log("onSubmit called", data); // Debugging line
      const response = await postAPI(`${BASE_URL}/rfi/save`, data);
      if (response?.status === 201) {
        showSuccessToast("RFI Created Successfully");
        reset();
      }
    } catch (e) {
      showErrorToast("Failed to Create RFI");
    } finally {
      setLoading(false);
    }
    console.log(data, "-------rfidata-----");
  };

  if (loading) return <Loading />;
  return (
    <form
      data-testid="form"
      style={{
        display: "flex",
        width: "100%",
        marginTop: "16px", // Replace with theme spacing if applicable
        flexDirection: "column",
        alignItems: "center",
        // Replace with theme spacing if needed
        minHeight: formHeight,
        maxHeight: "100vh",
        position: "relative",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingX: isMobile ? "10px" : "53px",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "8px 0px", // Replace with theme spacing if needed
            justifyContent: "center",
            alignItems: "center",
            gap: "3px", // Replace with theme spacing or variable if applicable
            cursor: "pointer",
          }}
          data-testid="Back to list"
          onClick={handleBack}
        >
          <ArrowBackOutlinedIcon
            sx={{ fill: "#005CBC", width: "24px", height: "24px" }}
          />
          <Typography
            sx={{
              color: "#005CBC", // Replace with theme token if applicable
              fontFamily: "Inter", // Ensure Inter font is loaded in your project
              fontSize: "16px", // Body font size
              fontStyle: "normal", // Normal style
              fontWeight: 700, // Bold font weight
              lineHeight: "22px", // Line height
              letterSpacing: "1px", // Letter spacing
            }}
          >
            Back to list
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex", // Flex container
            width: "100%", // Set width
            padding: "16px", // Padding
            flexDirection: "column", // Arrange children in a column
            alignItems: "flex-start", // Align children to the start horizontally
            gap: "24px", // Gap between child elements
            height: "50vh",
            flexGrow: 1,
            borderRadius: "var(--Corner-radius-corner-radius-medium, 16px)", // Custom radius with fallback
            background: isDarkTheme ? darkTheme.backgroundColorLayer2 : "#FFF", // White background
          }}
        >
          <TextInputField
            label="Request title"
            name="title"
            type="text"
            placeholder={"Add title"}
            errors={errors}
            register={register}
            isMobile={isMobile}
            onChange={(e) => onFieldChange("title", e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: errors.description
                  ? "red"
                  : isDarkTheme
                  ? "white"
                  : "var(--Tokens-Text-Primary, #3A3A3A)",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-XSmall, 12px)",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "var(--Line-Height-XSmall, 18px)", // 150%
                letterSpacing: "1px",
              }}
            >
              Description
            </Typography>
            <TextField
              placeholder="Type here"
              multiline
              data-testid="textarea-input"
              minRows={4} // Controls the initial height
              maxRows={isMobile ? 6 : 8}
              fullWidth={isMobile} // Adjust width for mobile view
              style={{
                width: isMobile ? "100%" : "338px",
                backgroundColor: isDarkTheme
                  ? darkTheme.cardBackgroundColorLayer1
                  : lightTheme.cardBackgroundColorLayer1,
                border: "1px solid white",
              }}
              InputProps={{
                style: {
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                },
              }}
              {...register("description")}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 500, // Restrict to 200 characters
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex", // Flex container
          width: "100%", // Set width
          padding: "16px 24px", // Padding using spacing variables
          justifyContent: "space-between", // Distribute children with space between
          alignItems: "center", // Align children vertically at the center
          position: "absolute",
          bottom: 0,
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : "var(--layer-color-layer-01, #FBFCFE)", // Set background color
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
        }}
      >
        <Box
          sx={{
            display: "flex", // Flexbox layout
            width: "100%", // Set the width to 1290px
            justifyContent: "flex-end", // Align items to the end of the container horizontally
            alignItems: "center", // Align items vertically at the center
            gap: "12px", // Set the gap between the children to 292px
            flexShrink: 0, // Prevent the container from shrinking
            flexDirection: isMobile ? "column-reverse" : "",
          }}
        >
          <Button
            text="Cancel"
            variant="secondary"
            size="medium"
            type="reset"
            style={{
              width: isMobile ? "100%" : "",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          />
          <Button
            text="Submit request"
            data-testid="submitButton"
            size="medium"
            type="submit"
            style={{
              width: isMobile ? "100%" : "",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
