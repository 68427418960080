import React from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import Loading from "../utils/Loader";
import { BASE_URL } from "../constant/apiUrl";
import useFetchInternal from "../hooks/useFetchInternal";
import PropTypes from "prop-types";
function SustainabilitySubpage({ urlName, header, action, globalState }) {
  const userId = 1;

  const { loading } = useFetchInternal(
    `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=${urlName}&userId=${userId}`,
    action,
    globalState
  );

  if (loading) return <Loading data-testid="loading-spinner" />;
  return (
    <InternalPageLayout
      pageData={globalState}
      header={header}
      data-testid="internal-page-layout"
    />
  );
}

export default SustainabilitySubpage;

SustainabilitySubpage.propTypes = {
  urlName: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};
