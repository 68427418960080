import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import {
    Box,
    IconButton,
    CircularProgress,
    useMediaQuery,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { STREAMING_URL } from "../../constant/apiUrl";
import fetchAndCacheImage from "../../utils/fetchandCatchImages";

function VideoPlayer({ documentData }) {
    const videoRef = useRef(null);
    const token = localStorage.getItem("auth_token");
    const [poster, setPoster] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasPlayed, setHasPlayed] = useState(false);
    const isMobile = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        const fetchPoster = async () => {
            if (documentData?.thumbnailFileId) {
                const url = await fetchAndCacheImage(documentData?.thumbnailFileId);
                setPoster(url);
            }
        };
        fetchPoster();
    }, [documentData]);

    const playVideo = () => {
        if (!documentData?.fileId) return;

        setLoading(true);
        const streamUrl = `${STREAMING_URL}/fritolay/video/hls/${documentData?.fileId}`;

        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.config.xhrSetup = (xhr, url) => {
                console.log("Requesting:", url);
                xhr.setRequestHeader("Authorization", `Bearer ${token}`);
            };
            hls.attachMedia(videoRef.current);
            hls.loadSource(streamUrl);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                videoRef.current.play()
                    .then(() => setHasPlayed(true))
                    .catch(err => console.error("Playback error:", err));
            });

            hls.on(Hls.Events.ERROR, (_, data) => {
                console.error("HLS error:", data);
            });
        } else {
            videoRef.current.src = streamUrl;
            videoRef.current.play()
                .then(() => setHasPlayed(true))
                .catch(err => console.error("Playback error:", err));
        }

        setLoading(false);
    };

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: isMobile ? "200px" : "667px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: poster ? "black" : "#f0f0f0",
                overflow: "hidden",
            }}
        >
            <video
                ref={videoRef}
                controls
                muted
                playsInline
                style={{
                    width: "100%",
                    height: "100%",
                    display: hasPlayed ? "block" : "none",
                    objectFit: "cover",
                }}
            />

            {!hasPlayed && (
                <>
                    {poster ? (
                        <img
                            src={poster}
                            alt="Video Thumbnail"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    ) : (
                        <Box sx={{ width: "100%", height: "100%", background: "#e0e0e0" }} />
                    )}

                    <IconButton
                        onClick={playVideo}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "rgba(0,0,0,0.5)",
                            color: "white",
                            width: 64,
                            height: 64,
                            borderRadius: "50%",
                            "&:hover": { background: "rgba(0,0,0,0.7)" },
                        }}
                    >
                        {loading ? (
                            <CircularProgress size={40} color="inherit" />
                        ) : (
                            <PlayArrowIcon fontSize="large" />
                        )}
                    </IconButton>
                </>
            )}
        </Box>
    );
}

export default VideoPlayer;
