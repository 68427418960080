const editrequestValidations = (
  firstName,
  email,
  lastName,
  company,
  country
) => {
  const errors = {};

  if (!firstName || firstName.trim() === "") {
    errors.firstName = "FirstName cannot be empty.";
  }

  if (!lastName || lastName.trim() === "") {
    errors.lastName = "LastName  cannot be empty.";
  }
  if (!email || email.trim() === "") {
    errors.email = "Email Address cannot be empty.";
  }
  if (!company || company.trim() === "") {
    errors.company = "Company cannot be empty.";
  }
  if (!country || country.trim() === "") {
    errors.country = "Select Country";
  }
  return errors;
};

export default editrequestValidations;
