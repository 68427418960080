import { Box, Typography } from "@mui/material";
import { Button, Filter, Search } from "pepsico-ds";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomPagination from "../../../PotatoProtection/LargeScreen/Landing/CustomPagination";
import AddSpecialProject from "./AddSpecialProject";
import { useDispatch } from "react-redux";
import { setSpecialProjectDrawer } from "../../../../slices/adminPageSlice";
import useThemeRender from "../../../../theme/useThemeRender";
import { getAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
import SpecialProjectAccordion from "./SpecialProjectAccordion";
import { sortTableData } from "../../../../utils/function";
import Loading from "../../../../utils/Loader";
function SpecialProject() {
  const isDarkTheme = useSelector((state) => state.theme);
  const [expanded, setExpanded] = useState(null);
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const handleToggle = (accordionId) => {
    setExpanded((prevExpanded) =>
      prevExpanded === accordionId ? null : accordionId
    );
  };
  const { favourite } = useSelector((store) => store.internalPage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [Data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [user, setUser] = useState([]);
  const [item, setItem] = useState([]);
  const [originalItem, setOriginalItem] = useState([]);
  const [status, setStatus] = useState([
    { id: 1, name: "Active", value: true },
    { id: 2, name: "Inactive", value: false },
  ]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [search, setSearch] = useState();
  const paginatedData =
    filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ||
    [];
  console.log(filterData, "DataDataData");
  const handleSearchSpecialProject = (value) => {
    setSearch(value);
  };
  const handleSelectedUser = (user) => {
    setSelectedUser(user);
  };
  const handleSelectedItem = (selectItem) => {
    const selectedItem = originalItem?.find(
      (item) => item?.id == selectItem?.id
    );
    setSelectedItem(selectedItem);
  };
  const handleSelectedStatus = (user) => {
    setSelectedStatus(user);
  };
  const [isDescending, setIsDescending] = useState(true);
  const handleShort = () => {
    const sortedData = [...filterData].sort((a, b) => {
      if (isDescending) {
        return b.name.localeCompare(a.name);
      } else {
        return a.name.localeCompare(b.name);
      }
    });
    setFilterData(sortedData);
    setIsDescending(!isDescending);
  };
  function filterContent(
    documents,
    search = "",
    selectedUser = null,
    selectedItem = null,
    selectedStatus = null
  ) {
    const searchText = search?.toLowerCase() || "";
    const matchesFilters = (item) => {
      const matchesSearch = Object.values(item)?.some((value) =>
        String(value)?.toLowerCase()?.includes(searchText)
      );
      const matchesUser =
        !selectedUser ||
        (Array.isArray(item.users) &&
          item?.users?.some((content) =>
            content?.name
              .toLowerCase()
              .includes(selectedUser?.label?.toLowerCase())
          ));

      const matchesItem =
        !selectedItem ||
        (Array.isArray(item.contents) &&
          item?.contents?.some((content) =>
            content?.title
              .toLowerCase()
              .includes(selectedItem?.title?.toLowerCase())
          ));

      const matchesStatus =
        !selectedStatus || item?.status == selectedStatus?.value;
      return matchesSearch && matchesStatus && matchesItem && matchesUser;
    };
    const filteredData = documents?.filter(matchesFilters);
    console.log(filteredData, "filteredDatafilteredData");
    setFilterData(filteredData);
  }
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/admin/users/find-all`);

        console.log(response, "--------responseeUserss-------");

        if (response?.status === 200) {
          const fetch = response?.data?.response;

          const uniqueUsers = [
            ...fetch
              .reduce((acc, item) => {
                const normalizedName = item.name.trim().toLowerCase(); // Trim spaces & convert to lowercase
                if (!acc.has(normalizedName)) {
                  acc.set(normalizedName, item);
                }
                return acc;
              }, new Map())
              .values(),
          ];

          const sortedUser = sortTableData(uniqueUsers, "name");

          console.log(uniqueUsers, "-------uniqueUsers------");
          setUser(sortedUser);
        }
      } catch (err) {
        setError(err.message);
      } finally {
      }
    };
    fetchUserData();
  }, []);
  useEffect(() => {
    filterContent(Data, search, selectedUser, selectedItem, selectedStatus);
  }, [search, selectedUser, selectedItem, selectedStatus]);

  useEffect(() => {
    setLoading(true);
    const fetchSpecialProjectData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/special-projects/listAllProjects`
        );
        if (response?.status === 200) {
          const sortedData = sortTableData(response?.data, "name");
          setData(sortedData);
          setFilterData(sortedData);
          const allContents = response?.data?.flatMap(
            (project) => project?.contents
          );

          const uniqueContents = Array.from(
            new Map(
              allContents?.map((content) => [content?.id, content])
            ).values()
          );
          setOriginalItem(uniqueContents);

          const sortedData1 = sortTableData(uniqueContents, "title");
          setItem(sortedData1);
        }
      } catch (err) {
        setError(err?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSpecialProjectData();
  }, [favourite]);

  return (
    <Box
      sx={{
        display: "flex",
        padding: "24px 16px 0px",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        borderRadius: "16px",
        backgroundColor: useThemeRender("cardBackgroundColorLayer3"),
        boxShadow: "0px 4px 24px 0px rgba(58, 58, 58, 0.08)",
      }}
    >
      {loading && <Loading />}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignSelf="stretch"
        padding="16px 16px 0px"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Typography
          sx={{
            color: useThemeRender("textColorLayer1"),
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H5, 24px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H5, 32px)",
          }}
        >
          Special projects
        </Typography>
        <Box
          sx={{
            mt: {
              xs: "10px",
              sm: "0px",
            },
            display: "flex",
            gap: "10px",
          }}
        >
          <Search onUpdate={handleSearchSpecialProject} isDark={isDarkTheme} />
          <Box
            sx={{
              display: "flex",
              minWidth: "32px",
              height: "32px",
              padding:
                "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-4, 4px)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "var(--Corner-radius-effect-corner-1, 4px)",
              background: "var(--Tokens-Interaction-Active, #005CBC)",
              cursor: "pointer",
            }}
            onClick={handleShort}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M6.66667 12H9.33333V10.6667H6.66667V12ZM2 4V5.33333H14V4H2ZM4 8.66667H12V7.33333H4V8.66667Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignSelf="stretch"
        padding="0px 16px 16px"
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: {
            xs: "start",
            md: "center",
          },
        }}
      >
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          <Filter
            label="User"
            onChange={handleSelectedUser}
            options={[
              ...user?.map((item) => {
                return { id: item?.id, label: item?.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          <Filter
            label="Item"
            onChange={handleSelectedItem}
            options={[
              ...item?.map((item) => {
                return { id: item?.id, label: item?.title };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label="Status"
            onChange={handleSelectedStatus}
            options={[
              ...status?.map((item) => {
                return { id: item?.id, label: item?.name, value: item?.value };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          {/* <Button
            size="medium"
            style={{
              border: " 1px solid #005CBC",
              color: "var(--Tokens-Button-Outlined, #005CBC)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
            }}
            text="Reset"
            variant="secondary"
            //   onClick={handleResetFilters}
            isDark={isDarkTheme}
          ></Button> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: {
              xs: "10px",
              md: "0px",
            },
          }}
          onClick={() => dispatch(setSpecialProjectDrawer(true))}
        >
          <Box
            sx={{
              display: "flex",
              padding:
                "var(--Spacing-spacing-02, 8px) var(--Spacing-spacing-04, 16px)",
              justifyContent: "center",
              alignItems: "center",
              gap: "var(--Spacing-spacing-02, 4px)",
              flex: "1 0 0",
              background: "var(--Tokens-Button-Primary, #005CBC)",
              borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
              fontWeight: 700,
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "var(--Colors-White, #FFF)",
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-Small, 14px)",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "var(--Line-Height-Small, 20px)",
              }}
            >
              Add new special project
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3.33341 5.00002H1.66675V16.6667C1.66675 17.5834 2.41675 18.3334 3.33341 18.3334H15.0001V16.6667H3.33341V5.00002ZM16.6667 1.66669H6.66675C5.75008 1.66669 5.00008 2.41669 5.00008 3.33335V13.3334C5.00008 14.25 5.75008 15 6.66675 15H16.6667C17.5834 15 18.3334 14.25 18.3334 13.3334V3.33335C18.3334 2.41669 17.5834 1.66669 16.6667 1.66669ZM16.6667 13.3334H6.66675V3.33335H16.6667V13.3334ZM10.8334 11.6667H12.5001V9.16669H15.0001V7.50002H12.5001V5.00002H10.8334V7.50002H8.33342V9.16669H10.8334V11.6667Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", borderBottom: "2px solid #D7E5F8" }}>
        {paginatedData.map((item) => (
          <SpecialProjectAccordion
            item={item}
            expanded={expanded}
            handleToggle={handleToggle}
          />
        ))}
      </Box>
      <CustomPagination
        page={page}
        setPage={setPage}
        totalPages={Math.ceil(filterData?.length / rowsPerPage)}
      />
      <AddSpecialProject screen={"add"} />
    </Box>
  );
}

export default SpecialProject;
