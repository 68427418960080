import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sectorFilter: [],
  countryFilter: [],
  varietyFilter: [],
  flashColorFilter: [],
  ipOwnerShipFilter: [],
  regionFilter: [],
  laguageFilter: [],
  subjectFilter: [],
  cropTypeFilter: [],
  visibility: [],
  contentLevel: [],
  specialProjects: [],
  contentType: [],
  periods: [],
  disclaimers: [],
};

const internalFilter = createSlice({
  name: "internalFilter",
  initialState,
  reducers: {
    setSectorFilter: (state, action) => {
      state.sectorFilter = action.payload;
    },
    setCountryFilter: (state, action) => {
      state.countryFilter = action.payload;
    },
    setVarietyFilter: (state, action) => {
      state.varietyFilter = action.payload;
    },
    setFlashColorFilter: (state, action) => {
      state.flashColorFilter = action.payload;
    },
    setIpOwnerShipFilter: (state, action) => {
      state.ipOwnerShipFilter = action.payload;
    },
    setRegionFilter: (state, action) => {
      state.regionFilter = action.payload;
    },
    setLanguageFilter: (state, action) => {
      state.laguageFilter = action.payload;
    },
    setSubjectFilter: (state, action) => {
      state.subjectFilter = action.payload;
    },

    setCropTypeFilter: (state, action) => {
      state.cropTypeFilter = action.payload;
    },
    setContentLevel: (state, action) => {
      state.contentLevel = action.payload;
    },
    setVisibility: (state, action) => {
      state.visibility = action.payload;
    },
    setSpecialProjects: (state, action) => {
      state.specialProjects = action.payload;
    },
    setDisclaimers: (state, action) => {
      state.disclaimers = action.payload;
    },
    setPeriods: (state, action) => {
      state.periods = action.payload;
    },
  },
});
export const {
  setCountryFilter,
  setFlashColorFilter,
  setIpOwnerShipFilter,
  setSectorFilter,
  setVarietyFilter,
  setRegionFilter,
  setLanguageFilter,
  setSubjectFilter,
  setCropTypeFilter,
  setVisibility,
  setContentLevel,
  setSpecialProjects,
  setDisclaimers,
  setPeriods,
  setCropType,
} = internalFilter.actions;
export default internalFilter.reducer;
