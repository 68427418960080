import React, { useEffect } from "react";
import { Button } from "pepsico-ds";
import { Box, useMediaQuery } from "@mui/material";
import DividerComponent from "../CommonComponent/DividerComponent";
import TextInputField from "../../../../utils/Fields/TextInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formValidations from "../ExternalAdminInvitationForm/formValidation";
import * as yup from "yup";
import { BASE_URL } from "../../../../constant/apiUrl";
import {
  showSuccessToast,
  showErrorToast,
} from "../../../../utils/Toast/toast";
import { postAPI } from "../../../../API/ApiService";
import { useDispatch } from "react-redux";
import { setDisplay } from "../../../../slices/adminPageSlice";
import { darkTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._%+-]+@pepsico\.com$/,
      "Email must end with @pepsico.com"
    )
    .required("Email address is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
});

export default function InternalForm() {
  const {
    register,
    formState,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const isDarkTheme = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const { errors } = formState;
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallOrMedium = useMediaQuery("(max-width: 800px)");
  useEffect(() => {
    dispatch(setDisplay(false));
  }, []);
  const onFieldChange = (field, value) => {
    setValue(field, value); // Update the field value
    const validationErrors = formValidations(
      getValues("firstName"),
      getValues("lastName"),
      getValues("email")
    );

    if (validationErrors[field]) {
      setError(field, { type: "manual", message: validationErrors[field] });
    } else {
      clearErrors(field); // Clear the error if validation passes
    }
  };
  const onSubmit = async (data) => {
    console.log(data, "------dataaa-----");

    try {
      const response = await postAPI(
        `${BASE_URL}/invitations/send-internal-user`,
        data
      );
      if (response?.status === 200) {
        if (response?.data?.status === "FAILURE") {
          showErrorToast("Failed to send invitation.");
        } else {
          showSuccessToast("Invitation sent successfully!");
          reset();
        }
      } else if (response?.status === 409) {
        showErrorToast("User Already Exist");
      } else {
        showErrorToast("Failed to send the invitation. Please try again.");
      }
    } catch (error) {
      console.log(error);
      showErrorToast("Failed to send the invitation. Please try again.");
    }
  };
  return (
    <Box
      sx={{
        position: "relative", // Relative positioning for the container
        minHeight: "60vh", // Full viewport height to allow the form to take maximum height
        display: "flex", // Flexbox container
        flexDirection: "column", // Stack items vertically
        flexGrow: 1,
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : "var(--color-interaction-form-background)",
        boxShadow:
          "var(--Elevation-X-Elevation-04, 4px) var(--Elevation-Y-Elevation-04, 0px) var(--Elevation-Blur-Elevation-04, 24px) var(--Elevation-Spread-Elevation-04, -2px) var(--Elevation-Colors-Elevation-04, rgba(0, 92, 188, 0.08))",
        padding: "48px 24px 24px",
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
          height: isMobile ? "100%" : "auto",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-16, 16px)",
            alignSelf: "stretch",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: isMobile ? "100%" : "58px",
              alignItems: isMobile ? "centre" : "flex-start",
              flexDirection: isMobile ? "column" : "",
              width: isMobile ? "100%" : "400px",
              gap: "var(--Spacing-spacing-16, 16px)",
              alignSelf: "stretch",
            }}
          >
            <TextInputField
              label="First name"
              errors={errors}
              register={register}
              name="firstName"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              isMobile={isMobile}
              placeholder="Type here"
              onChange={(e) => onFieldChange("firstName", e.target.value)}
              datatestid="text-input-first-name"
            />
            <TextInputField
              label="Last name"
              errors={errors}
              register={register}
              name="lastName"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              isMobile={isMobile}
              placeholder="Type here"
              onChange={(e) => onFieldChange("lastName", e.target.value)}
              datatestid="text-input-last-name"
            />
            <TextInputField
              label="Email"
              errors={errors}
              register={register}
              name="email"
              width={isSmallOrMedium ? "100%" : "400px"}
              required={true}
              type="email"
              isMobile={isMobile}
              placeholder="Type here"
              onChange={(e) => onFieldChange("email", e.target.value)}
              datatestid="text-input-email-address"
            />
          </Box>
        </Box>
        <div>
          <DividerComponent />
          <Box
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
              alignItems: isMobile ? "" : "flex-end",
              flexDirection: isMobile ? "column-reverse" : "row",
              gap: "24px",
              alignSelf: "stretch",
              paddingTop: "16px",
            }}
          >
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              type="reset"
              style={{
                width: isMobile ? "100%" : "",
                justifyContent: "center",
              }}
              data-testid="cancel-button"
              onClick={() => reset()}
            />
            <Button
              size="large"
              text="Send Invitation"
              variant="primary"
              type="sumbit"
              style={{
                width: isMobile ? "100%" : "",
                justifyContent: "center",
              }}
              data-testid="submit-button"
            />
          </Box>
        </div>
      </form>
    </Box>
  );
}
