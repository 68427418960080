// src/styles.js

export const boxStyles = {
  display: "flex",
  width: "100%",
  paddingTop: "8px",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "8px",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
};

export const chipSection = {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "8px",
  flexWrap: 'wrap',
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
};

export const titleStyles = {
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
};

export const subjectBoxStyles = {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
};

export const subjectChipStyles = {
  display: "flex",
  // height: "24px",
  padding: "8px 4px", // Use specific values instead of variables if they aren't defined
  alignItems: "center",
  gap: "2px",
  borderRadius: "4px",
  backgroundColor: "#B2CAEA", // Tokens-Background-Layer-03 equivalent
};

export const subjectTypographyStyles = {
  color: "#000", // Equivalent to var(--text-color-text-black, #000)
  fontFamily: "Inter, sans-serif", // Ensure 'Inter' is imported or available
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "133%", // Same as 1.33 (relative) or 15.96px (absolute)
  fontFeatureSettings: "'liga' off, 'clig' off",
};
