import React from "react";
import { Box } from "@mui/material";

import GridSection from "./GridSection/GridSection";
import SubjectSection from "./SubjectSection/SubjectSection";
import { useSelector } from "react-redux";
import { darkTheme } from "../../theme/Theme";
export default function AccordionChild({
  accordionContent,
  sectionData,
  component,
}) {
  const isDarkTheme = useSelector((state) => state.theme);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        background: isDarkTheme
          ? darkTheme.insideCardBackgroundColorLayer3
          : "var(--Tokens-Background-Default, #F3F7FD)",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <GridSection accordionContent={accordionContent} />
      {component}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          padding: "16px",
          width: "100%"
        }}
      >
        {sectionData?.map((data, index) => (
          <SubjectSection sectionData={data} key={index} />
        ))}
      </Box>
    </Box>
  );
}
