import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import InternalHeader from "./InternalHeader";
import InternalFilter from "./InternalFilter";
import InternalCommonSection from "./CommonSection/InternalCommonSection";
import { useSelector, useDispatch } from "react-redux";
import ViewSummary from "../ViewSummary/ViewSummary";
import { setInternalPageHeader } from "../../slices/internalPageSlice";

export default function InternalPageLayout({
  pageData,
  header,
  showDropdown = true,
}) {
  const selectedView = useSelector((state) => state.view);
  const [grid, setGrid] = useState(selectedView);
  const [selectedSection, setSelectedSection] = useState(null);
  console.log(pageData, "favouriteData");
  const dispatch = useDispatch();
  useEffect(() => {
    setGrid(selectedView);
    dispatch(setInternalPageHeader(header));
  }, [selectedView]);

  return (
    <Box
      sx={{
        mt: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        mb: "24px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <InternalHeader header={header} />

      <Box sx={{ zIndex: 1000 }}>
        <InternalFilter
          setGrid={setGrid}
          pageData={pageData}
          header={header}
          grid={grid}
          setSelection={setSelectedSection}
          showDropdown={showDropdown}
        />
      </Box>
      {pageData?.internalSubPages?.length > 0 &&
        (pageData?.internalSubPages || [])?.map((section, index) => {
          return (
            <InternalCommonSection
              data={section}
              key={section?.subPageId}
              grid={grid}
              selectedSection={selectedSection}
              setGrid={setGrid}
              subPageId={section?.subPageId}
            />
          );
        })}
      <ViewSummary />
    </Box>
  );
}

InternalPageLayout.propTypes = {
  pageData: PropTypes.shape({
    internalSubPages: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }),
  header: PropTypes.string.isRequired,
};
