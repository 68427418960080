import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Typography,
  Button,
  TextField,
  InputAdornment,
  LinearProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { useDropzone } from "react-dropzone";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";
import uploadIcon from "../../assets/upload.png";
import {FILE_SERVICE_BASE_URL} from "../../constant/apiUrl.js";
import Thumbnail from "../../assets/Thumbnail.png";
import { darkTheme, lightTheme } from "../../theme/Theme";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import useThemeRender from "../../theme/useThemeRender";
import axios from "axios";
import CryptoJS from "crypto-js";
import { postAPI } from "../../API/ApiService.js";
import { showErrorToast } from "../../utils/Toast/toast";
import {
  setDisableNextButton,
  setFileUploaded,
  setFileUploadPageName,
  setInternalFileDocument,
} from "../../slices/adminPageSlice.js";
import { useDispatch } from "react-redux";
import { setImage } from "./../../slices/imageSlice.js"
import { Card, Radio } from "pepsico-ds";


const StepOneForm = ({ internalData, setInternalData, setFileType }) => {
  const [contentType, setContentType] = useState("DOCUMENT");
  // const [document, setDocument] = useState({});
  const [isUploadingDocument, setUploadingDocument] = useState(false);
  // const [thumbnail, setThumbnail] = useState(null);
  const [isUploadingThumbnail, setUploadingThumbnail] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const thumbnailInputRef = useRef();
  const token = localStorage.getItem("auth_token");
  const { fileUploaded, internalFileDocument } = useSelector(
    (store) => store.adminpage
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const thumbnail = useSelector((state) => state.images.images["thumbnail"]);
  const dispatch = useDispatch();
  const handlePreview = () => {
    if (internalFileDocument.file) {
      console.log(internalFileDocument.type);
      try {
        if (internalFileDocument.type === "application/pdf") {
          const fileURL = URL.createObjectURL(internalFileDocument.file);
          window.open(fileURL, "_blank");
        }
      } catch (error) {
        console.error("Error opening PDF:", error);
      }
    }
  };
  useEffect(() => {
    if (contentType === "DOCUMENT") {
      if (
        !internalFileDocument ||
        Object?.keys(internalFileDocument)?.length === 0
      ) {
        dispatch(setFileUploaded(false));
        dispatch(setInternalFileDocument(null));
      } else {
        dispatch(setDisableNextButton(false));
        dispatch(setFileUploaded(true));
        dispatch(setFileUploadPageName("external"));
      }
    } else if (contentType === "LINK") {
      if (internalData?.attachmentLinkUrl && internalData?.attachmentLinkUrl?.trim() !== "") {
        dispatch(setDisableNextButton(false));
        // dispatch(setFileUploaded(true));
        // dispatch(setFileUploadPageName("external"));
      } else {
        // dispatch(setFileUploaded(false));
        // dispatch(setInternalFileDocument(null)); 
        dispatch(setDisableNextButton(true));
      }

    }

    console.log(internalData, "contentType");
  }, [contentType]);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setInternalData((prevData) => ({
      ...prevData,
      attachmentLinkUrl: newLink,
      attachmentFileId: 0,
    }));
    if(newLink){
      dispatch(setDisableNextButton(false));
      // dispatch(setFileUploaded(true));
      // dispatch(setFileUploadPageName("external"));
    }else{
      // dispatch(setFileUploaded(false));
      // dispatch(setInternalFileDocument(null)); 
      dispatch(setDisableNextButton(true));
    }
  };
  const getThemeColor = (lightColor, darkColor) =>
    isDarkTheme ? darkColor : lightColor;

  const handleChange = (val) => {
    setContentType(val === 'LINK' ? 'LINK' : 'DOCUMENT');
    console.log("content type",val)
    setInternalData((prevData) => ({
      ...prevData,
      contentTypeId:val
    }))
  };

  const onDrop = async (acceptedFiles) => {
    const MAX_FILE_SIZE = 250 * 1024 * 1024;
    const file = acceptedFiles[0];

    if (!file) return;
    if (file?.size > MAX_FILE_SIZE) {
      showErrorToast(
        "File size exceeds the 250 MB limit. Please upload a smaller file."
      );
      // alert("File size exceeds the 250 MB limit. Please upload a smaller file.");
      return;
    }
    setUploadingDocument(true);

    try {
      // Step 1: Initiate upload
       const isVideo = file.type.startsWith("video/");
       setFileType(isVideo ? "VIDEO" : "DOCUMENT");
      const chunkSize = 5 * 1024 * 1024; // 5MB
      const initResponse = await postAPI(
        `${FILE_SERVICE_BASE_URL}/fritolay/upload/init`,
        {
          fileName: file?.name,
          fileSize: file?.size,
          chunkSize,
        }
      );
      console.log("upload initiated", initResponse.data);
      const { fileId, chunkCount } = initResponse.data;
      const chunks = Math.ceil(file?.size / chunkSize);
      const chunkUploadPromises = [];

      // Step 2: Upload each chunk
      for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(file?.size, start + chunkSize);
        const chunk = file?.slice(start, end);

        const chunkArrayBuffer = await chunk.arrayBuffer();
        const checksum = CryptoJS.MD5(
          CryptoJS.lib.WordArray.create(chunkArrayBuffer)
        ).toString(CryptoJS.enc.Base64);

        chunkUploadPromises.push(
          axios.put(
            `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/chunk/${i}`,
            chunk,
            {
              headers: {
                fileId,
                chunkIndex: i,
                checksum,
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
        );
      }

      await Promise.all(chunkUploadPromises);

        // Step 3: Verify each chunk
        const computeChunkChecksum = (chunk) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    try {
                        const wordArray = CryptoJS.lib.WordArray.create(reader.result);
                        const checksum = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
                        resolve(checksum);
                    } catch (err) {
                        reject(err);
                    }
                };

                reader.onerror = () => reject(reader.error);
                reader.readAsArrayBuffer(chunk); // Efficient, does not load entire file at once
            });
        };

        const chunkVerificationPromises = [];
        let allChunkChecksums = []; // Array to store all chunk checksums

        for (let i = 0; i < chunks; i++) {
            const chunk = file.slice(i * chunkSize, Math.min(file.size, (i + 1) * chunkSize));

            // Handle chunk checksum and upload
            const checksumPromise = computeChunkChecksum(chunk).then((checksum) => {
                allChunkChecksums.push(checksum); // Store the checksum for later
                return axios.post(
                    `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/chunk/${i}/complete`,
                    { checksum },
                    {
                        headers: {
                            fileId,
                            chunkIndex: i,
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                        },
                    }
                );
            });

            chunkVerificationPromises.push(checksumPromise);

            // Once we have 5 promises, wait for them to settle
            if (chunkVerificationPromises.length >= 5) {
                await Promise.allSettled(chunkVerificationPromises);
                chunkVerificationPromises.length = 0;  // Reset the batch
            }
        }

        // Wait for all remaining promises to settle
        await Promise.allSettled(chunkVerificationPromises);

        // Calculate final checksum by combining all chunk checksums
        const finalChecksum = CryptoJS.MD5(allChunkChecksums.join('')).toString(CryptoJS.enc.Base64);

        // Final completion request with final checksum
        await axios.post(
            `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}/complete`,
            {
                fileName: file.name,
                finalChecksum,
            },
            {
                headers: {
                    fileId,
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            }
        );

        dispatch(
        setInternalFileDocument({
          name: file?.name,
          type: file?.type,
          file: file,
          fileId: fileId,
        })
      );
      setInternalData((prevData) => ({
        ...prevData,
        attachmentFileId: parseInt(fileId),
        attachmentLinkUrl: "",
      }));
      dispatch(setDisableNextButton(false));
      dispatch(setFileUploaded(true));
      dispatch(setFileUploadPageName("external"));
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadingDocument(false);
    }
  };
  useEffect(() => {
    if (fileUploaded) {
      dispatch(setDisableNextButton(false));
    } else {
      dispatch(setDisableNextButton(true));
    }
    console.log(fileUploaded, "fileUploadedfileUploaded");
  }, [fileUploaded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    "application/pdf": [],
    "video/*": [],
  });

  const handleThumbnailUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploadingThumbnail(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${FILE_SERVICE_BASE_URL}/fritolay/content-management/upload?user=ADMIN`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }

      );

      const data = response.data;
      // setThumbnail(URL.createObjectURL(file));
      const fileUrl = URL.createObjectURL(file);
      dispatch(setImage({ id: "thumbnail", url: fileUrl }));
      console.log("Upload successful:", data);
      setInternalData((prevData) => ({
        ...prevData,
        thumbnailFileId: data?.fileId,
      }));
    } catch (error) {
      // Handle error cases
      console.error(
        "Error uploading file:",
        error.response?.data || error.message
      );
    } finally {
      setUploadingThumbnail(false);
    }
  };

  const handleDocumentDelete = async (fileId) => {
    if (!fileId) {
      console.error("File ID is required to delete the file.");
      return;
    }

    try {
      const response = await axios.delete(
        `${FILE_SERVICE_BASE_URL}/fritolay/upload/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      console.log("File deleted successfully:", response?.data);
      dispatch(setFileUploaded(false));
      dispatch(setInternalFileDocument(null));
    } catch (error) {
      console.error("Error deleting the file:", error);
    }
  };

  const renderProgressBar = () => (
    <Box sx={{ width: "100%", mt: 2 }}>
      <LinearProgress />
    </Box>
  );

  // const renderDropzone = (label) => (
  //   <Box>
  //     <FormLabel
  //       sx={{
  //         color: getThemeColor(
  //           lightTheme.textColorLayer1,
  //           darkTheme.textColorLayer1
  //         ),
  //       }}
  //     >
  //       {label}
  //     </FormLabel>
  //     <Box
  //       sx={{
  //         display: "flex",
  //         mt: 2,
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderRadius: "8px",
  //         border: "1px dashed #D7E5F8",
  //         padding: "24px",
  //         width: { xs: "100%", sm: "50%" },
  //         background: getThemeColor(
  //           lightTheme.insideCardBackgroundColorLayer3,
  //           darkTheme.insideCardBackgroundColorLayer3
  //         ),
  //         position: "relative",
  //       }}
  //     >
  //       {isUploadingThumbnail ? (
  //         renderProgressBar()
  //       ) : thumbnail ? (
  //         <>
  //           <img
  //             src={thumbnail}
  //             alt="Uploaded Thumbnail"
  //             style={{
  //               maxWidth: "100%",
  //               borderRadius: "4px",
  //               objectFit: "cover",
  //             }}
  //           />
  //           <Button
  //             variant="contained"
  //             sx={{
  //               position: "absolute",
  //               top: "50%",
  //               left: "45%",
  //               borderRadius: "24px",
  //               fontWeight: 700,
  //             }}
  //             startIcon={<AutorenewOutlinedIcon />}
  //             onClick={() => thumbnailInputRef.current.click()}
  //           >
  //             Replace
  //           </Button>
  //           <input
  //             type="file"
  //             accept="image/*"
  //             ref={thumbnailInputRef}
  //             style={{ display: "none" }}
  //             onChange={handleThumbnailUpload}
  //           />
  //         </>
  //       ) : (
  //         <>
  //           <img src={Thumbnail} alt="Thumbnail Icon" />
  //           <Typography
  //             variant="body1"
  //             sx={{
  //               textAlign: "center",
  //               color: getThemeColor(
  //                 lightTheme.textColorLayer1,
  //                 darkTheme.textColorLayer1
  //               ),
  //             }}
  //           >
  //             Drag and drop your file here <br /> (only .jpg, .png)
  //           </Typography>
  //           <Button
  //             variant="text"
  //             sx={{
  //               mt: 2,
  //               borderRadius: "24px",
  //               border: "2px solid #005CBC",
  //               fontWeight: 700,
  //               py: 1,
  //               px: 2,
  //             }}
  //             startIcon={<UploadOutlinedIcon />}
  //             onClick={() => thumbnailInputRef.current.click()}
  //           >
  //             Upload File
  //           </Button>
  //           <input
  //             type="file"
  //             accept="image/*"
  //             ref={thumbnailInputRef}
  //             style={{ display: "none" }}
  //             onChange={handleThumbnailUpload}
  //           />
  //         </>
  //       )}
  //     </Box>
  //   </Box>
  // );
  const renderDropzone = (label) => {
    
  
    const handleDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = () => {
      setIsDragging(false);
    };
  
    const handleDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);
  
      const file = event.dataTransfer.files[0]; // Get the first dropped file
      if (file) {
        handleThumbnailUpload({ target: { files: [file] } }); // Simulate file input change event
      }
    };
  
    return (
      <Box>
        <FormLabel
          sx={{
            color: getThemeColor(
              lightTheme.textColorLayer1,
              darkTheme.textColorLayer1
            ),
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "18px",
            fontFamily: "inherit"
          }}
        >
          {label}
        </FormLabel>
        <Box
          sx={{
            display: "flex",
            mt: 2,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            border: `1px dashed ${isDragging ? "#005CBC" : "#D7E5F8"}`,
            padding: "24px",
            width: { xs: "100%", sm: "50%" },
            background: getThemeColor(
              lightTheme.insideCardBackgroundColorLayer3,
              darkTheme.insideCardBackgroundColorLayer3
            ),
            position: "relative",
            transition: "border 0.3s ease-in-out",
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {isUploadingThumbnail ? (
            renderProgressBar()
          ) : thumbnail ? (
            <>
              <img
                src={thumbnail}
                alt="Uploaded Thumbnail"
                style={{
                  maxWidth: "100%",
                  borderRadius: "4px",
                  objectFit: "cover",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "45%",
                  borderRadius: "24px",
                  fontWeight: 700,
                }}
                startIcon={<AutorenewOutlinedIcon />}
                onClick={() => thumbnailInputRef.current.click()}
              >
                Replace
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={thumbnailInputRef}
                style={{ display: "none" }}
                onChange={handleThumbnailUpload}
              />
            </>
          ) : (
            <>
              <img src={Thumbnail} alt="Thumbnail Icon" />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  color: getThemeColor(
                    lightTheme.textColorLayer1,
                    darkTheme.textColorLayer1
                  ),
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  fontFamily: "inherit"
                }}
              >
                {isDragging ? "Drop your file here!" : "Drag and drop your file here (only .jpg, .png)"}
              </Typography>
              <Button
                variant="text"
                sx={{
                  mt: 2,
                  borderRadius: "24px",
                  border: "2px solid #005CBC",
                  fontWeight: 700,
                  py: 1,
                  px: 2,
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontFamily: "inherit"
                }}
                startIcon={<UploadOutlinedIcon />}
                onClick={() => thumbnailInputRef.current.click()}
              >
                Upload File
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={thumbnailInputRef}
                style={{ display: "none" }}
                onChange={handleThumbnailUpload}
              />
            </>
          )}
        </Box>
      </Box>
    );
  };
  
  const dropzoneStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px dashed #D7E5F8",
    padding: "24px",
    background: useThemeRender("insideCardBackgroundColorLayer3"),
    width: { xs: "100%", sm: "50%" },
  };


  
  return (
    <Card
      ellipsis={false}
      isDark={isDarkTheme}
      icon=""
      title="Step 1: Upload"
      style={{borderRadius: '8px'}}
    >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "8px"
      }}
    >
       <FormLabel
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontSize: '12px',
          lineHeight: "18px",
          fontFamily: "inherit"
        }}
      >
        Content type
      </FormLabel>
      <Radio
        items={[
          'Upload',
          'Link'
        ]}
        orientation="horizontal"
        reference="radio button"
        showText
        size="small"
        onUpdate={(index, value) => handleChange(value?.toLocaleUpperCase())}
        selectedItemIndex={contentType ? contentType === 'LINK' ? 1 : 0 : 0}
        isDark={isDarkTheme}
        // style={{margin: '8px 0'}}
      />


      {contentType === "LINK" && (
        <>
                      <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        mt: 1,
                        mb: 0.5,
                        fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                        //color: isDarkTheme && darkTheme.textColorLayer1,
                      }}
                    >
                      Link
                    </Typography>
        <TextField
          placeholder="Paste Link"
          sx={{width: isMobile ? "100%" : "50%",
            "& .MuiOutlinedInput-input": {
              padding: "0px 8px",
              lineHeight: "20px",
              fontSize: "14px",
              height: "32px"
            },
          }}
          value={internalData.attachmentLinkUrl}
          onChange={handleLinkChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LinkIcon sx={{fontSize: "16px"}} />
              </InputAdornment>
            )           
          }}
        />
        </>
      )}

      {contentType === "DOCUMENT" && (
        <>
          <Box>
            {!internalFileDocument ? (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ width: { xs: "100%", sm: "50%" } }}
                >
                  <FormLabel
                    sx={{
                      color: getThemeColor(
                        lightTheme.textColorLayer1,
                        darkTheme.textColorLayer1
                      ),
                      fontSize: "12px",
                      lineHeight: "18px",
                      fontFamily: "inherit"
                    }}
                  >
                    Document
                  </FormLabel>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel
                      sx={{
                        color: getThemeColor(
                          lightTheme.textColorLayer4,
                          darkTheme.textColorLayer4
                        ),
                        fontSize: "10px",
                        fontFamily: "inherit"
                      }}
                    >
                      *Required
                    </FormLabel>
                  </Box>
                </Box>
                <Box
                  {...getRootProps()}
                  sx={{ mt: 2, mb: 2, ...dropzoneStyle }}
                >
                  {isUploadingDocument ? (
                    renderProgressBar()
                  ) : (
                    <img src={uploadIcon} alt="Upload Icon" />
                  )}
                  <input {...getInputProps()} />
                  <Typography
                    sx={{
                      color: getThemeColor(
                        lightTheme.textColorLayer1,
                        darkTheme.textColorLayer1
                      ),
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      fontFamily: "inherit"
                    }}
                  >
                    Drag and drop your file here
                  </Typography>
                  <Button
                    variant="text"
                    sx={{
                      mt: 2,
                      borderRadius: "24px",
                      border: "2px solid #005CBC",
                      fontWeight: 700,
                      py: 1,
                      px: 2,
                      fontSize: "12px",
                      lineHeight: "18px",
                      fontFamily: "inherit"
                    }}
                    startIcon={<UploadOutlinedIcon />}
                  >
                    Upload File
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: { xs: "100%", sm: "50%" },
                  height: {sm: "32px"},
                  background: getThemeColor(
                    lightTheme.insideCardBackgroundColorLayer3,
                    darkTheme.insideCardBackgroundColorLayer3
                  ),
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handlePreview}>
                <InsertDriveFileOutlinedIcon fontSize="small" sx={{ marginRight: 1, color: "primary.main" }} />
                <Typography sx={{fontSize: "12px", fontFamily: "inherit", lineHeight: "18px"}}>{internalFileDocument?.name}</Typography>
                </Box>
                <IconButton
                  onClick={() =>
                    handleDocumentDelete(internalFileDocument?.fileId)
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize= 'small' color="primary" />
                </IconButton>
              </Box>
            )}
          </Box>
          {renderDropzone("Thumbnail upload")}
        </>
      )}

      {contentType === "LINK" && renderDropzone("Thumbnail upload")}
    </Box>
    </Card>
  );
};

export default StepOneForm;
