import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import BackToListButton from "./BackToListButton";
import RequestTitle from "./RequestTitle";
import DescriptionCard from "./DescriptionCard";
import { useParams } from "react-router-dom";
import { getAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import Loading from "../../../utils/Loader";
import { showErrorToast } from "../../../utils/Toast/toast";
import SuccessBox from "../../Upload/SuccessBox";

export default function RequestInformationDetails() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(true); // Set default as true
  const [rfiData, setRfiData] = useState(null);
  const [fetch, setFetch] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Ensure loading is set before fetching
      try {
        const response = await getAPI(`${BASE_URL}/rfi/${id}`);
        if (response?.status === 200) {
          setRfiData(response?.data);
          setAuthorized(response?.data?.archived);
        }
      } catch (e) {
        showErrorToast("Failed to load RFI");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, fetch]);

  console.log(rfiData, "----rfiData-------------");

  if (authorized) return <SuccessBox text={true} data-testid="access-denied" />;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginTop: "16px",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      {/* Show Loader Before Rendering Anything Else */}
      {loading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingX: isMobile ? "10px" : "53px",
            gap: "24px",
          }}
        >
          <BackToListButton />
          <RequestTitle rfiData={rfiData} id={id} />
          <DescriptionCard
            rfiData={rfiData}
            id={id}
            setRfiData={setRfiData}
            fetch={fetch}
            setFetch={setFetch}
          />
        </Box>
      )}
    </Box>
  );
}
