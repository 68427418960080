export const styles = {
  dropdownButton: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    borderRadius: "24px",
    cursor: "pointer",
  },
  dropdownLabel: {
    color: "var(--Tokens-Link-Default, #005CBC)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "var(--Line-Height-Small, 20px)",
  },
  dropdownIcon: {
    width: 16,
    height: 16,
    fill: "#005CBC",
  },
  popoverBox: {
    position: "relative",
    width: "120%",
    mt: "22px",
  },
  menuBox: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    // padding: "8px 8px",
    flexShrink: 0,
    borderRadius: "4px",
    border: "1px solid #DFDFDF",
    boxShadow: "0px 4px 24px rgba(58, 58, 58, 0.08)",
  },
  menuItemBox: {
    display: "flex",
    width: "100%", // Ensure the box takes the full width
    height: 32,
    padding: 2,
    alignItems: "center",
    flexShrink: 0,
    alignSelf: "stretch",
    boxShadow: (theme) => `0px -1px 0px 0px ${theme.palette.divider} inset`,
    // Ensure padding and margins don't restrict the shadow width
    boxSizing: "border-box", // Ensures the padding does not affect width
  },
};
