import { Box, Typography, Card, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./VideoScreenStyle.css";
import Loading from "../../utils/Loader";
import useThemeRender from "../../theme/useThemeRender";
import VideoPlayer from "./VideoPlayer";
import { useLocation, useParams } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import { getAPI, postAPI } from "../../API/ApiService";
import DocumentSummary from "../DocumentScreen/DocumentSummary";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToast } from "../../utils/Toast/toast";
import { setFetchFavourtie } from "../../slices/internalPageSlice";
import DocumentDetails from "../DocumentScreen/DocumentDetails";
import { increaseViewCount } from "../../utils/function";

export default function VideoDetails() {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { contentId } = useParams();
  const { selectedGlobalState, selectedAction } = useSelector(
    (store) => store.internalPage
  );
  const [videoDetails, setVideoDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const video = location.state;

  const [fetch, setFetch] = useState(false);
  const userId = 1;

  const dispatch = useDispatch();
  const fetchData = async () => {
    if (contentId !== null) {
      setIsLoading(true);
      try {
        await getAPI(`${BASE_URL}/content/${contentId}/user/${userId}`).then(
          (response) => {
            setVideoDetails({
              ...response?.data,
              thumbnailUrl: video.thumbnailUrl,
            });

            const views = response?.data?.viewCount;
            const likes = response?.data?.likes;
            const data = increaseViewCount(
              selectedGlobalState,
              Number(contentId),
              location.state.content,
              views,
              likes
            );
            dispatch(selectedAction(data));
          }
        );
      } catch (err) {
        //showErrorToast(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [fetch, contentId]);

  console.log(videoDetails, "----locattt-----");

  const handleAddFavourite = async () => {
    setIsLoading(true);
    try {
      const response = await postAPI(
        `${BASE_URL}/favorites/save?contentId=${contentId}&userId=1&favorite=${!videoDetails?.isFavorite}`
      );
      if (response?.status === 200) {
        fetchData();
        dispatch(setFetchFavourtie(true));
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: {
          xs: "0px",
          sm: "16px 53.3px",
        },
        flexDirection: "column",
        gap: "var(--Spacing-spacing-24, 24px)",
        margin: 0,
        backgroundColor: useThemeRender("backgroundColorLayer1"),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", sm: "space-between" },
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: "var(--Spacing-spacing-24, 24px)", sm: "10px" },
          alignSelf: { xs: "stretch", sm: "auto" },
          flexDirection: { xs: "column", sm: "row" },
          padding: { xs: "16px", sm: "8px 0px" },
        }}
      >
        <Typography
          sx={{
            color: useThemeRender("textColorLayer2"),
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H4, 28px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H4, 40px)",
            width: { xs: "100%", sm: "70%" },
          }}
        >
          {location?.state?.title}
        </Typography>
      </Box>
      {isLoading && <Loading />}
      <Box sx={{ paddingX: isMobile ? "31.5px" : "" }}>
        <VideoPlayer documentData={videoDetails} contentId={contentId} />
      </Box>

      <DocumentSummary
        documentData={videoDetails}
        contentId={contentId}
        handleAddFavourite={handleAddFavourite}
        fetch={fetch}
        setFetch={setFetch}
        url={location.pathname}
      />
      <DocumentDetails documentData={videoDetails} content={"video"} />
      <Card
        className="SummaryCard"
        sx={{
          background: useThemeRender("backgroundColorLayer3"),
        }}
      >
        <Typography
          className="SummaryTitle"
          sx={{
            color: useThemeRender("textColorLayer2"),
          }}
        >
          Disclaimer
        </Typography>
        <Box
          className="SummaryContentBox"
          sx={{
            background: useThemeRender("backgroundColorLayer1"),
          }}
        >
          <Typography
            className="SummaryContents"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            {videoDetails?.disclaimer}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
