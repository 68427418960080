import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import RequestInformationHeader from "../components/RFI/RfiHeader/RequestInformationHeader";
import RequestInformationBody from "../components/RFI/MainBody/RequestInformationBody";

export default function RequestForInformation() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      data-testid="rfi-container"
      sx={
        isMobile
          ? { width: "100%", marginBottom: "24px" }
          : { paddingX: "52px", height: "100%", marginBottom: "24px" }
      }
    >
      <RequestInformationHeader data-testid="rfi-header" />
      <RequestInformationBody data-testid="rfi-body" />
    </Box>
  );
}
