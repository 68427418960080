import React, { useState, useEffect } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { commonTextStyle } from "../InternalStyle";
import {
  linkContainer,
  linkTypography,
  buttonStyle,
} from "../Carousal-ExpandedView/carousalStyle";
import image from "../../../assets/spotlight3.png";
import useThemeRender from "../../../theme/useThemeRender";
import { useNavigate, Link } from "react-router-dom";
import { patchAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import {
  setSummaryDetails,
  setViewPageName,
  setViewSummaryDrawer,
} from "../../../slices/viewSummarySlice";
import ListViewCard from "./ListVewiCard";
import { showErrorToast } from "../../../utils/Toast/toast";
import Loading from "../../../utils/Loader";
export default function ListViewDesign({
  data,
  content,
  pageType,
  subPageId,
  viewMode,
  localState,
  setLocalState,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isExpanded, setIsExpanded] = useState(false);
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // Function to toggle expand/collapse state

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  const navigate = useNavigate();
  const addViewCount = async (contentId, goto, card) => {
    setLoading(true);
    try {
      const response = await patchAPI(`${BASE_URL}/content/${contentId}`, {});
      if (response?.status === 200) {
        const data = { ...card, content: content };
        navigate(goto, { state: data });
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleNavigate = (card, pageName) => {
    const contentId = card?.contentId ? card?.contentId : card?.id;
    if (pageName === "Link") {
      addViewCount(contentId, `/link/${contentId}`, card);
    } else if (pageName === "Document") {
      addViewCount(contentId, `/document/${contentId}`, card);
    } else if (pageName === "Video") {
      addViewCount(contentId, `/video-details/${contentId}`, card);
    }
  };

  const handleOpenSummary = (details) => {
    dispatch(setViewSummaryDrawer(true));
    dispatch(setViewPageName(content));
    dispatch(setSummaryDetails(details));
  };

  const visibleData = isExpanded ? data : data?.slice(0, 3);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        // padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            ...commonTextStyle,
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "28px",
            color: useThemeRender("textColorLayer2"),
          }}
        >
          {content}
        </Typography>
        {pageType !== "LibraryDetails" && (
          <Button
            onClick={handleExpandClick}
            sx={{
              display: "flex",
              flexDirection: "row",
              textTransform: "none",
              gap: "2px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
              {isExpanded ? "Collapse" : "Expand all"}
            </Typography>
            {isExpanded ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M3.705 8.07121L6 5.78121L8.295 8.07121L9 7.36621L6 4.36621L3 7.36621L3.705 8.07121Z"
                  fill="#005CBC"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
                  fill="#005CBC"
                />
              </svg>
            )}
          </Button>
        )}
      </Box>

      {(viewMode !== "list" ? visibleData : data)?.map((item) => (
        <ListViewCard
          key={item.id}
          item={item}
          content={content}
          localState={localState}
          setLocalState={setLocalState}
          handleOpenSummary={handleOpenSummary}
          handleNavigate={handleNavigate}
          isMobile={isMobile}
          styles={{
            insideCardBackgroundColorLayer1,
            boxShadowlayerColor1,
            textColorLayer2,
            textColorLayer4,
            linkContainer,
            linkTypography,
            buttonStyle,
          }}
          setLoading={setLoading}
          fallbackImage={image} // Provide a fallback image
        />
      ))}

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#005CBC",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {/* {isExpanded
            ? `${data?.length} of ${data?.length}`
            : `${(viewMode !== "list" ? visibleData : data)?.length} of ${
                data?.length
              }`} */}
        </Typography>
        {pageType === "LibraryDetails" && viewMode !== "list" && (
          <Link to={`/library-details/${subPageId}`}>
            <Typography
              sx={{
                color: "#005CBC",
                textAlign: "right",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              See all {content}{" "}
              <ArrowForwardIcon
                sx={{ fill: "#005CBC", width: "20px", height: "24px" }}
              />
            </Typography>
          </Link>
        )}
      </div>
      {loading && <Loading />}
    </Box>
  );
}
