import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DetailItem from "./DetailItem";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";
const MobilePatatoProtection = ({ rows, searchText }) => {
  const isDarkTheme = useSelector((state) => state.theme);

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-16, 16px)",
        borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
        background: "var(--Tokens-Background-Layer-01, #FBFCFE)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          minHeight: "56px",
          padding: "var(--Spacing-spacing-16, 16px)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-8, 8px)",
          alignSelf: "stretch",
          backgroundColor: isDarkTheme
            ? darkTheme.cardBackgroundColorLayer1
            : "white",
          boxShadow: isDarkTheme
            ? "inset 0px 0px 0px 0px var(--Tokens-Border-Divider, #DFDFDF)"
            : "inset 0px -1px 0px 0px var(--Tokens-Border-Divider, #DFDFDF)",
        }}
      >
        {filteredRows?.map((item) => (
          <React.Fragment key={item.id}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "var(--Spacing-spacing-02, 4px)",
                alignSelf: "stretch",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  color: isDarkTheme
                    ? darkTheme.textColorLayer4
                    : lightTheme.textColorLayer4,
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Body, 16px)",
                  fontWeight: 700,
                  lineHeight: "var(--Line-Height-Body, 22px)", // 137.5%
                  wordBreak: "break-word",
                }}
              >
                {item?.varietyId}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                padding:
                  "var(--Spacing-spacing-12, 12px) var(--Spacing-spacing-8, 8px)",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "var(--Spacing-spacing-4, 4px)",
                alignSelf: "stretch",
                // background: "var(--Tokens-Background-Default, #F3F7FD)",
                background: isDarkTheme
                  ? darkTheme.insideCardBackgroundColorLayer2
                  : "var(--Tokens-Background-Default, #F3F7FD)",
              }}
            >
              <DetailItem label="Variety:" value={item?.varietyId} />
              <DetailItem
                label="Date of first world sale :"
                value={item?.firstSaleDate}
              />
              <DetailItem
                label="Country of first world sale :"
                value={item.firstSaleCountry}
              />
              <DetailItem label="Comments:" value={item.comments} />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignSelf: "stretch",
                justifyContent: "flex-end",
              }}
            >
              <Link
                to={`/potato-protection-status/${item.id}`}
                className="flex flex-row gap-2 justify-center items-center"
              >
                <Typography
                  sx={{
                    color: "var(--Tokens-Link-Default, #005CBC)",
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    fontSize: "var(--Font-Size-Small, 14px)",
                    fontWeight: 400,
                    lineHeight: "var(--Line-Height-Small, 20px)",
                  }}
                >
                  View details
                </Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M8.00033 3.03357L7.06033 3.97357L10.7803 7.70024H2.66699V9.03357H10.7803L7.06033 12.7602L8.00033 13.7002L13.3337 8.3669L8.00033 3.03357Z"
                    fill="#005CBC"
                  />
                </svg>
              </Link>
            </Box>
            <Box
              sx={{
                border: isDarkTheme ? "1px solid white" : "none",
                height: "1px",
                width: "100%",
              }}
            ></Box>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default MobilePatatoProtection;
