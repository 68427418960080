import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Switch,
  Button,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomMultiSelect from "./CustomMultiSelect";
import { setView } from "./../../slices/viewSlice";
import { setPreferenceTheme, toggleDarkTheme } from "../../slices/themeSlice";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { getAPI, postAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import useThemeRender from "../../theme/useThemeRender";
import useLookupData from "../../hooks/useLookupData";
import PropTypes from "prop-types";
import {
  setSubjectFilter,
  setVarietyFilter,
} from "../../slices/internalFilterSlice";
import ArrowDownwardIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";

const CustomeSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#005CBC",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const PreferencesDrawer = ({ open, onClose, setDrawerOpen }) => {
  const [language, setLanguage] = useState();
  const [region, setRegion] = useState([]);
  const [country, setCountry] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [fleshColor, setFleshColor] = useState([]);
  const [tempLanguage, setTempLanguage] = useState([]);
  const [tempRegion, setTempRegion] = useState([]);
  const [tempCountry, setTempCountry] = useState([]);
  const [tempSubjects, setTempSubjects] = useState([]);
  const [tempVarieties, setTempVarieties] = useState([]);
  const [tempFleshColor, setTempFleshColor] = useState([]);
  const [drawerHeight, setDrawerHeight] = useState("90%");
  const [prevLanguage, setPrevLanguage] = useState();
  const [prevRegion, setPrevRegion] = useState([]);
  const [prevCountry, setPrevCountry] = useState([]);
  const [prevSubjects, setPrevSubjects] = useState([]);
  const [prevVarieties, setPrevVarieties] = useState([]);
  const [prevFleshColor, setPrevFleshColor] = useState([]);

  const [firstTimeOpen, setFirstTimeOpen] = useState(true);
  const {
    countryFilter,
    laguageFilter,
    regionFilter,
    varietyFilter,
    flashColorFilter,
    subjectFilter,
  } = useSelector((store) => store.internalFilter);

  const dispatch = useDispatch();
  const selectedView = useSelector((state) => state.view);
  const handleViewChange = (event) => {
    dispatch(setView(event.target.value));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useLookupData("FLESH_COLOR");
  useLookupData("SECTOR");
  useLookupData("COUNTRY");
  useLookupData("IPOWNERSHIP");
  useLookupData("REGION");
  useLookupData("LANGUAGE");
  useLookupData("SPECIAL_PROJECTS");
  useLookupData("CONTENT_LEVEL");
  useLookupData("VISIBILITY");
  useLookupData("CROP_TYPE");
  useLookupData("DISCLAIMER");
  useLookupData("PERIOD");
  useLookupData("VARIETY");

  useEffect(() => {
    const fetchSubjects = async () => {
      const response = await getAPI(`${BASE_URL}/administrative-subject/list`);
      if (response?.status === 200) {
        const fetchData = response?.data;

        dispatch(setSubjectFilter(fetchData));
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const fetchVariety = async () => {
      try {
        if (!varietyFilter || varietyFilter.length === 0) {
          const data = await getAPI(`${BASE_URL}/variety`);
          if (data?.status === 200) {
            const response = data?.data;

            const newData = response?.map((item) => ({
              id: item.id,
              name: item?.variety,
              label: item?.variety,
            }));

            dispatch(setVarietyFilter(newData));
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchVariety();
  }, [varietyFilter, dispatch]); // Add dependencies

  useEffect(() => {
    if (laguageFilter?.length > 0) {
      const fetchUserPreferences = async () => {
        try {
          const response = await getAPI(
            `${BASE_URL}/preferences/find-by-user-id`
          );

          if (response?.status === 200) {
            const preferences = response?.data?.response;
            if (preferences?.appPreferencesDTO?.theme === "DARK") {
              dispatch(setPreferenceTheme(true));
            }
            if (preferences?.appPreferencesDTO?.theme === "LIGHT") {
              dispatch(setPreferenceTheme(false));
            }
            if (preferences?.appPreferencesDTO?.view === "TILE") {
              dispatch(setView("tile"));
            }
            if (preferences?.appPreferencesDTO?.view === "LIST") {
              dispatch(setView("list"));
            }
            const selectedLanguage = laguageFilter?.find(
              (item) =>
                item?.id ===
                preferences?.appPreferencesDTO?.USER_PREFERENCES_LANGUAGE[0]?.id
            );
            const language =
              preferences?.appPreferencesDTO?.USER_PREFERENCES_LANGUAGE;
            const region =
              preferences?.userPreferencesDTOS?.USER_PREFERENCES_REGION;
            const country =
              preferences?.userPreferencesDTOS?.USER_PREFERENCES_COUNTRY;
            const subject =
              preferences?.userPreferencesDTOS?.USER_PREFERENCES_SUBJECT;
            const variety =
              preferences?.userPreferencesDTOS?.USER_PREFERENCES_VARIETY;
            const fleshColor =
              preferences?.userPreferencesDTOS?.USER_PREFERENCES_FLESH_COLOR;
            setTempLanguage(selectedLanguage);
            setPrevLanguage(language || []);
            setTempRegion(region || []);
            setPrevRegion(region || []);
            setTempCountry(country || []);
            setPrevCountry(country || []);
            setTempSubjects(subject || []);
            setPrevSubjects(subject || []);
            setTempVarieties(variety || []);
            setPrevVarieties(variety || []);
            setTempFleshColor(fleshColor || []);
            setPrevFleshColor(fleshColor || []);

            if (
              (language?.length === 0 ||
                region?.length === 0 ||
                country?.length === 0 ||
                subject?.length === 0 ||
                variety?.length === 0 ||
                fleshColor?.length === 0) &&
              firstTimeOpen
            ) {
              setDrawerOpen(true);
              setFirstTimeOpen(false);
            }
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchUserPreferences();
    }
  }, [open, laguageFilter, dispatch, firstTimeOpen, setDrawerOpen]);
  const handleLanguageChange = (event) => {
    const uniqueToSecondArray = prevLanguage
      ?.filter(
        (secondItem) =>
          ![event.target.value]?.some(
            (firstItem) => firstItem.id === secondItem.id
          )
      )
      ?.map((item) => ({ ...item, mode: "PURGE" }));
    const newArray = [
      {
        id: event.target.value.id,
        name: event.target.value.name,
        mode: "PERSIST",
      },
      ...(Array.isArray(uniqueToSecondArray) ? uniqueToSecondArray : []),
    ];

    setTempLanguage(event.target.value);
    setPrevLanguage(newArray);
  };
  const isDarkTheme = useSelector((state) => state.theme);

  const handleSave = async () => {
    setLanguage(tempLanguage);
    setRegion(tempRegion);
    setCountry(tempCountry);
    setSubjects(tempSubjects);
    setVarieties(tempVarieties);
    setFleshColor(tempFleshColor);
    const payload = {
      appPreferencesDTO: {
        theme: isDarkTheme ? "DARK" : "LIGHT",
        view: selectedView === "tile" ? "TILE" : "LIST",
        USER_PREFERENCES_LANGUAGE: prevLanguage,
      },
      userPreferencesDTOS: {
        USER_PREFERENCES_REGION: prevRegion,
        USER_PREFERENCES_COUNTRY: prevCountry,
        USER_PREFERENCES_SUBJECT: prevSubjects,
        USER_PREFERENCES_VARIETY: prevVarieties,
        USER_PREFERENCES_FLESH_COLOR: prevFleshColor,
      },
    };

    try {
      const response = await postAPI(`${BASE_URL}/preferences/save`, payload);
      if (response?.status === 200) {
        onClose();
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleCancel = () => {
    setTempLanguage(language);
    setTempRegion(region);
    setTempCountry(country);
    setTempSubjects(subjects);
    setTempVarieties(varieties);
    setTempFleshColor(fleshColor);
    onClose();
  };

  const handleResizeStart = (e) => {
    e.preventDefault();

    const handleResize = (moveEvent) => {
      const currentY = moveEvent.clientY || moveEvent.touches[0].clientY;
      const newHeight = `calc(100% - ${currentY}px)`;
      setDrawerHeight(newHeight);
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", stopResize);
      window.removeEventListener("touchmove", handleResize);
      window.removeEventListener("touchend", stopResize);
    };

    window.addEventListener("mousemove", handleResize);
    window.addEventListener("mouseup", stopResize);
    window.addEventListener("touchmove", handleResize);
    window.addEventListener("touchend", stopResize);
  };

  const handleToggle = () => {
    dispatch(toggleDarkTheme());
  };

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: isMobile ? drawerHeight : "100%",
          overflow: "auto",
          background: useThemeRender("backgroundColorLayer3"),
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1"),
          },
        },
      }}
    >
      {isMobile && (
        <Box
          sx={{
            width: "36px",
            height: "5px",
            background:
              "linear-gradient(0deg, rgba(61, 61, 61, 0.5) 0%, rgba(61, 61, 61, 0.5) 100%), rgba(127, 127, 127, 0.4)",
            backgroundBlendMode: "overlay, luminosity",
            borderRadius: "2.5px",
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "ns-resize",
            zIndex: 1,
          }}
          onMouseDown={handleResizeStart}
          onTouchStart={handleResizeStart}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px",
          fontWeight: 700,
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: isDarkTheme && darkTheme.textColorLayer1,
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H6, 18px)",
          }}
        >
          Preferences
        </Typography>
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon
            style={{
              color: useThemeRender("iconColor1"),
            }}
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          width: isMobile ? "90%" : 350,
          padding: "34px 20px",
          backgroundColor: useThemeRender("backgroundColorLayer1"),
          mb: 2,
          mx: isMobile ? 2 : "24px",
        }}
        role="image"
      >
        <Typography
          sx={{
            fontWeight: 700,
            marginBottom: 2,
            color: isDarkTheme && darkTheme.textColorLayer1,
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H6, 18px)",
            lineHeight: "var(--Line-Height-H6, 28px)",
          }}
        >
          Application
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginBottom: 1,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "12px",
            }}
          >
            Dark Theme
          </Typography>
          {/* <Switch checked={isDarkTheme} onChange={handleToggle} /> */}
          <CustomeSwitch
            sx={{ marginLeft: "8px" }}
            checked={isDarkTheme}
            onChange={handleToggle}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: isDarkTheme && darkTheme.textColorLayer1,
            // marginBottom: "8px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
              }}
            >
              Default View
            </Typography>
            <RadioGroup row value={selectedView} onChange={handleViewChange}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // marginBottom: 2,
                }}
              >
                <FormControlLabel
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                  }}
                  value="tile"
                  control={<Radio />}
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                      }}
                    >
                      Tile
                    </Typography>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // marginBottom: 2,
                }}
              >
                <FormControlLabel
                  value="list"
                  control={<Radio />}
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                      }}
                    >
                      List
                    </Typography>
                  }
                />
              </Box>
            </RadioGroup>
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: 1,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              marginBottom: 1,
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            Language
          </Typography>
          <FormControl fullWidth>
            <Select
              IconComponent={(props) => <ArrowDownwardIcon {...props} />}
              labelId="language-select-label"
              id="language-select"
              value={tempLanguage}
              onChange={handleLanguageChange}
              sx={{
                backgroundColor: useThemeRender("cardBackgroundColorLayer1"),
                height: "32px",
                color: useThemeRender("textColorLayer1"),
                "& .MuiSelect-select": {
                  padding: "4px 8px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: useThemeRender("borderColorLayer1"),
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: useThemeRender("borderColorLayer1"),
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: useThemeRender("borderColorLayer1"),
                },
                "& .MuiSelect-icon": {
                  color: useThemeRender("iconColor1"),
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor:
                      isDarkTheme && darkTheme.backgroundColorLayer3,
                  },
                },
              }}
            >
              {laguageFilter?.map((item) => (
                <MenuItem
                  value={item}
                  key={item.id}
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.textColorLayer1,
                  }}
                >
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <CustomMultiSelect
          label="Region"
          value={tempRegion}
          onChange={setTempRegion}
          options={regionFilter}
          prevSelectedValues={prevRegion}
          setPrevSelectedValues={setPrevRegion}
        />

        <CustomMultiSelect
          label="Country"
          value={tempCountry}
          onChange={setTempCountry}
          options={countryFilter}
          prevSelectedValues={prevCountry}
          setPrevSelectedValues={setPrevCountry}
        />

        <Typography
          sx={{
            fontWeight: 700,
            marginBottom: 2,
            marginTop: 4,
            color: isDarkTheme && darkTheme.textColorLayer1,
            fontFamily: "Inter",
            fontSize: "18px",
          }}
        >
          Content
        </Typography>

        <CustomMultiSelect
          label="Subjects"
          value={tempSubjects}
          onChange={setTempSubjects}
          options={subjectFilter}
          prevSelectedValues={prevSubjects}
          setPrevSelectedValues={setPrevSubjects}
        />

        <CustomMultiSelect
          label="Varieties"
          value={tempVarieties}
          onChange={setTempVarieties}
          options={varietyFilter}
          prevSelectedValues={prevVarieties}
          setPrevSelectedValues={setPrevVarieties}
        />

        <CustomMultiSelect
          label="Flesh Color"
          value={tempFleshColor}
          onChange={setTempFleshColor}
          options={flashColorFilter}
          prevSelectedValues={prevFleshColor}
          setPrevSelectedValues={setPrevFleshColor}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "64px",
          padding: "16px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
          alignSelf: "stretch",
          background: "#FBFCFE",
          boxShadow: "0px 1px 0px 0px #DFDFDF inset",
          backgroundColor: useThemeRender("backgroundColorLayer3"),
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: "1 0 0",
            gap: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              color: "#005CBC",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
              textTransform: "none",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

PreferencesDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setDrawerOpen: PropTypes.func,
};

export default React.memo(PreferencesDrawer);
