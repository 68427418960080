export const LIBRARY_CONSTANT = {
    "all": "all",
    "document":"document",
    "videos":"video",
    "links":"link",
}

export const HEADER_CONSTANT = {
    special_project :  "Special Project"
}

export const LOOKUP_API_FIELDS = {
    "BUSINESS_UNIT": "BUSINESS_UNIT",
    "COMPANY": "COMPANY",
    "CONTENT_LEVEL": "CONTENT_LEVEL",
    "CROP_TYPE": "CROP_TYPE",
    "COUNTRY": "COUNTRY",
    "DEPARTMENT": "DEPARTMENT",
    "DISCLAIMER": "DISCLAIMER",
    "FLESH_COLOR": "FLESH_COLOR",
    "IPOWNERSHIP": "IPOWNERSHIP",
    "LANGUAGE": "LANGUAGE",
    "ORGANIZATION": "ORGANIZATION",
    "PARTNER_TYPE": "PARTNER_TYPE",
    "PERIOD": "PERIOD",
    "REASON_FOR_ACCESS": "REASON_FOR_ACCESS",
    "REGION": "REGION",
    "SECTOR": "SECTOR",
    "SPECIAL_PROJECTS": "SPECIAL_PROJECTS",
    "SUBJECT": "SUBJECT",
    "VARIETY": "VARIETY",
    "VISIBILITY": "VISIBILITY",
}

export const CURATOR_PAGE_API_CONSTANT = {
    "pending_approval": "pending_approval",
    "yearly_review": "yearly_review",
    "artificat_history": "artificat_history",
    "denied_history": "denied_history",
}

export const CURATOR_LEGAL_APPROVAL_OPTIONS = [
    {id: 1, label: 'Not applicable', name: 'Not applicable'},
    {id: 2, label: 'Yes', name: 'Yes'},
    {id: 3, label: 'No', name: 'No'}
]

export const CURATOR_SUMMARY_LANGUAGE_OPTIONS = [
    {id: 1, label: 'Yes', name: 'Yes'},
    {id: 2, label: 'No', name: 'No'}
]