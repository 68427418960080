import {
  Box,
  useMediaQuery,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextInputField from "../../../utils/Fields/TextInputField";
import CustomCuratorRadioGroup from "./CustomCuratorRadioGroup";
import CustomSelect from "../../Upload/CustomSelect";
import { options } from "../PendingApproval/ModifyPendingApproval";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import {
  getDropDownOptionValues,
  getCountryList,
} from "../../../utils/function";
import useThemeRender from "../../../theme/useThemeRender";

const ContentDetails = ({
  errors,
  register,
  setContentDetailsSectionData,
  contentDetailsSectionData,
  lookUpOptions,
  countryData,
  secotrByCountrylist,
  setCountryData,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const [selectedSectorData, setSelectedSectorData] = useState([]);
  const [selectedCountryData, setSelectedCountryData] = useState([]);
  const [selectedSubjectData, setSelectedSubjectData] = useState([]);
  const [selectedSpecialProjectData, setSelectedSpecialProjectData] = useState(
    []
  );
  const [selectedVarities, setSelectedVarities] = useState([]);
  const [selecteSubjectMatterExpert, setSelecteSubjectMatterExpert] = useState(
    []
  );

  useEffect(() => {
    const selectedData = lookUpOptions?.sectors?.filter((item) =>
      contentDetailsSectionData?.sectors?.includes(item?.id)
    );
    setSelectedSectorData(selectedData);
  }, [contentDetailsSectionData?.sectors, lookUpOptions?.sectors]);
  useEffect(() => {
    const selectedData = lookUpOptions?.specialProjects?.filter((item) =>
      contentDetailsSectionData?.specialProjects?.includes(item?.id)
    );
    setSelectedSpecialProjectData(selectedData);
  }, [
    contentDetailsSectionData?.specialProjects,
    lookUpOptions?.specialProjects,
  ]);
  useEffect(() => {
    const selectedData = lookUpOptions?.subjects?.filter((item) =>
      contentDetailsSectionData?.subjects?.includes(item?.id)
    );
    setSelectedSubjectData(selectedData);
  }, [contentDetailsSectionData?.subjects, lookUpOptions?.subjects]);
  useEffect(() => {
    const selectedVarieties = lookUpOptions?.varieties?.filter((item) =>
      contentDetailsSectionData?.varieties?.includes(item?.id)
    );
    setSelectedVarities(selectedVarieties);
  }, [contentDetailsSectionData?.varieties, lookUpOptions?.varieties]);
  useEffect(() => {
    const selectedSME = lookUpOptions?.subjectMatterExpert?.filter((item) =>
      contentDetailsSectionData?.subjectMatterExpert?.includes(item?.id)
    );
    setSelecteSubjectMatterExpert(selectedSME);
  }, [
    contentDetailsSectionData?.subjectMatterExpert,
    lookUpOptions?.subjectMatterExpert,
  ]);
  useEffect(() => {
    const selectedData = countryData?.filter((item) =>
      contentDetailsSectionData?.countries?.includes(item?.id)
    );
    setSelectedCountryData(selectedData);
  }, [
    contentDetailsSectionData?.countries,
    countryData,
    setCountryData,
    setSelectedSubjectData,
  ]);
  const handleSelect = (name, e) => {
    if (name === "sectors") {
      const ids = e?.map((item) => item.id);
      const countryList = getCountryList(ids, secotrByCountrylist);
      setCountryData(getDropDownOptionValues(countryList, true));
      setContentDetailsSectionData((stateData) => {
        return {
          ...stateData,
          [name]: [...ids],
        };
      });
    } else {
      const ids = e?.map((item) => item.id);
      setContentDetailsSectionData((stateData) => {
        return {
          ...stateData,
          [name]: [...ids],
        };
      });
    }
  };

  const setSpecialProjectDisabled = () => {
    let isDisabled = false;
    if (
      lookUpOptions?.visibility !== null &&
      lookUpOptions?.visibility?.length > 0
    ) {
      const selectedOptions = lookUpOptions?.visibility?.filter((item) => {
        return item?.id === contentDetailsSectionData?.visibility;
      });
      if (
        selectedOptions?.[0]?.name === "External" ||
        selectedOptions?.[0]?.name === "Internal"
      ) {
        isDisabled = true;
      }
    }

    return isDisabled;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "16px",
        background: useThemeRender("cardBackgroundColorLayer2"),
      }}
    >
      <Box
        className="content-details"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <TextInputField
          label="Content title"
          errors={errors}
          register={register}
          name="contentTitle"
          disabled={true}
          value={contentDetailsSectionData?.contentTitle}
          placeholder={"Add title"}
          onChange={(event) => {
            setTimeout(() => {
              setContentDetailsSectionData((stateData) => {
                return {
                  ...stateData,
                  contentTitle: event.target.value,
                };
              });
            }, 1400);
          }}
        />

        <CustomCuratorRadioGroup
          label={"Visibility"}
          name="visibilitys"
          value={contentDetailsSectionData?.visibility}
          onChange={(event) => {
            setContentDetailsSectionData((stateData) => {
              return {
                ...stateData,
                visibility: parseInt(event.target.value),
              };
            });
          }}
          radioGroup={lookUpOptions?.visibility}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: "32px",
            "& .MuiTypography-root": {
              fontSize: "12px",
            },

            "& .MuiBox-root": {
              marginBottom: 0,
            },
          }}
        >
          <CustomSelect
            label="Special Projects"
            value={selectedSpecialProjectData}
            // onChange={(e) => {
            //   setContentDetailsSectionData((stateData) => {
            //     return {
            //       ...stateData,
            //       specialProjects: [...e],
            //     };
            //   });
            // }}
            disabled={setSpecialProjectDisabled()}
            onChange={(e) => handleSelect("specialProjects", e)}
            options={lookUpOptions?.specialProjects}
          />
          <CustomSelect
            label="Subjects"
            value={selectedSubjectData}
            // onChange={(e) => {
            //   setContentDetailsSectionData((stateData) => {
            //     return {
            //       ...stateData,
            //       subjects: [...e],
            //     };
            //   });
            // }}
            onChange={(e) => handleSelect("subjects", e)}
            options={lookUpOptions?.subjects}
          />
          <CustomSelect
            label="Sectors"
            value={selectedSectorData}
            // onChange={(e) => {
            //   setContentDetailsSectionData((stateData) => {
            //     return {
            //       ...stateData,
            //       sectors: [...e],
            //     };
            //   });
            // }}
            onChange={(e) => handleSelect("sectors", e)}
            options={lookUpOptions?.sectors}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile ? "column" : "row",
            gap: "32px",
            "& .MuiTypography-root": {
              fontSize: "12px",
            },

            "& .MuiBox-root": {
              marginBottom: 0,
            },
          }}
        >
          <CustomSelect
            label="Countries"
            value={selectedCountryData}
            // onChange={(e) => {
            //   setContentDetailsSectionData((stateData) => {
            //     return {
            //       ...stateData,
            //       countries: [...e],
            //     };
            //   });
            // }}
            // disabled={selectedSubjectData?.length === 0 ? true : false}
            onChange={(e) => handleSelect("countries", e)}
            options={countryData}
          />

          <Box
            sx={{
              width: { xs: "300px", md: "400px" },
              marginBottom: 2,
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            <Typography
              variant="subtitle1"
              component="label"
              sx={{ marginBottom: 0.5 }}
            >
              Language
            </Typography>
            <Select
              value={contentDetailsSectionData?.language || ""}
              onChange={(e) => {
                const selectedLanguage = e.target.value;
                setContentDetailsSectionData((stateData) => ({
                  ...stateData,
                  language: selectedLanguage,
                }));
              }}
              sx={{
                width: "100%",
                maxWidth: "100%",
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 14px",
                  backgroundColor: isDarkTheme
                    ? darkTheme.cardBackgroundColorLayer1
                    : lightTheme.cardBackgroundColorLayer1,
                  border: "1px solid",
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                  borderRadius: 1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "& .MuiSelect-icon": {
                  color: isDarkTheme
                    ? darkTheme.iconColor1
                    : lightTheme.iconColor1,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: isDarkTheme
                      ? darkTheme.backgroundColorLayer3
                      : lightTheme.backgroundColorLayer3,
                    "& .MuiMenuItem-root": {
                      color: isDarkTheme
                        ? darkTheme.textColorLayer1
                        : lightTheme.textColorLayer1,
                      "&:hover": {
                        backgroundColor: isDarkTheme
                          ? darkTheme.dropdownHoverColorLayer3
                          : lightTheme.dropdownHoverColorLayer3,
                      },
                    },
                  },
                },
              }}
            >
              {lookUpOptions?.language?.map((option) => (
                <MenuItem
                  key={option.name}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <CustomSelect
            label="Subject matter expert"
            value={selecteSubjectMatterExpert}
            onChange={(e) => handleSelect("subjectMatterExpert", e)}
            options={lookUpOptions?.subjectMatterExpert}
            required
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile ? "column" : "row",
            gap: "32px",
            "& .MuiTypography-root": {
              fontSize: "12px",
            },

            "& .MuiBox-root": {
              marginBottom: 0,
            },
          }}
        >
          <Box
            sx={{
              width: { xs: "300px", md: "400px" },
              marginBottom: 2,
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                component="label"
                sx={{ marginBottom: 1 }}
              >
                Ip ownership
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: 1, fontSize: "14px", color: "#616161" }}
              >
                *Required
              </Typography>
            </Box>
            <Select
              value={contentDetailsSectionData?.ipOwnerShip || ""}
              onChange={(e) => {
                const selectedipOwnerShip = e.target.value; // Get the selected value
                setContentDetailsSectionData((stateData) => ({
                  ...stateData,
                  ipOwnerShip: selectedipOwnerShip, // Update the correct field
                }));
              }}
              sx={{
                width: "100%",
                maxWidth: "100%",
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 14px",
                  backgroundColor: isDarkTheme
                    ? darkTheme.cardBackgroundColorLayer1
                    : lightTheme.cardBackgroundColorLayer1,
                  border: "1px solid",
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                  borderRadius: 1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "& .MuiSelect-icon": {
                  color: isDarkTheme
                    ? darkTheme.iconColor1
                    : lightTheme.iconColor1,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: isDarkTheme
                      ? darkTheme.backgroundColorLayer3
                      : lightTheme.backgroundColorLayer3,
                    "& .MuiMenuItem-root": {
                      color: isDarkTheme
                        ? darkTheme.textColorLayer1
                        : lightTheme.textColorLayer1,
                      "&:hover": {
                        backgroundColor: isDarkTheme
                          ? darkTheme.dropdownHoverColorLayer3
                          : lightTheme.dropdownHoverColorLayer3,
                      },
                    },
                  },
                },
              }}
            >
              {lookUpOptions?.ipOwnerShip?.map((option) => (
                <MenuItem
                  key={option.name}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <CustomSelect
            label="Varieties"
            value={selectedVarities}
            onChange={(e) => handleSelect("varieties", e)}
            options={lookUpOptions?.varieties}
            required
          />
          <Box
            sx={{
              width: { xs: "300px", md: "400px" },
              marginBottom: 2,
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="subtitle1"
                component="label"
                sx={{ marginBottom: 1 }}
              >
                Flesh color
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: 1, fontSize: "14px", color: "#616161" }}
              >
                *Required
              </Typography>
            </Box>
            <Select
              value={contentDetailsSectionData?.fleshColor || ""}
              onChange={(e) => {
                const selectedFlashColor = e.target.value; // Get the selected value
                setContentDetailsSectionData((stateData) => ({
                  ...stateData,
                  fleshColor: selectedFlashColor, // Update the correct field
                }));
              }}
              sx={{
                width: "100%",
                maxWidth: "100%",
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 14px",
                  backgroundColor: isDarkTheme
                    ? darkTheme.cardBackgroundColorLayer1
                    : lightTheme.cardBackgroundColorLayer1,
                  border: "1px solid",
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                  borderRadius: 1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkTheme
                    ? darkTheme.borderColorLayer1
                    : lightTheme.borderColorLayer1,
                },
                "& .MuiSelect-icon": {
                  color: isDarkTheme
                    ? darkTheme.iconColor1
                    : lightTheme.iconColor1,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: isDarkTheme
                      ? darkTheme.backgroundColorLayer3
                      : lightTheme.backgroundColorLayer3,
                    "& .MuiMenuItem-root": {
                      color: isDarkTheme
                        ? darkTheme.textColorLayer1
                        : lightTheme.textColorLayer1,
                      "&:hover": {
                        backgroundColor: isDarkTheme
                          ? darkTheme.dropdownHoverColorLayer3
                          : lightTheme.dropdownHoverColorLayer3,
                      },
                    },
                  },
                },
              }}
            >
              {lookUpOptions?.fleshColor?.map((option) => (
                <MenuItem
                  key={option.name}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <CustomCuratorRadioGroup
          label={"Content Level"}
          value={contentDetailsSectionData?.contentLevel}
          onChange={(e) => {
            setContentDetailsSectionData((stateData) => {
              return {
                ...stateData,
                contentLevel: parseInt(e?.target?.value),
              };
            });
          }}
          radioGroup={lookUpOptions?.contentLevel}
        />
      </Box>
    </Box>
  );
};

export default ContentDetails;
