import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../../theme/Theme";

export default function CustomPagination({ page, setPage, totalPages }) {
  const isDarkTheme = useSelector((state) => state.theme);

  const handleChangePage = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  // Function to generate page numbers with ellipsis logic
  const getPageNumbers = () => {
    if (totalPages <= 10) {
      return [...Array(totalPages).keys()]; // Show all pages if ≤ 10
    }

    let pages = [0, 1]; // Always show first two pages
    if (page > 4) pages.push("..."); // Add ellipsis before middle range

    let middleStart = Math.max(2, page - 1);
    let middleEnd = Math.min(totalPages - 3, page + 1);

    for (let i = middleStart; i <= middleEnd; i++) {
      pages.push(i);
    }

    if (page < totalPages - 4) pages.push("..."); // Add ellipsis after middle range
    pages.push(totalPages - 3, totalPages - 2, totalPages - 1); // Always show last three pages

    return pages;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "16px",
        backgroundColor: isDarkTheme ? "#333333" : "#EFF5FC",
        color: isDarkTheme
          ? lightTheme.insideCardBackgroundColorLayer5
          : darkTheme.insideCardBackgroundColorLayer5,
        height: "32px",
        borderRadius: "4px",
        border: "1px solid #D7E5F8",
        padding: "0 8px",
      }}
    >
      {/* Previous Page Button */}
      <IconButton
        onClick={() => handleChangePage(page - 1)}
        disabled={page === 0}
        sx={{
          color: page === 0 ? "#BDBDBD" : "#1976d2",
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <ArrowBackIosNewIcon sx={{ fontSize: "16px" }} />
      </IconButton>

      {/* Page Numbers with Ellipsis */}
      {getPageNumbers().map((pageNumber, index) => (
        <Box key={index}>
          {pageNumber === "..." ? (
            <Typography
              sx={{
                padding: "0 8px",
                color: "#BDBDBD",
                fontWeight: "bold",
                userSelect: "none",
              }}
            >
              ...
            </Typography>
          ) : (
            <Button
              onClick={() => handleChangePage(pageNumber)}
              sx={{
                backgroundColor:
                  page === pageNumber ? "#1976d2" : "transparent",
                color: page === pageNumber ? "#FFFFFF" : "#005CBC",
                fontWeight: 700,
                minWidth: "32px",
                height: "32px",
                padding: "0px 16px",
                justifyContent: "center",
                alignItems: "center",
                border: page === pageNumber ? "none" : "1px solid #D7E5F8",
                "&:hover": {
                  backgroundColor: page === pageNumber ? "#1565C0" : "#E0E0E0",
                },
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontWeight: 400,
                lineHeight: "var(--Line-Height-Small, 20px)",
              }}
            >
              {pageNumber + 1}
            </Button>
          )}
        </Box>
      ))}

      {/* Next Page Button */}
      <IconButton
        onClick={() => handleChangePage(page + 1)}
        disabled={page === totalPages - 1}
        sx={{
          color: page === totalPages - 1 ? "#BDBDBD" : "#1976d2",
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
      </IconButton>
    </Box>
  );
}
