import React from "react";
import { Box, useMediaQuery, InputBase, IconButton } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import SearchIcon from "@mui/icons-material/Search";
import CustomSearch from "../../utils/CustomSearch/CustomSearch";

export default function SubjectMatterFilter({ setSearch }) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleSearch = (value) => {
    setSearch(value);
  };
  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <CustomSearch handleSearch={handleSearch} />
    </Box>
  );
}
