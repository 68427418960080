import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeRender from "../../../../theme/useThemeRender";
import SpecialProjectInformation from "./SpecialProjectInformation";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setPage,
  setReset,
  setSpecialProjectDescription,
  setSpecialProjectDrawer,
  setSpecialProjectEditData,
  setSpecialProjectEditId,
  setSpecialProjectStatus,
  setSpecialProjectTitle,
} from "../../../../slices/adminPageSlice";
import SpecialProjectDocument from "./SpecialProjectDocument";
import SpecialProjectUser from "./SpecialProjectUser";
import DocumentBottom from "./DocumentBottom";
import AddSpecialProject from "./AddSpecialProject";
import ConfirmPopup from "./ConfirmPopup";
import ViewSummary from "../../../ViewSummary/ViewSummary";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../constant/apiUrl";
import axios from "axios";
import "./SpecialProject.css";
import { getAPI } from "../../../../API/ApiService";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/Toast/toast";
import Loading from "../../../../utils/Loader";
function SpecialProjectEditScreen() {
  const {
    specialProjectEditData,
    specialProjectStatus,
    specialProjectTitle,
    specialProjectDescription,
    specialProjectEditId,
  } = useSelector((store) => store.adminpage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(setPage("Special projects"));
    dispatch(setSpecialProjectTitle(""));
    dispatch(setSpecialProjectDescription(""));
    dispatch(setSpecialProjectStatus(true));
    setSpecialProjectEditId("");
    dispatch(setReset(false));
    navigate("/admin");
  };
  const location = useLocation();
  const InitialData = location.state;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const documentsPerPage = 6;
  const userPerPage = 8;
  const [documentPage, setDocumentPage] = useState(0);
  const [userPage, setUserPage] = useState(0);
  useEffect(() => {
    setFilterData(InitialData);
    dispatch(setSpecialProjectEditId(InitialData.id));
    dispatch(setSpecialProjectTitle(InitialData.name));
    dispatch(setSpecialProjectDescription(InitialData.description));
    dispatch(setSpecialProjectStatus(InitialData.status));
  }, []);
  const DocumentData = filterData?.contents?.slice(
    documentPage * documentsPerPage,
    documentPage * documentsPerPage + documentsPerPage
  );
  const UserData = filterData?.users?.slice(
    userPage * userPerPage,
    userPage * userPerPage + userPerPage
  );
  const token = localStorage.getItem("auth_token");
  const { favourite } = useSelector((store) => store.internalPage);
  const handleAPIError = (err) => {
    if (err?.response?.status === 400) {
      const errors = err?.response?.data;
      Object?.keys(errors)?.forEach((key) => {
        if (
          errors[key] ===
          "Query did not return a unique result: 2 results were returned"
        ) {
          showErrorToast("Special Project Name already exists");
        } else {
          showErrorToast(errors[key]);
        }
      });
    }
    setError(err.message);
  };
  const handleSave = () => {
    const userIds = filterData?.users?.map((item) => item.id);
    const newData = {
      name: specialProjectTitle.trim(),
      description: specialProjectDescription.trim(),
      status: specialProjectStatus,
      userIds: userIds,
    };
    if (newData?.name?.length > 0) {
      const editSpecialProject = async () => {
        try {
          const response = await axios.put(
            `${BASE_URL}/special-projects/updateSpecialProject/${specialProjectEditId}`,
            newData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          );
          if (response?.status === 200) {
            showSuccessToast("Special Project has been Updated");
            handleNavigate();
          }
        } catch (err) {
          handleAPIError(err);
        } finally {
          setLoading(false);
        }
      };
      editSpecialProject();
    } else if (newData?.name?.length == 0) {
      showErrorToast("Please enter the special project title");
    }
  };
  useEffect(() => {
    if (specialProjectEditData !== "") {
      setFilterData(specialProjectEditData);
      dispatch(setSpecialProjectEditData(""));
    }
  }, [specialProjectEditData]);
  useEffect(() => {
    setLoading(true);
    const fetchSpecialProjectData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/special-projects/listAllProjects`
        );
        if (response?.status === 200) {
          const result = response?.data?.find(
            (item) => item?.id === InitialData?.id
          );
          setFilterData(result);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSpecialProjectData();
    setFilterData(specialProjectEditData);
  }, [favourite]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          background: useThemeRender("backgroundColorLayer1"),
          padding: "16px",
          gap: "16px",
        }}
      >
        {loading && <Loading />}
        <Box
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-03, 8px) 0px",
            justifyContent: "center",
            alignItems: "center",
            gap: "var(--Spacing-spacing-03, 8px)",
            flex: "1 0 0",
            cursor: "pointer",
          }}
          onClick={handleNavigate}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
              fill="#005CBC"
            />
          </svg>
          <Typography
            sx={{
              color: "var(--Tokens-Button-Borderless, #005CBC)",
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Body, 16px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Body, 22px)",
            }}
          >
            Back to list
          </Typography>
        </Box>
        <SpecialProjectInformation screen={"edit"} />
        <Box
          className="DetailCard cardStyle"
          sx={{
            background: useThemeRender("backgroundColorLayer3"),
            gap: "8px",
            width: "100%",
            borderRadius: "16px",
          }}
        >
          <Typography
            sx={{
              color: useThemeRender("textColorLayer1"),
            }}
            className="titleText"
            variant="h5"
          >
            Documents
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              width: "100%",
            }}
          >
            <SpecialProjectDocument paginatedData={DocumentData} />
          </Box>
          <DocumentBottom
            page={documentPage}
            setPage={setDocumentPage}
            Data={filterData?.contents ? filterData?.contents : []}
            rowsPerPage={documentsPerPage}
          />
        </Box>
        <Box
          className="DetailCard cardStyle"
          sx={{
            background: useThemeRender("backgroundColorLayer3"),
            gap: "8px",
            width: "100%",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: useThemeRender("textColorLayer1"),
              }}
              className="titleText"
              variant="h5"
            >
              Users
            </Typography>
            <Box
              sx={{ display: "flex", gap: "5px", cursor: "pointer" }}
              onClick={() => dispatch(setSpecialProjectDrawer(true))}
            >
              <Typography
                sx={{
                  color: "var(--Tokens-Button-Borderless, #005CBC)",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Body, 16px)",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "var(--Line-Height-Body, 22px)",
                }}
              >
                Add users
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM11 8C11 9.1 10.1 10 9 10C7.9 10 7 9.1 7 8C7 6.9 7.9 6 9 6C10.1 6 11 6.9 11 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18ZM3 18C3.2 17.29 6.3 16 9 16C11.69 16 14.78 17.28 15 18H3ZM20 15V12H23V10H20V7H18V10H15V12H18V15H20Z"
                  fill="#005CBC"
                />
              </svg>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              width: "100%",
            }}
          >
            <SpecialProjectUser paginatedData={UserData} />
          </Box>
          <DocumentBottom
            page={userPage}
            setPage={setUserPage}
            Data={filterData?.users ? filterData?.users : []}
            rowsPerPage={userPerPage}
          />
        </Box>
        <AddSpecialProject
          screen={"addUser"}
          existingUserData={filterData?.users}
          handleAPIError={handleAPIError}
        />
        <ConfirmPopup />
        <ViewSummary />
      </Box>
      <Box
        sx={{
          background: useThemeRender("insideCardBackgroundColorLayer1"),
        }}
        className="ButtonBotttomBoxStyle"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "15px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              border:
                "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
            }}
            className="ButtonStyle"
            onClick={handleNavigate}
          >
            <Typography
              sx={{
                color: "#005CBC",
                fontWeight: 700,
              }}
              className="textStyle"
            >
              Cancel
            </Typography>
          </Box>
          <Box
            sx={{
              background: "var(--Tokens-Button-Primary, #005CBC)",
            }}
            className="ButtonStyle"
            onClick={handleSave}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 700,
                p: "5px 10px",
              }}
              className="textStyle"
            >
              Save
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SpecialProjectEditScreen;
