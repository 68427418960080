import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import useThemeRender from "../../../../theme/useThemeRender";
import DocumentHeader from "./DocumentHeader";
import InsideCardUser from "./InsideCardUser";
function SpecialProjectUser({ paginatedData }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  return paginatedData?.map((item) => (
    <Box
      sx={{
        background: insideCardBackgroundColorLayer1,
        width: isMobile ? "100%" : "24%",
      }}
      key={item.id}
      className="specialProjectDocument"
    >
      <DocumentHeader
        title={item?.name}
        name={"user"}
        popupContent={`Remove ${item?.name} as a user of the special project?`}
        data={item}
      />
      <InsideCardUser cardName={"Access level"} value={item?.accessLevel} />
      <InsideCardUser cardName={"Organization "} value={item?.company} />
      <InsideCardUser cardName={"Email address"} value={item?.email} />
      <InsideCardUser cardName={"Country"} value={item?.country} />
    </Box>
  ));
}

export default SpecialProjectUser;
