import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./DataTable.css";
import DynamicTable from "../../../utils/Table/DynamicTable";
import { capitalizeFirstLetter } from "../../../utils/function";

const DataTable = ({ pageName, Tabledata, serachText }) => {
  const navigate = useNavigate();

  const handleNavigate = (item) => {
    console.log(item, "adddityaaarow1");

    if (pageName === "artifcatsHistory") {
      navigate(`/curator/artifact-history/${item?.contentGuid}`);
    } else if (pageName === "deniedHistory") {
      navigate(`/curator/denied-artificats-history/${item?.contentGuid}`);
    }
  };

  const truncateArray = (array, max = 3) => {
    if (!array || array.length === 0) return "-";
    return array.length > max
      ? `${array.slice(0, max).join(", ")} +${array.length - max} more`
      : array.join(", ");
  };
  function truncateText(text, maxLength) {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }
  const processedRows = Tabledata?.map((row) => ({
    ...row,
    type: capitalizeFirstLetter(row.type),
    status: capitalizeFirstLetter(row.status),
    truncateTitle: truncateText(row.title, 20),
    truncatedSector: truncateArray(row.sector.split(",")),
    truncatedCountry: truncateArray(row.country.split(",")),
    truncatedSubject: "Internal",
    fullSector: row.sector, // Store full values separately
    fullCountry: row.country,
    fullSubject: row.subject,
  }));
  const baseColumns = [
    {
      id: "truncateTitle",
      label: "CONTENT TITLE",
      width: "200px",
      headerWidth: "100%",
      filterable: true,
    },
    {
      id: "type",
      label: "CONTENT TYPE",
      width: "200px",
      headerWidth: "100%",
      filterable: true,
    },
    {
      id: "status",
      label: "STATUS",
      width: "200px",
      cellStyle: "cellStyle",
      filterable: true,
    },
    {
      id: "truncatedSector",
      label: "SECTOR",
      width: "200px",
      filterable: true,
    },
    {
      id: "truncatedCountry",
      label: "COUNTRY",
      width: "200px",
      filterable: true,
    },
    { id: "language", label: "LANGUAGE", width: "200px", filterable: true },
    {
      id: "truncatedSubject",
      label: "SUBJECT",
      width: "200px",
      filterable: true,
    },
    { id: "actions", label: "ACTIONS", width: "80px", filterable: false },
  ];

  const renderActions = (row) => {
    return (
      <Typography
        onClick={() => handleNavigate(row)}
        sx={{
          color: "var(--Tokens-Link-Default, #005CBC)",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-Small, 14px)",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "var(--Line-Height-Small, 20px)",
        }}
      >
        EDIT
      </Typography>
    );
  };
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        "& .MuiDataGrid-filler": { display: "none" },
        "& .MuiDataGrid-footerContainer": { display: "none" },
        "& .MuiDataGrid-virtualScroller": {
          overflowY: "hidden",
        },
      }}
      style={{ overflowY: "auto" }}
    >
      <DynamicTable
        rows={processedRows}
        columns={baseColumns}
        rowsPerPage={10}
        searchText={serachText}
        onRowClick={(row) => {
          console.log("Row clicked:", row);
        }}
        renderActions={(row) => renderActions(row)}
        showTooltip={true}
      />
    </Box>
  );
};

export default DataTable;
