import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import CustomSearch from "../../../../utils/CustomSearch/CustomSearch";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import { Filter, Button } from "pepsico-ds";
import {
  commonButtonStyle,
  commonTextStyle,
} from "../../../PotatoProtection/LargeScreen/Landing/potatoprotectstyle";

export default function InvitationHistoryHeader({
  setSearch,
  handleExportCsv,
  header,
  setOrganization,
  getUniqueOptions,
  setFilter,
  setSponsor,
  user,
  setAccess,
}) {
  const isDarkTheme = useSelector((state) => state.theme);
  const handleSearch = (value) => {
    setSearch(value);
  };
  const handle = (value) => {
    setFilter(value);
  };
  const handleSponsor = (value) => {
    setSponsor(value);
  };
  const handleOrganization = (value) => {
    setOrganization(value);
  };

  const handleAccess = (value) => {
    setAccess(value);
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isMobile ? "14px" : "",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-8, 8px)",
          alignItems: isMobile ? "" : "center",
          alignSelf: "stretch",
          flexDirection: isMobile ? "column" : "",
          gap: isMobile ? "12px" : "",
          justifyContent: isMobile ? "" : "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: "1 0 0",
          }}
        >
          <Typography
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer2
                : lightTheme.textColorLayer2,
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H5, 24px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-H5, 32px)",
            }}
          >
            {header === "Access:invitation history"
              ? "Invitation history"
              : header}
          </Typography>
        </Box>
        <CustomSearch handleSearch={handleSearch} />
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-8, 8px)",
          paddingBottom: "16px",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: isMobile ? "" : "space-between",
          flexDirection: isMobile ? "column" : "",
          gap: isMobile ? "14px" : "",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : "var(--Tokens-Background-Layer-01, #FBFCFE)",
          boxShadow: "0px -2px 0px 0px #D7E5F8 inset",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "4px",
          }}
        >
          {header === "Access:invitation history" ? (
            <>
              <Filter
                label="Status"
                isDark={isDarkTheme}
                options={[
                  {
                    id: 1,
                    label: "SENT",
                    value: "SENT",
                  },

                  {
                    id: 3,
                    label: "ACCEPTED",
                    value: "ACCEPTED",
                  },
                ]}
                onChange={(val) => handle(val)}
              />
              <Filter
                label="Sponsor"
                options={getUniqueOptions("sponsorEmail")}
                isDark={isDarkTheme}
                onChange={(val) => handleSponsor(val)}
              />
            </>
          ) : header === "Access history" ? (
            <>
              <Filter
                label="Access type"
                options={user?.map((item) => {
                  return { id: item.id, label: item.name };
                })}
                data-testid="access-filter"
                isDark={isDarkTheme}
                onChange={(val) => handleAccess(val)}
              />

              <Filter
                label="Organization"
                options={getUniqueOptions("organization")}
                isDark={isDarkTheme}
                onChange={(val) => handleOrganization(val)}
              />
              <Filter
                label="PepsiCo Sponsor’s email"
                options={getUniqueOptions("sponsorEmail")}
                isDark={isDarkTheme}
                onChange={(val) => handleSponsor(val)}
              />
            </>
          ) : (
            <Filter
              label="Sponsor"
              isDark={isDarkTheme}
              options={getUniqueOptions("sponsorEmail")}
              onChange={(val) => handleSponsor(val)}
            />
          )}
        </Box>
        <Button
          style={{ ...commonButtonStyle, width: isMobile ? "100%" : "" }}
          onClick={handleExportCsv}
        >
          <Typography sx={commonTextStyle}>Export</Typography>
          {/* <SaveAltIcon sx={{ fill: "white" }} /> */}
        </Button>
      </Box>
    </Box>
  );
}
