import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import { useState } from "react";
import { BASE_URL } from "../../../constant/apiUrl";
import { useLocation } from "react-router-dom";
import { postAPI } from "../../../API/ApiService";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  removeItemByContentId,
  toggleFavourite,
} from "../../../utils/function";
import {
  setFetchFavourtie,
  setFavourites,
} from "../../../slices/internalPageSlice";
import { showErrorToast } from "../../../utils/Toast/toast";
import { useImageCache } from "../../../contexts/ImageCacheContext";
const ListViewCard = ({
  item,
  content,
  handleOpenSummary,
  handleNavigate,
  isMobile,
  styles,
  fallbackImage,
  localState,
  setLocalState,
  setLoading,
}) => {
  console.log(item, "----ListViewCardItem--------");
  const [isFavourite, setIsFavourite] = useState(
    item.favorite || item.is_favorite
  );
  const { favourites, selectedAction } = useSelector(
    (store) => store.internalPage
  );
  const [imageUrl, setImageUrl] = useState(null);

  console.log(item, "------Listitemssss-------");

  const dispatch = useDispatch();
  const location = useLocation();
  const specialRoutes = ["/favorites"];
  const isDynamicSearchRoute = location.pathname.startsWith("/search");
  const isSpecialRoute = specialRoutes.includes(location.pathname);
  const handleAddFavourite = async (card) => {
    setLoading(true);
    const contentId = card?.contentId ? card?.contentId : card?.id;
    try {
      const response = await postAPI(
        `${BASE_URL}/favorites/save?contentId=${contentId}&userId=1&favorite=${!card.favorite}`
      );
      if (response?.status === 200) {
        setIsFavourite(response?.data?.favorite);

        if (isSpecialRoute) {
          const updatedData = removeItemByContentId(
            favourites,
            response?.data?.contentId
          );

          dispatch(setFetchFavourtie(true));

          dispatch(setFavourites(updatedData));
        } else if (isDynamicSearchRoute) {
          dispatch(setFetchFavourtie(true));
        } else {
          const result = toggleFavourite(
            localState,
            contentId,
            content,
            response?.data.favorite
          );
          setLocalState(result);
          dispatch(selectedAction(result));
          console.log(result, "-----result-------");
        }
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };
  const { fetchImage } = useImageCache();

  useEffect(() => {
    const fetchImages = async () => {
      if (!item.thumbnailFileId) {
        return;
      }
      let imageBlob = await fetchImage(item.thumbnailFileId);
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImageUrl(imageObjectURL);
    };

    fetchImages();
  }, [fetchImage]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: isMobile ? "8px" : "16px",
        background: styles?.insideCardBackgroundColorLayer1,
        borderRadius: "8px",
        // boxShadow: `0px 0px 24px 0px ${styles?.boxShadowlayerColor1}`,
        width: "100%", // Ensures the list is 100% width of the container
        boxShadow: "none",
        border: "1px solid #D7E5F8",
      }}
    >
      {/* Thumbnail + Details */}
      <div className="flex flex-row gap-[24px]">
        {!isMobile ? (
          <div className="w-[194px] h-[132px]">
            {imageUrl && (
              <img
                key={item.thumbnailFileId}
                src={imageUrl}
                alt="Thumbnail"
                className="h-full w-full rounded-[8px]"
              />
            )}
            {!imageUrl && (
              <img
                src={fallbackImage}
                alt="Thumbnail"
                className="h-full w-full rounded-[8px]"
              />
            )}
          </div>
        ) : null}

        <div className="flex flex-col" style={{ flex: 1 }}>
          <Typography
            sx={{
              color: styles?.textColorLayer2,
              fontWeight: 700,
              fontSize: isMobile ? "18px" : "1.2rem",
              lineHeight: "32px",
              fontFamily: "Inter",
              marginBottom: "8px",
            }}
          >
            {item?.title}
          </Typography>
          <Typography
            sx={{
              color: styles?.textColorLayer2,
              fontWeight: 500,
              fontSize: isMobile ? "12px" : "16px",
              lineHeight: "20px",
              fontFamily: "Inter",
              marginBottom: "8px",
            }}
          >
            {item?.description}
          </Typography>

          <div className="flex mb-[12px]">
            <div
              className={`${
                isMobile ? "mr-[8px]" : ""
              } flex flex-row gap-2 mr-[24px]`}
            >
              <Typography
                sx={{
                  color: styles?.textColorLayer2,
                  fontSize: isMobile ? "12px" : "14px",
                  fontFamily: "Inter",
                  fontWeight: 600,
                }}
              >
                Date:
              </Typography>
              <Typography
                sx={{
                  color: styles?.textColorLayer2,
                  fontSize: isMobile ? "12px" : "14px",
                  fontFamily: "Inter",
                  fontWeight: 400,
                }}
              >
                {formatDate(item?.uploadedOn) || formatDate(item?.date)}
              </Typography>
            </div>

            {content === "Documents" && (
              <div
                className={`${
                  isMobile ? "mr-[8px]" : ""
                } flex flex-row gap-2 mr-[24px]`}
              >
                <Typography
                  sx={{
                    color: styles?.textColorLayer2,
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: "Inter",
                    fontWeight: 600,
                  }}
                >
                  View Count:
                </Typography>
                <Typography
                  sx={{
                    color: styles?.textColorLayer2,
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: "Inter",
                    fontWeight: 400,
                  }}
                >
                  {item?.counts || item?.views || 0}
                </Typography>
              </div>
            )}

            <div className="flex flex-row gap-2">
              <Typography
                sx={{
                  color: styles?.textColorLayer2,
                  fontSize: isMobile ? "12px" : "14px",
                  fontFamily: "Inter",
                  fontWeight: 600,
                }}
              >
                Likes:
              </Typography>
              <Typography
                sx={{
                  color: styles?.textColorLayer2,
                  fontSize: isMobile ? "12px" : "14px",
                  fontFamily: "Inter",
                  fontWeight: 400,
                }}
              >
                {item?.likes || "0"}
              </Typography>
            </div>
          </div>
          {/* Actions: Favorite & View Summary */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="flex flex-row gap-3">
              {/* Favorite Button */}
              <Box
                sx={styles?.linkContainer}
                onClick={() => handleAddFavourite(item)}
              >
                {isFavourite ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18 17"
                    fill="none"
                  >
                    <path
                      d="M11.3417 6.18366L17.3334 6.70033L12.7917 10.642L14.15 16.5003L9.00002 13.392L3.85002 16.5003L5.21669 10.642L0.666687 6.70033L6.65835 6.19199L9.00002 0.666992L11.3417 6.18366Z"
                      fill="#005CBC"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M18.3334 7.70033L12.3417 7.18366L10 1.66699L7.65835 7.19199L1.66669 7.70033L6.21669 11.642L4.85002 17.5003L10 14.392L15.15 17.5003L13.7917 11.642L18.3334 7.70033ZM10 12.8337L6.86669 14.7253L7.70002 11.1587L4.93335 8.75866L8.58335 8.44199L10 5.08366L11.425 8.45033L15.075 8.76699L12.3084 11.167L13.1417 14.7337L10 12.8337Z"
                      fill="#005CBC"
                    />
                  </svg>
                )}
                <Typography
                  sx={isMobile ? { display: "none" } : styles?.linkTypography}
                >
                  Favorite
                </Typography>
              </Box>

              {/* View Summary */}
              <Box
                sx={styles?.linkContainer}
                onClick={() => handleOpenSummary(item)}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ fill: "#005CBC", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={isMobile ? { display: "none" } : styles?.linkTypography}
                >
                  View Summary
                </Typography>
              </Box>
            </div>

            {/* View Button */}
            <Button
              sx={styles?.buttonStyle}
              onClick={() => handleNavigate(item, content.replace(/s$/, ""))}
            >
              <Typography
                sx={{
                  color: "#005CBC",
                  fontWeight: 700,
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                View {content.replace(/s$/, "")}
              </Typography>
              {content === "Links" ? (
                <LaunchOutlinedIcon
                  sx={{ fill: "#005CBC", width: "20px", height: "20px" }}
                />
              ) : (
                <ArrowForwardIcon
                  sx={{ fill: "#005CBC", width: "20px", height: "20px" }}
                />
              )}
            </Button>
          </Box>
        </div>
      </div>
    </Box>
  );
};

// Define PropTypes for validation
ListViewCard.propTypes = {
  item: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  handleAddFavourite: PropTypes.func.isRequired,
  handleOpenSummary: PropTypes.func.isRequired,
  handleNavigate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  styles: PropTypes.object.isRequired,
  fallbackImage: PropTypes.string.isRequired,
};

export default ListViewCard;
