// InternalPageLayout.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionItem } from "pepsico-ds";
import ListViewDesign from "../InternalPages/ListView/ListViewDesign";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LIBRARY_CONSTANT } from "../../constant/constant";
import useThemeRender from "../../theme/useThemeRender";
import "./LibraryAccordion.css";
import { useMediaQuery } from "@mui/material";
export default function LibraryAccordion({
  data,
  redirectLibraryDetailView,
  subPageId,
  tabValue,
}) {
  const [localState, setLocalState] = useState(data);

  useEffect(() => {
    if (localState !== data) {
      setLocalState(data);
    }
  }, [data]);
  const { documents, videos, links } = data;
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const redirectToVisitSubject = (event, viewType) => {
    event.stopPropagation();
    redirectLibraryDetailView(data);
  };
  return (
    <Accordion
      className={`library-accordion-container ${
        isDarkTheme ? "dark-theme-text-color" : null
      }`}
      alignment="left"
      onUpdate={function Qa() {}}
      size="xlarge"
      state="enable"
      style={{
        backgroundColor: useThemeRender("backgroundColorLayer2"),
        padding: "0",
        borderRadius: "16px",
      }}
    >
      <AccordionItem
        className="library-accordion-item"
        alignment="right"
        label={
          <span
            style={{
              color: textColorLayer4,
            }}
            className={`${isMobile ? "leading-[20px] text-[14px]" : ""}`}
          >
            {data?.subjectName}
          </span>
        }
        subTitle={
          <span
            onClick={redirectToVisitSubject}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--Spacing-spacing-4, 4px)",
            }}
            className={`${isMobile ? "text-[12px]" : ""}`}
          >
            Visit Subject
            <ArrowForwardIcon
              sx={{
                width: "24px",
                height: "24px",
                top: "2px",
                left: "4px",
                paddingRight: "8px",
              }}
              className={`${isMobile ? "w-[16px] h-[16px]" : ""}`}
            />
          </span>
        }
      >
        <div className={`${isMobile ? "pt-[24px]" : "pt-[38px]"}`}>
          {documents?.length > 0 &&
            (tabValue === LIBRARY_CONSTANT?.document ||
              tabValue === LIBRARY_CONSTANT?.all) && (
              <ListViewDesign
                data={documents}
                content={"Documents"}
                pageType="LibraryDetails"
                subPageId={subPageId}
                setLocalState={setLocalState}
                localState={localState}
              />
            )}
          {videos?.length > 0 &&
            (tabValue === LIBRARY_CONSTANT?.videos ||
              tabValue === LIBRARY_CONSTANT?.all) && (
              <ListViewDesign
                data={videos}
                content={"Videos"}
                pageType="LibraryDetails"
                subPageId={subPageId}
                setLocalState={setLocalState}
                localState={localState}
              />
            )}
          {links?.length > 0 &&
            (tabValue === LIBRARY_CONSTANT?.links ||
              tabValue === LIBRARY_CONSTANT?.all) && (
              <ListViewDesign
                data={links}
                content={"Links"}
                pageType="LibraryDetails"
                subPageId={subPageId}
                setLocalState={setLocalState}
                localState={localState}
              />
            )}
        </div>
      </AccordionItem>
    </Accordion>
  );
}
