import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useThemeRender from "../../theme/useThemeRender";
import {
  Typography,
  styled,
  Box,
  TextField,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { darkTheme, lightTheme } from "../../theme/Theme";
import Button from "@mui/material/Button";
import { getFormattedDate } from "../../utils/function";
import CloseIcon from "@mui/icons-material/Close";

const StyledBox = styled(Box)({
  display: "flex",
  width: "full",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  background: "var(--Tokens-Background-Layer-02, #EFF5FC)",
  padding: "8px 16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
});

const StyledTypography = styled(Typography)({
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
});

const StyledTypography2 = styled(Typography)({
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 12px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-Small, 18px)", // 142.857%
});

export default function CuratorYearlyApprovalModal({
  approveModal,
  isArchiveType,
  setIsModalVisible,
  makePendingApprovalApiCall,
}) {
  const [comments, setComments] = useState("");
  const handleClose = () => {
    setIsModalVisible(false);
  };
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const submitTheReviewData = () => {
    makePendingApprovalApiCall(isArchiveType ? "archive" : "confirm", comments);
  };
  const { flagUser } = useSelector((store) => store.user);

  return (
    <Dialog
      open={approveModal}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid  ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("cardBackgroundColorLayer1"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: isMobile ? "20px" : "40px",
          width: isMobile ? "100%" : "500px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            color: "var(--Tokens-Text-Primary, #3A3A3A)",
            textAlign: "center",
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H5, 24px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H5, 32px)",
          }}
        >
          Review
        </Typography>
        <StyledBox>
          <StyledTypography>Reviewer name</StyledTypography>
          <StyledTypography2>{`${flagUser?.firstName} ${flagUser?.lastName}`}</StyledTypography2>
        </StyledBox>
        <StyledBox>
          <StyledTypography>Reviewer date</StyledTypography>
          <StyledTypography2>{getFormattedDate()}</StyledTypography2>
        </StyledBox>
        <Box
          sx={{
            display: "flex",
            width: "full",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              color: isDarkTheme && darkTheme.textColorLayer1,
            }}
          >
            Comments
          </Typography>

          <TextField
            placeholder="Type here"
            multiline
            minRows={4}
            maxRows={8}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            style={{
              width: "100%",
              backgroundColor: isDarkTheme
                ? darkTheme.cardBackgroundColorLayer1
                : lightTheme.cardBackgroundColorLayer1,
              border: "1px solid white",
            }}
            InputProps={{
              style: {
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              },
            }}
            variant="outlined"
            size="small"
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button
          onClick={() => handleClose()}
          variant="outlined"
          sx={{
            textTransform: "none",
            font: "bold",
            fontWeight: 700,
            borderRadius: "24px",
            "&:hover": {
              background: "#004B9A",
            },
            marginRight: "10px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => submitTheReviewData()}
          variant="contained"
          sx={{
            font: "bold",
            fontWeight: 700,
            textTransform: "none",
            borderRadius: "24px",
            backgroundColor: "#005CBC",
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
