import { ViewSidebar } from "@mui/icons-material";
import fetchAndCacheImage from "./fetchandCatchImages";
import axios from "axios";
import { FILE_SERVICE_BASE_URL } from "../constant/apiUrl";
import { showErrorToast, showSuccessToast } from "./Toast/toast";
import { downloadFileToLocal } from "../API/curatorApi";
const token = localStorage.getItem("auth_token");
export const formatDate = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const day = String(date.getDate());
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

/**
 * Convert the Incoming array to dropdown option array
 * @param {*} inputOption
 * @returns
 */
export const getDropDownOptionValues = (inputOption = [], isSme = false) => {
  let dropDownOptions = [];
  inputOption?.forEach((item) => {
    if (item?.active || isSme) {
      dropDownOptions.push({
        id: item?.id,
        label: item?.name,
        name: item?.name,
        description: item?.description,
      });
    }
  });

  return dropDownOptions;
};

export const getDisclaimerDetailsFromId = (
  disclaimerId,
  lookupDisclaimerData
) => {
  let disclaimerContent = "";
  lookupDisclaimerData.forEach((item) => {
    if (item.id === disclaimerId) {
      disclaimerContent = item?.description;
    }
  });

  return disclaimerContent;
};

export const getPepsicoDropdownOptions = (optionData) => {
  const optionArray = [];
  optionData?.forEach((item) => {
    optionArray.push({ id: item?.id, displayText: item?.name });
  });

  return optionArray;
};

export const getDisplayTextForDropDown = (selectedItemId, lookupData) => {
  const selectedItem = lookupData?.filter((item) => item.id === selectedItemId);

  return selectedItem;
};

/**
 * Convert the Incoming array to dropdown option array
 * @param {*} inputOption
 * @returns
 */
export const getDropDownOptionValuesForCuratorFilters = (inputOption = []) => {
  let dropDownOptions = [];
  inputOption?.forEach((item) => {
    dropDownOptions.push({
      id: item?.id,
      label: item?.name,
      description: item?.description,
      active: item?.active,
    });
  });
  return dropDownOptions;
};

/**
 * Format date with dd-mmm-yyyy and return
 * @returns date string
 */
export function getFormattedDate() {
  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()]; // Get month as abbreviated name
  const year = date.getFullYear(); // Get full year

  return `${day}-${month}-${year}`;
}

/**
 * Return the accordian content
 * @param {*} accordion
 * @returns
 */

export const accordionContent = (accordion) => {
  return [
    {
      label: "Content type",
      content: accordion?.contentType,
    },
    {
      label: "Sector",
      content: accordion?.sector,
    },
    {
      label: "Country",
      content: accordion?.country,
    },
    {
      label: "Uploaded by",
      content: accordion?.uploadedBy,
    },
    {
      label: "Language",
      content: accordion?.language,
    },
    {
      label: "Visibility",
      content: accordion?.visibility,
    },
    {
      label: "Version Number",
      content: accordion?.versionNumber,
    },
    {
      label: "Updated Date",
      content: formatDate(accordion?.uploadedDate),
    },
    {
      label: "Content Level",
      content: accordion?.contentLevel,
    },
    {
      label: "Legal Review",
      content: accordion?.legalReview,
    },
    {
      label: "Special Projects",
      content:
        accordion?.specialProjects?.length > 0
          ? accordion?.specialProjects?.join()
          : "",
    },
    {
      label: "Crop Type",
      content: accordion?.cropType,
    },
  ];
};

/**
 * Return the section content
 * @param {*} accordion
 * @returns
 */
export const generateSectionData = (accordion) => {
  const sectionData = [
    {
      label: "Subjects",
      content: accordion?.subjects?.length > 0 ? [accordion?.subjects] : [],
    },
    {
      label: "Keywords",
      content: accordion?.keywords?.length > 0 ? [accordion?.keywords] : [],
    },
  ];
  return sectionData;
};

/**
 * Convert string and make first letter capital
 * @param {*} string
 * @returns
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const prepareCurtatorTableData = (tableData = []) => {
  const formatedTableData = [];

  tableData.forEach((item) => {
    formatedTableData.push({
      contentId: item?.contentId,
      contentGuid: item?.contentGuid,
      title: item?.title,
      type: item?.type,
      status: item?.status,
      sector: displayArrayValueAsCommaSeperated(item?.sector),
      country: displayArrayValueAsCommaSeperated(item?.country),
      language: displayArrayValueAsCommaSeperated(item?.language),
      subject: displayArrayValueAsCommaSeperated(item?.subject),
    });
  });

  return formatedTableData;
};

export const getTheCsvArrayValue = (csvArrayData) => {
  if (Array.isArray(csvArrayData) && csvArrayData?.length > 0) {
    return csvArrayData?.join(",");
  } else {
    return csvArrayData;
  }
};

export const escapeCsvValue = (value) => {
  if (value === null || value === undefined || value === "") {
    return "";
  }
  if (typeof value === "string") {
    if (value.includes('"')) {
      value = value.replace(/"/g, '""');
    }
    if (value.includes(",") || value.includes("\n") || value.includes('"')) {
      value = `"${value}"`;
    }
  }
  return value;
};

export const curatorCSVFileDownload = (csvData, filename) => {
  const csvRows = [];
  const headers = [
    "Content Title",
    "Content Type",
    "Status",
    "Sector",
    "Country",
    "Language",
    "Subject",
  ];
  csvRows.push(headers?.join(","));
  csvData?.forEach((row) => {
    const values = [
      escapeCsvValue(row?.title),
      escapeCsvValue(row?.type),
      escapeCsvValue(row?.status),
      escapeCsvValue(getTheCsvArrayValue(row?.sector)),
      escapeCsvValue(getTheCsvArrayValue(row?.country)),
      escapeCsvValue(getTheCsvArrayValue(row?.language)),
      escapeCsvValue(getTheCsvArrayValue(row?.subject)),
    ];
    csvRows.push(values?.join(","));
  });
  const csvContent = csvRows?.join("\n");
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  URL.revokeObjectURL(url);
};

export const getActiveItems = (items) => {
  return items
    .filter((item) => item.active) // Filter active items
    .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name
};

export const sortTableData = (data, params) => {
  return data.sort((a, b) => {
    const nameA = a[params]?.toLowerCase() || ""; // Handle case insensitivity and null values
    const nameB = b[params]?.toLowerCase() || "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

export const urlTagging = async (fetch, params) => {
  const updatedItems = await Promise.all(
    fetch.map(async (item) => {
      const data = await fetchAndCacheImage(item?.[params]);
      return { ...item, thumbnailUrl: data }; // Create a new object with the `url` property
    })
  );
  return updatedItems;
};

export const displayArrayValueAsCommaSeperated = (contentValue) => {
  if (Array.isArray(contentValue)) {
    return contentValue?.join(",");
  } else {
    return contentValue;
  }
};

export const increaseViewCount = (
  globalState,
  docId,
  content,
  views,
  likes
) => {
  console.log(globalState, docId, content, likes, views, "-------props-----");

  const contentKeyMap = {
    Documents: "documents",
    Links: "links",
    Videos: "videos",
  };

  const contentKey = contentKeyMap[content];

  if (!contentKey) {
    console.log("Invalid content type");
    return globalState;
  }

  const subPageIndex = globalState.internalSubPages.findIndex((subPage) =>
    subPage[contentKey].some((item) => item.id === docId)
  );

  if (subPageIndex === -1) {
    console.log(`${content} not found in any subPage`);
    return globalState; // Exit if the item is not found
  }

  // Update the likes and views fields for the matched item
  const updatedItems = globalState.internalSubPages[subPageIndex][
    contentKey
  ].map((item) =>
    item.id === docId ? { ...item, likes: likes, views: views } : item
  );

  // Update globalState while keeping the hierarchy
  return {
    ...globalState,
    internalSubPages: globalState.internalSubPages.map((subPage, index) =>
      index === subPageIndex
        ? { ...subPage, [contentKey]: updatedItems } // Only update the matched subPage
        : subPage
    ),
  };
};

export const toggleFavourite = (globalState, contentId, content, favorite) => {
  console.log(
    favorite,
    contentId,
    content,
    globalState,
    "-------modifiedFavourite------"
  );
  const contentKeyMap = {
    Documents: "documents",
    Links: "links",
    Videos: "videos",
  };

  const contentKey = contentKeyMap[content];

  const subPageIndex = globalState.internalSubPages.findIndex((subPage) =>
    subPage[contentKey].some((item) => item.id === contentId)
  );

  if (subPageIndex === -1) {
    return globalState; // Exit if the item is not found
  }

  const updatedItems = globalState.internalSubPages[subPageIndex][
    contentKey
  ].map((item) => {
    if (item.id === contentId) {
      return { ...item, favorite: favorite };
    } else {
      return item;
    }
  });

  return {
    ...globalState,
    internalSubPages: globalState.internalSubPages.map((subPage, index) =>
      index === subPageIndex
        ? { ...subPage, [contentKey]: updatedItems } // Only update the matched subPage
        : subPage
    ),
  };
};

// export const libraryToggle = (globalState, contentId, content, favorite) => {
//   console.log(
//     favorite,
//     contentId,
//     content,
//     globalState,
//     "-------modifiedFavourite------"
//   );
//   const contentKeyMap = {
//     Documents: "documents",
//     Links: "links",
//     Videos: "videos",
//   };

//   const contentKey = contentKeyMap[content];
//   const subPageIndex = globalState.subjectItems.findIndex((subPage) =>
//     subPage[contentKey].some((item) => item.id === contentId)
//   );
//   console.log(subPageIndex, "----libraryReswwwult------");
//   // if (subPageIndex === -1) {
//   //   return globalState; // Exit if the item is not found
//   // }
//   console.log(subPageIndex, "----libraryResult------");

//   const updatedItems = globalState.subjectItems[subPageIndex][contentKey].map(
//     (item) => {
//       if (item.id === contentId) {
//         return { ...item, favorite: favorite };
//       } else {
//         return item;
//       }
//     }
//   );
//   console.log(updatedItems, favorite, contentId, "------libraryResult1---");

//   return {
//     ...globalState,
//     subjectItems: globalState.subjectItems.map((subPage, index) =>
//       index === subPageIndex
//         ? { ...subPage, [contentKey]: updatedItems } // Only update the matched subPage
//         : subPage
//     ),
//   };
// };

export const uploadFileAndGiveFileId = async (files) => {
  const fileId = await Promise.all(
    files.map(async (item) => {
      const formData = new FormData();
      formData.append("file", item);
      const response1 = await axios.post(
        `${FILE_SERVICE_BASE_URL}/fritolay/file-manager/upload?user=ADMIN`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response1.status === 200) {
        return response1.data.fileId;
      }
    })
  );
  return fileId;
};

export const removeItemByContentId = (data, contentId) => {
  return {
    ...data,
    internalSubPages: data.internalSubPages.map((page) => ({
      ...page,
      documents: page.documents.filter((doc) => doc.id !== contentId),
      links: page.links.filter((link) => link.id !== contentId),
      videos: page.videos.filter((video) => video.id !== contentId),
    })),
  };
};

export const addItemByContentType = (data, newItem) => {
  // Ensure data has the expected structure
  const initializedData = {
    internalSubPages: data?.internalSubPages?.length
      ? data.internalSubPages
      : [{ documents: [], links: [], videos: [] }],
  };

  return {
    ...initializedData,
    internalSubPages: initializedData.internalSubPages.map((page) => ({
      ...page,
      documents:
        newItem.contentType === "DOCUMENT"
          ? [...(page.documents || []), newItem]
          : page.documents,
      links:
        newItem.contentType === "LINK"
          ? [...(page.links || []), newItem]
          : page.links,
      videos:
        newItem.contentType === "VIDEO"
          ? [...(page.videos || []), newItem]
          : page.videos,
    })),
  };
};

export function saveOctetStreamToFile(
  octetStream,
  fileName,
  mimeType = "application/octet-stream"
) {
  // Create a Blob from the Octet Stream
  const blob = new Blob([octetStream], { type: mimeType });

  // Convert Blob to a File
  const file = new File([blob], fileName, { type: mimeType });

  // Create a download link for the file
  const link = document.createElement("a");
  link.href = URL.createObjectURL(file);
  link.download = fileName;

  // Trigger the download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const openItem = async (fileId, contentUrl, contentType) => {
  if (contentType === "LINK") {
    if (contentUrl === "") {
      return Promise.reject("Unable to open the link");
    }
    window.open(contentUrl, "_blank", "flag");
    return Promise.resolve("Link opened");
  } else {
    return downloadFileToLocal(fileId, true)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve("File downloaded successfully");
        } else {
          return Promise.reject("Something went wrong while downloading file");
        }
      })
      .catch((error) => {
        console.log("error in file download", error);
        return Promise.reject("Something went wrong while downloading file");
      });
  }
};

export const getCountryList = (sectorIds, secotrByCountrylist = []) => {
  const selectedCountryList = [];
  let countryList = [];
  sectorIds?.forEach((item) => {
    secotrByCountrylist.forEach((countryData) => {
      if (countryData?.id === item) {
        selectedCountryList.push(countryData);
      }
    });
  });

  selectedCountryList?.forEach((item) => {
    countryList.push(...item?.countries);
  });

  return countryList;
};

export const handleShare = (location) => {
  const urlToShare = `${window.location.origin}${location?.pathname}`; // You can replace this with the specific URL you want to copy
  navigator.clipboard
    .writeText(urlToShare)
    .then(() => {
      showSuccessToast("Link copied to clipboard!");
    })
    .catch((error) => {
      console.error("Failed to copy text: ", error);
      alert("Failed to copy the link. Please try again.");
    });
};
