// components/LogoComponent.js
import Box from "@mui/material/Box";
import { Logo } from "pepsico-ds";
import PepsicoSymbol from "../../../assets/PepsicoSymbol.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useResize from "../../../hooks/useResize";
import { useMediaQuery } from "@mui/material";
const LogoComponent = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box>
      {/* Mobile Logo */}
      {/* <Box
        sx={{
          display: isMobile ? "flex" : "none",
          alignItems: "center",
        }}
      >
        <Logo
          colorStyle="color"
          isDark={isDarkTheme}
          logoType="pepsicoSymbol"
          style={{
            width: "40px",
            height: "auto",
            cursor: "pointer",
          }}
          onClick={handleNavigate}
        />
      </Box> */}
      {/* Desktop Logo */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            width: isMobile ? "94px" : "125.39px",
            height: "29.31px",
            textAlign: "center",
          }}
        >
          <Logo
            colorStyle="color"
            logoType="pepsicoFullLogo"
            isDark={isDarkTheme}
            onClick={handleNavigate}
            style={{ cursor: "pointer" }}
            data-testid="logo"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LogoComponent;
