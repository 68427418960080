import React from "react";
import potato from "../../assets/potato.png";
import { Box, Typography, Card, Grid } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import { formatDate } from "../../utils/function";
import LazyImage from "../../utils/LazyImage";
import { useSelector } from "react-redux";

function LinkDetails({ linkData }) {
  const insideCardBackgroundColorLayer4 = useThemeRender(
    "insideCardBackgroundColorLayer4"
  );
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const { flagUser } = useSelector((store) => store.user);
  const textColorLayer1 = useThemeRender("textColorLayer1");
  return (
    <Card
      sx={{
        background: useThemeRender("backgroundColorLayer3"),
      }}
      className="SummaryCard"
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer2"),
        }}
        className="SummaryTitle"
      >
        Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: "var(--Spacing-spacing-16, 16px)",
          gap: "var(--Spacing-spacing-12, 12px)",
          flexDirection: "column",
          background: useThemeRender("backgroundColorLayer1"),
          alignSelf: "stretch",
        }}
      >
        <LazyImage width={276} height={103} id={linkData?.thumbnailFileId} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                View count
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.viewCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Likes count
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.likes}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Content level
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.contentLevel}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Language
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.language}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Version
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.version}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Edited by
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.editedBy}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Added date
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {formatDate(linkData?.createdOn)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Added by
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.createdBy}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Reviewed date
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {formatDate(linkData?.reviewDate)}
              </Typography>
            </Box>
          </Grid>
          {flagUser?.roleName?.toLowerCase() !== "external" && (
            <Grid item xs={12} sm={3}>
              <Box
                className="DetailCardBox"
                sx={{
                  background: insideCardBackgroundColorLayer3,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Legal review
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {linkData?.isLegalReview ? "Yes" : "No"}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Subjects
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.subjects}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "auto",
                padding:
                  "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px)",
                flexDirection: "column",
                gap: "var(--Spacing-spacing-8, 8px)",
                alignItems: "flex-start",
                background: useThemeRender("insideCardBackgroundColorLayer3"),
                borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
              }}
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Keywords
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  flexWrap: "wrap",
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--Spacing-spacing-12, 12px)",
                }}
              >
                {linkData?.keywords?.split(",")?.map((val) => (
                  <Typography
                    sx={{
                      background: insideCardBackgroundColorLayer4,
                      color: textColorLayer1,
                      padding: "4px 4px",
                      lineHeight: "16px",
                      borderRadius: "4px",
                      fontSize: "var(--Font-font-size-small, 12px)",
                    }}
                    key={val}
                  >
                    {val}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default LinkDetails;
