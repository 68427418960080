import React from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { boxStyles, labelStyles, contentStyles } from "./gridStyle";

import {
  subjectChipStyles,
  subjectTypographyStyles,
} from "../SubjectSection/subjectStyle";
export default function GridSection({ accordionContent }) {
  const isDarkTheme = useSelector((state) => state.theme);

  const displayContentData = (contentValue) => {
    if (Array.isArray(contentValue)) {
      if (contentValue.length <= 3) {
        return contentValue.join(", "); // Show all if 3 or fewer
      } else {
        return `${contentValue.slice(0, 3).join(", ")} +${
          contentValue.length - 3
        } more`;
      }
    } else {
      return contentValue; // Return as is if not an array
    }
  };

  const renderGridItem = (label, content) => (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Box sx={boxStyles(isDarkTheme ? darkTheme : lightTheme)}>
        {/* {label === "Sectors" || label === "Country" ? (
          content?.map((data, index) => (
            <Box>
              <Typography sx={labelStyles(isDarkTheme)}>{label}</Typography>
              <Box sx={subjectChipStyles}>
                <Typography sx={subjectTypographyStyles}>{data}</Typography>
              </Box>
            </Box>
          ))
        ) : ( */}
        <Box style={{width: "100%"}}>
          <Typography sx={labelStyles(isDarkTheme)}>{label}</Typography>
          <Typography sx={contentStyles(isDarkTheme)}>
            {displayContentData(content)}
          </Typography>
        </Box>
        {/* )} */}
      </Box>
    </Grid>
  );

  return (
    <div className="flex flex-row gap-4 flex-wrap w-full">
      <Grid container spacing={2}>
        {accordionContent?.map(({ label, content }) =>
          renderGridItem(label, content)
        )}
      </Grid>
    </div>
  );
}
