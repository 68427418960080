import React, { useEffect, useState } from "react";
import { BASE_URL } from "../constant/apiUrl";
import Loading from "../utils/Loader";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import axios from "axios";
import DefaultPage from "../components/Administration/Screens/DefaultPage";
import { HEADER_CONSTANT } from "../constant/constant";
import { getAPI } from "../API/ApiService";

function SpeacialProject() {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/special-projects/screen/find-all`
        );
        if (response?.status === 200) {
          const newSpecial = response?.data?.response?.specialProjectItems?.map(
            (item) => {
              return { ...item, subPageName: item.specialProjectName };
            }
          );

          setPageData({
            internalSubPages: newSpecial,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <Loading data-testid="loading" />;

  return (
    <>
      {pageData?.internalSubPages?.length > 0 ? (
        <InternalPageLayout
          pageData={pageData}
          header={HEADER_CONSTANT.special_project}
          showDropdown={true}
          data-testid="special-project-container"
        />
      ) : (
        <DefaultPage
          text={"You are not assigned to any Special Project"}
          data-testid="default-page"
        />
      )}
    </>
  );
}

export default SpeacialProject;
