import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useState } from "react";
import useThemeRender from "../../../theme/useThemeRender";
import { openItem, formatDate } from "../../../utils/function";
import noPreviewImage from "../../../assets/no-preview.png";
import LazyImage from "../../../utils/LazyImage";
import { ViewDocumentButton } from "../Components/curatorApprovalStyle";
import { showErrorToast, showSuccessToast } from "../../../utils/Toast/toast";
import Loading from "../../../utils/Loader";
function DeniedDetailArtifactManagement({ detailData }) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const cardBackgroundColorLayer2 = useThemeRender("cardBackgroundColorLayer2");
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const downloadFile = (fileId, contentUrl, contentType) => {
    setIsPageLoading(true);
    openItem(fileId, contentUrl, contentType)
      .then((value) => {
        showSuccessToast(value);
      })
      .catch((reason) => showErrorToast(reason))
      .finally(() => setIsPageLoading(false));
  };

  return (
    <Box>
      {isPageLoading && <Loading />}
      {detailData?.artifactList?.map((item) => (
        <Box
          sx={{
            background: cardBackgroundColorLayer2,
          }}
          className="custom-container"
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={4}
            >
              <Box
                className="DetailCardBox"
                sx={{
                  background: insideCardBackgroundColorLayer3,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Version number
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {item?.versionNumber}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <Box
                className="DetailCardBox"
                sx={{
                  background: insideCardBackgroundColorLayer3,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Uploaded by
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {item?.uploadedBy}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <Box
                className="DetailCardBox"
                sx={{
                  background: insideCardBackgroundColorLayer3,
                }}
              >
                <Typography
                  className="DetailCardGridFirstContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  Upload date
                </Typography>
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                  }}
                >
                  {formatDate(item?.uploadedDate)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            sx={{
              color: textColorLayer4,
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-Small, 14px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-Small, 20px)",
            }}
          >
            Thumbnail
          </Typography>
          <LazyImage
            width={360}
            height={160}
            id={item?.thumbnailFileId}
          />
          {item?.fileName && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-03, 8px)",
                paddingTop: "12px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M9.33268 1.33398H3.99935C3.26602 1.33398 2.67268 1.93398 2.67268 2.66732L2.66602 13.334C2.66602 14.0673 3.25935 14.6673 3.99268 14.6673H11.9993C12.7327 14.6673 13.3327 14.0673 13.3327 13.334V5.33398L9.33268 1.33398ZM3.99935 13.334V2.66732H8.66602V6.00065H11.9993V13.334H3.99935Z"
                  fill="#0078A7"
                />
              </svg>
              <Typography
                sx={{
                  color: textColorLayer2,
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-XSmall, 12px)",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "var(--Line-Height-XSmall, 18px)",
                }}
              >
                {item?.fileName}
              </Typography>
            </Box>
          )}
          <ViewDocumentButton
            variant="outlined"
            sx={{
              marginLeft: isMobile ? "auto" : "0",
              my: "16px",
            }}
            onClick={() =>
              downloadFile(
                item?.fileId,
                item?.fileUrl,
                !!item?.fileId ? "DOCUMENT" : "LINK"
              )
            }
          >
            Open item
            <ArrowForwardIcon sx={{ height: "16px", width: "16px" }} />
          </ViewDocumentButton>
        </Box>
      ))}
    </Box>
  );
}

export default DeniedDetailArtifactManagement;
