// InternalPageLayout.js
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InternalHeader from "../InternalPages/InternalHeader";

import { useSelector } from "react-redux";
import TabContentView from "./TabContentView";
import LibraryFilter from "./LibraryFilter";

import ViewSummary from "../ViewSummary/ViewSummary";

export default function LibraryPageLayout({ pageData, header }) {
  const selectedView = useSelector((state) => state.view);
  // const searchText = useSelector((state) => state.internalPage.search);

  const [grid, setGrid] = useState(selectedView);
  const [pageHeader, setPageHeader] = useState(header);
  const [searchText, setSearchText] = useState(null);
  const [selectedSection, setSelection] = useState(null);

  const [prevPageData, setPrevPageData] = useState(pageData);
  const [filteredData, setFilteredData] = useState(pageData);

  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);

  useEffect(() => {
    if (searchText?.length > 0) {
      setFilteredData(searchItems(searchText));
    }
    else {
      setFilteredData(prevPageData);
    }
  }, [searchText]);

  const searchItems = (text) => {
    const result = pageData?.subjectItems?.filter((item) => {
      const subjectNameMatch = item.subjectName
        .toLowerCase()
        .includes(text.toLowerCase());

      const documentTitlesMatch = item.documents.some((doc) =>
        doc.title.toLowerCase().includes(text.toLowerCase())
      );

      const videoTitlesMatch = item.videos.some((video) =>
        video.title.toLowerCase().includes(text.toLowerCase())
      );

      const linkTitlesMatch = item.links.some((link) =>
        link.title.toLowerCase().includes(text.toLowerCase())
      );

      return (
        subjectNameMatch ||
        documentTitlesMatch ||
        videoTitlesMatch ||
        linkTitlesMatch
      );
    });

    return { subjectItems: [...result] };
  };

  const setSearchTextData = (data) => {
    console.log('setSearchTextData data', data);
    setTimeout(() => {
      setSearchText(data);
    }, 500)
  }

  console.log('Library LibraryPageLayout pageData', pageData);

  return (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <InternalHeader header={pageHeader} /> {/* Pass setSelection */}
      <Box sx={{ zIndex: 1000 }}>
        {/* Pass setSelection to InternalFilter */}
        <LibraryFilter
          setGrid={setGrid}
          pageData={pageData}
          header={header}
          grid={grid}
          setSearchTextData={setSearchTextData}
          setSelection={setSelection}
        />
      </Box>
      <TabContentView tabData={filteredData} pageName="Library" />
      <ViewSummary />
    </Box>
  );
}
