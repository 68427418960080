// indexedDB.js
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("imageCacheDB", 1);

    request.onupgradeneeded = (e) => {
      const db = e.target.result;
      if (!db.objectStoreNames.contains("images")) {
        db.createObjectStore("images", { keyPath: "id" });
      }
    };

    request.onerror = (e) => reject(e.target.error);
    request.onsuccess = (e) => resolve(e.target.result);
  });
};

const saveImageToDB = (id, blob) => {
  openDB().then((db) => {
    const transaction = db.transaction("images", "readwrite");
    const store = transaction.objectStore("images");
    store.put({ id, blob });
    transaction.oncomplete = () => console.log("Image saved to IndexedDB");
  });
};

const getImageFromDB = (id) => {
  return new Promise((resolve, reject) => {
    openDB().then((db) => {
      const transaction = db.transaction("images", "readonly");
      const store = transaction.objectStore("images");
      const request = store.get(id);

      request.onsuccess = (e) => resolve(e.target.result);
      request.onerror = (e) => reject(e.target.error);
    });
  });
};

export { saveImageToDB, getImageFromDB };
