import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
export default function CustomMessage({ message, success }) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "500px",
        maxWidth: "600px",
        maxHeight: "56px",
        padding: "0", // Ensure no padding
        alignItems: "center",
        gap: "8px",
        borderRadius: "4px",
        backgroundColor: success ? "#008553" : "#D40020", // Success background color

        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        {success ? (
          <CheckCircleOutlineOutlinedIcon data-testid="CheckCircleOutlineOutlinedIcon" />
        ) : (
          <ErrorOutlineOutlinedIcon data-testid="ErrorOutlineOutlinedIcon" />
        )}
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "var(--Colors-White, #FFF)", // Text color
            fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
            fontSize: "var(--Font-Size-Small, 14px)", // Font size
            fontStyle: "normal", // Font style
            fontWeight: 400, // Font weight
            lineHeight: "var(--Line-Height-Small, 20px)", // Line height
          }}
        >
          {message}
        </Typography>
      </Box>

      <CloseOutlinedIcon />
    </Box>
  );
}
