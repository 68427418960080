import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Box,
  Typography,
  IconButton,
  TextField,
  useMediaQuery
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { getActiveItems } from "../../utils/function";
import { Card, Dropdown } from "pepsico-ds";

const CROP_TYPE_OPTIONS = [
  { value: "type1", label: "Type 1" },
  { value: "type2", label: "Type 2" },
  { value: "type3", label: "Type 3" },
];

const StepThreeForm = ({ internalData, setInternalData }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [cropData,setCropData] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
useEffect(()=>{
  const fetchCropData = async () => {
    try {
      const response = await getAPI(
        `${BASE_URL}/lookup/all?lookUpType=CROP_TYPE`
      );
      if(response?.status === 200){
        const activeItems = getActiveItems(response?.data);
        setCropData(activeItems);
        console.log("crop type",activeItems)
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  fetchCropData()
},[])
  const handleChange = (selectedValues) => {
    const [value] = selectedValues;
    setInternalData((prevData) => ({
      ...prevData,
      cropTypeId: value?.id,
      selectedCropData: value,
    }));
  };
  const renderSelectOptions = (options) =>
    options.map((option) => (
      <MenuItem
        key={option.name}
        value={option.id}
        sx={{
          color: isDarkTheme
            ? darkTheme.textColorLayer1
            : lightTheme.textColorLayer1,
        }}
      >
        {option.name}
      </MenuItem>
    ));

  return (
    <Card
      ellipsis={false}
      isDark={isDarkTheme}
      icon=""
      title="Step 3: Keywords"
      style={{borderRadius: '8px'}}
    >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        width: "100%",
      }}
    >
      <FormControl sx={{ width: { xs: "300px", md: "400px" }, }}>
        {cropData && <Dropdown
          childList={cropData?.map((item) => ({id: item.id, displayText: item.name}))}
          isClearable
          label="Crop Type"
          selection="single"
          placeholder="Select Crop"
          setSelectedValue={handleChange}
          style={{fontSize: 'var(--Font-Size-small, 14px)'}}
          size="medium"
          selectedValue={internalData?.selectedCropData ? [internalData.selectedCropData] : []}
        />}
      </FormControl>

      <Box>
        <Typography
         fontSize='.75rem'
         fontFamily='inherit'
         fontWeight= '18px'
          sx={{ 
          color: useThemeRender("textColorLayer4")         
         }}>
          Keywords
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          sx={{
            width: { xs: "300px", md: "405px" },
            padding: "8px 0px 8px 8px",
            borderRadius: "4px",
            ml:-1,
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer2
              : lightTheme.borderColorLayer2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorHover
                  : lightTheme.borderColorHover,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorFocus
                  : lightTheme.borderColorFocus,
              },
            },
          }}
          value={internalData.keywords || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue.length <= 600) {
              setInternalData((prevData) => ({
                ...prevData,
                keywords: newValue,
              }));
            }
          }}
          placeholder="type here"
          variant="outlined"
          helperText={`${internalData.keywords?.length}/600`}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: "8px",
              right: "8px",
              color: isDarkTheme
                ? darkTheme.textColorLayer4
                : lightTheme.textColorLayer4,
              fontSize: "12px",
            },
          }}
        />

        <Box display="flex" alignItems="center">
          <Tooltip title="Note: Separate each keyword by a comma.">
            <IconButton sx={{padding: '0px 4px 0px 0px'}}>
              <InfoOutlinedIcon
                sx={{
                  color: isDarkTheme
                    ? darkTheme.iconColor2
                    : lightTheme.iconColor1,
                    fontSize: '16px'
                }}
                
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer4,
                fontFamily: 'inherit',
                // fontSize: isMobile ? '10px' : '12px',
            }}
          >
            Note: Separate each keyword by a comma.
          </Typography>
        </Box>
      </Box>
    </Box>
    </Card>
  );
};
export default StepThreeForm;
