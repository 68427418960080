import { Box, Button, Card, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeRender from "../../theme/useThemeRender";
import "./FritoForumDetailPage.css";
import ViewSummary from "../ViewSummary/ViewSummary";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import { getAPI, postAPI } from "../../API/ApiService";
import ForumSubDetailPage from "./ForumSubDetailPage";
import { useSelector, useDispatch } from "react-redux";
import InternalCarousal from "../InternalPages/Carousal-ExpandedView/InternalCarousal";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { showErrorToast } from "../../utils/Toast/toast";
import {
  clearFritoImages,
  setFritoImage,
} from "../../slices/fritoForumPageSlice";
import fetchAndCacheImage from "../../utils/fetchandCatchImages";
import Loading from "../../utils/Loader";
import { handleShare } from "../../utils/function";
function FritoForumDetailPage() {
  const location = useLocation([]);
  const { id } = useParams();
  const forumCardData = location.state;
  const [data, setData] = useState();
  const documentData = data?.documents || [];
  const videoData = data?.videos || [];
  const linksData = data?.links || [];
  const [loading, setLoading] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;
  const { favourite } = useSelector((store) => store.internalPage);
  const [fetch, setFetch] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleMoveBack = () => {
    navigate("/frito-forum-main-page");
  };
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const backgroundColorLayer1 = useThemeRender("backgroundColorLayer1");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const handleLike = async () => {
    if (isLiked) {
      const data = {
        id: forumCardData?.id,
        likes: false,
        dislikes: false,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/forum/save-forum-metrics`,
          data
        );
        if (response.status === 200) {
          console.log(response, "--dislikedResponse----------");
          setIsLiked(false); // Remove dislike
          setFetch(!fetch);
        }
      } catch {}
    } else {
      const newData = {
        id: forumCardData?.id,
        likes: true,
        dislikes: false,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/forum/save-forum-metrics`,
          newData
        );
        if (response?.status === 200) {
          console.log(response, "------response--------");
          setIsLiked(true); // Set like
          setIsDisliked(false); // Remove dislike (toggle logic)
          setFetch(!fetch);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDislike = async () => {
    if (isDisliked) {
      const data = {
        id: forumCardData?.id,
        likes: false,
        dislikes: false,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/forum/save-forum-metrics`,
          data
        );
        if (response.status === 200) {
          console.log(response, "--dislikedResponse----------");
          setIsDisliked(false); // Remove dislike

          setFetch(!fetch);
        }
      } catch {}
    } else {
      const newData = {
        id: forumCardData?.id,
        likes: false,
        dislikes: true,
      };
      try {
        const response = await postAPI(
          `${BASE_URL}/forum/save-forum-metrics`,
          newData
        );
        if (response.status === 200) {
          setIsDisliked(true); // Set dislike
          setIsLiked(false); // Remove like (toggle logic)
          setFetch(!fetch);
        }
        if (response?.status === 400) {
          showErrorToast(response?.message);
        }
      } catch (error) {
        console.log(error, "-----err-------");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/forum/find-by-forum-id/${id}`
        );
        if (response?.status === 200) {
          console.log(response, "response forum----");
          dispatch(clearFritoImages());
          await fetchAndCacheImage(
            response.data?.response?.thumbnailFileId,
            setFritoImage,
            dispatch
          );

          setData(response?.data?.response);
          setIsLiked(response?.data?.response?.liked);
          setIsDisliked(response?.data?.response?.disliked);
        }
      } catch (err) {
        showErrorToast(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [favourite, dispatch, forumCardData, fetch]);

  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        background: backgroundColorLayer1,
        padding: {
          xs: "0px",
          sm: " 0px 30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "40px",
          padding: "var(--Spacing-spacing-03, 8px) 0px",
          justifyContent: "center",
          alignItems: "center",
          gap: "var(--Spacing-spacing-03, 8px)",
          margin: {
            xs: "20px 10px 0px",
            sm: "20px 0px 0px",
            md: "16px 0px",
          },
          cursor: "pointer",
        }}
        onClick={handleMoveBack}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            fill="#005CBC"
          />
        </svg>
        <Typography
          sx={{
            color: "var(--Tokens-Button-Borderless, #005CBC)",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Body, 16px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-Body, 22px)",
          }}
        >
          Back to Frito forum
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
          width: "100%",
        }}
      >
        <Card
          className="SummaryCard"
          sx={{
            background: backgroundColorLayer3,
            width: "100%",
          }}
        >
          <Typography
            className="SummaryTitle"
            sx={{
              color: textColorLayer2,
              fontSize: "25px !important",
            }}
          >
            {forumCardData?.title}
          </Typography>
          <Box
            className="SummaryContentBox"
            sx={{
              background: backgroundColorLayer1,
            }}
          >
            <Typography
              className="ForumSummaryContents"
              sx={{
                color: textColorLayer2,
                width: {
                  xs: "100%",
                  md: "80%",
                },
                padding: "12px",
              }}
            >
              {data?.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-16, 16px)",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleShare(location)}
              >
                <Button className="LogoButton">
                  <Typography className="LogoContent">Share</Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15 13.3998C14.3667 13.3998 13.8 13.6498 13.3667 14.0415L7.425 10.5832C7.46667 10.3915 7.5 10.1998 7.5 9.99984C7.5 9.79984 7.46667 9.60817 7.425 9.4165L13.3 5.9915C13.75 6.40817 14.3417 6.6665 15 6.6665C16.3833 6.6665 17.5 5.54984 17.5 4.1665C17.5 2.78317 16.3833 1.6665 15 1.6665C13.6167 1.6665 12.5 2.78317 12.5 4.1665C12.5 4.3665 12.5333 4.55817 12.575 4.74984L6.7 8.17484C6.25 7.75817 5.65833 7.49984 5 7.49984C3.61667 7.49984 2.5 8.6165 2.5 9.99984C2.5 11.3832 3.61667 12.4998 5 12.4998C5.65833 12.4998 6.25 12.2415 6.7 11.8248L12.6333 15.2915C12.5917 15.4665 12.5667 15.6498 12.5667 15.8332C12.5667 17.1748 13.6583 18.2665 15 18.2665C16.3417 18.2665 17.4333 17.1748 17.4333 15.8332C17.4333 14.4915 16.3417 13.3998 15 13.3998ZM15 3.33317C15.4583 3.33317 15.8333 3.70817 15.8333 4.1665C15.8333 4.62484 15.4583 4.99984 15 4.99984C14.5417 4.99984 14.1667 4.62484 14.1667 4.1665C14.1667 3.70817 14.5417 3.33317 15 3.33317ZM5 10.8332C4.54167 10.8332 4.16667 10.4582 4.16667 9.99984C4.16667 9.5415 4.54167 9.1665 5 9.1665C5.45833 9.1665 5.83333 9.5415 5.83333 9.99984C5.83333 10.4582 5.45833 10.8332 5 10.8332ZM15 16.6832C14.5417 16.6832 14.1667 16.3082 14.1667 15.8498C14.1667 15.3915 14.5417 15.0165 15 15.0165C15.4583 15.0165 15.8333 15.3915 15.8333 15.8498C15.8333 16.3082 15.4583 16.6832 15 16.6832Z"
                      fill="#005CBC"
                    />
                  </svg>
                  <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    autoHideDuration={1000}
                    onClose={handleClose}
                    sx={{
                      "& .MuiSnackbarContent-root": {
                        maxWidth: "200px",
                      },
                    }}
                    message={
                      <span style={{ textTransform: "none" }}>Link copied</span>
                    }
                    key={vertical + horizontal}
                  />
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button className="LogoButton" onClick={handleLike}>
                  <Typography className="LogoContent">Like</Typography>
                  {isLiked ? (
                    <ThumbUpIcon
                      style={{
                        color: "#005CBC",
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    <ThumbUpOutlinedIcon
                      style={{
                        color: "#005CBC",
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button className="LogoButton" onClick={handleDislike}>
                  <Typography className="LogoContent">Dislike</Typography>
                  {isDisliked ? (
                    <ThumbDownAltIcon
                      style={{
                        color: "#005CBC",
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    <ThumbDownOffAltOutlinedIcon
                      style={{
                        color: "#005CBC",
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
          <Typography
            className="SummaryTitle"
            sx={{
              color: textColorLayer2,
              fontSize: "25px !important",
            }}
          >
            Details
          </Typography>
          <ForumSubDetailPage data={data} />
          <Typography
            className="SummaryTitle"
            sx={{
              color: textColorLayer2,
              fontSize: "25px !important",
            }}
          >
            Disclaimer
          </Typography>
          <Box
            className="SummaryContentBox"
            sx={{
              background: backgroundColorLayer1,
              padding: "12px",
            }}
          >
            <Typography
              className="ForumSummaryContents"
              sx={{
                color: textColorLayer2,
                width: {
                  xs: "100%",
                  md: "80%",
                },
              }}
            >
              {data?.disclaimerText}
            </Typography>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          color: textColorLayer2,
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
          marginLeft: "15px",
          mb: "10px",
        }}
      >
        Associated Content
      </Box>
      {/* {documentData?.length > 0 && ( */}
      <Box
        sx={{
          backgroundColor: backgroundColorLayer3,
        }}
        className="curosalBackground"
      >
        <InternalCarousal
          data={documentData}
          content={"Documents"}
          flexDirection={"column"}
          heading={"Documents"}
          isHeadingBig={false}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: backgroundColorLayer3,
        }}
        className="curosalBackground"
      >
        <InternalCarousal
          data={videoData}
          content={"Videos"}
          flexDirection={"column"}
          heading={"Videos"}
          isHeadingBig={false}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: backgroundColorLayer3,
          marginBottom: "16px",
        }}
        className="curosalBackground"
      >
        <InternalCarousal
          data={linksData}
          content={"Links"}
          flexDirection={"column"}
          heading={"Links"}
          isHeadingBig={false}
        />
      </Box>

      <ViewSummary />
    </Box>
  );
}

export default FritoForumDetailPage;
