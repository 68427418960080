import React from "react";

import { ButtonHierarchyUtility as ButtonHierarchy } from "pepsico-ds";
const ActionButtons = ({ onCancel, onSave, disabled }) => {
  console.log(disabled, "---actionDisable-----");

  return (
    <ButtonHierarchy
      auxiliary={false}
      style={{ paddingTop: "12px" }}
      items={[
        {
          buttonType: "primary",
          onClick: onSave, // Your functions here
          text: "Save",
          disabled: disabled,
        },
        {
          buttonType: "secondary",
          onClick: onCancel, // Your functions here
          text: "Cancel",
        },
      ]}
      primary
      secondary
      size="medium"
      tertairy={false}
      type="primary"
    />
  );
};

export default ActionButtons;
