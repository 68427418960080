import React, { useEffect, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import { getAllSubjectSectorCountryList } from "../../API/curatorApi";
import { setDisableNextButton } from "../../slices/adminPageSlice";
import { getDropDownOptionValues, getCountryList } from "../../utils/function";
import { Card, TextInput, Radio, Dropdown } from "pepsico-ds";
import { useMediaQuery } from "@mui/material";
import { CropTypeWrapper } from "../Curator/Components/curatorApprovalStyle";
import useThemeRender from "../../theme/useThemeRender";
import "./StepTwoForm.css"

const StepTwoForm = ({ internalData, setInternalData, handleDropDown }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [sectorData, setSectorData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [secotrByCountrylist, setSectorByCountryList] = useState([]);
  const [visibilityData, setvisibilityData] = useState([]);
  const [specialProjectData, setSpecialProjectData] = useState([]);
  const [contentLevels, setContentLevels] = useState();
  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    sectorFilter,
    subjectFilter,
    visibility,
    contentLevel,
    specialProjects,
  } = useSelector((store) => store.internalFilter);

  const dispatch = useDispatch();
  useEffect(() => {
    getAllSubjectSectorCountryList()
      .then((data) => {
        console.log("counry based on sector list", data);
        setSectorByCountryList(data);
      })
      .catch((error) => {
        console.log("counry based on sector list error", error);
      });
    setSectorData(getDropDownOptionValues(sectorFilter));
    setSubjectData(getDropDownOptionValues(subjectFilter, true));
    setvisibilityData(getDropDownOptionValues(visibility));
    setSpecialProjectData(getDropDownOptionValues(specialProjects));
    setContentLevels(getDropDownOptionValues(contentLevel));
    console.log("visibility", visibility);
    const visibilityId = visibility?.filter(
      (item) => item?.name === "External" && item?.id
    );
    setInternalData((prevData) => ({
      ...prevData,
      visibilityId: visibilityId?.[0].id,
    }));
  }, []);
  const handleSelect = (selectedItem, catagory) => {
    const ids = selectedItem ? selectedItem.map((item) => item.id) : [];
    if (catagory === "sectorIds" && ids.length > 0) {
      const countryList = getCountryList(ids, secotrByCountrylist);
      console.log('countryList', countryList);
      setCountryData(getDropDownOptionValues(countryList, true));
    } else if (catagory === "sectorIds" && ids.length === 0) {
      setCountryData([]);
    }
    handleDropDown(catagory, ids);
  };
  const [selectedSectorData, setSelectedSectorData] = useState([]);
  const [selectedCountryData, setSelectedCountryData] = useState([]);
  const [selectedSubjectData, setSelectedSubjectData] = useState([]);
  const [selectedSpecialProjectData, setSelectedSpecialProjectData] = useState(
    []
  );
  const [selectedVisibilityIndex, setSelectedVisibilityIndex] = useState(0);

  useEffect(() => {
    if (countryData.length === 0 && internalData?.sectorIds?.length > 0 && secotrByCountrylist?.length > 0) {
      getCountryList(internalData?.sectorIds);
       const countryList = getCountryList(internalData?.sectorIds, secotrByCountrylist);
       setCountryData(getDropDownOptionValues(countryList, true));
    }
  }, [secotrByCountrylist]);

  console.log("countryData", countryData);
  useEffect(() => {
    const selectedData = sectorData?.filter((item) =>
      internalData?.sectorIds?.includes(item?.id)
    );
    setSelectedSectorData(selectedData);
  }, [internalData?.sectorIds, sectorData]);
  useEffect(() => {
    const selectedData = specialProjectData?.filter((item) =>
      internalData.specailProjects?.includes(item?.id)
    );
    setSelectedSpecialProjectData(selectedData);
  }, [internalData.specailProjects, specialProjectData]);
  useEffect(() => {
    const selectedSubjectData = subjectData?.filter((item) =>
      internalData?.subjectIds?.includes(item?.id)
    );
    setSelectedSubjectData(selectedSubjectData);
  }, [internalData?.subjectIds, subjectData]);
  useEffect(() => {
    const selectedData = countryData?.filter((item) =>
      internalData?.countryIds?.includes(item?.id)
    );
    setSelectedCountryData(selectedData);
  }, [internalData?.countryIds, countryData]);

  const handleTitleChange = (value) => {
    setInternalData((prevData) => ({
      ...prevData,
      contentTitle: value,
    }));
  };

  const handleVisibilityChange = (index, value) => {
    setInternalData((prevData) => ({
      ...prevData,
      visibilityId: Number(visibilityData[index]?.id),
    }));
    setSelectedVisibilityIndex(index);
  };
  const handleContentLevelChange = (index, value) => {
    setInternalData((prevData) => ({
      ...prevData,
      contentLevelId: Number(contentLevels[index]?.id),
      selectedContentIndex: index,
    }));
  };

  useEffect(() => {
    if (
      (internalData?.subjectIds?.length ?? 0) > 0 &&
      (internalData?.countryIds?.length ?? 0) > 0 &&
      (internalData?.sectorIds?.length ?? 0) > 0 &&
      (internalData?.contentTitle?.length ?? 0) > 0
    ) {
      dispatch(setDisableNextButton(false));
    } else {
      dispatch(setDisableNextButton(true));
    }
  }, [
    internalData?.subjectIds,
    internalData?.countryIds,
    internalData?.sectorIds,
    internalData.contentTitle,
    internalData?.visibilityId,
    dispatch,
  ]);

  useEffect(() => {
    if (
      (internalData?.subjectIds?.length === 0 || !internalData?.subjectIds) &&
      (internalData?.countryIds?.length === 0 || !internalData?.countryIds) &&
      (internalData?.sectorIds?.length === 0 || !internalData?.sectorIds) &&
      (internalData?.visibilityId?.length === 0 ||
        !internalData?.visibilityId) &&
      (internalData?.contentTitle?.length === 0 || !internalData?.contentTitle)
    ) {
      dispatch(setDisableNextButton(true));
    }
  }, []);

  // const getAllSubjectSectorCountryList = async () => {
  //   try {
  //     await getAPI(`${BASE_URL}/lookup/find-all-sector-country`)
  //       .then((response) => response?.data)
  //       .then((data) => {
  //         console.log("counry based on sector list", data?.response);
  //         setSectorByCountryList(data?.response?.sectors);
  //       })
  //       .catch((error) => {
  //         console.log("counry based on sector list error", error);
  //       })
  //       .finally(() => {
  //         console.log("Inside the finally block");
  //       });
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  // const getCountryList = (sectorIds) => {
  //   const selectedCountryList = [];
  //   let countryList = [];
  //   sectorIds?.forEach((item) => {
  //     secotrByCountrylist.forEach((countryData) => {
  //       if (countryData?.id === item) {
  //         selectedCountryList.push(countryData);
  //       }
  //     });
  //   });

  //   selectedCountryList?.forEach((item) => {
  //     countryList.push(...item?.countries);
  //   });
  //   setCountryData(getDropDownOptionValues(countryList, true));
  // };

  const shouldShowSpecialProjects = () => {
    let seletedVisibility = [];
    seletedVisibility = visibility?.filter(
      (item) => item?.id === parseInt(internalData?.visibilityId)
    );
    return seletedVisibility?.[0]?.name === "Special projects" &&
      specialProjectData?.length > 0
      ? true
      : false;
  };

  return (
    <Card
      ellipsis={false}
      isDark={isDarkTheme}
      icon=""
      title="Step 2: Metadata"
      style={{ borderRadius: "8px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
        }}
      >
        <Box>
          <TextInput
            label="Content title"
            value={internalData?.contentTitle}
            onUpdate={handleTitleChange}
            placeholderText="Add Title"
            isDark={isDarkTheme}
            inputSize="medium"
            style={{ fontSize: "var(--Font-Size-small, 14px)"}}
            // required={true}
          />
        </Box>

        <Box>
          <FormControl component="fieldset">
            {visibilityData?.length > 0 && (
              <Radio
                items={visibilityData?.map((item) => item.name)}
                label="Visibility"
                orientation={isMobile ? "vertical" : "horizontal"}
                reference="radio button"
                showText
                size="small"
                onUpdate={(index, value) =>
                  handleVisibilityChange(index, value)
                }
                selectedItemIndex={selectedVisibilityIndex}
                isDark={isDarkTheme}
                style={{ margin: "8px 0" }}
              />
            )}
          </FormControl>
        </Box>
        <CropTypeWrapper
          sx={{
            "& .label-element": {
              color: useThemeRender("textColorLayer2"),
            },
          }}
        >
         {shouldShowSpecialProjects() && (
          <Dropdown
            childList={specialProjectData.map((item) => ({
              id: item.id,
              displayText: item.name,
            }))}
            isClearable
            label="Special projects"
            selection="multiple"
            setSelectedValue={(values) =>
              handleSelect(values, "specailProjects")
            }
            size={isMobile ? "small" : "medium"}
            sx={{
              fontFamily: "var(--Typography-Font-Family-Body, Inter)",
              fontSize: "var(--Font-Size-XSmall, 12px)",
              fontStyle: "normal",
              fontWeight: 400,
              width: "100%",
              lineHeight: "var(--Line-Height-XSmall, 18px)",
            }}
            style={{ fontSize: "var(--Font-Size-Small, 14px)" }}
            selectedValue={selectedSpecialProjectData?.map((item) => ({
              id: item.id,
              displayText: item.name,
            }))}
            isDark={isDarkTheme}
          />
        )}
        </CropTypeWrapper>
        <CropTypeWrapper
          sx={{
            "& .label-element": {
              color: useThemeRender("textColorLayer2"),
            },
          }}
        >
          {subjectData && (
            <Dropdown
              childList={subjectData.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isClearable
              isRequired
              label="Subjects"
              selection="multiple"
              setSelectedValue={(values) => handleSelect(values, "subjectIds")}
              size={isMobile ? "small" : "medium"}
              sx={{
                fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                fontSize: "var(--Font-Size-XSmall, 12px)",
                fontStyle: "normal",
                fontWeight: 400,
                width: "100%",
                lineHeight: "var(--Line-Height-XSmall, 18px)",
              }}
              style={{ fontSize: "var(--Font-Size-Small, 14px)" }}
              selectedValue={selectedSubjectData?.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isDark={isDarkTheme}
            />
          )}
        </CropTypeWrapper>
        <CropTypeWrapper
          sx={{
            "& .label-element": {
              color: useThemeRender("textColorLayer2"),
            },
          }}
        >
          {sectorData && (
            <Dropdown
              childList={sectorData.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isClearable
              isRequired
              label="Sectors"
              selection="multiple"
              setSelectedValue={(values) => handleSelect(values, "sectorIds")}
              size={isMobile ? "small" : "medium"}
              style={{ fontSize: "var(--Font-Size-small, 14px)" }}
              selectedValue={selectedSectorData?.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isDark={isDarkTheme}
            />
          )}
        </CropTypeWrapper>
        <CropTypeWrapper
          sx={{
            "& .label-element": {
              color: useThemeRender("textColorLayer2"),
            },
          }}
        >
          {countryData && (
            <Dropdown
              childList={countryData.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isClearable
              isRequired
              label="Countries"
              selection="multiple"
              setSelectedValue={(values) => handleSelect(values, "countryIds")}
              size={isMobile ? "small" : "medium"}
              style={{
                fontSize: "var(--Font-Size-small, 14px)",
                //  width: "inherit"
              }}
              selectedValue={selectedCountryData?.map((item) => ({
                id: item.id,
                displayText: item.name,
              }))}
              isDark={isDarkTheme}
            />
          )}
        </CropTypeWrapper>
        <Box>
          <FormControl component="fieldset" fullWidth>
            {contentLevels?.length > 0 && (
              <Radio
                items={contentLevels?.map((item) => item.name)}
                label="Content Level"
                orientation={isMobile ? "vertical" : "horizontal"}
                reference="radio button"
                showText
                size="small"
                onUpdate={(index, value) =>
                  handleContentLevelChange(index, value)
                }
                selectedItemIndex={internalData?.selectedContentIndex ?? 0}
                isDark={isDarkTheme}
                style={{ margin: "8px 0" }}
              />
            )}
          </FormControl>
        </Box>
      </Box>
    </Card>
  );
};

export default StepTwoForm;
