import React from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../../slices/adminPageSlice";

import { Dropdown as CustomDropdown } from "pepsico-ds";
import "../admin-common.css";

export default function DropDown({ datatestid }) {
  const dispatch = useDispatch();
  const options = [
    "Subject matter experts",
    "Send invitation: external partners",
    "Send invitation: internal users",
    "Access: invitation history",
    "In the spotlight",
    "Subjects",
    "Landing page hero",
    "Access: pending approvals",
    "Access",
    "Special projects",
    "List of values",
  ]
    .sort((a, b) => a.localeCompare(b))
    .map((item, idx) => ({ id: idx + 1, displayText: item }));
  const isMobile = useMediaQuery("(max-width:600px)");
  const { selectedPage } = useSelector((store) => store.adminpage);
  const HandleChange = (event, newValue) => {
    newValue && dispatch(setPage(newValue));
  };
  const width = "234px";
  const isDarkTheme = useSelector((state) => state.theme);
  const sp = options.filter((o) => o.displayText === selectedPage);
  console.log(selectedPage, "--------selectedPage---------");

  return (
    <CustomDropdown
      label="Administration type"
      childList={options}
      isDark={isDarkTheme}
      setSelectedValue={(e) => HandleChange(e, e && e[0]?.displayText)}
      className="admin-dropdown"
      selectedValue={
        options.filter((item) => item.displayText === selectedPage) || []
      }
      isClearable={false}
      placeholder="Select One"
      datatestid={datatestid}
      value={selectedPage}
    />
  );
}
